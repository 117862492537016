import React, { useEffect, useState } from 'react';

import * as Yup from 'yup'
import { useFormik } from 'formik';

import { taxRateService } from 'services/tax.service';
import { closeModal, doDateFormatWithTime, getSortIcon, handleFilters, routes, serialNumber, strings } from 'utils';
import { _Object } from 'utils/interfaces';
import { Breadcrumbs, Button, InputField, LinkButton, Pagination, ReportExport, SEOHeader, SearchField } from 'views/components';
import { Link } from 'react-router-dom';

const TaxRates = () => {

  const [id, setId] = useState('')
  const [loading, setLoading] = useState<_Object>(
    {
      page: false,
      btn: false,
      edit: false,
    });

  const [taxRates, setTaxRates] = useState<_Object>({
    items: []
  })
  const [initialData, setInitialData] = useState<_Object>({})
  const [filterData, setFilterData] = useState<_Object>({
    page: 1,
    sort: '-created_at',
    per_page: process.env.REACT_APP_PER_PAGE,
  })

  const handleCallApi = () => {
    setLoading({ ...loading, page: true });
    taxRateService.getTaxRates({ ...filterData, q: searchFormik.values.q }).then((data: any) => {
      setTaxRates(data)
      setLoading({ ...loading, page: false });
    })
  }

  const searchFormik = useFormik({
    initialValues: { q: '' },
    enableReinitialize: true,
    onSubmit: () => {
      if (searchFormik.values.q.trim() !== '') {
        handleCallApi()
      }
    }
  });

  useEffect(() => {
    if (searchFormik.values.q === '') {
      handleCallApi()
    }
  }, [filterData, searchFormik.values.q])

  useEffect(() => {
    if (id) {
      setInitialData({
        title: '',
        rate: '',
        status: 'active',
      })
      setLoading({ ...loading, edit: true });
      taxRateService.getTaxRatesDetails(id).then((data) => {
        setInitialData(data)
        setLoading({ ...loading, edit: false });
      })
    }
  }, [id])


  const formik = useFormik({
    initialValues: initialData,
    enableReinitialize: true,

    validationSchema: Yup.object({
      title: Yup.string().label('Tax').required(),
      rate: Yup.number()
        .label('Rate')
        .required()
        .min(1, 'Rate must be greater than or equal to 1')
        .test('decimal-places', 'Rate must have at most 2 decimal places', value => {
          if (!value) return true;
          const decimalPlaces = (value.toString().split('.')[1] || []).length;
          return decimalPlaces <= 2;
        })
    }),

    onSubmit: (values) => {
      setLoading({ ...loading, btn: true });

      if (id) {
        delete values.id,
          delete values.created_at
        delete values.created_by
        delete values.updated_at

        taxRateService.updateTaxRates(id, values).then((data: _Object) => {
          if (data.error === false) {
            setLoading({ ...loading })
            handleCallApi()
            formik.resetForm();
            closeModal('addTaxExpensesModal');
            setId('')
          } else {
            setLoading({ ...loading, btn: false })
            throw new Error(data.error);
          }
        }).catch((error) => {
          error && setLoading({ btn: false })
        })
      } else {
        values.status = 'active'
        taxRateService.createTaxRates(values).then((data: _Object) => {
          if (data.error === false) {
            setLoading({ ...loading, btn: true })
            handleCallApi()
            formik.resetForm();
            setId('')
            closeModal('addTaxExpensesModal');
          } else {
            setLoading({ ...loading, btn: false })
            throw new Error(data.error);
          }
        }).catch((error) => {
          error && setLoading({ btn: false })
        })
      }
    }
  })

  const deleteItem = async (id: string) => {
    if (window.confirm('Are you sure to delete this item?')) {
      setLoading({ ...loading, page: true })
      await taxRateService.deleteTaxRates(id).then(() => {
        handleCallApi()
        setLoading({ ...loading, page: false })
      })
    }
  }

  return (
    <>
    <SEOHeader title="Tax-rates" />
      <Breadcrumbs
        trails={[
          {
            label: 'Products',
            path: `${routes.products}`
          },
          {
            label: 'Tax rates',
            path: ''
          }
        ]}
      />

      <div className="pos-justify pos-between pos-align pos-center">
        <div className="d-flex header-loading">
          <form onSubmit={searchFormik.handleSubmit}>
            <SearchField
              type="search"
              name="q"
              className="mb-md-0"
              disabled={!searchFormik.values.q}
              value={searchFormik.values.q}
              onChange={(e: _Object) => searchFormik.setFieldValue('q', e.target.value)}
            />
          </form>
          <span className={`ms-4 ${loading.page === true ? 'is-loading' : ''}`} />
        </div>

        <span className="d-flex align-items-center gap-3">
          <Link
            to="#"
            className="primary text-decoration-none"
            data-bs-toggle="modal" data-bs-target="#export-report"
          >
            Export report
          </Link>

          <button type="button"
            onClick={() => {
              formik.resetForm(); setId(''); setInitialData({
                name: '',
                rate: '',
                status: '',
              })
            }}
            className="btn btn-primary"
            data-bs-toggle="modal"
            data-bs-target="#addTaxExpensesModal">
            <i className="fa-solid fa-plus text-white"></i>
            Add new
          </button>
        </span>

        {/* Modal */}
        <div className="modal fade" id="addTaxExpensesModal" tabIndex={-1} aria-labelledby="addTaxExpensesModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <form className={`modal-content ${loading.edit === true ? 'is-loading' : ''}`} onSubmit={formik.handleSubmit}>
              <div className="modal-header">
                <aside>
                  <h4 className="modal-title" id="addTaxExpensesModalLabel">{id ? 'Update tax' : 'New tax'}</h4>
                </aside>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>

              <div className="modal-body">
                <InputField
                  name="title"
                  label="Title"
                  required={true}
                  onBlur={formik.handleBlur}
                  value={formik?.values?.title}
                  onChange={formik.handleChange}
                  error={formik.touched.title && formik.errors.title}
                />

                <InputField
                  name="rate"
                  label="Rate"
                  required={true}
                  value={formik.values.rate}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={formik.touched.rate && formik.errors.rate}
                />
              </div>

              <div className="modal-footer">
                <LinkButton path={`${routes.taxRates}`} label="Cancel" data-bs-dismiss="modal" className="link text-danger" />
                <Button type="submit" className="primary" name={id ? 'Update' : 'Submit'} loading={loading.btn} />
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="card border-0 my-3">
        <div className="table-responsive">
          <table className="card-body mb-0 table table-borderless table-striped">
            <thead>
              <tr className="bg-white">
                <th >#</th>
                <th>
                  <button
                    className="btn p-0 border-0"
                    onClick={() => { handleFilters('sort', filterData.sort === '-name' ? 'name' : '-name', setFilterData) }}>
                    Title
                    {getSortIcon(filterData.sort.includes('name') ? filterData.sort : '')}
                  </button>
                </th>

                <th>Rate</th>

                <th>
                  <button
                    className="btn p-0 border-0"
                    onClick={() => { handleFilters('sort', filterData.sort === '-created_at' ? 'created_at' : '-created_at', setFilterData) }}>
                    Create date
                    {getSortIcon(filterData.sort.includes('created_at') ? filterData.sort : '')}
                  </button>
                </th>

                <th>
                  <button
                    className="btn p-0 border-0"
                    onClick={() => { handleFilters('sort', filterData.sort === '-updated_at' ? 'updated_at' : '-updated_at', setFilterData) }}>
                    Update date
                    {getSortIcon(filterData.sort.includes('updated_at') ? filterData.sort : '')}
                  </button>
                </th>

                <th className="action">Action</th>
              </tr>
            </thead>

            <tbody>
              {taxRates?.items?.map((item: _Object, i: number) => {
                return (
                  <tr key={i} className="align-middle" >
                    <td>{serialNumber(filterData.per_page, taxRates?.pagination?.current_page, i)}</td>
                    <td className="text-uppercase">{item.title}</td>
                    <td>{item.rate || '-'}%</td>
                    <td>{doDateFormatWithTime(item.created_at)}</td>
                    <td>{doDateFormatWithTime(item.updated_at)}</td>
                    <td>
                      <ul className="list-inline">
                        <li className="list-inline-item">
                          <button type="button"
                            onClick={() => setId(item.id)}
                            id="addTaxExpensesModal"
                            data-bs-toggle="modal"
                            className="btn btn-transparent p-0 border-0"
                            data-bs-target="#addTaxExpensesModal">
                            <i className="fa fa-pen"></i>
                          </button>
                        </li>
                        <li className="list-inline-item">
                          <Button type="button" onClick={() => deleteItem(item.id)} className="link text-danger p-0">
                            <i className="fa fa-trash text-danger"></i>
                          </Button>
                        </li>
                      </ul>

                    </td>
                  </tr>
                )
              })}

              {loading.page == false && taxRates?.items?.length == 0 && <tr>
                <td colSpan={6} className="text-center no-entry">
                  <aside>
                    <i className="fa-solid fa-ban"></i>
                    {strings.noRecordFound}
                  </aside>
                </td>
              </tr>
              }
            </tbody>
          </table>
        </div>
      </div >

      {taxRates?.pagination?.total_pages > 1 &&
        <Pagination
          current_page={taxRates?.pagination?.current_page}
          per_page={taxRates?.pagination?.per_page}
          total_pages={taxRates?.pagination?.total_pages}
          total_items={taxRates?.pagination?.total_items}
          onClick={(i: { [key: string]: number }) =>
            setFilterData((prev) => ({
              ...prev,
              page: i.selected + 1,
            }))
          }
        />
      }

      <ReportExport
        title="Export report tax"
        type="tax"
      />
    </>
  )
}
export default TaxRates