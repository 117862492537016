import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as Yup from 'yup'
import { useFormik } from 'formik';

import { locationService } from 'services';
import { routes, serialNumber, doDateFormatWithTime, strings, handleFilters, closeModal, getSortIcon } from 'utils';
import { _Object } from 'utils/interfaces';
import { SearchField, Button, LinkButton, Pagination, SelectField, InputField, RadioButton, Breadcrumbs, TextArea, SEOHeader, ActiveFilters } from 'views/components';

const Locations = () => {
  const { t } = useTranslation()

  const [id, setId] = useState('')
  const [loading, setLoading] = useState<_Object>(
    {
      listing: false,
      edit: false,
      update: false
    });
  const [locations, setLocations] = useState<_Object>([])
  const [filterdLabel, setFilteredLabel] = useState<_Object>({})
  const [initialData, setInitialData] = useState<_Object>({})
  const [filterData, setFilterData] = useState<_Object>({
    page: 1,
    per_page: process.env.REACT_APP_PER_PAGE,
    sort: '-created_at',
    filter: {
      type: '',
    }
  })

  const handleCallApi = () => {
    setLoading({ listing: true });
    locationService.getLocations({ ...filterData, q: searchFormik.values.q }).then((data: _Object) => {
      setLocations(data)
      setLoading({ listing: false });
    })
  }

  const searchFormik = useFormik({
    initialValues: { q: '' },
    enableReinitialize: true,
    onSubmit: () => {
      if (searchFormik.values.q.trim() !== '') {
        handleCallApi()
      }
    }
  })

  useEffect(() => {
    if (searchFormik.values.q === '') {
      handleCallApi()
    }
  }, [filterData, searchFormik.values.q])

  useEffect(() => {
    setInitialData({
      name: '',
      type: '',
      address: '',
    })
    if (id) {
      setLoading({ edit: true })
      locationService.getLocationDetails(id).then((data) => {
        setInitialData(data)
        setLoading({ edit: false });
      })
    }
  }, [id])

  const formik = useFormik({
    initialValues: initialData,
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string().label('Name').required(),
      type: Yup.string().label('Location type').required(),
    }),

    onSubmit: (values) => {
      setLoading({ update: true });

      if (id) {
        delete values.id,
          delete values.created_at
        delete values.updated_at

        locationService.updateLocation(id, values).then((data: _Object) => {

          if (data.error === false) {
            setLoading({ update: true, listing: true })
            handleCallApi();
            formik.resetForm();
            setId('')
            closeModal('addLocationModal');
          } else {
            setLoading({ update: false, listing: false })
            throw new Error(data.error);
          }

        }).catch((error) => {
          error && setLoading({ update: false, listing: false })
        })
      } else {

        locationService.createLocation(values).then((data: _Object) => {
          if (data.error === false) {
            setLoading({ update: true, listing: true })
            handleCallApi();
            formik.resetForm();
            setId('')
            closeModal('addLocationModal');
          } else {
            setLoading({ update: false, listing: false })
            throw new Error(data.error);
          }
        }).catch((error) => {
          error && setLoading({ update: false, listing: false })
        })
      }
    }
  })

  const deleteItem = (id: string) => {
    if (window.confirm('Are you sure to delete this item?')) {
      setLoading({ listing: true })
      locationService.deleteLocation(id).then(() => {
        locationService.getLocations(filterData).then((data: _Object) => {
          setLocations(data)
          setLoading({ listing: false })
        })
      })
    }
  };

  return (
    <>
      <SEOHeader title="Locations" />

      <Breadcrumbs
        trails={[
          {
            label: t('location.locations'),
            path: ''
          }
        ]}
      />

      <div className="pos-justify pos-between pos-align pos-center">
        <div className="d-flex header-loading">
          <form onSubmit={searchFormik.handleSubmit}>
            <SearchField
              type="search"
              name="q"
              className="mb-md-0"
              disabled={!searchFormik.values.q}
              value={searchFormik.values.q}
              onChange={(e: _Object) => {
                searchFormik.setFieldValue('q', e.target.value)
              }}
            />
          </form>
          <span className={`ms-4 ${loading.listing === true ? 'is-loading' : ''}`} />
        </div>

        {Object.values(filterData.filter).some(value => !!value) && (
          <ActiveFilters filterdLabel={filterdLabel.filter} setFilteredLabel={setFilteredLabel} setFilterData={setFilterData} />
        )}

        <button type="button" onClick={() => {
          formik.resetForm(); setId(''); setInitialData({
            name: '',
            type: '',
            address: '',
          })
        }} className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#addLocationModal">
          <i className="fa-solid fa-plus text-white"></i> {t('location.add')}
        </button>

        {/* Modal */}
        <div className="modal fade" id="addLocationModal" tabIndex={-1} aria-labelledby="addLocationModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <form className={`modal-content ${loading.edit === true ? 'is-loading' : ''}`} onSubmit={formik.handleSubmit}>
              <div className="modal-header">
                <aside>
                  <h4 className="mb-0">{id ? t('location.update') : t('location.new')}</h4>
                </aside>

                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>

              <div className="modal-body">
                <RadioButton
                  name="type"
                  label={t('location.type')}
                  required={true}
                  options={[
                    { label: t('location.warehouse'), value: 'warehouse' },
                    { label: t('location.outlet'), value: 'outlet' },
                  ]}
                  value={formik.values.type}
                  onChange={formik.handleChange}
                  error={formik?.touched.type && formik.errors.type}
                />

                <InputField
                  name="name"
                  label={t('common.name')}
                  required={true}
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik?.touched.name && formik.errors.name}
                />

                <TextArea
                  rows={3}
                  name="address"
                  label={t('common.address')}
                  value={formik.values.address}
                  onChange={formik.handleChange}
                  placeholder={t('location.enter_address')}
                />
              </div>

              <div className="modal-footer">
                <LinkButton path={`${routes.locations}`} label={t('common.cancel')} data-bs-dismiss="modal" className="link text-danger" />
                <Button type="submit" className="primary" name={id ? t('common.update') : t('common.submit')} loading={loading.update} />
              </div>
            </form>
          </div>
        </div>

        <div className="modal fade" id="subLocationModal" tabIndex={-1} aria-labelledby="subLocationModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <form className={`modal-content ${loading.edit === true ? 'is-loading' : ''}`} onSubmit={formik.handleSubmit}>
              <div className="modal-header">
                <aside>
                  <h4 className="mb-0">{formik.values?.name} - Sublocations</h4>
                </aside>

                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>

              <div className="modal-body">
                <ul>
                  {formik.values?.sub_locations?.map((item: _Object, i: number) => (

                    <li key={i}>{item.name}
                      {/* <ul className="list-inline">
                        <li className="list-inline-item">
                          <button onClick={() => setId(item.id)} id="subLocationModal" type="button" className="btn btn-transparent p-0 border-0" data-bs-toggle="modal" data-bs-target="#subLocationModal">
                            <i className="fa-solid fa-eye"></i>
                          </button>
                        </li>
                        <li className="list-inline-item">
                          <button onClick={() => setId(item.id)} id="addLocationModal" type="button" className="btn btn-transparent p-0 border-0" data-bs-toggle="modal" data-bs-target="#addLocationModal">
                            <i className="fa fa-pen"></i>
                          </button>
                        </li>
                        <li className="list-inline-item">
                          <Button type="submit" className="link text-danger p-0" onClick={() => deleteItem(item.id)}>
                            <i className="fa fa-trash text-danger" aria-hidden="true"></i>
                          </Button>
                        </li>
                      </ul> */}
                    </li>
                  ))}</ul>
                {/* <Button type="button" className="primary" name={'Add New'} loading={loading.update} /> */}
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="card border-0 my-3">
        <div className="table-responsive">
          <table className="card-body mb-0 table table-borderless table-striped">
            <thead>
              <tr className="bg-white">
                <th>#</th>
                <th>
                  <button
                    className="btn p-0 border-0"
                    onClick={() => { handleFilters('sort', filterData.sort === '-name' ? 'name' : '-name', setFilterData) }}>
                    {t('name')}
                    {getSortIcon(filterData.sort.includes('name') ? filterData.sort : '')}
                  </button>
                </th>
                <th>
                  <button type="button" className={`btn btn-transparent dropdown-toggle p-0 border-0 ${filterData.filter?.type?.length > 0 ? 'show filter-add' : 'show'}`}
                    data-bs-toggle="dropdown" aria-expanded="true" data-bs-auto-close="outside">
                    {t('type')}
                    <i className="fa-solid fa-chevron-down"></i>
                  </button>
                  <div className="dropdown">
                    <div className="dropdown-menu dropdown-menu-end">
                      <SelectField
                        name="type"
                        menuIsOpen
                        isSearchableHide={true}
                        options={[
                          { label: 'Warehouse', value: 'warehouse' },
                          { label: 'outlet', value: 'outlet' }
                        ]}
                        onChange={(val: _Object) => {
                          setFilterData((prev) => ({ ...prev, page: 1, filter: { ...prev.filter, type: val?.value || '' } }))
                          setFilteredLabel((prevLabel) => ({
                            filter: {
                              ...prevLabel.filter,
                              type: val?.label || '',
                            },
                          }));
                        }}
                        getOptionLabel={(option: _Object) => option.label}
                        getOptionValue={(option: _Object) => option.value}
                        isClearable
                      />
                    </div>
                  </div>
                </th>

                <th>{t('address')}</th>

                <th>
                  <button
                    className="btn p-0 border-0"
                    onClick={() => { handleFilters('sort', filterData.sort === '-created_at' ? 'created_at' : '-created_at', setFilterData) }}>
                    {t('createDate')}
                    {getSortIcon(filterData.sort.includes('created_at') ? filterData.sort : '')}
                  </button>
                </th>
                <th>
                  <button
                    className="btn p-0 border-0"
                    onClick={() => { handleFilters('sort', filterData.sort === '-updated_at' ? 'updated_at' : '-updated_at', setFilterData) }}>
                    {t('updateDate')}
                    {getSortIcon(filterData.sort.includes('updated_at') ? filterData.sort : '')}
                  </button>
                </th>
                <th className="action">{t('action')}</th>
              </tr>
            </thead>

            <tbody>
              {locations?.items?.map((data: _Object, i: number) => {
                return (
                  <tr key={i}>
                    <td>{serialNumber(filterData.per_page, locations?.pagination?.current_page, i)}</td>
                    <td>{data.name}</td>
                    <td>{t(data.type)}</td>
                    <td className="break-spaces">{data.address || '-'}</td>
                    <td>{doDateFormatWithTime(data.created_at)}</td>
                    <td>{doDateFormatWithTime(data.updated_at)}</td>

                    <td>
                      <ul className="list-inline">
                        <li className="list-inline-item">
                          <button onClick={() => setId(data.id)} id="subLocationModal" type="button" className="btn btn-transparent p-0 border-0" data-bs-toggle="modal" data-bs-target="#subLocationModal">
                            <i className="fa-solid fa-eye"></i>
                          </button>
                        </li>
                        <li className="list-inline-item">
                          <button onClick={() => setId(data.id)} id="addLocationModal" type="button" className="btn btn-transparent p-0 border-0" data-bs-toggle="modal" data-bs-target="#addLocationModal">
                            <i className="fa fa-pen"></i>
                          </button>
                        </li>
                        <li className="list-inline-item">
                          <Button type="submit" className="link text-danger p-0" onClick={() => deleteItem(data.id)}>
                            <i className="fa fa-trash text-danger" aria-hidden="true"></i>
                          </Button>
                        </li>
                      </ul>
                    </td>
                  </tr>
                )
              })}
              {loading.listing === false && locations?.items?.length === 0 && <tr>
                <td colSpan={7} className="text-center no-entry">
                  <aside>
                    <i className="fa-solid fa-ban"></i>
                    {strings.noRecordFound}
                  </aside>
                </td>
              </tr>
              }
            </tbody>
          </table>
        </div>
      </div>

      {locations?.pagination?.total_pages > 1 &&
        <Pagination
          current_page={locations?.pagination?.current_page}
          per_page={locations?.pagination?.per_page}
          total_pages={locations?.pagination?.total_pages}
          total_items={locations?.pagination?.total_items}
          onClick={(i: { [key: string]: number }) =>
            setFilterData((prev) => ({
              ...prev,
              page: i.selected + 1,
            }))
          }
        />
      }
    </>
  )
}
export default Locations