import React from 'react'
import Button from './button';
import { _Object } from 'utils/interfaces';

const Quantity = ({ value, onChange, decrementQuantity, incrementQuantity, ...props }: _Object) => {
  
  return (
    <div className="input-group quantity">
      <Button type="button" onClick={decrementQuantity}>
        <i className="fa-solid fa-minus me-0" style={{ color: 'white' }}></i>
      </Button>

      <input
        type="number"
        name="quantity"
        className="form-control input-number"
        value={value}
        onChange={onChange}
        {...props}
      />

      <Button type="button" onClick={incrementQuantity}>
        <i className="fa fa-plus me-0" style={{ color: 'white' }}></i>
      </Button>
    </div>
  )
}
export default Quantity;