import CommonService from './common.service';
import { generateQueryParams } from 'utils';
import { _Object } from 'utils/interfaces';

class BrandService extends CommonService {
  async getBrands(params: _Object | string) {
    return await this.get(`brands?${generateQueryParams(params)}`)
  }
  async getBrandDetails(id: _Object | string) {
    return await this.get(`brands/${id}`)
  }
  async createBrand(parmas: { [key: string]: boolean | string }) {
    return await this.post('brands', parmas)
  }
  async updateBrand(id: string, params: { [key: string]: boolean | string }) {
    return await this.put(`brands/${id}`, params)
  }
  async deleteBrand(id: string) {
    return await this.delete(`brands/${id}`)
  }
}
export const brandService = new BrandService();
