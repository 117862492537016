import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import * as Yup from 'yup';
import { useFormik } from 'formik';

import { _Object } from 'utils/interfaces';
import { capitalizeFirstLetter, closeModal, doAmountFormat, handleCartTotal, routes } from 'utils';
import { addToCart, setQuantity, incrementQuantity, decrementQuantity, removeCartItem, lineItemDiscount } from 'redux/slices/cart.slice';

import { Button, Customer, InputField, InputGroup, Quantity, TextArea } from 'views/components';
import SearchAndModel from './search';
import TodaySalesSummary from './summary';
import CartDiscountModal from './cart-discount-modal';
import { useLocation } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

const SalesTemplate1 = ({orderFormik, btnLoading}: _Object) => {

	const { t } = useTranslation()
	const dispatch = useDispatch()
	const location = useLocation()
	const { cart } = useSelector((state: _Object) => state.cart)
	const { globalSettings } = useSelector((state: _Object) => state.session)

	const [clickedItem, setClickedItem] = useState<_Object>({});

	useEffect(() => {
		const localdata: any = localStorage.getItem('cartLineItems')
		if (JSON.parse(localdata)?.length > 0 && cart?.lineItems?.length === 0) {
			dispatch(addToCart(JSON.parse(localdata)))
		}
	}, [])

	const discountFormik = useFormik({
		initialValues: { label: '', amount: '' },
		validationSchema: Yup.object({
			amount: Yup.number()
				.label('Discount amount')
				.positive('Amount must be greater than zero')
				.required()

		}),
		enableReinitialize: true,
		onSubmit: (values) => {
			dispatch(lineItemDiscount({ clickedItem, values }));
			closeModal('discount-modal');
			discountFormik.resetForm();
		},
	});

	const cartTotal = handleCartTotal(cart, globalSettings);

	const decimalPart = orderFormik.values.amount.toString().split('.')[1];
	const decimalValue = !decimalPart || decimalPart.length <= 2 || decimalPart.slice(2).length === 0;

	useEffect(() => {
		if (cartTotal.total !== 0) {
			orderFormik.setFieldValue('amount', cartTotal.total)
		}
	}, [cartTotal.total])

	return (
		<>
			<div className="row h-100">
				<div className="col-8 col-md-8 col-lg-8 col-xl-9 col-xxl-9 mt-3 sales-list">
					<>
						<SearchAndModel />
						<p>Template 1</p>

						{/* Cart list */}
						{cart?.lineItems?.length >= 1 &&
							<div className="position-relative">
								<div className="table-responsive">
									<table className="table table-striped caption-top sales-table h-auto">
										<caption>
											<h3>{t('checkout.cart')}</h3>
										</caption>
										<thead className="sticky-top">
											<tr>
												<th>{t('common.item')}</th>
												<th>{t('common.price')}</th>
												<th>{t('common.quantity')}</th>
												<th>{t('common.total')}</th>
												<th className="text-end" style={{ width: '50px' }}></th>
											</tr>
										</thead>

										<tbody>
											{cart?.lineItems?.map((item: _Object, index: number) => {
												return (
													<tr key={index}>
														<td className="break-spaces">
															{capitalizeFirstLetter(item?.name)}<br />
															<small className="text-secondary">{t('common.sku')}: {item?.sku}</small>
															{item?.attributes?.map((items: any) => {
																return (
																	<span key={items?.id} className="d-block text-secondary">{`${items?.name}: ${items?.attribute_values?.map((value: any) => value?.name)}`}</span>
																)
															})}
														</td>
														<td>
															<span className="d-flex">
																{doAmountFormat(item.price)}&nbsp;
																{globalSettings.store_enable_discount == true && <Button type="button" onClick={() => setClickedItem(item)} data-bs-toggle="modal" data-bs-target="#discount-modal" className="text-decoration-none p-0">
																	<i className="fa-solid fa-tags"></i>
																</Button>}
															</span>
															{globalSettings.store_enable_discount == true && item?.discount &&
																<span className="d-block text-secondary">{`Discount: ${item?.discount}`}</span>
															}
														</td>
														<td>
															<Quantity
																onChange={(e: _Object) => dispatch(setQuantity({ 'quantity': e?.target?.value, id: item?.id }))}
																value={item?.quantity}
																incrementQuantity={() => dispatch(incrementQuantity(item?.id))}
																decrementQuantity={() => dispatch(decrementQuantity(item?.id))}
															/>
														</td>
														<td>
															<strong>{doAmountFormat(item?.price * item?.quantity)}</strong><br />
															{item?.tax_rate?.rate &&
																<small className="d-block text-secondary">{`${item?.tax_rate?.name || item.tax_rate?.title?.toUpperCase()} ${item?.tax_rate?.rate}%`}</small>}
														</td>
														<td>
															<Button type="button" className="link text-danger p-0 float-end" onClick={() => dispatch(removeCartItem(index))}>
																<i className="fa-solid fa-trash me-0"></i>
															</Button>
														</td>
													</tr>
												)
											})}
										</tbody>
									</table>
								</div>
							</div>}
					</>

					<TodaySalesSummary />
				</div>

				<div className="col-4 col-md-4 col-lg-4 col-xl-3 col-xxl-3 bg-white border-start">
					<Customer />

					<div className="mt-3 customer-list">
						{location.pathname === `${routes.sales}` &&
							<div className="">
								{cart?.customer?.id && cart.lineItems.length > 0 &&
									<form onSubmit={orderFormik.handleSubmit}>
										<div className="mt-2 h-100">
											<div className="body">
												<div className="payment-methods">
													<h6 className="mb-0 d-flex align-items-center">{t('payment_terms')}
														{orderFormik.values.payment_terms &&
															<Button type="button" onClick={() => orderFormik.setFieldValue('payment_terms', '')} className="transparent p-0 ms-2 border-0">
																<FontAwesomeIcon icon={faXmark} /></Button>
														}
													</h6>
													<ul className="list-inline w-100 d-inline-flex py-2">

														<li className="list-inline-item">
															<Button
																className={`outline-primary text-nowrap ${orderFormik.values.payment_terms === '15' && 'active'}`}
																type={'button'}
																onClick={() => orderFormik.setFieldValue('payment_terms', '15')}
															>
																15 {t('days')}
															</Button>
														</li>

														<li className="list-inline-item">
															<Button
																className={`outline-primary text-nowrap ${orderFormik.values.payment_terms === '30' && 'active'}`}
																type={'button'}
																onClick={() => orderFormik.setFieldValue('payment_terms', '30')}
															>
																30 {t('days')}
															</Button>
														</li>

														<li className="list-inline-item">
															<Button
																className={`outline-primary text-nowrap ${orderFormik.values.payment_terms === '45' && 'active'}`}
																type={'button'}
																onClick={() => orderFormik.setFieldValue('payment_terms', '45')}
															>
																45 {t('days')}
															</Button>
														</li>

														<li className="list-inline-item">
															<Button
																className={`outline-primary text-nowrap ${orderFormik.values.payment_terms === '60' && 'active'}`}
																type={'button'}
																onClick={() => orderFormik.setFieldValue('payment_terms', '60')}
															>
																60 {t('days')}
															</Button>
														</li>
													</ul>
												</div>

												<hr />

												<div className="payment-methods">
													<h6 className="mb-0 d-flex align-items-center">{t('payment_mode')}
														{orderFormik.values.payment_mode &&
															<Button type="button" onClick={() => orderFormik.setFieldValue('payment_mode', '')} className="transparent p-0 ms-2 border-0">
																<FontAwesomeIcon icon={faXmark} /></Button>
														}
													</h6>
													<ul className="list-inline w-100 d-inline-flex py-2">
														<li className="list-inline-item">
															<Button
																className={`outline-primary text-nowrap ${orderFormik.values.payment_mode === 'cash' && 'active'}`}
																label="Cash"
																type={'button'}
																onClick={() => {
																	orderFormik.setFieldValue('payment_mode', 'cash')
																}}>
																{t('cash')}
															</Button>
														</li>

														<li className="list-inline-item">
															<Button
																className={`outline-primary text-nowrap ${orderFormik.values.payment_mode === 'phonepe' && 'active'}`}
																label="Phonepe"
																type={'button'}
																onClick={() => {
																	orderFormik.setFieldValue('payment_mode', 'phonepe')
																}}>
																{t('phonepe')}
															</Button>
														</li>

														<li className="list-inline-item">
															<Button
																className={`outline-primary text-nowrap ${orderFormik.values.payment_mode === 'paytm' && 'active'}`}
																label="Paytm"
																type={'button'}
																onClick={() => {
																	orderFormik.setFieldValue('payment_mode', 'paytm')
																}}>
																{t('paytm')}
															</Button>
														</li>

														<li className="list-inline-item">
															<Button
																className={`outline-primary text-nowrap ${orderFormik.values.payment_mode === 'googlepay' && 'active'}`}
																label="Google pay"
																type={'button'}
																onClick={() => {
																	orderFormik.setFieldValue('payment_mode', 'googlepay')
																}}>
																{t('googlepay')}
															</Button>
														</li>

														<li className="list-inline-item">
															<Button
																className={`outline-primary text-nowrap ${orderFormik.values.payment_mode === 'upi' && 'active'}`}
																label="Upi"
																type={'button'}
																onClick={() => {
																	orderFormik.setFieldValue('payment_mode', 'upi')
																}}>
																{t('upi')}
															</Button>
														</li>

														<li className="list-inline-item">
															<Button
																className={`outline-primary text-nowrap ${orderFormik.values.payment_mode === 'card' && 'active'}`}
																label="Credit card"
																type={'button'}
																onClick={() => {
																	orderFormik.setFieldValue('payment_mode', 'card')
																}}>
																{t('debitCredit')}
															</Button>
														</li>
													</ul>
												</div>

												<hr />

												<div className="payment-methods mt-2">
													<h6 >{t('paymentRef')} #</h6>
													<InputField
														name="payment_ref_number"
														placeholder={t('enter_here')}
														onChange={orderFormik.handleChange}
														value={orderFormik.values.payment_ref_number}
													/>
												</div>
												<hr />

												<div className="payment-methods mt-2">
													<h6 >{t('orderNote')}</h6>
													<TextArea
														name="order_note"
														placeholder={t('enterNote')}
														onChange={orderFormik.handleChange}
														value={orderFormik.values.order_note}
													/>
												</div>
											</div>

											<div className="payment-mode border-top">
												<ul className="list-item ps-0 payable-amount">

													<li className="list-item d-flex justify-content-between mb-3">
														<h6>{t('subTotal')}</h6>
														<span>{doAmountFormat(cartTotal.subTotal)}</span>
													</li>

													{globalSettings.store_enable_discount === true &&
														<li className="list-item d-flex justify-content-between mb-3">
															<h6>{t('discount')}</h6>
															<span>{doAmountFormat(cartTotal.discount)}</span>
														</li>}

													{globalSettings.store_prices_entered_with_tax === 'excluding' &&
														<li className="list-item d-flex justify-content-between mb-3">
															<h6>{t('tax')}</h6>
															<span>{doAmountFormat(cartTotal.tax)}</span>
														</li>}

													<li className="list-item d-flex justify-content-between">
														<h6>{t('total')}&nbsp;</h6>
														{globalSettings.store_enable_discount === true &&
															<Button
																type="button"
																data-bs-toggle="modal"
																data-bs-target="#cartitem-modal"
																className="text-decoration-none border-none p-0"
															>
																<i className="fa-solid fa-tags"></i>&nbsp;{cart?.discounts &&
																	<small >({capitalizeFirstLetter(cart?.discounts.label)}: {cart?.discounts.amount})</small>
																}
															</Button>
														}
														<span>
															{doAmountFormat(cartTotal.total)}
														</span>
													</li>

													<li className="list-item d-flex justify-content-end mb-3">
														<small className="mb-0">
															({t('includes')}{' '}
															{globalSettings.store_prices_entered_with_tax === 'including' &&
																cart.lineItems.map((item: any, i: number) => {
																	return (
																		item.tax_rate?.title && item.tax_rate?.rate && (
																			<small key={i}>
																				{item.tax_rate.title?.toUpperCase()} {item.tax_rate.rate}%&nbsp;
																				{doAmountFormat(
																					(item.tax_rate.rate / 100) * item.price * item.quantity
																				)}
																				{i !== cart.lineItems.length - 1 ? ', ' : ''}
																			</small>
																		)
																	);
																})}
															)
														</small>
													</li>

													<li className="list-item d-flex justify-content-between mb-3 align-items-center">
														<h6>{t('payAmount')}</h6>
														<InputGroup
															type="number"
															required="true"
															name="amount"
															leftIcon={true}
															value={orderFormik.values.amount}
															onBlur={orderFormik.handleBlur}
															onChange={orderFormik.handleChange}
															className="pay-input"
															error={orderFormik.touched.amount && orderFormik.errors.amount}
														/>
													</li>
												</ul>
												<Button
													type={'submit'}
													className="primary w-100"
													disabled={
														!(
															cart?.customer?.id &&
															cart?.lineItems?.length !== 0 &&
															(orderFormik.values.payment_mode !== '' || orderFormik.values.payment_terms !== '') &&
															(orderFormik.values.amount <= cartTotal.total) &&
															decimalValue
														)
													}
													name={t('placeOrder')}
													loading={btnLoading}
													onClick={() => {
														localStorage.removeItem('cartLineItems');
													}}
												/>
											</div>
										</div>
									</form>
								}

								<CartDiscountModal amount={orderFormik.values.amount} />

							</div>
						}

					</div>
				</div>

			</div>
		</>
	)
}
export default SalesTemplate1