import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import * as Yup from 'yup'
import { useFormik } from 'formik';

import { routes } from 'utils';
import { _Object } from 'utils/interfaces';
import { locationService } from 'services';
import { Button, InputField, LinkButton, RadioButton } from 'views/components';

const CreateLocation = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [isEditMode, setEditMode] = useState(false)

  const [initialData, setInitialData] = useState<_Object>({})

  useEffect(() => {
    setEditMode(false)
    setInitialData({
      name: '',
      address: '',
      type: '',
    })
    if (id) {
      setEditMode(true)
      locationService.getLocationDetails(id).then((data) => {
        setInitialData(data)
      })
    }
  }, [id])

  const formik = useFormik({
    initialValues: initialData,
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string().label('Name').required(),
      address: Yup.string().label('Address').required(),
      // type: Yup.string().label('type').required(),
    }),

    onSubmit: (values) => {
      setLoading(true)
      if (id) {
        delete values.id,
          delete values.created_at
        delete values.updated_at
        locationService.updateLocation(id, values).then((data: _Object) => {
          data.error === false && navigate(`${routes.locations}`)
        })
      } else {
        locationService.createLocation(values).then((data: _Object) => {
          data.error === false && navigate(`${routes.locations}`)
        })
      }
      setLoading(false)
    }
  })
  return (
    <>
      <header className="my-2">
        <h2>{isEditMode ? 'Update location' : 'Add location '}</h2>
        <h4>{isEditMode ? 'Update location' : 'Create new location'}</h4>
      </header>

      <form className="row filters" onSubmit={formik.handleSubmit}>
        <InputField
          name="name"
          className="col-4"
          label="Location name"
          required={true}
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik?.touched.name && formik.errors.name}
        />

        <InputField
          name="address"
          className="col-4"
          label="Address"
          required={true}
          value={formik.values.address}
          onChange={formik.handleChange}
          error={formik?.touched.address && formik.errors.address}
        />

        <RadioButton
          name="type"
          className="col-4"
          label="Recipient type"
          required={true}
          options={[
            { label: 'Warehouse', value: 'warehouse' },
            { label: 'Outlet', value: 'outlet' },
          ]}
          value={formik.values.type}
          onChange={formik.handleChange}
          error={formik?.touched.type && formik.errors.type}
        />
        <footer className="row my-2">
          <div className="col-12 d-flex">
            <Button name={isEditMode ? 'Update' : 'Submit'} className="primary" loading={loading} />
            <LinkButton
              path={routes.locations}
              label="Cancel"
              className="link text-dark" />
          </div>
        </footer>
      </form>

    </>
  )
}

export default CreateLocation;