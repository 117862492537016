import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import * as Yup from 'yup';
import { useFormik } from 'formik';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { userService } from 'services';
import { _Object } from 'utils/interfaces';
import { closeModal, routes } from 'utils';
import { countries } from 'utils/countries';
import { setCustomer } from 'redux/slices/cart.slice';

import { InputField, Button, LinkButton, SelectField } from '../../../components/form-inputs';
import moment from 'moment';

const Modal = ({ value, setSearchText }: any) => {
  const { t } = useTranslation()

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const { me } = useSelector((state: _Object) => state.session)

  const formik = useFormik({
    initialValues: {
      email: '',
      last_name: '',
      first_name: '',
      status: 'active',
      location_id: me?.location?.id,
      mobile_number: value ? value : '',
      // role: 'customer',
      // password: '123456',
      city: '',
      state: '',
      address1: '',
      address2: '',
      postcode: '',
      full_name: '',
      company_name: '',
      alternate_number: '',
      country: 'IN',
      dob: '',
      anniversary: '',
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      // first_name: Yup.string().required('First name is required'),
      // last_name: Yup.string().required('Last name is required'),
      mobile_number: Yup.string()
        .required('Mobile number is required')
        .min(10, 'Mobile number must be at least 10 characters'),
      email: Yup.string().email('Please enter valid email'),
    }),

    onSubmit: async (values: _Object) => {
      values.email = values.email === '' ? `${values.mobile_number}@gmail.com` : values.email
      values.address = JSON.stringify({
        address1: values.address1,
        address2: values.address2,
        city: values.city,
        state: values.state,
        country: values.country,
        postcode: values.postcode,
      })

      setLoading(true)
      userService.createCustomers(values).then((data: _Object) => {
        if (data.error === false) {
          navigate(`${routes.sales}`)
          closeModal('staticBackdrop');
          dispatch(setCustomer(data.data))
          setSearchText()
        } else {
          throw new Error(data.error);
        }
      }).catch((error) => {
        error && setLoading((false))
      })
    },
  });

  return (
    <div className="modal fade" id="staticBackdrop" tabIndex={-1} aria-hidden="true" aria-labelledby="staticBackdropLabel">
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <form className={`modal-content ${loading === true ? 'is-loading' : ''}`} onSubmit={formik.handleSubmit}>
          <div className="modal-content">

            <div className="modal-header">
              <h4 className="m-0">{t('customer.createNewCustomer')}</h4>
              <Button type="button" className="close me-2" data-bs-dismiss="modal"></Button>
            </div>

            <div className="modal-body">
              <div className="row">
                <InputField
                  name="first_name"
                  className="col-6"
                  label={t('common.firstName')}
                  // required={true}
                  value={formik.values.first_name}
                  onChange={formik.handleChange}
                // error={formik?.touched.first_name && formik.errors.first_name && formik.errors.first_name}
                />

                <InputField
                  name="last_name"
                  className="col-6"
                  label={t('common.lastName')}
                  // required={true}
                  onChange={formik.handleChange}
                  value={formik.values.last_name}
                // error={formik?.touched.last_name && formik.errors.last_name && formik.errors.last_name}
                />

                <InputField
                  required={true}
                  className="col-4"
                  name="mobile_number"
                  label={t('common.mobile_number')}
                  type="number"
                  onChange={(e: any) => {
                    const inputValue = e.target.value;
                    const numericValue = inputValue.replace(/\D/g, '');  // Remove non-numeric characters
                    const trimmedValue = numericValue.slice(0, 10);  // Keep only the first 10 digits
                    formik.setFieldValue('mobile_number', trimmedValue);
                  }}
                  value={formik.values.mobile_number}
                  error={formik?.touched.mobile_number && formik.errors.mobile_number && formik.errors.mobile_number}
                />

                <InputField
                  name="email"
                  className="col-4"
                  label={t('common.email')}
                  type="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik?.touched.email && formik.errors.email && formik.errors.email}
                />

                <InputField
                  label={t('common.company')}
                  className="col-4"
                  name="company_name"
                  onChange={formik.handleChange}
                  value={formik.values.company_name}
                />
                <div className="form-group mb-3 col-6">
                  <label className="label-form mb-1">{t('common.dob')}</label>

                  <DatePicker
                    dateFormat="MMM dd, yyyy"
                    className={`${formik.touched.dob && formik.errors.dob && 'invalid'}`}
                    placeholderText={t('common.enter_here')}
                    onChange={(value: any) => {
                      const selectedDate = moment(value).format('YYYY-MM-DD')
                      formik.setFieldValue('dob', selectedDate)
                    }}
                    selected={formik.values.dob ? moment(formik.values.dob, 'YYYY-MM-DD').toDate() : null}
                    maxDate={new Date()}
                  />
                </div>

                <div className="form-group mb-3 col-6">
                  <label className="label-form mb-1">{t('customer.anniversary')}</label>
                  <DatePicker
                    dateFormat="MMM dd, yyyy"
                    className={`${formik.touched.anniversary && formik.errors.anniversary && 'invalid'}`}
                    placeholderText={t('common.enter_here')}
                    onChange={(value: any) => {
                      const selectedDate = moment(value).format('YYYY-MM-DD')
                      formik.setFieldValue('anniversary', selectedDate)
                    }}
                    selected={formik.values.anniversary ? moment(formik.values.anniversary, 'YYYY-MM-DD').toDate() : null}
                    maxDate={new Date()}
                  />
                </div>

                <div className="col-12">
                  <fieldset className="border py-1 px-3 mt-1">
                    <legend className="float-none w-auto px-1 mb-1"><h4 className="mb-0">{t('common.address')}</h4></legend>
                    <div className="row">
                      <InputField
                        name="address1"
                        className="col-4"
                        label={`${t('common.address')} 1`}
                        onChange={formik.handleChange}
                        value={formik.values.address1}
                      />

                      <InputField
                        name="address2"
                        className="col-4"
                        label={`${t('common.address')} 2`}
                        onChange={formik.handleChange}
                        value={formik.values.address2}
                      />

                      <InputField
                        name="city"
                        className="col-4"
                        label={t('customer.townCity')}
                        onChange={formik.handleChange}
                        value={formik.values.city}
                      />

                      <InputField
                        type="number"
                        name="postcode"
                        label={t('common.postCode')}
                        className="col-4"
                        onChange={formik.handleChange}
                        value={formik.values.postcode}
                      />

                      <SelectField
                        className="col-4"
                        name="country"
                        label={t('common.country')}
                        options={countries}
                        value={{ value: formik.values.country || 'IN' }}
                        onChange={(e: _Object) => {
                          formik.setFieldValue('country', e?.value || '')
                        }}
                      />

                      <SelectField
                        className="col-4"
                        name="state"
                        placeholder={t('choose')}
                        label={t('common.state')}
                        options={countries?.find((obj: _Object) => obj.value === (formik?.values?.country?.length > 0 ? formik?.values?.country : 'IN'))?.states}
                        onChange={(e: _Object) => {
                          formik.setFieldValue('state', e?.label || '')
                        }}
                        value={{ label: formik.values.state }}
                        // disabled={!formik.values.country}
                        getOptionLabel={(option: { [key: string]: string }) => option?.label}
                        getOptionValue={(option: { [key: string]: string }) => option?.label}
                      />
                    </div>
                  </fieldset>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <LinkButton path={`${routes.sales}`} label={t('common.cancel')} data-bs-dismiss="modal" className="link text-danger fw-medium" />
              <Button className="primary"
                // name={modalId ? 'Update' : 'Submit'}
                name={t('common.submit')}
                loading={loading} />
            </div>
          </div>
        </form>
      </div>
    </div >
  )
}
export default Modal