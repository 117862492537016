import React, { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

import { userService, orderService } from 'services';
import { _Object } from 'utils/interfaces';
import { strings, generateAvatar, doAmountFormat, handleFilters, doDateFormatWithTime, getSortIcon, routes, serialNumber, generatePdf } from 'utils';
import { SearchField, LinkButton, Pagination, SelectField, Breadcrumbs, ActiveFilters, SEOHeader, ReportExport } from 'views/components';
import { AsyncSelectField, Button } from 'views/components/form-inputs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoice, faPrint } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const OrdersList = () => {
  const { t } = useTranslation()

  const selectAsyncSelect: any = useRef([])
  const { globalSettings } = useSelector((state: _Object) => state.session)

  const [loading, setLoading] = useState<_Object>(
    {
      listing: false,
      edit: false,
      update: false,
      pdf: false,

    });
  const [orders, setOrders] = useState<_Object>([])
  const [customers, setCustomers] = useState<_Object>([])
  const [filterdLabel, setFilteredLabel] = useState<_Object>({})

  const [filterData, setFilterData] = useState<_Object>({
    page: 1,
    per_page: process.env.REACT_APP_PER_PAGE,
    sort: '-created_at',
    filter: {
      customer_id: '',
      payment_status: '',
    }
  })

  const handleCallApi = () => {
    setLoading({ listing: true });
    orderService.getOrders({ q: formik.values.q, ...filterData }).then((data: _Object) => {
      if (data?.items) {
        setOrders(data)
        setLoading({ listing: false });
      }
    })
  }

  const formik = useFormik({
    initialValues: { q: '' },
    enableReinitialize: true,
    onSubmit: () => {
      if (formik.values.q.trim() !== '') {
        handleCallApi()
      }
    },
  });

  useEffect(() => {
    if (formik.values.q == '') {
      handleCallApi()
    }
  }, [filterData, formik.values.q])

  useEffect(() => {
    userService.getCustomers('').then((data: _Object) => {
      setCustomers(data?.items)
    })
  }, [])

  let getTotalPrice = 0;
  let getDiscountPrice = 0;
  orders?.items?.filter((item: _Object) => {
    getTotalPrice = item.total + getTotalPrice
    getDiscountPrice = item.discount + getDiscountPrice
  })

  const customerNameOptions = async (searchValue: string) => {
    const data: _Object = await userService.getCustomers({ q: searchValue })
    return data?.items?.map((item: _Object) => { return { label: generateAvatar(item.first_name, item.last_name), value: item.id } })
  }
  const [itemID, setItemID] = useState('')
  const handleOrderDetailsApi = (type: string, id: string) => {
    setItemID(id)
    setLoading({ pdf: true })
    orderService.getOrderDetails(id).then((data: _Object) => {
      setLoading({ pdf: false })
      if (data) {
        if (type == 'print') {
          generatePdf('print', data, globalSettings, setLoading)
        } else {
          generatePdf('pdf', data, globalSettings, setLoading)
        }
      }
    })
  }


  return (
    <>
      <SEOHeader title="Orders" />
      <Breadcrumbs
        trails={[
          {
            label: t('order.orders'),
            path: ''
          }
        ]}
      />

      <div className="pos-justify pos-between pos-align pos-center mt-3">
        <div className="d-flex header-loading">
          <form onSubmit={formik.handleSubmit}>
            <SearchField
              type="search"
              name="q"
              autoFocus
              disabled={!formik.values.q}
              className="mb-md-0"
              value={formik.values.q}
              onChange={(e: _Object) => {
                formik.setFieldValue('q', e.target.value)
              }}
            />
          </form>
          <span className={`ms-4 ${loading.listing === true ? 'is-loading' : ''}`} />
        </div>
        {Object.values(filterData.filter).some(value => !!value) && (
          <ActiveFilters filterdLabel={filterdLabel.filter} setFilteredLabel={setFilteredLabel} setFilterData={setFilterData} />
        )}
        <span className="d-flex align-items-center gap-3">
          <Link
            to="#"
            className="primary text-decoration-none"
            data-bs-toggle="modal" data-bs-target="#export-report"
          >
            {t('common.export_report')}
          </Link>
          <LinkButton className="primary" path={`${routes.sales}`} label="">
            <i className="fa-solid fa-plus text-white"></i>
            {t('order.add')}
          </LinkButton>
        </span>
      </div>
      <div className="card border-0 my-3 position-relative">
        <div className="table-responsive">
          <table className="card-body mb-0 table table-borderless table-striped order-listing">
            <thead className="bg-white sticky-top">
              <tr>
                <th>#</th>
                <th>{t('order.orderId')}</th>

                <th>
                  <button type="button" className={`btn btn-transparent dropdown-toggle p-0 border-0 ${filterData.filter?.customer_id?.length > 0 ? 'show filter-add' : 'show'}`}
                    data-bs-toggle="dropdown" aria-expanded="true" data-bs-auto-close="outside">
                    {t('customer.customer')}
                    <i className="fa-solid fa-chevron-down"></i>
                  </button>
                  <div className="dropdown">
                    <div className="dropdown-menu dropdown-menu-end">
                      <AsyncSelectField
                        isClearable
                        menuIsOpen
                        cacheOptions
                        name="customer_id"
                        loadOptions={customerNameOptions}
                        ref={(ref: any) => (selectAsyncSelect.current = ref)}
                        defaultOptions={customers?.map((item: _Object) => ({
                          label: generateAvatar(item.first_name, item.last_name),
                          value: item.id,
                        }))}
                        onChange={(val: _Object) => {
                          setFilterData((prevData) => ({
                            ...prevData,
                            filter: {
                              ...prevData.filter,
                              customer_id: val?.value || '',
                            },
                          }));
                          setFilteredLabel((prevLabel) => ({
                            filter: {
                              ...prevLabel.filter,
                              customer_id: val?.label || '',
                            },
                          }));
                        }}
                      />
                    </div>
                  </div>
                </th>

                <th>
                  <button type="button" className={`btn btn-transparent dropdown-toggle p-0 border-0 ${filterData.filter?.payment_status?.length > 0 ? 'show filter-add' : 'show'}`}
                    data-bs-toggle="dropdown" aria-expanded="true" data-bs-auto-close="outside">
                    {t('common.status')}
                    <i className="fa-solid fa-chevron-down"></i>
                  </button>
                  <div className="dropdown">
                    <div className="dropdown-menu dropdown-menu-end">
                      <SelectField
                        menuIsOpen
                        isSearchableHide={true}
                        name="payment_status"
                        className="col"
                        options={[
                          { label: t('order.paid'), value: 'paid' },
                          { label: t('order.unpaid'), value: 'unpaid' },
                          { label: t('order.partialpaid'), value: 'partialpaid' },
                        ]}
                        onChange={(val: _Object) => {
                          setFilterData((e) => ({ ...e, filter: { ...e.filter, payment_status: val?.value || '' } }));
                          setFilteredLabel((prevLabel) => ({
                            filter: {
                              ...prevLabel.filter,
                              payment_status: val?.label || '',
                            },
                          }));
                        }}
                        getOptionLabel={(option: _Object) => option.label}
                        getOptionValue={(option: _Object) => option.value}
                        isClearable
                      />
                    </div>
                  </div>
                </th>
                <th>{t('common.total')}</th>

                <th>
                  <button
                    className="btn p-0 border-0"
                    onClick={() => { handleFilters('sort', filterData.sort === '-created_at' ? 'created_at' : '-created_at', setFilterData) }}>
                    {t('common.createDate')}
                    {getSortIcon(filterData.sort.includes('created_at') ? filterData.sort : '')}
                  </button>
                </th>

                <th>
                  <button
                    className="btn p-0 border-0"
                    onClick={() => { handleFilters('sort', filterData.sort === '-updated_at' ? 'updated_at' : '-updated_at', setFilterData) }}>
                    {t('common.updateDate')}
                    {getSortIcon(filterData.sort.includes('updated_at') ? filterData.sort : '')}
                  </button>
                </th>
                <th className="action">{t('common.action')}</th>
              </tr>
            </thead>
            <tbody>
              {orders?.items?.map((item: _Object, i: number) => {
                return (
                  <tr key={item.order_number}>
                    <td>{serialNumber(filterData.per_page, orders?.pagination?.current_page, i)}</td>
                    <td>{item.order_number}</td>
                    <td>
                      {item?.customer?.first_name ? generateAvatar(item.customer.first_name, item.customer.last_name) : '-'}<br></br>
                      <small>{item?.customer?.mobile_number}</small>
                    </td>
                    <td>{item?.payment_status ? t(`order.${item.payment_status}`) : '-'}</td>

                    <td>
                      <p className="summary mb-0">
                        {t('common.sub_total')}: <span>{doAmountFormat(item.subtotal)}</span>
                      </p>
                      {item.tax_total !== 0 && <p className="summary mb-0">
                        {t('common.tax')}: <span>{doAmountFormat(item.tax_total)}</span>
                      </p>
                      }
                      {item.discount_total !== 0 && <p className="summary mb-0">
                        {t('checkout.discount')}: <span>{doAmountFormat(item.discount_total)}</span>
                      </p>}
                      <p className="summary mb-0">
                        {t('common.total')}: <span>{doAmountFormat(item.total ? item.total : 0)}</span>
                      </p>
                    </td>
                    <td>
                      <span dangerouslySetInnerHTML={{ __html: doDateFormatWithTime(item?.created_at) }} /></td>
                    <td>
                      {doDateFormatWithTime(item?.updated_at)}
                    </td>
                    <td className="position-relative">
                      {itemID == item.id && loading.pdf && <span className="loader"></span>}
                      <ul className="list-inline">
                        <li className="list-inline-item">
                          <LinkButton
                            className="link p-0 text-dark"
                            path={`${routes.orders}/${item?.id}`}
                            label=""
                          >
                            <i className="fa-solid fa-eye"></i>
                          </LinkButton>
                        </li>

                        <li className="list-inline-item">
                          <Button
                            type="button"
                            onClick={() => handleOrderDetailsApi('pdf', item.id)}
                            className="link text-dark p-0"
                          >
                            <FontAwesomeIcon icon={faFileInvoice} />
                          </Button>
                        </li>

                        <li className="list-inline-item">
                          <Button
                            type="button"
                            onClick={() => handleOrderDetailsApi('print', item.id)}
                            className="link text-dark p-0"
                          >
                            <FontAwesomeIcon icon={faPrint} />
                          </Button>
                        </li>

                        {/* <li className="list-inline-item">
                        <LinkButton
                          className="link text-dark p-0"
                          path={'#'}
                          label=""
                        >
                          <i className="fa fa-pen"></i>
                        </LinkButton>
                      </li>

                      <li className="list-inline-item">
                        <Button type="submit" onClick={() => deleteItem(item?.id)} className="transparent p-0">
                          <i className="fa-solid fa-trash text-danger"></i>
                        </Button>
                      </li> */}

                        {/* <li className="list-inline-item">
                        <LinkButton
                          className="link text-success p-0"
                          path={'#'}
                          label=""
                        >
                          <i className="fa-solid fa-file-waveform"></i>
                        </LinkButton>
                      </li> */}
                      </ul>
                    </td>
                  </tr>
                )
              })}
              {loading.listing === false && orders?.items?.length === 0 && <tr>
                <td colSpan={8} className="text-center no-entry">
                  <aside>
                    <i className="fa-solid fa-ban"></i>
                    {strings.noRecordFound}
                  </aside>
                </td>
              </tr>
              }
            </tbody>
          </table>
        </div>
      </div>
      {/* <div className="d-flex justify-content-center mb-3">
        {loading.listing === true && <span className="loader"></span>}
      </div> */}
      {orders?.pagination?.total_pages > 1 &&
        <Pagination
          current_page={orders?.pagination?.current_page}
          per_page={orders?.pagination?.per_page}
          total_pages={orders?.pagination?.total_pages}
          total_items={orders?.pagination?.total_items}
          onClick={(i: { [key: string]: number }) =>
            setFilterData((prev) => ({
              ...prev,
              page: i.selected + 1,
            }))
          }
        />
      }

      <ReportExport
        title={'Export report orders'}
        type="sales"
      />
    </>
  )
}
export default OrdersList
