
import CommonService from './common.service';
import { generateQueryParams } from 'utils';
import { _Object } from 'utils/interfaces';

class PaymentService extends CommonService {
  async getPayments(params: _Object | string) {
    return await this.get(`payments?${generateQueryParams(params)}`)
  }
  async createPayment(parmas: _Object) {
    return await this.post('payments', parmas)
  }
  async getPaymentDetails(id: string) {
    return await this.post(`payments/${id}`)
  }
  async addPayment(params: _Object) {
    return await this.post('payments', params)
  }
}
export const paymentService = new PaymentService();
