import React, { Suspense } from 'react'
import { Outlet } from 'react-router-dom'

import { Loading, Footer, } from '../components'

const PrivateLayout = () => {
  return (
    <>
      <Suspense fallback={<Loading />}>
        <main className="container-fluid overflow-auto" style={{ height: 'calc(100vh - 55px)' }}>
          <Outlet />
        </main>
      </Suspense>
      <Footer />
    </>
  )
}
export default PrivateLayout