import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import App from './App';
import store from 'redux/store';

import 'react-toastify/dist/ReactToastify.css';
import './assets/scss/app.scss'

import '@fortawesome/fontawesome-free/js/all.js'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'

import { ToastContainer } from 'react-toastify';

import i18n from './i18n/config';
import { I18nextProvider } from 'react-i18next'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider store={store}>
        <I18nextProvider i18n={i18n}>
    <App />
    <ToastContainer
      toastStyle={{ backgroundColor: 'blue', color: '#ffff' }}
      autoClose={2000}
      pauseOnHover
    />
    </I18nextProvider>

  </Provider>
);