import ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'

import { reportService } from 'services'
import { capitalizeFirstLetter, doAmountFormatWithoutCurrency, doDateFormat } from 'utils'
import { _Object } from 'utils/interfaces'

export const generateExcelFile = async (dates: any, type: string, setLoading:any) => {
  const overViewData = []
  const listData = []

  const fonts: any = {
    boldFont: { color: { argb: 'FF000000' }, bold: true, size: 12 },
    bgbroun: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'D9D9D9' } },
    textWrap: { wrapText: true, vertical: 'middle', horizontal: 'center' },
    bgCoral: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FCE5CD' } },
    fontSizeMiddleWithBold: { size: 11, bold: true }
  }

  const workbook = new ExcelJS.Workbook()

  const overviewSheet = workbook.addWorksheet('Overview')
  const listSheet = workbook.addWorksheet('List')

  setLoading(true)

  if(type === 'sales'){
    const order:any = await reportService.getOrdersReport(dates)

    const newOverView = [
        {col1: 'Sales Overview (₹)'},
        {col1: `Between ${doDateFormat(dates.start_date)} and ${doDateFormat(dates.end_date)}`},
        {col1: 'Details', col2: '', col3: 'Qty in No.', col4: 'Amount'}
    ];
    
    const mappedOverview = order.overview.map((item:_Object) => ({
      col1: item.status === 'partialpaid' ? 'Partial paid' : capitalizeFirstLetter(item.status),
      col2: '',
      col3: item.quantity,
      col4: doAmountFormatWithoutCurrency(item.total)
    }));

    const listHeader = [
      {col1: 'Order No.', col2: 'Full Name', col3: 'Email', col4: 'Status', col5: 'Total', col6: 'Subtotal', col7: 'Discount', col8: 'Fee', col9: 'Tax', col10: 'Date'}
    ]

    const mappedListItems = order.items.map((item: _Object) => ({
      col1: item.order_number,
      col2: item.full_name,
      col3: item.email,
      col4: item.payment_status === 'partialpaid' ? 'Partial paid' : capitalizeFirstLetter(item.payment_status),
      col5: doAmountFormatWithoutCurrency(item.total),
      col6: doAmountFormatWithoutCurrency(item.subtotal),
      col7: doAmountFormatWithoutCurrency(item.discount),
      col8: doAmountFormatWithoutCurrency(item.fee),
      col9: doAmountFormatWithoutCurrency(item.tax),
      col10: doDateFormat(item.date)
    }))

    overViewData.push([...newOverView, ...mappedOverview, ...[{col1: 'NET(₹)', col2: '', col3: '', col4: doAmountFormatWithoutCurrency(order.total)}]])

    listData.push([...listHeader, ...mappedListItems])

    overViewData[0].forEach((data) => {
      overviewSheet.addRow(Object.values(data))
    })

    listData[0].forEach((data) =>{
      listSheet.addRow(Object.values(data))
    })

    overviewSheet.getColumn(1).eachCell((cell, cellNumber) =>{
      
      if(cellNumber === 1){
          cell.font = fonts.boldFont
          cell.alignment = fonts.textWrap;
          cell.fill = fonts.bgbroun;
      }
        
      if(cellNumber === 2){
        cell.alignment = fonts.textWrap;
        cell.fill = fonts.bgCoral;
      }
    })

    let lastDataRow = 1;

    overviewSheet.eachRow({ includeEmpty: false }, (row, rowNumber) => {
      if (rowNumber > lastDataRow) {
        lastDataRow = rowNumber;
      }
    });

    overviewSheet.mergeCells('A1:D1')
    overviewSheet.mergeCells('A2:D2')
    overviewSheet.getColumn('A').width = 11;
    overviewSheet.getColumn('C').width = 11;
    overviewSheet.getColumn('D').width = 11;
    overviewSheet.getRow(2).font = fonts.fontSizeMiddleWithBold
    overviewSheet.getRow(3).font = fonts.fontSizeMiddleWithBold
    overviewSheet.getRow(lastDataRow).font = fonts.fontSizeMiddleWithBold

    listSheet.getRow(1).font = fonts.fontSizeMiddleWithBold
    listSheet.getColumn('A').width = 11
    listSheet.getColumn('B').width = 25
    listSheet.getColumn('C').width = 23
    listSheet.getColumn('D').width = 11
    listSheet.getColumn('E').width = 11
    listSheet.getColumn('F').width = 11
    listSheet.getColumn('G').width = 11
    listSheet.getColumn('H').width = 11
    listSheet.getColumn('I').width = 11
    listSheet.getColumn('J').width = 13
  } else if(type === 'expenses'){
    const expenseData:any = await reportService.getExpensesReport(dates)

    const newOverView = [
      {col1: 'Expense Overview (₹)'},
      {col1: `Between ${doDateFormat(dates.start_date)} and ${doDateFormat(dates.end_date)}`},
      {col1: 'Category', col2: '', col3: '', col4: 'Amount'}
    ];

    const mappedOverview = expenseData.overview.map((item: _Object) => ({
      col1: capitalizeFirstLetter(item.category),
      col2: '',
      col3: '',
      col4: doAmountFormatWithoutCurrency(item.total_amount)
    }))

    const listHeader = [
      {col1: 'Title', col2: 'Amount', col3: 'Reference #', col4: 'Category', col5: 'Date'}
    ]

    const mappedListItems = expenseData.items.map((item: _Object) => ({
      col1: item.title,
      col2: doAmountFormatWithoutCurrency(item.amount),
      col3: item.reference_number,
      col4: item.category,
      col5: doDateFormat(item.expense_date)
    }))


    overViewData.push([...newOverView, ...mappedOverview, ...[{col1: 'NET(₹)', col2: '', col3: '', col4: doAmountFormatWithoutCurrency(expenseData.total)}]])

    listData.push([...listHeader, ...mappedListItems])

    overViewData[0].forEach((data) => {
      overviewSheet.addRow(Object.values(data))
    })

    listData[0].forEach((data) =>{
      listSheet.addRow(Object.values(data))
    })

    overviewSheet.getColumn(1).eachCell((cell, cellNumber) =>{
      
      if(cellNumber === 1){
          cell.font = fonts.boldFont
          cell.alignment = fonts.textWrap;
          cell.fill = fonts.bgbroun;
      }
        
      if(cellNumber === 2){
        cell.alignment = fonts.textWrap;
        cell.fill = fonts.bgCoral;
      }
    })

    let lastDataRow = 1;

    overviewSheet.eachRow({ includeEmpty: false }, (row, rowNumber) => {
      if (rowNumber > lastDataRow) {
        lastDataRow = rowNumber;
      }
    });

    overviewSheet.mergeCells('A1:D1')
    overviewSheet.mergeCells('A2:D2')
    overviewSheet.getColumn('A').width = 20;
    overviewSheet.getColumn('D').width = 11;
    overviewSheet.getRow(2).font = fonts.fontSizeMiddleWithBold
    overviewSheet.getRow(3).font = fonts.fontSizeMiddleWithBold
    overviewSheet.getRow(lastDataRow).font = fonts.fontSizeMiddleWithBold

    listSheet.getRow(1).font = fonts.fontSizeMiddleWithBold
    listSheet.getColumn('A').width = 20
    listSheet.getColumn('B').width = 11
    listSheet.getColumn('C').width = 15
    listSheet.getColumn('D').width = 15
    listSheet.getColumn('E').width = 13
  } else if(type === 'tax'){
    const taxData:any = await reportService.getTaxRatesReport(dates)

    const newOverView = [
      {col1: 'Tax Overview GST (₹)'},
      {col1: `Between ${doDateFormat(dates.start_date)} and ${doDateFormat(dates.end_date)}`},
      {col1: `* Gross Invoiced: ₹${taxData.total}`},
      {col1: 'Category', col2: '', col3: '', col4: 'Amount'}
    ];

    const mappedOverview = taxData.overview.map((item: _Object) => ({
      col1: `${item.rate}%`,
      col2: '',
      col3: '',
      col4: doAmountFormatWithoutCurrency(item.amount)
    }))

    const listHeader = [
      {col1: 'Order No.', col2: 'Order Total', col3: 'Order Subtotal', col4: 'Item Total', col5: 'Item Subtotal', col6: 'Tax Name', col7: 'Tax Rate', col8: 'Tax amount', col9: 'Date'}
    ]

    const mappedListItems = taxData.items.map((item: _Object) => ({
      col1: item.order_number,
      col2: doAmountFormatWithoutCurrency(item.total),
      col3: doAmountFormatWithoutCurrency(item.subtotal),
      col4: doAmountFormatWithoutCurrency(item.item_total),
      col5: doAmountFormatWithoutCurrency(item.item_subtotal),
      col6: item.name,
      col7: item.rate,
      col8: doAmountFormatWithoutCurrency(item.amount),
      col9: doDateFormat(item.date)
    }))


    overViewData.push([...newOverView, ...mappedOverview, ...[{col1: 'NET(₹)', col2: '', col3: '', col4: doAmountFormatWithoutCurrency(taxData.tax_total)}]])

    listData.push([...listHeader, ...mappedListItems])

    overViewData[0].forEach((data) => {
      overviewSheet.addRow(Object.values(data))
    })

    listData[0].forEach((data) =>{
      listSheet.addRow(Object.values(data))
    })

    overviewSheet.getColumn(1).eachCell((cell, cellNumber) =>{
      
      if(cellNumber === 1){
          cell.font = fonts.boldFont
          cell.alignment = fonts.textWrap;
          cell.fill = fonts.bgbroun;
      }
        
      if(cellNumber === 2){
        cell.alignment = fonts.textWrap;
        cell.fill = fonts.bgCoral;
      }
    })

    let lastDataRow = 1;

    overviewSheet.eachRow({ includeEmpty: false }, (row, rowNumber) => {
      if (rowNumber > lastDataRow) {
        lastDataRow = rowNumber;
      }
    });

    overviewSheet.mergeCells('A1:D1')
    overviewSheet.mergeCells('A2:D2')
    overviewSheet.mergeCells('A3:D3')
    overviewSheet.getColumn('A').width = 20;
    overviewSheet.getColumn('D').width = 11;
    overviewSheet.getRow(2).font = fonts.fontSizeMiddleWithBold
    overviewSheet.getRow(3).font = fonts.fontSizeMiddleWithBold
    overviewSheet.getRow(4).font = fonts.fontSizeMiddleWithBold
    overviewSheet.getRow(lastDataRow).font = fonts.fontSizeMiddleWithBold

    listSheet.getRow(1).font = fonts.fontSizeMiddleWithBold
    listSheet.getColumn('A').width = 15
    listSheet.getColumn('B').width = 15
    listSheet.getColumn('C').width = 15
    listSheet.getColumn('D').width = 15
    listSheet.getColumn('E').width = 15
    listSheet.getColumn('F').width = 11
    listSheet.getColumn('H').width = 13
    listSheet.getColumn('I').width = 13
  }else if(type === 'inventory'){
    const inventoryData:any = await reportService.getInventoryReport(dates)

    const newOverView = [
      {col1: 'Expense Overview (₹)'},
      {col1: `Between ${doDateFormat(dates.start_date)} and ${doDateFormat(dates.end_date)}`},
    ]; 
    
    const product = [
      {
        col1: 'In Stock', col2:'', col3:'', col4: inventoryData?.product?.in_stock
      },
      {
        col1: 'Out of Stock', col2:'', col3:'', col4: inventoryData?.product?.out_of_stock
      },
      {
        col1: 'Low Stock', col2:'', col3:'', col4: inventoryData?.product?.low_stock_threshold
      },
      {},
      {}
    ]

    const mappedListItems = inventoryData.invertory.map((item: _Object) => ({
      col1: capitalizeFirstLetter(item.status),
      col2: '',
      col3: '',
      col4: item.quantity
    }))

    overViewData.push([...newOverView, ...product, ...mappedListItems])

    overViewData[0].forEach((data) => {
      overviewSheet.addRow(Object.values(data))
    })

    overviewSheet.getColumn(1).eachCell((cell, cellNumber) =>{
      
      if(cellNumber === 1){
          cell.font = fonts.boldFont
          cell.alignment = fonts.textWrap;
          cell.fill = fonts.bgbroun;
      }
        
      if(cellNumber === 2){
        cell.alignment = fonts.textWrap;
        cell.fill = fonts.bgCoral;
      }
    })

    overviewSheet.mergeCells('A1:D1');
    overviewSheet.mergeCells('A2:D2');
    overviewSheet.getColumn('A').width = 20;
    overviewSheet.getRow(2).font = fonts.fontSizeMiddleWithBold
  }
  workbook.xlsx.writeBuffer().then((buffer:any) => {
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })

    if(type === 'sales'){
      saveAs(blob, 'Sales.xlsx')
      setLoading(false)
    }else if(type === 'expenses'){
      saveAs(blob, 'Expenses.xlsx')
      setLoading(false)
    }else if(type === 'tax'){
      saveAs(blob, 'Tax-rates.xlsx')
      setLoading(false)
    }else if(type === 'inventory'){
      saveAs(blob, 'Inventory.xlsx')
      setLoading(false)
    }
  })
}