import React, { useEffect, useState } from 'react';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import { reportService } from 'services';
import { doAmountFormat, doDateFormat } from 'utils';
import { _Object } from 'utils/interfaces';
import ReportSkeleton from './skelton';
import moment from 'moment';

const TopSellingProducts = (props: _Object) => {
  const { dateRange } = props

  const [topSellingProducts, setTopSellingProducts] = useState<_Object>([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    reportService.getTopSellingProducts(dateRange.date1).then((data: _Object) => {
      setLoading(false)
      if (data?.items?.length > 0) {
        setTopSellingProducts(data)
      }else {
        setTopSellingProducts([])
      }
    })
  }, [dateRange.date1.start_date])

  const series1 = topSellingProducts?.items?.length > 0
    ? topSellingProducts.items.map((item: _Object) => [parseFloat(item.total.toFixed(2))])
    : [];

  const options = {
    chart: {
      type: 'bar',
    },
    title: {
      text: 'Top selling products',
      align: 'left',
      margin: 35,
    },
    subtitle: {
      text: doAmountFormat(topSellingProducts?.total ? topSellingProducts?.total : 0),
      align: 'left',
    },
    xAxis: {
      categories: topSellingProducts?.items?.map((item: _Object) => `${item.name.substring(0, 10)}\n${item.sku}`),
      gridLineWidth: 1,
      lineWidth: 0,
      labels: {
        useHTML: true,
        formatter: function (this: any): string {
          const parts = this.value.split('\n');
          return `<div>${parts[0]}</div><div>${parts[1]}</div>`;
        },
        align: 'left',
        reserveSpace: true,
      },
    },
    yAxis: {
      min: 0,
      labels: {
        overflow: 'justify',
        enabled: false,
      },
      title: {
        text: null,
      },
      gridLineWidth: 0,
      showEmpty: false,
    },
    tooltip: {
      crosshairs: true,
      shared: true,
      formatter: function (this: Highcharts.TooltipFormatterContextObject): string {
        const sku: any = this.points?.[0]?.x;
        const splitSKU: string = (sku.split('\n')[1] || '').trim();

        const total = (this.points?.[0]?.y);
        const count = topSellingProducts?.items?.find((item: _Object) => item.sku === splitSKU);

        const tooltipContent = 
          '<br />Orders count: ' + count?.count + '<br /><b>Orders total: ' + doAmountFormat(total) + '</b>';

        return tooltipContent;
      },
      style: {
        fontSize: '10px',
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 0,
        dataLabels: {
          inside: false,
          enabled: true,
          verticalAlign: 'middle',
        },
        groupPadding: 0.2,
      },
    },
    series: [
      {
        name: `${moment(dateRange.date1.start_date).format('DD MMM')} - ${doDateFormat(dateRange.date1.end_date)}`,
        data: series1,
      }],
  };

  return (
    <>
      {loading ? <ReportSkeleton />
        :
        <HighchartsReact highcharts={Highcharts} options={options} />
      }
    </>
  );
};

export default TopSellingProducts;
