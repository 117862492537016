import React, { useEffect, useState } from 'react'

import * as Yup from 'yup'
import { useFormik } from 'formik';

import { taskService } from 'services'
import { _Object } from 'utils/interfaces'
import { routes, serialNumber, doDateFormatWithTime, strings, closeModal, handleFilters, getSortIcon } from 'utils'
import { LinkButton, SearchField, Button, Pagination, InputField, TextArea, Breadcrumbs, SEOHeader } from 'views/components'

const TaskCategories = () => {
  const [id, setId] = useState('');
  const [loading, setLoading] = useState<_Object>
    ({
      listing: false,
      edit: false,
      update: false
    });
  const [initialData, setInitialData] = useState<_Object>({})
  const [taskCategories, setTaskCategories] = useState<_Object>([])
  const [filterData, setFilterData] = useState<_Object>({
    page: 1,
    per_page: process.env.REACT_APP_PER_PAGE,
    sort: '-created_at',
  })

  const handleCallApi = () => {
    setLoading({ listing: true });
    taskService.getTaskCategories({ ...filterData, q: searchFormik.values.q }).then((data: _Object) => {
      setTaskCategories(data)
      setLoading({ listing: false });
    })
  }

  const searchFormik = useFormik({
    initialValues: { q: '' },
    enableReinitialize: true,
    onSubmit: () => {
      if (searchFormik.values.q.trim() !== '') {
        handleCallApi()
      }
    }
  })

  useEffect(() => {
    if (searchFormik.values.q === '') {
      handleCallApi()
    }
  }, [filterData, searchFormik.values.q])

  useEffect(() => {
    setInitialData({
      title: '',
      description: '',
    })
    if (id) {
      setLoading({ edit: true })
      taskService.getTaskCategoroyDetails(id).then((data: _Object) => {
        setInitialData(data)
        setLoading({ edit: false })
      })
    }

  }, [id])

  const formik = useFormik({
    initialValues: initialData,
    enableReinitialize: true,
    validationSchema: Yup.object({
      title: Yup.string().label('Title').required(),
    }),

    onSubmit: (values) => {
      setLoading({ update: true });

      if (id) {
        delete values.id,
          delete values.created_by
        delete values.created_at
        delete values.category
        delete values.updated_at

        taskService.updateTaskCategories(id, values).then((data: _Object) => {
          if (data.error === false) {
            setLoading({ update: true, listing: true })
            handleCallApi();
            formik.resetForm();
            setId('')
            closeModal('addCategoryTasksModal');
          } else {
            setLoading({ update: false, listing: false })
            throw new Error(data.error);
          }
        }).catch((error) => {
          error && setLoading({ update: false, listing: false })
        })
      } else {
        taskService.createTaskCategories(values).then((data: _Object) => {
          if (data.error === false) {
            setLoading({ update: true, listing: true })
            handleCallApi();
            formik.resetForm();
            setId('')
            closeModal('addCategoryTasksModal');
          } else {
            setLoading({ update: false, listing: false })
            throw new Error(data.error);
          }
        }).catch((error) => {
          error && setLoading({ update: false, listing: false })
        })
      }
    },
  })

  const deleteItem = (id: string) => {
    if (window.confirm('Are you sure to delete this item?')) {
      setLoading({ listing: true })
      taskService.deleteTaskCategories(id).then(() => {
        handleCallApi();
        setLoading({ listing: false })
      });
    }
  };


  return (
    <>
      <SEOHeader title="Tasks" />
      <Breadcrumbs
        trails={[
          {
            label: 'Tasks',
            path: `${routes.tasks}`
          },
          {
            label: 'Categories',
            path: ''
          }
        ]}
      />

      <div className="pos-justify pos-between pos-align pos-center">
        <div className="d-flex header-loading">
          <form onSubmit={searchFormik.handleSubmit}>
            <SearchField
              type="search"
              name="q"
              className="mb-md-0"
              disabled={!searchFormik.values.q}
              value={searchFormik.values.q}
              onChange={(e: _Object) => {
                searchFormik.setFieldValue('q', e.target.value)
              }}
            />
          </form>
          <span className={`ms-4 ${loading.listing === true ? 'is-loading' : ''}`} />
        </div>
        <button type="button" onClick={() => {
          formik.resetForm(); setId('');
          setInitialData({
            title: '',
            description: '',
          })
        }} className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#addCategoryTasksModal">
          <i className="fa-solid fa-plus text-white"></i>Add new</button>

        {/* Modal */}
        <div className="modal fade" id="addCategoryTasksModal" tabIndex={-1} aria-labelledby="addCategoryTasksModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <form className={`modal-content ${loading.edit ? 'is-loading' : ''}`} onSubmit={formik.handleSubmit}>
              <div className="modal-header">
                <h4 className="mb-0" id="addCategoryTasksModalLabel">{id ? 'Update task category' : 'New task category'}</h4>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>

              <div className="modal-body">
                <InputField
                  name="title"
                  label="Title"
                  required={true}
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  error={formik.touched.title && formik.errors.title}
                />

                <TextArea
                  rows={5}
                  name="description"
                  label="Description"
                  onChange={formik.handleChange}
                  placeholder="Enter description"
                  value={formik.values.description}
                />
              </div>

              <div className="modal-footer">
                <LinkButton path={`${routes.taskCategories}`} label="Cancel" data-bs-dismiss="modal" className="link text-danger fw-medium" />
                <Button type="submit" className="primary" name={id ? 'Update' : 'Submit'} loading={loading.update} />
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="card border-0 my-3">
        <div className="table-responsive">
          <table className="card-body mb-0 table table-borderless table-striped order-listing">
            <thead>
              <tr className="bg-white">
                <th >#</th>
                <th style={{ width: '26rem' }}>
                  <button
                    className="btn p-0 border-0"
                    onClick={() => { handleFilters('sort', filterData.sort === '-title' ? 'title' : '-title', setFilterData) }}>
                    Title
                    {getSortIcon(filterData.sort.includes('title') ? filterData.sort : '')}
                  </button>
                </th>
                <th style={{ width: '55rem' }}>Description</th>
                <th style={{ width: '18rem' }}>
                  <button
                    className="btn p-0 border-0"
                    onClick={() => { handleFilters('sort', filterData.sort === '-created_at' ? 'created_at' : '-created_at', setFilterData) }}>
                    Create date
                    {getSortIcon(filterData.sort.includes('created_at') ? filterData.sort : '')}
                  </button>
                </th>
                <th style={{ width: '18rem' }}>
                  <button
                    className="btn p-0 border-0"
                    onClick={() => { handleFilters('sort', filterData.sort === '-updated_at' ? 'updated_at' : '-updated_at', setFilterData) }}>
                    Update date
                    {getSortIcon(filterData.sort.includes('updated_at') ? filterData.sort : '')}
                  </button>
                </th>
                <th>Action</th>
              </tr>
            </thead>

            <tbody>
              {taskCategories?.items?.map((item: _Object, i: number) => {
                return (
                  <tr className="align-middle" key={i}>
                    <td>{serialNumber(filterData.per_page, filterData.page, i)}</td>
                    <td >{item?.title}</td>
                    <td className="break-spaces">{item?.description || '-'}</td>
                    <td>{doDateFormatWithTime(item?.created_at)}</td>
                    <td>{doDateFormatWithTime(item?.updated_at)}</td>
                    <td>
                      <ul className="list-inline">
                        <li className="list-inline-item">
                          <button type="button" onClick={() => setId(item?.id)} id="addCategoryTasksModal" className="btn btn-transparent p-0 border-0" data-bs-toggle="modal" data-bs-target="#addCategoryTasksModal">
                            <i className="fa fa-pen"></i>
                          </button>
                        </li>
                        <li className="list-inline-item">
                          <Button type="button" className="link text-danger p-0"
                            onClick={() => deleteItem(item.id)}>
                            <i className="fa fa-trash text-danger"></i>
                          </Button>
                        </li>
                      </ul>

                    </td>
                  </tr>
                )
              })}

              {loading.listing === false && taskCategories?.items?.length === 0 && <tr>
                <td colSpan={12} className="text-center no-entry">
                  <aside>
                    <i className="fa-solid fa-ban"></i>
                    {strings.noRecordFound}
                  </aside>
                </td>
              </tr>}
            </tbody>
          </table>
        </div>
      </div>

      {taskCategories?.pagination?.total_pages > 1 &&
        <Pagination
          current_page={taskCategories?.pagination?.current_page}
          per_page={taskCategories?.pagination?.per_page}
          total_items={taskCategories?.pagination?.total_items}
          total_pages={taskCategories?.pagination?.total_pages}
          onClick={(i: { [key: string]: number }) =>
            setFilterData((prev) => ({
              ...prev,
              page: i.selected + 1,
            }))
          }
        />
      }

    </>
  )
}

export default TaskCategories