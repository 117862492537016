import React, { useEffect, useState } from 'react';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import { reportService } from 'services';
import { doAmountFormat, doDateFormat } from 'utils';
import { _Object } from 'utils/interfaces';
import ReportSkeleton from './skelton';
import moment from 'moment';

const TopBuyers = (props: _Object) => {
  const { dateRange } = props

  const [topBuyers, setTopBuyers] = useState<_Object>([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    reportService.getTopBuyers(dateRange.date1).then((data: _Object) => {
      setLoading(false)
      if (data?.items?.length > 0) {
        setTopBuyers(data)
      } else {
        setTopBuyers([])
      }
    })
  }, [dateRange.date1.start_date])

  const series1 = topBuyers?.items?.length > 0 ? topBuyers?.items?.map((item: _Object) => [parseFloat(item.total.toFixed(2))]) : []

  const options = {
    chart: {
      type: 'bar',
    },
    title: {
      text: 'Top buyers',
      align: 'left',
      margin: 35
    },
    subtitle: {
      text: doAmountFormat(topBuyers?.total ? topBuyers?.total : 0),
      align: 'left',
    },

    xAxis: {
      categories: topBuyers?.items?.map((item: _Object) => `${item.name.substring(0, 10)}\n${item.phone_number}`),
      gridLineWidth: 1,
      lineWidth: 0,
      labels: {
        useHTML: true,
        formatter: function (this: any): string {
          const parts = this.value.split('\n');
          return `<div>${parts[0]}</div><div>${parts[1]}</div>`;
        },

      }
    },

    yAxis: {
      min: 0,
      labels: {
        overflow: 'justify',
        enabled: false, // Hide y-axis labels
      },
      title: {
        text: null,
      },
      gridLineWidth: 0,
      showEmpty: false, // Hide y-axis when there is no data
    },

    tooltip: {
      crosshairs: true,
      shared: true,
      formatter: function (this: Highcharts.TooltipFormatterContextObject): string {
        const number: any = this.points?.[0]?.x;
        const splitNumber: string = (number.split('\n')[1] || '').trim();
        const total = (this.points?.[0]?.y);
        const count = topBuyers?.items?.find((item: _Object) => item.phone_number === splitNumber);

        const tooltipContent =
          '<br />Orders count: ' + count?.count + '<br /><b>Orders total: ' + doAmountFormat(total) + '</b>';
        return tooltipContent;
      },
      style: {
        fontSize: '10px',
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 0, // You can adjust the radius value as needed
        dataLabels: {
          inside: false,
          enabled: true,
          verticalAlign: 'middle',
        },
        groupPadding: 0.2,
      },
    },
    series: [
      {
        name: `${moment(dateRange.date1.start_date).format('DD MMM')} - ${doDateFormat(dateRange.date1.end_date)}`,
        data: series1,
      }],
  };

  return (
    <>
      {loading ? <ReportSkeleton />
        :
        <HighchartsReact highcharts={Highcharts} options={options} />
      }
    </>
  );
};

export default TopBuyers;
