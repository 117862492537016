import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import * as Yup from 'yup'
import { useFormik } from 'formik';

import { routes } from 'utils';
import { tagService } from 'services';
import { _Object } from 'utils/interfaces';
import { Button, InputField, LinkButton, TextArea } from 'views/components';

const CreateTag = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [isEditMode, setEditMode] = useState(false)
  const [initialData, setInitalData] = useState<_Object>({
    name: '',
    slug: '',
    description: ''
  })
  useEffect(() => {
    setEditMode(false)
    if (id) {
      setEditMode(true)
      tagService.getTagDetails(id).then((data) => {
        setInitalData(data)
      })
    }
  }, [])

  const formik = useFormik({
    initialValues: initialData,
    enableReinitialize: true,

    validationSchema: Yup.object({
      name: Yup.string().label('Name').required(),
      slug: Yup.string().label('Slug').required()
    }),

    onSubmit: (value) => {
      setLoading(true)
      if (id) {
        delete value.id,
          delete value.created_at
        delete value.updated_at

        tagService.updateTag(id, value).then((data: _Object) => {
          setTimeout(() => {
            data.error === false && navigate(`${routes.tags}`)
          }, 1000)
          setLoading(false)
        })
      } else {
        value.slug === '' && delete value.slug
        tagService.createTag(value).then((data: _Object) => {
          setTimeout(() => {
            data.error === false && navigate(`${routes.tags}`)
          }, 1000)
        })
      }
    }
  })

  return (
    <>
      <header className="my-2">
        <h2>{isEditMode ? 'Update Tag' : 'Add Tag '}</h2>
        <h4>{isEditMode ? 'Update Tag' : 'Create new Tag'}</h4>
      </header>

      <form className="row filters" onSubmit={formik.handleSubmit}>
        <InputField
          name="name"
          className="col-6"
          label="Name"
          placeholder="Name"
          onChange={formik.handleChange}
          required={true}
          error={formik?.touched.name && formik.errors.name && formik.errors.name}
        />
        <InputField
          name="slug"
          className="col-6"
          label="Slug"
          placeholder="Slug"
          onChange={formik.handleChange}
          required={true}
          error={formik?.touched.slug && formik.errors.slug && formik.errors.slug}
        />

        <TextArea
          name="description"
          label="Description"
          placeholder="Enter description"
          rows={5}
          value={formik.values.description}
          onChange={formik.handleChange}
          error={formik.touched.description && formik.errors.description}
        />

        <footer className="row my-2">
          <div className="col-12 d-flex">
            <Button type="submit" name={isEditMode ? 'Update' : 'Submit'} className="primary" loading={loading} />
            <LinkButton
              path={routes.tags}
              label="Cancel"
              className="link text-dark" />
          </div>
        </footer>
      </form>
    </>
  )
}
export default CreateTag