import CommonService from './common.service';
import { generateQueryParams } from 'utils';
import { _Object } from 'utils/interfaces';

class LocationService extends CommonService {
  async getLocations(params: _Object | string) {
    return await this.get(`locations?${generateQueryParams(params)}`)
  }
  async getLocationDetails(id: string) {
    return await this.get(`locations/${id}`)
  }
  async createLocation(parmas: _Object) {
    return await this.post('locations', parmas)
  }
  async updateLocation(id: string, params: _Object) {
    return await this.put(`locations/${id}`, params)
  }
  async deleteLocation(id: string) {
    return await this.delete(`locations/${id}`)
  }
}

export const locationService = new LocationService();
