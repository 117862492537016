import {
  Sales,
  ProductsList,
  CreateProduct,
  OrdersList,
  Reports,
  Settings,
  MoreFeature,
  Categories,
  CreateCategory,
  BrandsList,
  CreateBrand,
  Suppliers,
  CreateSupplier,
  CreateTag,
  Tags,
  UsersList,
  CreateUser,
  ExpensesList,
  CreateExpense,
  CategoriesList,
  AddCategory,
  Locations,
  CreateLocation,
  InventoyList,
  CreateInventory,
  ProductAttributes,
  TaskList,
  CreateTask,
  TaskCategories,
  ProductAttributeValue,
  OrderDetails,
  TaxRates,
  Customers,
  CreateCustomer,
  ImportCSV
} from 'views/pages';
import { routes } from 'utils';
import { OrderInvoice, PrintReceipt } from 'views/components';


const PrivateRoutes = [
  { path: `${routes.sales}`, exact: true, name: 'sales', component: Sales },
  { path: `${routes.createCustomer}`, exact: true, name: 'sales', component: Sales },

  { path: `${routes.orders}`, exact: true, name: 'orders', component: OrdersList },
  { path: `${routes.orders}/:orderId`, exact: true, name: 'orders', component: OrderDetails },

  { path: `${routes.users}`, exact: true, name: 'users', component: UsersList },
  { path: `${routes.createUser}`, exact: true, name: 'createUser', component: CreateUser },
  { path: `${routes.users}/edit/:id`, exact: true, name: 'updateUser', component: CreateUser },

  { path: `${routes.customers}`, exact: true, name: 'customers', component: Customers },
  { path: `${routes.createCustomers}`, exact: true, name: 'createCustomer', component: CreateCustomer },
  { path: `${routes.customers}/edit/:id`, exact: true, name: 'updateCustomer', component: CreateCustomer },

  { path: `${routes.expense}`, exact: true, name: 'expense', component: ExpensesList },
  { path: `${routes.createExpense}`, exact: true, name: 'createExpense', component: CreateExpense },
  { path: `${routes.expense}/edit/:id`, name: 'updtaExpanse', component: CreateExpense },
  { path: `${routes.expenseCategories}`, exact: true, name: 'expenseCategories', component: CategoriesList },
  { path: `${routes.createExpenseCategory}`, exact: true, name: 'createExpenseCategory', component: AddCategory },
  { path: `${routes.expenseCategories}/edit/:id`, name: 'updtaExpenseCategories', component: AddCategory },

  { path: `${routes.products}`, exact: true, name: 'products', component: ProductsList },
  { path: `${routes.createProduct}`, exact: true, name: 'createProduct', component: CreateProduct },
  { path: `${routes.products}/edit/:id`, name: 'updtaProduct', component: CreateProduct },
  { path: `${routes.products}/import`, name: 'import', component: ImportCSV },

  { path: `${routes.categories}`, exact: true, name: 'categories', component: Categories },
  { path: `${routes.createCategory}`, exact: true, name: 'createCategory', component: CreateCategory },
  { path: `${routes.categories}/edit/:id`, exact: true, name: 'updateCategory', component: CreateCategory },

  { path: `${routes.tags}`, exact: true, name: 'tags', component: Tags },
  { path: `${routes.createTag}`, exact: true, name: 'createTag', component: CreateTag },
  { path: `${routes.tags}/edit/:id`, exact: true, name: 'updateTag', component: CreateTag },

  { path: `${routes.brands}`, exact: true, name: 'brands', component: BrandsList },
  { path: `${routes.createBrand}`, exact: true, name: 'createBrand', component: CreateBrand },
  { path: `${routes.brands}/edit/:id`, exact: true, name: 'updateBrand', component: CreateBrand },

  { path: `${routes.suppilers}`, exact: true, name: 'suppliers', component: Suppliers },
  { path: `${routes.createSuppiler}`, exact: true, name: 'createSupplier', component: CreateSupplier },

  { path: `${routes.suppilers}/edit/:id`, exact: true, name: 'updateSupplier', component: CreateSupplier },

  { path: `${routes.inventory}`, exact: true, name: 'Inventory', component: InventoyList },
  { path: `${routes.createInventory}`, exact: true, name: 'createInventory', component: CreateInventory },
  { path: `${routes.inventory}/edit/:id`, exact: true, name: 'updateInventory', component: CreateInventory },

  { path: `${routes.locations}`, exact: true, name: 'Locations', component: Locations },
  { path: `${routes.createLocation}`, exact: true, name: 'createLocation', component: CreateLocation },
  { path: `${routes.locations}/edit/:id`, exact: true, name: 'updateLocation', component: CreateLocation },

  { path: `${routes.attributes}`, exact: true, name: 'productAttributes', component: ProductAttributes },
  { path: `${routes.attributesValues}/:id/options`, exact: true, name: 'productAttributeValue', component: ProductAttributeValue },
  { path: `${routes.attributes}/:id/values`, exact: true, name: 'updateProductAttributes', component: ProductAttributes },

  { path: `${routes.more}`, exact: true, name: 'MoreFeature', component: MoreFeature },
  { path: `${routes.analytics}`, exact: true, name: 'reports', component: Reports },
  { path: `${routes.settings}`, exact: true, name: 'setting', component: Settings },

  { path: `${routes.taxRates}`, exact: true, name: 'tax', component: TaxRates },

  { path: `${routes.tasks}`, exact: true, name: 'tasks', component: TaskList },
  { path: `${routes.createTask}`, exact: true, name: 'createTask', component: CreateTask },
  { path: `${routes.tasks}/edit/:id`, exact: true, name: 'updateTask', component: CreateTask },
  { path: `${routes.taskCategories}`, exact: true, name: 'taskCategories', component: TaskCategories },
  { path: '/app/pdfs', exact: true, name: 'OrderInvoice', component: OrderInvoice },
  { path: '/app/print', exact: true, name: 'PrintReceipt', component: PrintReceipt },

];
export default PrivateRoutes;
