import CommonService from './common.service';
import { generateQueryParams } from 'utils';
import { _Object } from 'utils/interfaces';

class TaskTagService extends CommonService {
  async getTaskTags(params: _Object | string) {
    return await this.get(`task-tags?${generateQueryParams(params)}`)
  }
  async getTaskTagDetails(id: string) {
    return await this.get(`task-tags/${id}`)
  }
  async createTaskTag(parmas: { [key: string]: boolean | string }) {
    return await this.post('task-tags', parmas)
  }
  async updateTaskTag(id: string, params: { [key: string]: boolean | string }) {
    return await this.put(`task-tags/${id}`, params)
  }
  async deleteTaskTag(id: string) {
    return await this.delete(`task-tags/${id}`)
  }
}
export const taskTagService = new TaskTagService();