import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useFormik } from 'formik';

import { inventoryService, locationService, productService, userService } from 'services'
import { routes, doDateFormatWithTime, serialNumber, strings, handleFilters, getSortIcon, getStatusLabel } from 'utils';
import { _Object } from 'utils/interfaces';

import { Button, SearchField, LinkButton, Pagination, SelectField, Breadcrumbs, SEOHeader, ActiveFilters, ReportExport } from 'views/components';
import { AsyncSelectField } from 'views/components/form-inputs';
import { Link } from 'react-router-dom';

const InventoyList = () => {
  const selectAsyncSelect: any = useRef([])
  const { t } = useTranslation()

  const [loading, setLoading] = useState(false);
  const [inventories, setInventories] = useState<_Object>([])

  const [filters, setFilters] = useState<_Object>({
    users: [],
    locations: [],
    products: []
  })
  const [filterdLabel, setFilteredLabel] = useState<_Object>({})

  const [filterData, setFilterData] = useState<_Object>({
    page: 1,
    per_page: process.env.REACT_APP_PER_PAGE,
    sort: '-created_at',
    filter: {
      status: '',
      order_id: '',
      product_id: '',
      handled_by: '',
      location_id: '',
    }
  })

  const handleCallApi = () => {
    setLoading(true);
    inventoryService.getinventory({ ...filterData, q: searchFormik.values.q }).then((data: _Object) => {
      setInventories(data)
      setLoading(false);
    })
  }

  const searchFormik = useFormik({
    initialValues: { q: '' },
    enableReinitialize: true,
    onSubmit: () => {
      if (searchFormik.values.q.trim() !== '') {
        handleCallApi();
      }
    }
  })

  useEffect(() => {
    if (searchFormik.values.q === '') {
      handleCallApi()
    }
  }, [filterData, searchFormik.values.q])

  useEffect(() => {
    locationService.getLocations('').then((data: _Object) => {
      setFilters((e: _Object) => ({
        ...e,
        locations: data?.items
      }))
    })

    productService.getProducts('').then((data: _Object) => {
      setFilters((e: _Object) => ({
        ...e,
        products: data?.items
      }))
    })

    userService.getUsers('').then((data: _Object) => {
      setFilters((e: _Object) => ({
        ...e,
        users: data?.items
      }))
    })
  }, [])

  const deleteItem = (idToDelete: string) => {
    if (window.confirm('Are you sure to delete this item?')) {
      setLoading(true)
      inventoryService.deleteInventory(idToDelete).then(() => {
        inventoryService.getinventory(filterData).then((data: _Object) => {
          setInventories(data)
          setLoading(false)
        })
      });
    }
  };

  const locationOption = async (locationValue: string) => {
    const data: _Object = await locationService.getLocations({ q: locationValue })
    return data.items?.map((item: _Object) => { return { label: item.name, value: item.id } })
  }

  const productOption = async (productValue: string) => {
    const data: _Object = await productService.getProducts({ q: productValue })
    return data.items?.map((item: _Object) => { return { label: item.name, value: item.id, isDisabled: item.manage_stock === 'false' } })
  }

  const userOption = async (userValue: string) => {
    const data: _Object = await userService.getUsers({ q: userValue })
    return data.items?.map((item: _Object) => { return { label: item.full_name, value: item.id } })
  }

  return (
    <>
      <SEOHeader title="Inventory" />
      <Breadcrumbs
        trails={[
          {
            label: t('inventory.inventory'),
            path: ''
          }
        ]}
      />

      <div className="pos-justify pos-between pos-align pos-center">
        <div className="d-flex header-loading">
          <form onSubmit={searchFormik.handleSubmit}>
            <SearchField
              type="search"
              name="q"
              className="mb-md-0"
              disabled={!searchFormik.values.q}
              value={searchFormik.values.q}
              onChange={(e: _Object) => {
                searchFormik.setFieldValue('q', e.target.value)
              }}
            />
          </form>
          <span className={`ms-4 ${loading === true ? 'is-loading' : ''}`} />
        </div>

        {Object.values(filterData.filter).some(value => !!value) && (
          <ActiveFilters filterdLabel={filterdLabel.filter} setFilteredLabel={setFilteredLabel} setFilterData={setFilterData} />
        )}

        <span className="d-flex align-items-center gap-3">
          <Link
            to="#"
            className="primary text-decoration-none"
            data-bs-toggle="modal" data-bs-target="#export-report"
          >
            {t('common.export_report')}
          </Link>
          <LinkButton className="btn btn-primary" path={routes.createInventory} label={t('inventory.add')}>
            <i className="fa-solid fa-plus text-white"></i>
          </LinkButton>
        </span>
      </div>

      <div className="card border-0 my-3">
        <div className="table-responsive">
          <table className="card-body mb-0 table table-borderless table-striped">
            <thead>
              <tr className="bg-white">
                <th>#</th>

                <th>
                  <button type="button" className={`btn btn-transparent dropdown-toggle p-0 border-0 ${filterData.filter?.product_id?.length > 0 ? 'show filter-add' : 'show'}`}
                    data-bs-toggle="dropdown" aria-expanded="true" data-bs-auto-close="outside">
                    {t('product.product')}
                    <i className="fa-solid fa-chevron-down"></i>
                  </button>
                  <div className="dropdown">
                    <div className="dropdown-menu dropdown-menu-end">
                      <AsyncSelectField
                        isClearable
                        cacheOptions
                        menuIsOpen={true}
                        name="product_id"
                        loadOptions={productOption}
                        ref={(ref: any) => (selectAsyncSelect.current = ref)}
                        defaultOptions={filters?.products?.map((item: _Object) => {
                          return {
                            label: item.name, value: item.id
                          }
                        })}
                        onChange={(val: _Object) => {
                          setFilterData((e) => ({
                            ...e,
                            filter: {
                              ...e.filter,
                              product_id: val?.value || ''
                            }
                          }));
                          setFilteredLabel((prevLabel) => ({
                            filter: {
                              ...prevLabel.filter,
                              product_id: val?.label || '',
                            },
                          }));
                        }}
                      />
                    </div>
                  </div>
                </th>

                <th>{t('common.quantity')}</th>

                <th>
                  <button type="button" className={`btn btn-transparent dropdown-toggle p-0 border-0 ${filterData.filter?.location_id?.length > 0 ? 'show filter-add' : 'show'}`}
                    data-bs-toggle="dropdown" aria-expanded="true" data-bs-auto-close="outside">
                    {t('location.location')}
                    <i className="fa-solid fa-chevron-down"></i>
                  </button>
                  <div className="dropdown">
                    <div className="dropdown-menu dropdown-menu-end">
                      <AsyncSelectField
                        isClearable
                        cacheOptions
                        menuIsOpen={true}
                        name="location_id"
                        loadOptions={locationOption}
                        ref={(ref: any) => (selectAsyncSelect.current = ref)}
                        defaultOptions={filters?.locations?.map((item: _Object) => {
                          return {
                            label: item.name, value: item.id
                          }
                        })}
                        onChange={(val: _Object) => {
                          setFilterData((e) => ({
                            ...e,
                            filter: {
                              ...e.filter,
                              location_id: val?.value || ''
                            }
                          }));
                          setFilteredLabel((prevLabel) => ({
                            filter: {
                              ...prevLabel.filter,
                              location_id: val?.label || '',
                            },
                          }));
                        }}
                      />

                    </div>
                  </div>
                </th>

                <th>{t('order.order')} #</th>

                <th>
                  <button type="button" className={`btn btn-transparent dropdown-toggle p-0 border-0 ${filterData.filter?.status?.length > 0 ? 'show filter-add' : 'show'}`}
                    data-bs-toggle="dropdown" aria-expanded="true" data-bs-auto-close="outside">
                    {t('common.status')}
                    <i className="fa-solid fa-chevron-down"></i>
                  </button>
                  <div className="dropdown">
                    <div className="dropdown-menu dropdown-menu-end">
                      <SelectField
                        name="status"
                        menuIsOpen
                        isSearchableHide={true}
                        options={[
                          { label: t('inventory.correction'), value: 'correction' },
                          { label: t('inventory.received'), value: 'recieved' },
                          { label: t('inventory.return_restock'), value: 'return_restock' },
                          { label: t('inventory.damaged'), value: 'damaged' },
                          { label: t('inventory.theft_or_loss'), value: 'theft_or_loss' },
                          { label: t('inventory.sale'), value: 'sale' },
                        ]}
                        onChange={(e: _Object) => {
                          setFilterData((prev) => ({
                            ...prev,
                            page: 1,
                            filter: {
                              ...prev.filter,
                              status: e?.value || ''
                            }
                          }));
                          setFilteredLabel((prevLabel) => ({
                            filter: {
                              ...prevLabel.filter,
                              status: e?.label || '',
                            },
                          }));
                        }}
                        getOptionLabel={(option: _Object) => option.label}
                        getOptionValue={(option: _Object) => option.value}
                        isClearable
                        controlShouldRenderValue={false}
                        hideSelectedOptions={false}
                      />
                    </div>
                  </div>
                </th>

                <th>{t('common.note')}</th>

                <th>
                  <button type="button" className={`btn btn-transparent dropdown-toggle p-0 border-0 ${filterData.filter?.handled_by?.length > 0 ? 'show filter-add' : 'show'}`}
                    data-bs-toggle="dropdown" aria-expanded="true" data-bs-auto-close="outside">
                    {t('user.user')}
                    <i className="fa-solid fa-chevron-down"></i>
                  </button>
                  <div className="dropdown">
                    <div className="dropdown-menu dropdown-menu-end">
                      <AsyncSelectField
                        isClearable
                        cacheOptions
                        menuIsOpen={true}
                        name="handled_by"
                        loadOptions={userOption}
                        ref={(ref: any) => (selectAsyncSelect.current = ref)}
                        defaultOptions={filters?.users?.map((item: _Object) => {
                          return {
                            label: item.full_name,
                            value: item.id
                          }
                        })}
                        onChange={(val: _Object) => {
                          setFilterData((e) => ({
                            ...e,
                            filter: {
                              ...e.filter,
                              handled_by: val?.value || ''
                            }
                          }));
                          setFilteredLabel((prevLabel) => ({
                            filter: {
                              ...prevLabel.filter,
                              handled_by: val?.label || '',
                            },
                          }));
                        }}
                      />
                    </div>
                  </div>
                </th>

                <th>
                  <button
                    className="btn p-0 border-0"
                    onClick={() => { handleFilters('sort', filterData.sort === '-created_at' ? 'created_at' : '-created_at', setFilterData) }}>
                    {t('common.createDate')}
                    {getSortIcon(filterData.sort.includes('created_at') ? filterData.sort : '')}
                  </button>
                </th>

                {/* <th style={{ width: '12rem' }} className="date">
                  <button
                    className="btn p-0 border-0"
                    onClick={() => { handleFilters('sort', filterData.sort === '-updated_at' ? 'updated_at' : '-updated_at', setFilterData) }}>
                    Update date
                    {getSortIcon(filterData.sort.includes('updated_at') ? filterData.sort : '')}
                  </button>
                </th> */}

                <th className="action">{t('common.action')}</th>
              </tr>
            </thead>

            <tbody>
              {inventories?.items?.map((item: _Object, i: number) => {
                return (
                  <tr className="align-middle" key={i}>
                    <td>{serialNumber(filterData.per_page, inventories?.pagination?.current_page, i)}</td>
                    <td className="break-spaces">
                      <LinkButton path={`${routes.products}`} label={`${item?.product?.name}`} className="link p-0 text-dark fw-normal" />
                      <small>{t('common.sku')}: {item?.product?.sku}</small>
                    </td>

                    <td>{item?.quantity}</td>
                    <td>
                      <LinkButton path={`${routes.locations}`} label={`${item?.location?.name || '-'}`} className="link p-0 text-dark fw-normal" />
                      <small>{item?.sub_location?.name ?? '-'}</small>
                    </td>
                    <td>{item.order?.order_number || '-'}</td>
                    <td>{getStatusLabel(item?.status)}</td>
                    <td className="break-spaces">{item.description?.length > 0 ? item.description : '-'}</td>
                    <td>
                      <LinkButton path={`${routes.users}`} label={`${item?.user?.first_name} ${item?.user?.last_name}`} className="link p-0 text-dark fw-normal" />
                    </td>
                    <td>{doDateFormatWithTime(item.created_at)}</td>
                    {/* <td>{doDateFormatWithTime(item.updated_at)}</td> */}
                    <td>
                      <ul className="list-inline">
                        <li className="list-inline-item">
                          <Button
                            className="link text-danger p-0"
                            onClick={() => deleteItem(item.id)}>
                            <i className="fa fa-trash text-danger"></i>
                          </Button>
                        </li>
                      </ul>
                    </td>
                  </tr>
                )
              })}
              {loading === false && inventories?.items?.length === 0 && <tr>
                <td colSpan={10} className="text-center no-entry">
                  <aside>
                    <i className="fa-solid fa-ban"></i>
                    {strings.noRecordFound}
                  </aside>
                </td>
              </tr>
              }
            </tbody>
          </table>
        </div>
      </div>

      {inventories?.pagination?.total_pages > 1 &&
        <Pagination
          current_page={inventories?.pagination?.current_page}
          per_page={inventories?.pagination?.per_page}
          total_pages={inventories?.pagination?.total_pages}
          total_items={inventories?.pagination?.total_items}
          onClick={(i: { [key: string]: number }) =>
            setFilterData((prev) => ({
              ...prev,
              page: i.selected + 1,
            }))
          }
        />
      }

      <ReportExport
        title="Export report inventory"
        type="inventory"
      />
    </>
  )
}
export default InventoyList
