import React from 'react';
import moment from 'moment';

import { strings } from './strings'
import { routes } from './routes';
import { _Object } from './interfaces';
import jsPDF from 'jspdf'
import { renderToString } from 'react-dom/server'
import { Cut, Printer, Text, render, } from 'react-thermal-printer';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUp, faArrowDown, faRightLeft } from '@fortawesome/free-solid-svg-icons'
import { OrderInvoice } from 'views/components';
import { poppinsFont } from 'views/components/pdfs/Poppins-Regular-normal';
import { countries } from './countries';

export {
  strings,
  routes,
}

export const generateQueryParams = (args: any) => {
  let str = ''
  let replace = ''
  for (const key in args) {
    if (typeof (args[key]) === 'object') {
      for (const key1 in args[key]) {
        str += `&filters[${key1}]=${args[key][key1]}`
        replace = str.slice(1)
      }
    } else {
      str += `&${key}=${args[key]}`
      replace = str.slice(1)
    }
  }
  return replace
}

export const serialNumber = (per_page: number, page: number, i: number) => {
  return ((per_page * page) - per_page + 1) + i
}

export const doDateFormat = (date: string, format = 'MMM DD, YYYY') => {
  return moment(date).format(format)
}

export const doTimeFormat = (date: string, format = 'hh:mm A') => {
  return moment(date).format(format)
}

export const doDateFormatWithTime = (date: string) => {
  return moment(date).format('MMM DD, YYYY hh:mm A')
}

export const generateAvatar = (first_name: string, last_name: string) => {
  const fullName = `${first_name} ${last_name}`
  return fullName.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
}

export const capitalize = (params: string) => {
  if (!params) return '-'
  const firstLetter = `${params}`
  return firstLetter.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
}

// export const capitalizeFirstLetter = (name = '') => {
//   return name.charAt(0).toUpperCase() + name.slice(1)
// }

export const capitalizeFirstLetter = (name = '') => {
  const firstLetter = name?.length > 0 ? name?.charAt(0).toUpperCase() : ''
  const restOfName = name?.length > 0 ? name?.slice(1).toLowerCase() : ''
  const capitalizedUserName = firstLetter + restOfName
  return capitalizedUserName;
};

export const getOrderStatusLabel = (status: string) => {
  switch (status) {
    case 'pending_payment':
      return 'Pending payment'
    case 'failed':
      return 'Failed'
    case 'processing':
      return 'Processing'
    case 'completed':
      return 'Completed'
    case 'on_hold':
      return 'On hold'
    case 'cancelled':
      return 'Cancelled'
    case 'refunded':
      return 'Refunded'
    case 'pending':
      return 'Pending'
  }
}

export const getStatusLabel = (status: string) => {
  switch (status) {
    case 'correction':
      return 'Correction'
    case 'recieved':
      return 'Recieved'
    case 'return_restock':
      return 'Return Restock'
    case 'damaged':
      return 'Damaged'
    case 'theft_or_loss':
      return 'Theft or Loss'
    case 'sale':
      return 'Sale'
  }
}

export const getPaymentModeLabel = (status: string) => {
  switch (status) {
    case 'cash':
      return 'Cash'
    case 'phonepe':
      return 'PhonePe'
    case 'paytm':
      return 'Paytm'
    case 'googlepay':
      return 'Google Pay'
    case 'upi':
      return 'UPI '
    case 'card':
      return 'Debit/Credit Card'
  }
}

export const getPriorityLabel = (status: string) => {
  switch (status) {
    case 'normal':
      return 'Normal'
    case 'urgent':
      return 'Urgent'
    case 'high':
      return 'High'
    case 'low':
      return 'Low'
    case 'medium':
      return 'Medium '
  }
}

export const getRoleLabel = (status: string) => {
  switch (status) {
    case 'administrator':
      return 'Administrator'
    case 'super_admin':
      return 'Super Admin'
    case 'outlet_manager':
      return 'Outlet Manager'
    case 'salesman':
      return 'Salesman'
  }
}

export const getFilteredLabel = (key: string) => {
  switch (key) {
    case 'customer_id':
      return 'Customer'
    case 'tag_id':
      return 'Tag '
    case 'brand_id':
      return 'Brand'
    case 'brand_ids':
      return 'Brand'
    case 'category_id':
      return 'Category'
    case 'product_id':
      return 'Product'
    case 'location_id':
      return 'Location'
    case 'handled_by':
      return 'User'
    case 'supplier_id':
      return 'Supplier'
    case 'status':
      return 'Status'
    case 'payment_status':
      return 'Status'
    case 'stock_status':
      return 'Stock'
    case 'role':
      return 'Role'
  }
}

export const stock_status = (status: string) => {
  switch (status) {
    case 'instock':
      return 'In stock'
    case 'outofstock':
      return 'Out of Stock'
  }
}

export const getPaymentStatusLabel = (status: string) => {
  switch (status) {
    case 'paid':
      return 'Paid'
    case 'unpaid':
      return 'Unpaid'
    case 'partialpaid':
      return 'Partial paid'
  }
}

export const doGraphTimeDateFormat = (start: string, end: string) => {
  if (start === end) {
    return '%H:%M'
  } else if (moment(start).format('YYYY') !== moment(end).format('YYYY')) {
    return '%Y'
  } else if (start !== end) {
    return '%b %e'
  }
}

export const locationType = (status: string) => {
  switch (status) {
    case 'warehouse':
      return 'Warehouse'
    case 'outlet':
      return 'Outlet'
  }
}

export const getSortIcon = (sortKey: string) => {
  if (!sortKey) return <FontAwesomeIcon icon={faRightLeft} className="arrow-up-down" />;
  if (sortKey.includes('-')) return <FontAwesomeIcon icon={faArrowDown} />;
  if (!sortKey.includes('-')) return <FontAwesomeIcon icon={faArrowUp} />;
}

export const handleCartTotal = (cartItem: any, globalSettings: any) => {
  let itemTax = 0;
  let itemTotal = 0;
  let itemSubTotal = 0;
  let itemDiscount = 0;
  let totalDiscount = 0;

  cartItem?.lineItems?.forEach((item: any) => {
    itemSubTotal += item.price * item.quantity;
    itemDiscount += globalSettings.store_enable_discount && item.discount ? item.discount : 0;
  });

  totalDiscount += globalSettings.store_enable_discount && cartItem?.discounts?.amount ? cartItem.discounts.amount : 0;

  cartItem?.lineItems?.forEach((item: any) => {
    if (item.tax_rate) {
      const taxRate = (parseFloat(item.tax_rate.rate) / 100) * itemSubTotal;
      itemTax += taxRate;
    }
  });

  if (globalSettings.store_prices_entered_with_tax === 'including') {
    if (itemDiscount || totalDiscount) {
      itemTotal = itemSubTotal - (itemDiscount + totalDiscount);
    } else {
      itemTotal = itemSubTotal;
    }
  } else {
    itemTotal = itemSubTotal + itemTax;
  }
  return {
    tax: itemTax,
    subTotal: itemSubTotal,
    discount: itemDiscount,
    total: itemTotal,
  };
};


export const handleFilters = (key: string, value: any, setState: any) => {

  if (key === 'filters') {
    setState((pre: _Object) => ({
      ...pre,
      filters: {
        ...pre.filters,
        [value?.key]: Array.isArray(value?.value) ? value?.value?.map((data: { [key: string]: string }) => { return data.value }) || '' : value?.value?.value || ''
      }
    }))
  } else {
    setState((pre: _Object) => ({
      ...pre,
      page: 1,
      [key]: value
    }))
  }
}

//Close modals
export const closeModal = (selector = 'create') => {
  const closeButton = document.querySelectorAll(`#${selector} .btn-close`)

  if (closeButton[0] instanceof HTMLElement) {
    closeButton[0]?.click()
  }
}

export const formatedAddress = (item: _Object) => {
  return `${item?.address1}, ${item?.address2}, ${item?.city}, ${item?.postcode} - ${item?.country}`;
}

export const generateFormattedAddress = (item: _Object) =>
  [item?.address1, item?.address2, item?.city, item?.state ? item?.state : item?.province, countries.find((country) => country.value === item?.country)?.label, item?.postcode]
    .filter(Boolean)
    .join(', ');

export const getInputFieldValue = (id: string) => {
  return (parseFloat((document.getElementById(id) as HTMLInputElement)?.value) || 0);
}


export const updateInputFieldValue = (id: string, value: any) => {
  (document.getElementById(id) as HTMLInputElement).value = value
  return true;
}

export const getSeriesGraphLabel = (statDate: any, endDate: any) => {
  return `${moment(statDate).format('DD MMM')} - ${doDateFormat(endDate)}`;
};


export const doAmountFormat = (params: any) => {
  return process.env.REACT_APP_CURRENCY + '' + params?.toLocaleString('hi', { maximumFractionDigits: 2 })
}

export const doAmountFormatWithoutCurrency = (params: any) => {
  return params?.toLocaleString('hi', { maximumFractionDigits: 2 })
}

export const generatePdf = (type: string, data: _Object, globalSettings: _Object, setLoading: any) => {
  setLoading({ pdf: true });
  let components: any = ''
  components = <OrderInvoice data={data} globalSettings={globalSettings} />
  const saveFile: any = `Invoice-${data.order_number || '-'}`

  const string = renderToString(components)
  const pdf = new jsPDF('p', 'pt', 'a4', true) // 595 x 842 in pixels

  pdf.addFileToVFS('Poppins-regular-normal.ttf', poppinsFont,)
  pdf.addFont('Poppins-regular-normal.ttf', 'Poppins', 'normal')

  pdf.html(string,
    {
      callback: function (pdf) {
        // pdf.deletePage(pdf.getNumberOfPages())
        if (type === 'pdf') {
          pdf.save(`${saveFile}.pdf`)
          setLoading({ pdf: false });
        } else if (type === 'print') {
          pdf.autoPrint();
          pdf.output('dataurlnewwindow');
          setLoading({ pdf: false });
        }
      },
    })
}

export const handleSeries = (type: string, dateRange: _Object, series1: _Object, series2: _Object, isComparison: boolean) => {
  let series = []

  if (isComparison === true) {
    series = [
      {
        name: `${moment(dateRange.date1.start_date).format('DD MMM')} - ${doDateFormat(dateRange.date1.end_date)}`,
        data: series1,
      },
      {
        dashStyle: type == 'chart' ? 'dot' : null,
        name: `${moment(dateRange.date2.start_date).format('DD MMM')} - ${doDateFormat(dateRange.date2.end_date)}`,
        data: series2,
      }
    ]
  } else {
    series = [
      {
        name: `${moment(dateRange.date1.start_date).format('DD MMM')} - ${doDateFormat(dateRange.date1.end_date)}`,
        data: series1,
      }]
  }
  return series;
}

export const generateNumInWords = (number: any) => {

  const first = ['', 'one ', 'two ', 'three ', 'four ', 'five ', 'six ', 'seven ', 'eight ', 'nine ', 'ten ', 'eleven ', 'twelve ', 'thirteen ', 'fourteen ', 'fifteen ', 'sixteen ', 'seventeen ', 'eighteen ', 'nineteen '];
  const tens = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];
  const mad = ['', 'thousand', 'million', 'billion', 'trillion'];
  let word = '';

  for (let i = 0; i < mad.length; i++) {
    let tempNumber = number % (100 * Math.pow(1000, i));
    if (Math.floor(tempNumber / Math.pow(1000, i)) !== 0) {
      if (Math.floor(tempNumber / Math.pow(1000, i)) < 20) {
        word = first[Math.floor(tempNumber / Math.pow(1000, i))] + mad[i] + ' ' + word;
      } else {
        word = tens[Math.floor(tempNumber / (10 * Math.pow(1000, i)))] + ' ' + first[Math.floor(tempNumber / Math.pow(1000, i)) % 10] + mad[i] + ' ' + word;
      }
    }

    tempNumber = number % (Math.pow(1000, i + 1));
    if (Math.floor(tempNumber / (100 * Math.pow(1000, i))) !== 0) word = first[Math.floor(tempNumber / (100 * Math.pow(1000, i)))] + 'hunderd ' + word;
  }

  return word;
}

const receiptTemplate = (cart: _Object, date: any, globalSettings: _Object, orderNumber: any, payment_mode: string) => {
  const cartTotal = handleCartTotal(cart, globalSettings); 
  return (
    <Printer type="epson">
      <Text align="center" bold={true}>{globalSettings?.store_name?.toUpperCase()}</Text>
      <Text align="center">{globalSettings?.store_address}</Text>
      <Text align="center">-------------------------------</Text>
      {/* Combine BILL NO. and Date in one line */}
      <Text align="left">{`BILL NO.: ${orderNumber}`}</Text>
      <Text align="left">{`Date: ${doDateFormatWithTime(date)}`}</Text>
      <Text align="left">{`CUSTOMER: ${cart.customer.first_name && cart.customer.last_name ? generateAvatar(cart.customer.first_name, cart.customer.last_name) : 'Guest' + ' ' + (cart.customer?.mobile_number ? cart.customer.mobile_number : '')}`}</Text>
      <Text align="center">-------------------------------</Text>
      <Text align="left" style={{ marginBottom: 10 }}>
        {('#').padEnd(2)} {('ITEMS').padEnd(13)} {('QTY').padEnd(3)} {('RATE').padEnd(4)} AMOUNT
      </Text>
      {cart.lineItems.map((item: _Object, index: number) => {
        const productName = item.name.substring(0, 13);
        return (
          <Text key={index} align="left">
            {`${`${(index + 1)}`.padEnd(2)} ${capitalizeFirstLetter(productName).padEnd(13)} ${(`${item.quantity}`).padEnd(3)} ${(`${item.price}`).padEnd(4)} ${item.quantity * item.price}`}
          </Text>
        );
      })}
      <Text align="center">-------------------------------</Text>
      <Text align="left">{'TOTAL'.padEnd(20)} = Rs{cartTotal.subTotal}</Text>
      {/* <Text align="left">{'TAX'.padEnd(20)} = {cartTotal.tax.toLocaleString('hi', { maximumFractionDigits: 2 })}</Text> */}
      {/* <Text align="left">{'GRAND TOTAL'.padEnd(20)} = Rs{cartTotal.total}</Text> */}
      <Text align="left">{'PAYMENT MODE'.padEnd(20)} = {payment_mode.toUpperCase()}</Text>
      <Text align="center">-------------------------------</Text>
      <Text align="center" style={{ marginTop: 10 }}>Thanks</Text>
      <Cut lineFeeds={3} />
    </Printer>
  )
}

export const doReceiptPrint = async (device: _Object, cart: _Object, date: _Object, globalSettings: _Object, orderNumber: _Object, payment_mode: string) => {

  const data = await render(receiptTemplate(cart, date, globalSettings, orderNumber, payment_mode));

  await device?.open();
  await device?.selectConfiguration(1);
  await device?.claimInterface(0);
  await device?.transferOut(
    device.configuration.interfaces[0].alternate.endpoints.find((obj: _Object) => obj.direction === 'out')
      .endpointNumber,
    data,
  );
  await device.close();
};

