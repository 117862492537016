import React, { useEffect, useState } from 'react'
import { useParams, useLocation, useNavigate } from 'react-router-dom';

import * as Yup from 'yup'
import { useFormik } from 'formik';

import { expenseService } from 'services';
import { routes } from 'utils';
import { _Object } from 'utils/interfaces';
import { InputField, TextArea, Button, LinkButton, SelectField } from 'views/components';

const CreateExpense = () => {
  const { id } = useParams()
  const location = useLocation()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [isEditMode, setEditMode] = useState(false)
  const [categories, setCategroies] = useState<_Object>([])
  const [initialData, setInitialData] = useState<_Object>({})

  useEffect(() => {
    setEditMode(false)
    setInitialData({
      category_id: isEditMode ? formik.values.category_id : '',
      name: '',
      cost: '',
      description: ''
    })
    if (id) {
      setEditMode(true)
      expenseService.getExpenseDetails(id).then((data: _Object) => {
        setInitialData(data)
      })
    }
    expenseService.getExpensesCategories().then((data: _Object) => {
      setCategroies(data.items)
    })
  }, [location])

  const formik = useFormik({
    initialValues: initialData,
    enableReinitialize: true,
    validationSchema: Yup.object({
      category_id: Yup.string().label('Category id').required(),
      name: Yup.string().label('Name').required(),
      cost: Yup.string().label('Cost').required(),
      description: Yup.string().label('Descriptions').required(),
    }),
    onSubmit: (values) => {
      setLoading(true)
      if (id) {
        delete values.id,
          delete values.created_by
        delete values.created_at
        delete values.category
        delete values.updated_at
        expenseService.updateExpense(id, values).then((data: _Object) => {
          data.error === false && navigate(`${routes.expense}`)
        })
      } else {
        expenseService.createExpense(values).then((data: _Object) => {
          data.error === false && navigate(`${routes.expense}`)
        })
      }
      setLoading(false)
    },
  })

  return (
    <>
      <header className="my-2">
        <h2>{isEditMode ? 'Update expense' : 'Create expense'}</h2>
        <h4>{isEditMode ? 'Update expense' : 'Create new expense'}</h4>
      </header>

      <form className="row filters" onSubmit={formik.handleSubmit}>

        <InputField
          name="name"
          className="col-4"
          label="Name"
          required={true}
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && formik.errors.name}
        />

        <InputField
          type="number"
          name="cost"
          className="col-4"
          label="Cost"
          required={true}
          value={formik.values.cost}
          onChange={formik.handleChange}
          error={formik.touched.cost && formik.errors.cost}
        />

        <SelectField
          name="category_id"
          className="col-4"
          label="Category"
          required={true}
          options={categories}
          value={categories?.filter((obj: _Object) => obj.id === formik.values.category_id)}
          onChange={(e: _Object) => {
            formik.setFieldValue('category_id', e.id)
          }}
          error={formik.touched.category_id && formik.errors.category_id}
          getOptionLabel={(option: _Object) => option.name}
          getOptionValue={(option: _Object) => option.id}
          isClearable
        />

        <TextArea
          name="description"
          className="col-12"
          label="Description"
          placeholder="Enter description"
          value={formik.values.description}
          onChange={formik.handleChange}
          error={formik.touched.description && formik.errors.description}
        />

        <footer className="row my-2">
          <div className="col-12 d-flex gap-3">
            <Button
              type="submit"
              name={isEditMode ? 'Update' : 'Submit'}
              className="primary"
              disabled={loading} />

            <LinkButton
              path={routes.expense}
              label="Cancel"
              className="link text-dark" />
          </div>
        </footer>
      </form>
    </>
  )
}
export default CreateExpense;