import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoice, faCirclePlus, faPrint, faRotate } from '@fortawesome/free-solid-svg-icons';

import moment from 'moment';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useParams } from 'react-router';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useSelector } from 'react-redux';

import { _Object } from 'utils/interfaces';
import { orderService, paymentService } from 'services';
import { capitalizeFirstLetter, closeModal, doAmountFormat, doDateFormatWithTime, generateFormattedAddress, generatePdf, getPaymentModeLabel, getPaymentStatusLabel, routes } from 'utils'

import { Breadcrumbs, Button, InputField, SEOHeader, SelectField } from 'views/components';

function OrderDetails() {

  const { orderId } = useParams()
  const { globalSettings } = useSelector((state: _Object) => state.session)
  const [loading, setLoading] = useState<_Object>({
    page: false,
    btn: false,
    pdf: false,
  })
  const [OrderDetails, setOrderDetails] = useState<_Object>({})

  useEffect(() => {
    if (orderId) {
      setLoading({ page: true })
      orderService.getOrderDetails(orderId).then((data: _Object) => {
        if (data) {
          setOrderDetails(data)
          setLoading({ page: false })
        }
      })
    }
  }, [orderId])

  const handleRecalculateOrder = () => {
    if (window.confirm('Are you sure? This will recalculate order total.')) {
      setLoading({ pdf: true })
      orderService.recalculateOrder(orderId).then(() => {
        orderService.getOrderDetails(orderId).then((data: _Object) => {
          if (data) {
            setOrderDetails(data)
            setLoading({ pdf: false })
          }
        })
      });
    }
  }

  const paidBalance = OrderDetails?.payments?.reduce((acc: number, item: { amount: number }) => {
    return acc + item.amount;
  }, 0);
  const dueBalance = OrderDetails.total - paidBalance

  const formik = useFormik({
    initialValues: {
      amount: '',
      payment_date: '',
      payment_ref_number: '',
      payment_mode: '',
      order_id: orderId,
      status: 'paid',
    },
    enableReinitialize: true,

    validationSchema: Yup.object({
      amount: Yup.number()
        .label('Amount')
        .positive('Amount must be greater than zero')
        .test('isValidAmount', 'Amount must not exceed balance due.', function (value: any) {
          const tolerance = 0.0001; // Adjust this value based on your specific needs
          return parseFloat(value) - dueBalance <= tolerance;
        })
        .test('maxDecimalPlaces', 'Amount must have at most 2 decimal places', function (value: any) {
          const decimalPlaces = value.toString().split('.')[1]?.length || 0;
          return decimalPlaces <= 2;
        })
        .test('maxThreeDigitAfterDecimal', 'Regular price must have at most 2 decimal places when we add 3 digit after decimal through error', function (value: any) {
          const decimalPart = value.toString().split('.')[1];
          return !decimalPart || decimalPart.length <= 2 || decimalPart.slice(2).length === 0;
        })
        .required('Amount is required'),

      payment_date: Yup.string().label('Date').required('Date is required'),
      payment_mode: Yup.string().label('Payment mode').required('Payment mode is required'),
    }),

    onSubmit: (values, { resetForm }) => {
      setLoading({ btn: true });
      paymentService.addPayment(values).then((data: _Object) => {
        setLoading({ btn: false });
        if (data) {
          resetForm();
          closeModal('addPaymentModal');
          setLoading({ page: true })
          orderService.getOrderDetails(orderId).then((data: _Object) => {
            if (data) {
              setOrderDetails(data)
              setLoading({ page: false })
            }
          })

        }
      });
    },
  });

  return (
    <>
      <SEOHeader title="Order details" />
      <div className="d-flex justify-content-between position-relative">
        <Breadcrumbs
          trails={[
            {
              label: 'Orders',
              path: `${routes.orders}`
            },
            {
              label: `${OrderDetails.order_number}`,
              path: ''
            }
          ]}
        />

        {loading.pdf == true && <span className="loader order-loader"></span>}

      </div>

      <div className={`confirmed-card card border-0 mb-3 ${loading.page === true ? 'is-loading' : ''}`}>
        <div className="card-header bg-white border-0 pt-3 pos-justify pos-between">
          <h3 className="position-relative mb-0">Your order has been confirmed&nbsp;
            <img src="/assets/images/success.png" width={22} height={22} alt="" className="img-fluid success-icon" />
          </h3>
          <ul className="list-inline d-flex align-items-center mb-0">
            <li className="list-inline-item">
              <Button
                className="link text-dark p-0 me-3"
                onClick={() => handleRecalculateOrder()}
              >
                <FontAwesomeIcon icon={faRotate} className="m-0 fs-5" />
              </Button>
            </li>
            <li className="list-inline-item">

              <Button
                className="link text-dark p-0 me-3"
                onClick={() => generatePdf('pdf', OrderDetails, globalSettings, setLoading)}
              >

                <FontAwesomeIcon icon={faFileInvoice} className="m-0 fs-5" />
              </Button>
            </li>

            <li className="list-inline-item">
              <Button
                className="link text-dark p-0"
                onClick={() => generatePdf('print', OrderDetails, globalSettings, setLoading)}
              >
                <FontAwesomeIcon icon={faPrint} className="m-0 fs-5" />
              </Button>
            </li>
          </ul>
        </div>
        <div className="card-body pt-0">

          <div className="row mt-4">
            <div className="col">
              <strong>Order number</strong>
              <p className="mt-2 mb-0">{OrderDetails.order_number || '-'}</p>
            </div>

            <div className="col">
              <strong>Order date</strong>
              <p className="mt-2 mb-0">{doDateFormatWithTime(OrderDetails.created_at)}</p>
            </div>

            <div className="col">
              <strong>Payment status</strong>
              <p className="mt-2 mb-0">
                {OrderDetails?.payment_status ? getPaymentStatusLabel(OrderDetails.payment_status) : '-'}
              </p>
            </div>

            <div className="col">
              <strong>Customer note</strong>
              <p className="mt-2 mb-0">
                {OrderDetails?.order_note ? capitalizeFirstLetter(OrderDetails.order_note) : '-'}
              </p>
            </div>

            <div className="col">
              <strong>Billing details</strong>
              <p className="mt-2 mb-0">
                {generateFormattedAddress(OrderDetails?.billing)}
              </p>
            </div>

            <div className="col">
              <strong>Customer</strong>

              <p className="mt-2 mb-0">{capitalizeFirstLetter(OrderDetails?.customer?.full_name)}</p>

              <p className="mb-0">
                <a href="tel:+4733378901" className="text-dark text-decoration-none" style={{ fontSize: '13px' }}>{OrderDetails?.customer?.mobile_number}</a>
              </p>
            </div>
          </div>

          <hr />

          <div className="card border-0 my-3">
            <div className="table-responsive">
              <table className="card-body mb-0 table table-borderless table-striped order-listing">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th className="price">Price</th>
                    <th className="quantity">Qty</th>
                    <th className="total">Total</th>
                    <th className="tax">Tax</th>
                  </tr>
                </thead>

                <tbody>
                  {OrderDetails?.order_items?.map((item: _Object, i: number) => {
                    return (
                      <tr key={item.id}>
                        <td>{i + 1}</td>
                        <td className="break-spaces">{item.item_name}</td>
                        <td><strong>{doAmountFormat(item.price ? item.price : 0)}</strong></td>
                        <td>{item.quantity}</td>
                        <td><strong>{doAmountFormat(item.subtotal ? item.subtotal : 0)}</strong></td>
                        <td>{doAmountFormat(item?.tax_total ? item?.tax_total : 0)}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>

          <hr />

          <div className="row justify-content-between">
            <div className="col col-md-6 col-lg-6 col-xl-5">
              <p className="d-flex align-items-center"> <strong className="me-2">Payments</strong>
                {OrderDetails.payment_status !== 'paid' && OrderDetails.payment_status !== null &&
                  <button type="button" className="btn btn-transparent p-0 border-0" data-bs-toggle="modal" data-bs-target="#addPaymentModal">
                    <FontAwesomeIcon icon={faCirclePlus} className="me-0 text-primary" />
                  </button>
                }
                {/* Modal */}
                <div className="modal fade" id="addPaymentModal" tabIndex={-1} aria-labelledby="addPaymentModalLabel" aria-hidden="true">
                  <div className="modal-dialog modal-dialog-centered">
                    <form className="modal-content" onSubmit={formik.handleSubmit}>
                      <div className="modal-header">
                        <aside>
                          <h4 className="mb-0">Add payment</h4>
                        </aside>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>
                      <div className="modal-body">
                        <div className="row">
                          <div className="form-group col-12 col-md-6">
                            <label className="label-form mb-1">Date {<span className="text-danger">*</span>}</label>

                            <DatePicker
                              dateFormat="MMM dd, yyyy"
                              className={`${formik.touched.payment_date && formik.errors.payment_date && 'invalid'}`}
                              placeholderText="Enter here"
                              onChange={(value: any) => {
                                const selectedDate = moment(value).format('YYYY-MM-DD')
                                formik.setFieldValue('payment_date', selectedDate)
                              }}
                              selected={formik.values.payment_date ? moment(formik.values.payment_date, 'YYYY-MM-DD').toDate() : null}
                              maxDate={new Date()}
                            />

                            {formik.touched.payment_date && formik.errors.payment_date && (
                              <span className="invalid-feedback text-danger d-block mt-1">
                                {formik.errors.payment_date}
                              </span>
                            )}
                          </div>

                          <InputField
                            label="Reference no. #"
                            name="payment_ref_number"
                            value={formik.values.payment_ref_number}
                            onChange={formik.handleChange}
                            className="col-12 col-md-6"
                            error={formik.touched.payment_ref_number && formik.errors.payment_ref_number}

                          />
                          <InputField
                            label="Amount"
                            type="number"
                            required="true"
                            name="amount"
                            value={formik.values.amount}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            className="col-12 col-md-6"
                            error={formik.touched.amount && formik.errors.amount}
                          />

                          <SelectField
                            name="payment_mode"
                            label="Payment mode"
                            required="true"
                            className="col-12 col-md-6"
                            value={{ value: formik.values.payment_mode }}
                            onBlur={formik.handleBlur}
                            options={[
                              { label: 'Cash', value: 'cash' },
                              { label: 'Card', value: 'card' },
                              { label: 'UPI', value: 'upi' },
                              // { label: 'PhonePe', value: 'phonepe' },
                              // { label: 'Paytm', value: 'paytm' },
                              // { label: 'Google Pay', value: 'googlepay' },
                            ]}
                            onChange={(e: _Object) => {
                              formik.setFieldValue('payment_mode', e?.value || '')
                            }}
                            error={formik.touched.payment_mode && formik.errors.payment_mode}
                            isClearable
                          />

                        </div>
                      </div>
                      <div className="modal-footer">
                        <Button name="Cancel" type="button" onClick={() => formik.resetForm()} data-bs-dismiss="modal" className="link text-danger" />
                        <Button
                          loading={loading.btn}
                          className="primary"
                          name={'Submit'}
                          disabled={
                            !formik.values.amount ||
                            !formik.values.payment_mode ||
                            !formik.values.payment_date ||
                            (formik.touched.amount && formik.errors.amount)
                          }
                        />

                      </div>
                    </form>
                  </div>
                </div>
              </p>
              <table className="table table-bordered table-striped order-payments">
                <thead>
                  <tr className="h-auto">
                    <th style={{ width: '160px' }}>Date</th>
                    <th>Ref. no. #</th>
                    <th>Amount</th>
                    <th style={{ width: '160px', textAlign: 'left' }}>Payment mode</th>
                  </tr>
                </thead>
                <tbody className="h-auto">
                  {OrderDetails?.payments?.map((item: _Object) => {
                    return (
                      <tr key={item.id} className="h-auto">
                        <td>{doDateFormatWithTime(item.created_at)}</td>
                        <td>{item.payment_ref_number ? capitalizeFirstLetter(item.payment_ref_number) : '-'}</td>
                        <td>{doAmountFormat(item.amount)}</td>
                        <td style={{textAlign: 'left'}}>{getPaymentModeLabel(item.payment_mode)}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
            <div className="col-4 col-md-4 col-lg-4 col-xl-4 offset-md-2 offset-lg-2 offset-xl-3">
              <ul className="p-0">

                <li className="d-flex justify-content-between align-items-center border-0 px-0 pb-1">
                  Items total <span>{doAmountFormat(OrderDetails?.subtotal || '0')}</span>
                </li>
                {OrderDetails.discount_total !== 0 && OrderDetails.discount_total !== null &&
                  <li className="d-flex justify-content-between align-items-center border-0 px-0 pb-1">
                    Discount <span>{OrderDetails.discount_total}</span>
                  </li>}
                {OrderDetails?.fee_total !== 0 && OrderDetails?.fee_total !== null &&
                  <li className="d-flex justify-content-between align-items-center border-0 px-0 pb-1">
                    Fee <span>{OrderDetails.fee_total ? OrderDetails.fee_total : '-'}</span>
                  </li>
                }
                {OrderDetails?.tax?.length > 0 && OrderDetails?.tax?.map((taxItem: _Object, i: number) => {
                  return (
                    <li key={i} className="d-flex justify-content-between align-items-center border-0 px-0 pb-1">
                      {taxItem?.tax_name?.toUpperCase()} {taxItem?.tax_rate}% <span>{doAmountFormat(taxItem?.amount ? taxItem?.amount : 0)}</span>
                    </li>
                  )
                })}
                <li className="d-flex justify-content-between align-items-center border-0 px-0 pb-1">
                  <strong className="fs-5">Total</strong>  <strong className="fs-5">{doAmountFormat(OrderDetails?.total ? OrderDetails?.total : 0)}</strong>
                </li>
                <li className="d-flex justify-content-between align-items-center border-0 px-0 pb-1">
                  <small className="text-secondary">Paid amount</small><small className="text-secondary">{doAmountFormat(Math.round(paidBalance))}</small>
                </li>
                {Math.round(dueBalance) > 0 &&
                  <li className="d-flex justify-content-between align-items-center border-0 px-0 pb-1 balance-due">
                    <strong className="fs-5">Balance due</strong><strong className="fs-5">{doAmountFormat(dueBalance)}</strong>
                  </li>
                }
              </ul>
            </div>
          </div>
        </div>
      </div >
    </>
  )
}

export default OrderDetails