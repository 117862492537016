import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';

import moment from 'moment';
import DatePicker from 'react-datepicker'
import { Button, LinkButton } from './form-inputs';
import { closeModal, routes } from 'utils';
import { generateExcelFile } from './excel';

const ReportExport = (props: any) => {
  const { t } = useTranslation()

  const currentDate = moment();
  const threeMonthsAgo = moment().subtract(1, 'months');

  const [loading, setLoading] = useState(false)

  const [startDate, setStartDate] = useState<any>(threeMonthsAgo.startOf('month').toDate());
  const [endDate, setEndDate] = useState<any>(currentDate.endOf('month').toDate());

  const handleExportReport = async () => {
    const start_date = moment(startDate).format('YYYY-MM-DD')
    const end_date = moment(endDate).format('YYYY-MM-DD')
    await generateExcelFile({ start_date: start_date, end_date: end_date }, props.type, setLoading)
    if (loading === false) {
      closeModal('export-report')
    }
  }

  return (
    <div className="modal fade" id="export-report" tabIndex={-1} aria-labelledby="addExpensesModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <form className={`modal-content ${loading === true ? 'is-loading' : ''}`}>
          <div className="modal-header">
            <aside>
              <h4 className="mb-0">{props?.title}</h4>
            </aside>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>

          <div className="modal-body">
            <div className="form-group mb-3 col-12 row">
              <div className="col-6">
                <label className="label-form mb-1">{t('common.start_date')} {<span className="text-danger">*</span>}</label>
                <DatePicker
                  placeholderText="MM/DD/YYYY"
                  selected={startDate}
                  onChange={(date: any) => {
                    setStartDate(date)
                  }}
                  dateFormat="dd MMM, yyyy"
                />
              </div>
              <div className="col-6">
                <label className="label-form mb-1">{t('common.end_date')} {<span className="text-danger">*</span>}</label>
                <DatePicker
                  placeholderText="MM/DD/YYYY"
                  selected={endDate}
                  onChange={(date: any) => {
                    setEndDate(date)
                  }}
                  dateFormat="dd MMM, yyyy"
                />
              </div>
            </div>
          </div>

          <div className="modal-footer">
            <LinkButton path={`${routes.orders}`} label={t('cancel')} data-bs-dismiss="modal" className="link text-danger" />
            <Button type="button" className="primary" name={t('export')} onClick={() => { handleExportReport() }} loading={loading} />
          </div>
        </form>
      </div>
    </div>
  )
}

export default ReportExport