import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import * as Yup from 'yup'
import { useFormik } from 'formik';

import { routes } from 'utils';
import { _Object } from 'utils/interfaces';
import { supplierService, brandService } from 'services';
import { Breadcrumbs, Button, InputField, LinkButton, SelectField, TextArea } from 'views/components';
import { AsyncSelectField } from 'views/components/form-inputs';
import { countries } from 'utils/countries';


const CreateSupplier = () => {
  const selectAsyncSelect: any = useRef([])

  const { id } = useParams()
  const navigate = useNavigate()

  const [loading, setLoading] = useState<_Object>(
    {
      listing: false,
      edit: false,
      update: false
    });
  const [brands, setBrands] = useState<_Object>([])
  const [isEditMode, setEditMode] = useState(false)
  const [initialData, setInitialData] = useState<_Object>({
    name: '',
    company_name: '',
    email: null,
    phone_number: null,
    mobile_number: null,
    status: '',
    address1: '',
    address2: '',
    city: '',
    province: '',
    country: 'IN',
    postcode: '',
    brand_ids: [],
    description: '',
  })

  useEffect(() => {
    setEditMode(false)
    if (id) {
      setLoading({ edit: true })
      setEditMode(true)
      supplierService.getSupplierDetails(id).then((data: _Object) => {
        setInitialData({ ...data, brand_ids: data.brand_list?.map((item: _Object) => item.id) });
        setLoading({ edit: false })
      })
    }
    brandService.getBrands('').then((data: _Object) => {
      setBrands(data.items)
    })
  }, [id])

  const formik = useFormik({
    initialValues: initialData,
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string().label('Name').required(),
      status: Yup.string().label('Status').required(),
    }),

    onSubmit: (values) => {

      setLoading({ update: true });
      if (id) {
        delete values.id,
          delete values.created_at
        delete values.updated_at
        delete values.brand
        supplierService.updateSupplier(id, values).then((data: _Object) => {
          setLoading({ update: true, listing: true })
          if (data.error === false) {
            navigate(`${routes.suppilers}`)
          } else {
            setLoading({ update: false, listing: false });
            throw new Error(data.error);
          }
        }).catch((error) => {
          error && setLoading({ update: false, listing: false });
        })
      } else {
        // values.phone_number = values.phone_number.toString()
        values.province = values.province.toString()

        // delete values.note
        supplierService.createSupplier(values).then((data: _Object) => {
          setLoading({ update: true, listing: true })
          if (data.error === false) {
            navigate(`${routes.suppilers}`)
          } else {
            setLoading({ update: false, listing: false });
            throw new Error(data.error);
          }
          throw new Error(data.error);
        }).catch((error) => {
          error && setLoading({ update: false, listing: false });
        })
      }
    }
  })

  const brandOption = async (brandValue: string) => {
    const data: _Object = await brandService.getBrands({ q: brandValue })
    return data.items?.map((item: _Object) => { return { label: item.name, value: item.id } })
  }

  return (
    <>
      <Breadcrumbs
        trails={[
          {
            label: 'Products',
            path: `${routes.products}`
          },
          {
            label: 'Suppliers',
            path: `${routes.suppilers}`
          },
          {
            label: `${isEditMode ? 'Update' : 'New supplier'}`,
            path: ''
          }
        ]}
        pageTitle={`${isEditMode ? 'Update supplier' : 'New supplier'}`}

      />

      <form className={`filters ${loading.edit === true ? 'is-loading' : ''}`} onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col col-md-6 col-lg-4 col-xl-4 col-xxl-4">
            <div className="card">
              <div className="card-header">
                <strong>
                  General information
                </strong>
              </div>
              <div className="card-body">
                <InputField
                  name="name"
                  label="Name"
                  required={true}
                  value={formik.values.name}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={formik?.touched.name && formik.errors.name}
                />

                <InputField
                  name="company_name"
                  label="Company name"
                  onBlur={formik.handleBlur}
                  value={formik.values.company_name}
                  onChange={formik.handleChange}
                />

                <SelectField
                  menuPlacement="auto"
                  name="status"
                  label="Status"
                  required={true}
                  options={[
                    { label: 'Active', value: 'active' },
                    { label: 'Deleted', value: 'deleted' },
                    { label: 'Completed', value: 'completed' },
                  ]}
                  value={{ value: formik.values.status }}
                  onChange={(e: _Object) => {
                    formik.setFieldValue('status', e?.value || '')
                  }}
                  getOptionLabel={(option: { [key: string]: string }) => option.label}
                  getOptionValue={(option: { [key: string]: string }) => option.value}
                  error={formik.touched.status && formik.errors.status}
                />

                <TextArea
                  rows={5}
                  name="description"
                  className="col-12"
                  label="Description"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  placeholder="Enter description"
                  value={formik.values.description}
                />
              </div>
            </div>
          </div>

          <div className="col col-md-6 col-lg-4 col-xl-4 col-xxl-4">
            <div className="card mb-3">
              <div className="card-header">
                <strong>Contact details</strong>
              </div>
              <div className="card-body">
                <InputField
                  type="number"
                  name="phone_number"
                  label="Contact number"
                  onBlur={formik.handleBlur}
                  value={formik.values.phone_number}
                  // onChange={formik.handleChange}
                  onChange={(e: any) => {
                    const inputValue = e.target.value;
                    const numericValue = inputValue.replace(/\D/g, '');  // Remove non-numeric characters
                    const trimmedValue = numericValue.slice(0, 10);  // Keep only the first 10 digits
                    formik.setFieldValue('phone_number', trimmedValue);
                  }}
                />

                <InputField
                  type="number"
                  name="mobile_number"
                  label="Mobile number"
                  onBlur={formik.handleBlur}
                  value={formik.values.mobile_number}
                  // onChange={formik.handleChange}
                  onChange={(e: any) => {
                    const inputValue = e.target.value;
                    const numericValue = inputValue.replace(/\D/g, '');  // Remove non-numeric characters
                    const trimmedValue = numericValue.slice(0, 10);  // Keep only the first 10 digits
                    formik.setFieldValue('mobile_number', trimmedValue);
                  }}
                />

                <InputField
                  type="email"
                  name="email"
                  label="Email"
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                />
              </div>
            </div>

            <div className="card">
              <div className="card-header">
                <strong>
                  Brands
                </strong>
              </div>
              <div className="card-body">
                <AsyncSelectField
                  menuPlacement="auto"
                  isMulti
                  isClearable
                  cacheOptions
                  label="Brands"
                  name="brand_ids"
                  loadOptions={brandOption}
                  ref={(ref: any) => (selectAsyncSelect.current = ref)}
                  defaultOptions={brands?.map((item: _Object) => { return { label: item?.name, value: item?.id } })}
                  onChange={(val: _Object) => {
                    formik.setFieldValue('brand_ids', val.map((item: _Object) => item?.value))
                    formik.setFieldValue('brand_list', val)
                  }}
                  value={
                    formik.values.brand_list?.map((item: _Object) => {
                      return {
                        label: item?.name || item?.label,
                        value: item?.id || item?.value
                      }
                    })
                  }
                />
              </div>
            </div>
          </div>

          <div className="col col-md-6 col-lg-4 col-xl-4 col-xxl-4">
            <div className="card">
              <div className="card-header">
                <strong>Address</strong>
              </div>
              <div className="card-body">
                <div className="row">
                  <InputField
                    name="address1"
                    label="Address 1"
                    onBlur={formik.handleBlur}
                    value={formik.values.address1}
                    onChange={formik.handleChange}
                  />

                  <InputField
                    name="address2"
                    label="Address 2"
                    onBlur={formik.handleBlur}
                    value={formik.values.address2}
                    onChange={formik.handleChange}
                  />

                  <InputField
                    className="col-6"
                    name="city"
                    label="City"
                    onBlur={formik.handleBlur}
                    value={formik.values.city}
                    onChange={formik.handleChange}
                  />

                  <InputField
                    className="col-6"
                    // type="number"
                    name="postcode"
                    label="Postcode"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.postcode}
                  />

                  <SelectField
                    className="col-6"
                    name="country"
                    label="Country"
                    options={countries}
                    value={{ value: formik.values.country || 'IN' }}
                    onChange={(e: _Object) => {
                      formik.setFieldValue('country', e?.value || '')
                    }}
                  />

                  <SelectField
                    className="col-6"
                    name="province"
                    placeholder="Choose state"
                    label="State"
                    options={countries?.find((obj: _Object) => obj.value === (formik?.values?.country?.length > 0 ? formik?.values?.country : 'IN'))?.states}
                    onChange={(e: _Object) => {
                      formik.setFieldValue('province', e?.label || '')
                    }}
                    value={{ label: formik.values.province }}
                    // disabled={!formik.values.country}
                    getOptionLabel={(option: { [key: string]: string }) => option?.label}
                    getOptionValue={(option: { [key: string]: string }) => option?.label}
                  />

                </div>
              </div>
            </div>
          </div>
        </div>

        <footer className="row my-3">
          <div className="col-12 d-flex">
            <Button type="submit" name={isEditMode ? 'Update' : 'Submit'} className="primary" loading={loading.update} />
            <LinkButton
              path={routes.suppilers}
              label="Cancel"
              className="link text-danger fw-medium" />
          </div>
        </footer>
      </form>
    </>
  )
}
export default CreateSupplier


{/* <InputField
  className="col-6"
  // type="number"
  name="province"
  label="Province"
  value={formik.values.province}
  onChange={formik.handleChange}
/> */}

{/* <SelectField
  menuPlacement="auto"
  className="col-6"
  name="country"
  label="Country"
  options={countryData()}
  // value={countryData().filter(obj => obj.name == formik.values.country)}
  value={{ name: formik.values.country || 'India' }}
  onChange={(data: _Object) => {
    formik.setFieldValue('country', data.name)
  }}
  getOptionLabel={(option: _Object) => option.name}
  getOptionValue={(option: _Object) => option.isoCode || option.name}
/> */}