import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { _Object } from 'utils/interfaces';

const SEOHeader = (props: _Object) => {
  const { title } = props
  const { globalSettings } = useSelector((state: _Object) => state.session)
  
  return (
    <Helmet>
      <title>{title} {globalSettings?.store_name ? `- ${globalSettings.store_name}` : ''}</title>
    </Helmet>
  )
}
export default SEOHeader;