import React, { useState } from 'react';
import { Cut, Printer, Text, render, } from 'react-thermal-printer';
import { _Object } from 'utils/interfaces';

const PrintReceipt = () => {
  const [printDevice, setPrintDevice] = useState<any>('')

  const receiptTemplate = () => {
    return (
      <Printer type="epson">
        <Text align="center" bold={true}>Retail-POS</Text>
        <Text align="center">Royal heights telghani chauraha guna (m.p.)</Text>
        <Text align="center">Fssai no. 1114261000146</Text>
        <Text align="center">-------------------------------</Text>
        <Text align="left">Name: Neha Tiwari (8770837754)</Text>
        <Text align="left">
          Date: Dec 13, 2023 03:26pm
        </Text>
        <Text align="left">
          Bill No.: 53526
        </Text>
        <Text align="center">-------------------------------</Text>
        <Text align="left" style={{ marginBottom: 10 }} bold={true}>{('Item').padEnd(19)} {('Qty').padEnd(5)} Amount</Text>
        <Text align="left">{`${('Barcode ScannerBarcode Scanner').padEnd(19)} ${'100'.padEnd(4)} 1900`}</Text>
        <Text align="left">{`${('Barcode Scanner').padEnd(19)} ${'50'.padEnd(4)} 190`}</Text>
        <Text align="left">{`${('Barcode Scanner').padEnd(19)} ${'2'.padEnd(4)} 590`}</Text>
        <Text align="left">{`${('Barcode Scanner').padEnd(19)} ${'2'.padEnd(4)} 480`}</Text>
        <Text align="left">{`${('Barcode Scanner').padEnd(19)} ${'2'.padEnd(4)} 190`}</Text>
        <Text align="left">{`${('Barcode Scanner').padEnd(19)} ${'2'.padEnd(4)} 190`}</Text>
        <Text align="left">{`${('Barcode Scanner').padEnd(19)} ${'2'.padEnd(4)} 190`}</Text>
        <Text align="left">{`${('Barcode Scanner').padEnd(19)} ${'2'.padEnd(4)} 190`}</Text>
        <Text align="left">{`${('Barcode Scanner').padEnd(19)} ${'2'.padEnd(4)} 200`}</Text>
        <Text align="center">-------------------------------</Text>
        <Text align="left">{'Sub Total'.padEnd(25)}2000</Text>
        <Text align="left">{'Tax Amount'.padEnd(25)}150</Text>
        <Text align="center">-------------------------------</Text>
        <Text align="center" bold={true}>Grand Total: 5000</Text>
        <Text align="center">-------------------------------</Text>
        <Text align="center" style={{ marginTop: 10 }}>Thanks</Text>
        <Cut lineFeeds={1} />

      </Printer>

    )
  }
  const requestPrinterDevice = async () => {
    try {
      // const device = await (navigator as any).bluetooth?.requestDevice({ acceptAllDevices: true });
      // console.log('Device >>>', device)
      // device.gatt.connect()
      // setPrintDevice(device)

      const device = await (navigator as any).usb?.requestDevice({ filters: [] });
      setPrintDevice(device)

    } catch (e) {
      console.error('Printer device request faild', e);
    }
  }

  const doReceiptPrint = async (device: _Object) => {
    const data = await render(receiptTemplate());

    await device?.open();
    await device?.selectConfiguration(1);
    await device?.claimInterface(0);
    await device?.transferOut(
      device.configuration.interfaces[0].alternate.endpoints.find((obj: _Object) => obj.direction === 'out')
        .endpointNumber,
      data,
    );
    await device.close();
  };

  return (
    <>
      <p>
        <button onClick={() => requestPrinterDevice()}>Request device</button>
      </p>
      <p>
        <button onClick={() => doReceiptPrint(printDevice)}>Print receipt</button>
      </p>
    </>
  );
};

export default PrintReceipt;