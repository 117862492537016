import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import * as Yup from 'yup'
import { useFormik } from 'formik'

import { routes } from 'utils'
import { _Object } from 'utils/interfaces'
import { setAuth } from 'redux/slices/session.slice'
import { authService } from 'services'
import './login.scss'

import { Button, InputField, LinkButton, SEOHeader, } from 'views/components'
import { addToCart } from 'redux/slices/cart.slice'

const Login = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [loading, setLoading] = useState(false)

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    enableReinitialize: true,

    validationSchema: Yup.object({
      username: Yup.string().label('Username').min(6).required(),
      password: Yup.string().label('Password').required(),
    }),

    onSubmit: async (values) => {
      setLoading(true)
      authService.login(values)
        .then((data: _Object) => {
          setLoading(false)
          if (data?.error === false) {
            localStorage.removeItem('cartLineItems')
            dispatch(addToCart([]))
            dispatch(setAuth(data.access_token))
            navigate(`${routes.sales}`)
          }
        })
    },
  })

  return (
    <>
      <SEOHeader title="Login" />
      <div className="col-8 mx-auto vh-100 pos-justify pos-align pos-center flex-column login login-form-vh">
        <div className="card border-0 position-relative">
          <div className="text-center">
            <img src="/assets/images/logo.svg" alt="" className="img-fluid w-50 mb-4" />
            <h4>{t('login.welcome_back')}</h4>
            <p>{t('login.sign_in_account')}</p>
          </div>
          <form onSubmit={formik.handleSubmit}>
            {/* <InputField
              type="text"
              name="company_name"
              label={t('login.company_name')}
              autoFocus
              required={true}
              value={formik.values.company_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik?.touched.company_name && formik.errors.company_name}
            /> */}

            <InputField
              type="text"
              name="username"
              label={t('common.username')}
              autoFocus
              required={true}
              value={formik.values.username}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik?.touched.username && formik.errors.username}
            />

            <InputField
              className="position-relative"
              type="password"
              name="password"
              label={t('common.password')}
              required={true}
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik?.touched.password && formik.errors.password}
            />

            <div className="pos-justify pos-between">
              <Button
                type="submit"
                name={t('login.sign_in')}
                className="primary"
                loading={loading}
              />
              <LinkButton
                label={t('login.forgot_password')}
                path={`${routes.forgotPassword}`}
                className="link px-0"
              />
            </div>
          </form>

          <div className="text-center mt-4 pt-4">
            <p className="mb-0">
              {t('login.by_continuing')}&nbsp;<LinkButton path="#" className="link text-decoration-underline d-inline-block p-0 fw-normal align-baseline" label={t('login.terms_of_use')} />
              &nbsp;& <LinkButton path="#" className="link text-decoration-underline d-inline-block p-0 fw-normal align-baseline" label={t('login.privacy_policy')} />
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Login