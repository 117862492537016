/* eslint-disable no-unsafe-optional-chaining */
import { createSlice } from '@reduxjs/toolkit'
import { _Object } from 'utils/interfaces'

const initialState: _Object = {
  cart: {
    customer: {},
    lineItems: [],
    categories: [],
    setCustomerId: ''
  },
}

const cartSlice = createSlice({
  name: 'cart',
  initialState: initialState,
  reducers: {
    setCustomer: (state, action) => {
      state.cart.customer = action?.payload
    },
    setCustomerId: (state, action) => {
      state.cart.setCustomerId = action?.payload
    },
    addToCart: (state, action) => {
      if (action?.payload?.length > 0) {
        action.payload.forEach((item: any) => {
          const itemInCart = state.cart.lineItems.find((cartItem: any) => cartItem.id === item.id);
          if (itemInCart) {
            itemInCart.quantity++;
          } else {
            state.cart.lineItems.push(item);
          }
        });
      } else {
        const itemInCart = state.cart.lineItems.find((cartItem: any) => cartItem?.id === action?.payload?.id);

        if (itemInCart) {
          itemInCart.quantity++;
        } else {
          state.cart.lineItems.push({ ...action.payload, quantity: action.payload.quantity || 1 });
        }
        if (action.payload.length === 0) {
          state.cart.lineItems = [];
        }
      }
      localStorage.setItem('cartLineItems', JSON.stringify(state.cart.lineItems))
    },

    lineItemDiscount: (state, action) => {
      const itemIndex = state.cart.lineItems.findIndex((cartItem: _Object) => cartItem.id === action.payload.clickedItem.id);

      if (itemIndex !== -1) {
        const currentItem = state.cart.lineItems[itemIndex];
        if ('discount' in currentItem) {
          state.cart.lineItems[itemIndex].discount = action.payload.values.amount;
        } else {
          state.cart.lineItems[itemIndex].discount = action.payload.values.amount;
        }

        localStorage.setItem('cartLineItems', JSON.stringify(state.cart.lineItems));
      }
    },

    cartLineItemDiscount: (state, action) => {
      state.cart = { ...state.cart, discounts: action.payload };
    },

    incrementQuantity: (state, action) => {
      const item = state.cart.lineItems.find((item: _Object) => item.id === action.payload);
      item.quantity++;
      localStorage.setItem('cartLineItems', JSON.stringify(state.cart.lineItems))
    },
    decrementQuantity: (state, action) => {
      const item = state.cart.lineItems.find((item: _Object) => item.id === action.payload);
      item.quantity > 1 && item.quantity--;
      localStorage.setItem('cartLineItems', JSON.stringify(state.cart.lineItems))
    },
    setQuantity: (state, action) => {
      const item = state.cart.lineItems.find((item: _Object) => item.id === action.payload.id);
      item.quantity = action.payload.quantity > 1 ? action.payload.quantity : 1;
      localStorage.setItem('cartLineItems', JSON.stringify(state.cart.lineItems))
    },
    removeCartItem: (state, action) => {
      if (action?.payload?.reverse) {
        state.cart.lineItems?.splice(state.cart.lineItems.length - action.payload.index - 1, 1);
        localStorage.setItem('cartLineItems', JSON.stringify(state.cart.lineItems))
      } else {
        state.cart.lineItems.splice(action.payload, 1)
        localStorage.setItem('cartLineItems', JSON.stringify(state.cart.lineItems))
      }
    },

  },
})

export const { setCustomer, setCustomerId, addToCart, lineItemDiscount, incrementQuantity, cartLineItemDiscount, decrementQuantity, removeCartItem, setQuantity, } = cartSlice.actions;

export default cartSlice.reducer
