import React from 'react';

import { _Object } from 'utils/interfaces';
import './styles.scss'
import { generateFormattedAddress, doDateFormatWithTime, capitalizeFirstLetter, doAmountFormat, generateNumInWords } from 'utils';

const OrderInvoice = (props: _Object) => {
  const { data, globalSettings } = props

  const totalQuantity = data?.order_items.reduce((acc: _Object, item: _Object) => acc + item.quantity, 0);
  const paidBalance = data?.payments?.reduce((acc: number, item: { amount: number }) => {
    return acc + item.amount;
  }, 0);
  const dueBalance = data.total - paidBalance
  return (
    <div className="pdf-container">
      <header className="wrapper">
        <table>
          <tbody>
            <tr>
              <td style={{ paddingBottom: '0' }}>
                <h6>Tax Invoice</h6>
                <h2>{globalSettings?.store_name}</h2>
                <p><strong>GSTIN {globalSettings?.store_gst}</strong></p>
                <p>{capitalizeFirstLetter(globalSettings?.store_address || '')}</p>
              </td>
              <td style={{ paddingBottom: '0' }}>
                <img src="/assets/images/pdf-logo.jpg" alt="pdf-logo"
                  style={{ width: 'auto', height: 'auto', maxHeight: '72pt' }}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </header>

      <table>
        <tbody>
          <tr className="h-100">
            <td>Invoice #: <strong>{data?.order_number}</strong> </td>
            <td colSpan={2}>Invoice Date: <strong>{doDateFormatWithTime(data?.created_at)}</strong> </td>
          </tr>

          <tr className="h-100">
            <td>
              <p>Customer details:</p>
              <p><strong>	{capitalizeFirstLetter(data?.customer?.full_name || '')}</strong></p>
            </td>
            <td className="break-spaces">
              <p>Billing address:</p>
              <p>{generateFormattedAddress(data?.billing)}</p>
            </td>
            <td className="break-spaces text-start">
              <p>Shipping address:</p>
              <p>{data?.shipping !== null ? generateFormattedAddress(data?.shipping) : '-'}</p>
            </td>
          </tr>
        </tbody>
      </table>

      <section className="wrapper">
        <table className="border-table">
          <thead>
            <tr className="h-100">
              <th style={{ width: '18pt' }}>#</th>
              <th style={{ width: '115pt' }}>Item</th>
              <th className="text-end" style={{ width: '30.5pt' }}>Price</th>
              <th className="text-end" style={{ width: '37.5pt' }}>Qty</th>
              <th className="text-end" style={{ width: '60pt' }}>Total</th>
            </tr>
          </thead>

          <tbody>
            {data?.order_items?.map((item: _Object, i: number) => {
              return (
                <tr className="h-auto" key={item.id}>
                  <td>{i + 1}</td>
                  <td className="break-spaces">{item.item_name}</td>
                  <td className="text-end">
                    <strong>{doAmountFormat(item.price ? item.price : 0)}
                    </strong>
                  </td>
                  <td className="text-end" >{item.quantity}</td>
                  <td><strong>{doAmountFormat(item.subtotal ? item.subtotal : item.subtotal)}</strong></td>
                </tr>
              )
            })}

            <tr className="h-auto">
              <td className="sub-total" colSpan={5}>
                <p>
                  <strong>Items total</strong>
                  <span><strong>{doAmountFormat(data.subtotal ? data.subtotal : 0)}</strong></span> </p>
                {data.discount_total !== 0 && data.discount_total !== null &&
                  <p>
                    Discount <span>{data.discount_total}</span>
                  </p>}
                {data?.fee_total !== 0 && data?.fee_total !== null &&
                  <p>
                    Fee <span>{data.fee_total}</span>
                  </p>
                }
                <p>Tax<span> {doAmountFormat(data.tax_total ? data.tax_total : 0)}</span></p>
                <p><strong>Total</strong><span><strong>{doAmountFormat(data.total)}</strong></span></p>
                <p><span className="paid-amount">Paid amount</span> <span className="paid-amount">{doAmountFormat(paidBalance)}</span></p>
                <p><span className="paid-amount">Balance due</span> <span className="paid-amount">{doAmountFormat(dueBalance)}</span></p>
              </td>
            </tr>
            <tr className="h-auto none-border">
              <td colSpan={2}>
                <small>Items: {data?.order_items?.length}, Qty: {totalQuantity}</small>
              </td>
              <td colSpan={3}>
                <small>
                  Total(in words): {capitalizeFirstLetter(generateNumInWords(data.total))}
                </small>
              </td>
            </tr>
          </tbody>
        </table>

        <table>
          <tbody>
            <tr>
              <td>
                <p><strong>Pay using UPI:</strong></p>
                <img src="/assets/images/upi.png" alt="pdf-logo"
                  style={{ width: 'auto', height: 'auto', maxHeight: '72pt' }}
                />
                <br />
                <span>Thank you for the business</span>
              </td>
              <td>
                <p><strong>Bank Details:</strong></p>
                <p><span style={{ minWidth: '65px', display: 'inline-block' }}>Bank:</span> <strong> {capitalizeFirstLetter(globalSettings?.store_bankdetails?.bank)}</strong></p>
                <p>
                  <span style={{ minWidth: '65px', display: 'inline-block' }}>Account #:</span> <strong> {globalSettings?.store_bankdetails?.account_number}</strong>
                </p>
                <p>
                  <span style={{ minWidth: '65px', display: 'inline-block' }}>IFSC: </span> <strong>{globalSettings?.store_bankdetails?.ifsc}</strong>
                </p>
                <p>
                  <span style={{ minWidth: '65px', display: 'inline-block' }}>Branch:</span> <strong> {capitalizeFirstLetter(globalSettings?.store_bankdetails?.branch)}</strong>
                </p>
              </td>
              <td>
                <img src="/assets/images/signature.jpg" alt="pdf-logo"
                  style={{ width: 'auto', height: 'auto', maxHeight: '72pt' }}
                />
              </td>
            </tr>
          </tbody>
        </table>

        <table>
          <tbody>
            <tr>
              <td className="text-start">

                <strong>Terms and conditions:</strong>
                <ul>
                  <li>
                    <small> Goods once sold cannot be taken back or exchanged.</small>
                  </li>
                  <li> <small>We are not the manufacturers, company will stand for warranty as per their terms  and conditions.</small></li>
                  <li> <small>Interest @24% p.a. will be charged for uncleared bills beyond 15 days </small></li>
                  <li> <small>Subject to local Jurisdiction. </small></li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </section>
    </div >
  )
}
export default OrderInvoice;