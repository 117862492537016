import React, { useState, useEffect } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom'

import * as Yup from 'yup'
import { useFormik } from 'formik'

import { routes } from 'utils'
import { _Object } from 'utils/interfaces'
import { userService, locationService } from 'services'
import { InputField, SelectField, Button, LinkButton, Breadcrumbs, SEOHeader, CheckBox } from 'views/components'
import { useTranslation } from 'react-i18next'

const CreateUser = () => {
  const { id } = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const [loading, setLoading] = useState<_Object>(
    {
      listing: false,
      edit: false,
      update: false
    });
  const [isEditMode, setEditMode] = useState(false)
  const [locations, setLocations] = useState([])
  const [initialData, setInitialData] = useState<_Object>({})

  useEffect(() => {
    setEditMode(false)
    setInitialData({
      role: '',
      email: '',
      status: '',
      password: '',
      last_name: '',
      username: '',
      first_name: '',
      location_id: '',
      mobile_number: '',
      confirm_password: '',
    })

    setLoading({ listing: true });
    locationService.getLocations({ per_page: 9999 }).then((data: _Object) => {
      setLocations(data.items)
      setLoading({ listing: false });
    })

    if (id) {
      setLoading({ edit: true })
      setEditMode(true)
      userService.getUserDetails(id).then((data: _Object) => {
        setInitialData(data)
        setLoading({ edit: false })
      })
    }
  }, [location])

  const formik = useFormik({
    initialValues: initialData,
    enableReinitialize: true,
    validationSchema: Yup.object({
      first_name: Yup.string().label('First name').required(),
      password: id ? Yup.string() : Yup.string()
        .label('Password')
        .required('Password is required')
        .min(8, 'Password must be at least 8 characters'),

      confirm_password: id ? Yup.string() : Yup.string()
        .label('Confirm Password')
        .required('Confirm Password is required')
        .oneOf([Yup.ref('password')], 'Confirm password must match'),

      status: Yup.string().label('Status').required(),
      last_name: Yup.string().label('Last name').required(),
      email: Yup.string().email('Invalid email format').required().label('Email'),
      // mobile_number: Yup.number().label('Mobile number').required(),
      mobile_number: Yup.string()
        .label('Mobile number')
        .required()
        .min(10, 'Mobile number must be at least 10 characters'),
      role: Yup.string().label('Role').required(),
      location_id: Yup.string().label('Location').required()
    }),
    onSubmit: (values) => {
      setLoading({ update: true });
      values.mobile_number = (values.mobile_number).toString()
      if (id) {
        delete values.id,
          delete values.password,
          delete values.token,
          delete values.location,
          delete values.created_at
        delete values.updated_at

        userService.updateUser(id, values).then((data: _Object) => {
          setLoading({ update: true, listing: true })
          if (data.error === false) {
            navigate(`${routes.users}`)
          } else {
            setLoading({ update: false, listing: false });
            throw new Error(data.error);
          }
        }).catch((error) => {
          error && setLoading({ update: false, listing: false });
        })
      } else {
        // delete values.status
        userService.createUser(values).then((data: _Object) => {
          setLoading({ update: true, listing: true })
          if (data.error === false) {
            navigate(`${routes.users}`)
          } else {
            setLoading({ update: false, listing: false });
            throw new Error(data.error);
          }
        }).catch((error) => {
          error && setLoading({ update: false, listing: false });
        })
      }
    }
  })

  return (
    <>
      <SEOHeader title="Users" />
      <Breadcrumbs
        trails={[
          {
            label: 'Users',
            path: `${routes.users}`
          },
          {
            label: `${isEditMode ? 'Update user' : 'New user'}`,
            path: ''
          },
        ]}
        pageTitle={`${isEditMode ? 'Update user' : 'New user'}`}
      />

      <form className={`row filters ${loading.edit === true ? 'is-loading' : ''}`} onSubmit={formik.handleSubmit} >
        <div className="col">
          <div className="card">
            <div className="card-header">
              <strong>General information</strong>
            </div>

            <div className="card-body">
              <InputField
                name="first_name"
                label="First name"
                required={true}
                value={formik.values.first_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.first_name && formik.errors.first_name}
              />

              <InputField
                name="last_name"
                label="Last name"
                required={true}
                value={formik.values.last_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.last_name && formik.errors.last_name}
              />

              <InputField
                type="text"
                name="mobile_number"
                label="Mobile no."
                required={true}
                value={formik.values.mobile_number}
                // onChange={formik.handleChange}
                onChange={(e: any) => {
                  const inputValue = e.target.value;
                  const numericValue = inputValue.replace(/\D/g, '');
                  const trimmedValue = numericValue.slice(0, 10);
                  formik.setFieldValue('mobile_number', trimmedValue);
                }}
                onBlur={formik.handleBlur}
                error={formik.touched.mobile_number && formik.errors.mobile_number}
              />
            </div>
          </div>
        </div>

        <div className="col">
          <div className="card">
            <div className="card-header">
              <strong>Accounts details</strong>
            </div>

            <div className="card-body">
              <div className="row">
                <InputField
                  className="col-6"
                  type="name"
                  readOnly={true}
                  name="username"
                  label="Username"
                  value={formik.values.username}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />

                <InputField
                  className="col-6"
                  type="email"
                  name="email"
                  label="Email"
                  required={true}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && formik.errors.email}
                />

                <SelectField
                  className="col-4"
                  name="location_id"
                  label="Shop/Location"
                  required={true}
                  options={locations}
                  value={locations?.filter((obj: _Object) => obj.id === formik.values.location_id)}
                  onChange={(val: _Object) => formik.setFieldValue('location_id', val?.id)}
                  onBlur={formik.handleBlur}
                  getOptionLabel={(option: _Object) => option?.name}
                  getOptionValue={(option: _Object) => option?.id}
                  error={formik.touched.location_id && formik.errors.location_id}
                  isClearable
                />

                <SelectField
                  className="col-4"
                  name="role"
                  label="Role"
                  required={true}
                  options={[
                    { label: 'Administrator', value: 'administrator' },
                    { label: 'Super admin', value: 'super_admin' },
                    { label: 'Outlet manager', value: 'outlet_manager' },
                    { label: 'Salesman', value: 'salesman' },
                  ]}
                  onChange={(e: _Object) => {
                    formik.setFieldValue('role', e?.value || '')
                  }}
                  value={{ value: formik.values.role }}
                  onBlur={formik.handleBlur}
                  getOptionLabel={(option: _Object) => option.label}
                  getOptionValue={(option: _Object) => option.value}
                  error={formik.touched.role && formik.errors.role}
                  isClearable
                />

                <SelectField
                  isClearable
                  name="status"
                  label="Status"
                  required={true}
                  className="col-4"
                  options={[
                    { label: 'Inactive', value: 'inactive' },
                    { label: 'Active', value: 'active' },
                  ]}
                  onChange={(e: _Object) => {
                    formik.setFieldValue('status', e?.value || '')
                  }}
                  value={{ value: formik.values.status }}
                  onBlur={formik.handleBlur}
                  getOptionLabel={(option: _Object) => option.label}
                  getOptionValue={(option: _Object) => option.value}
                  error={formik.touched.status && formik.errors.status}
                />

                {id &&
                  <CheckBox
                    name="change_password"
                    required={true}
                    className="col-12"
                    label={''}
                    value={formik?.values?.change_password}
                    options={[
                      { label: t('common.change_password'), value: false },
                    ]}
                    onChange={() => {
                      formik.setFieldValue('change_password', !formik?.values?.change_password)
                      setInitialData(() => ({
                        ...formik.values,
                        change_password: !formik?.values?.change_password,
                      }))
                    }} />
                }

                {((id && formik?.values?.change_password) || !id) &&
                  <>
                    <InputField
                      type="password"
                      name="password"
                      required={true}
                      label="Password"
                      className="col-6"
                      placeholder="Password"
                      onBlur={formik.handleBlur}
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      error={formik.touched.password && formik.errors.password}
                    />

                    <InputField
                      type="password"
                      required={true}
                      className="col-6"
                      placeholder="Password"
                      name="confirm_password"
                      label="Confirm password"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.confirm_password}
                      error={formik.touched.confirm_password && formik.errors.confirm_password}
                    />
                  </>
                }
              </div>
            </div>
          </div>
        </div>

        <footer className="row my-3">
          <div className="col-12 d-flex gap-3">
            <Button name={isEditMode ? 'Update' : 'Submit'} className="primary" loading={loading.update} />
            <LinkButton
              path={routes.users}
              label="Cancel"
              className="link text-danger fw-medium" />
          </div>
        </footer>
      </form>
    </>
  )
}
export default CreateUser