import React from 'react'
import { useTranslation } from 'react-i18next';

import { Link } from 'react-router-dom'
import { routes } from 'utils'
import { _Object } from 'utils/interfaces'

function Breadcrumbs({ trails, pageTitle }: _Object) {
  const { t } = useTranslation()

  return (
    <>
      <nav className="breadcrumb-table">
        <ul className="breadcrumb">
          <li className="breadcrumb-item"><Link to={routes.sales}>{t('common.home')}</Link></li>
          {trails?.map((item: _Object, i: number) => {
            return (
              <li key={i} className="breadcrumb-item">{item?.path?.length > 0 ?
                <Link to={item?.path}>{item?.label}</Link>
                :
                item.label}
              </li>
            )
          })}
        </ul>
      </nav>

      {pageTitle &&
        <header className="row my-2">
          <h2>{pageTitle}</h2>
        </header>
      }
    </>
  )
}

export default Breadcrumbs