import React from 'react';
import { useTranslation } from 'react-i18next';
import { _Object } from 'utils/interfaces';

import Select, { StylesConfig } from 'react-select'

const SelectField = ({ name, value, onChange, disabled, onBlur,
  options, getOptionLabel, getOptionValue, isSearchableHide, label, required, placeholder, error, className = '', ...props }: _Object) => {
  const { t } = useTranslation()

  const isSelected = () => {
    if (typeof value === 'object' && !Array.isArray(value)) {
      const flag = Object.keys(value)[0];
      if (options && options[0]?.options) {
        return options[0]?.options?.filter(
          (item: { [key: string]: string | number }) => value[flag].includes(item[flag])
        );
      } else {
        return options && options?.filter(
          (item: { [key: string]: string | number }) => value[flag] === item[flag]);
      }
    }

    return value;
  };

  const customStyles: StylesConfig = {
    control: (provided: Record<string, unknown>) => ({
      ...provided,
      border: error && '1px solid #FF0000',
    })
  };

  return (
    <div className={`form-group mb-3 ${className}`}>
      {label && <label className="label-form mb-1">{label} {required && <span className="text-danger">*</span>}</label>}
      <Select
        className={`react-dropdown ${error && 'invalid'} ${isSearchableHide === true && 'searchHide'}`}
        classNamePrefix="react-select"
        menuPlacement="auto"
        styles={customStyles}
        name={name}
        placeholder={placeholder?.length > 0 ? placeholder : t('common.choose_option')}
        isDisabled={disabled}
        options={options}
        value={isSelected()}
        onChange={onChange}
        onBlur={onBlur}
        onMenuClose={() => { document.getElementById('root')?.click() }}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        {...props}
      />
      {error && <span className="invalid-feedback text-danger d-block mt-1">{error}</span>}
    </div>
  )
}

export default SelectField
