import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCubesStacked } from '@fortawesome/free-solid-svg-icons';

import { _Object } from 'utils/interfaces';
import { orderService } from 'services';
import { doAmountFormat } from 'utils';

const TodaySalesSummary = () => {

	const { t } = useTranslation()

	const [loading, setLoading] = useState<_Object>({
		search: false,
		summary: false,
		discountbtn: false
	})
	const [modal, setModal] = useState(false);

	const [orderSummary, setOrderSummary] = useState<_Object>({});

	useEffect(() => {
		setLoading({ summary: true })
		orderService.getOrderSummary({ start_date: moment().format('YYYY-MM-DD'), end_date: moment().format('YYYY-MM-DD') }).then((data: _Object) => {
			setOrderSummary(data)
			setLoading({ summary: false })

		})
	}, [modal])

	return (
		<div className="summary-icons">
			<ul className="list-group list-unstyled">
				<li className="list-group-flush">
					<button type="button" className="btn btn-primary" onClick={() => setModal(true)} data-bs-toggle="modal" data-bs-target="#todaySummaryModal">
						<FontAwesomeIcon icon={faCubesStacked} className="me-0" />
					</button>

					<div className="modal fade" id="todaySummaryModal" tabIndex={-1} aria-labelledby="todaySummaryModalLabel" aria-hidden="true">
						<div className="modal-dialog modal-dialog-centered">
							<div className="modal-content">
								<div className="modal-header">
									<h4 className="mb-0">
										{t('checkout.sales_summary')}
									</h4>
									<button type="button" onClick={() => setModal(false)} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>

								</div>
								<div className="modal-body">
									<div className="d-flex align-items-center date-header border-bottom">
										<aside>
											<h4 className="mb-0">{moment().format('DD')}</h4>
										</aside>
										{moment().format('MMM').toUpperCase()} <br />
										{moment().format('YYYY')}
										<p className="mb-0">
											{moment().format('dddd').toUpperCase()}
										</p>
									</div>

									<ul className={`list-inline mb-0 ${modal === true && loading.summary === true ? 'is-loading' : ''}`}>
										<li className="list-inline-item">
											<h6>
												{t('common.sales')}
											</h6>
											<span>{doAmountFormat(orderSummary?.sales?.total)}</span>
										</li>
										<li className="list-inline-item">
											<h6>
												{t('common.expense')}
											</h6>
											<span>{doAmountFormat(orderSummary?.expense?.total)}</span>
										</li>
										<li className="list-inline-item">
											<h6><strong>{t('common.total')}</strong></h6>
											<span> <strong>{doAmountFormat(orderSummary?.sales?.total - orderSummary?.expense?.total)}</strong></span>
										</li>
									</ul>
								</div>

							</div>
						</div>
					</div>
				</li>

			</ul>
		</div>

	)
}
export default TodaySalesSummary