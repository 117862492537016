/* eslint-disable max-len */
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { _Object } from 'utils/interfaces'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

const InputField = ({ name, type = 'text', onChange, value, label, placeholder, required = false, className = '', icon = '', error, linkLabel = '', linkDisable = false, linkOnChange, ...props }: _Object) => {
  const { t } = useTranslation()

  const [eyeOn, setEyeOn] = useState(false)
  const [fieldType, setFieldType] = useState(type)

  const toggleEyeOn = () => {
    if (eyeOn === false) {
      setEyeOn(true)
      setFieldType('text')
    } else {
      setEyeOn(false)
      setFieldType('password')
    }
  }
  return (
    <div className={`form-group mb-3 ${className}`}>
      <div className="d-flex justify-content-between genral-label">
        {label && <label className="label-form mb-1">{label} {required && <span className="text-danger">*</span>}</label>}
        {linkLabel?.length > 0 && <Link to="#" onClick={linkOnChange} className={`primary text-decoration-none ${linkDisable ? '' : 'disabled-link'}`}>{linkLabel}</Link>}
      </div>

      <input
        type={fieldType ? fieldType : 'text'}
        autoComplete="off"
        name={name}
        placeholder={placeholder || t('common.enter_here')}
        // value={value}
        value={value ?? ''}
        onChange={onChange}
        className={`form-control ${error && 'invalid'} `}
        {...props}
      />

      {icon && <img src={icon} alt="Icon" className="position-absolute translate-middle-y form-control-icon top-50 px-3" />}

      {type === 'password' &&
        <button
          type="button"
          className="btn me-3 translate-middle-y p-0 border-0 show-password"
          onClick={() => toggleEyeOn()}
        >
          {eyeOn ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}
        </button>
      }
      {error && <span className="invalid-feedback text-danger d-block mt-1">{error}</span>}
    </div >
  )
}

export default InputField