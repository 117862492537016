import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

import { Button } from './form-inputs';
import { _Object } from 'utils/interfaces';
import { getFilteredLabel } from 'utils';

const ActiveFilters = (props: _Object) => {
  const { filterdLabel, setFilteredLabel, setFilterData } = props;

  const removeFilter = (keyToRemove: any) => {
    setFilterData((prevData: _Object) => {
      const updatedFilter = { ...prevData.filter };
      delete updatedFilter[keyToRemove];

      return {
        ...prevData,
        filter: updatedFilter,
      };
    });
    setFilteredLabel((prevData: _Object) => {
      const updatedFilter = { ...prevData.filter };
      delete updatedFilter[keyToRemove];

      return {
        ...prevData,
        filter: updatedFilter,
      };
    });
  };

  return (
    <>
      <ul className="list-inline mb-0 active-filters">
        {filterdLabel &&
          Object?.entries(filterdLabel).map(([key, value]: any) => (
            <li className="list-inline-item" key={key}>
              <Button
                className="primary text-capitalize"
                onClick={() => removeFilter(key)} // Use key instead of value.value
              >
                <span>{getFilteredLabel(key)}:&nbsp;</span> {value}&nbsp;
                <FontAwesomeIcon icon={faXmark} className="me-0" />
              </Button>
            </li>
          ))}
        <li className="list-inline-item">
          <Button
            onClick={() => {
              setFilterData((e: _Object) => ({
                ...e,
                filter: {},
              }));
              setFilteredLabel({})
            }}
            className="transparent border-0 text-danger"
            name={Object?.entries(filterdLabel).length === 1 ? 'Clear' : 'Clear all'}
          />
           
        </li>
      </ul>
    </>
  );
};

export default ActiveFilters;
