import CommonService from './common.service';
import { generateQueryParams } from 'utils';
import { _Object } from 'utils/interfaces';

class TagService extends CommonService {
  async getTags(params: _Object | string) {
    return await this.get(`tags?${generateQueryParams(params)}`)
  }
  async getTagDetails(id: string) {
    return await this.get(`tags/${id}`)
  }
  async createTag(parmas: { [key: string]: boolean | string }) {
    return await this.post('tags', parmas)
  }
  async updateTag(id: string, params: { [key: string]: boolean | string }) {
    return await this.put(`tags/${id}`, params)
  }
  async deleteTag(id: string) {
    return await this.delete(`tags/${id}`)
  }
}
export const tagService = new TagService();