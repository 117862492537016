import React from 'react'
import { useTranslation } from 'react-i18next'

import { _Object } from 'utils/interfaces'

const SearchField = ({ name, type = 'text', disabled, icon, loading, onChange, value, label, required = false, className = '', error, ...props }: _Object) => {
  const { t } = useTranslation()

  return (
    <div className={`form-group search-input mb-3 ${className}`}>

      <div className="input-group">
        <button type="submit" disabled={disabled} className={`input-group-text ${loading ? 'btn-loading' : ''}`}>
          {icon ? <i className={icon}></i> : <i className="fa fa-magnifying-glass"></i>}
        </button>

        {label && <label className="label-form">{label} {required && <span className="text-danger"></span>}</label>}

        <input
          type={type}
          autoComplete={'off'}
          name={name}
          onChange={onChange}
          value={value}
          // autoComplete="off"
          placeholder={t('common.keyword')}
          className="form-control"
          {...props} />

      </div>

      {error && <span className="invalid-feedback text-danger d-block mt-1">{error}</span>}
    </div>
  )
}

export default SearchField

