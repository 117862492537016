import React, { useState } from 'react';
import { useTranslation } from 'react-i18next'

import * as Yup from 'yup'
import { useFormik } from 'formik';

import { _Object } from 'utils/interfaces';
import { authService } from 'services';

import { Button, InputField, LinkButton, SEOHeader } from 'views/components';

const Forgot = () => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)

  const formik = useFormik({
    initialValues: { username: '' },
    enableReinitialize: true,

    validationSchema: Yup.object().shape({
      username: Yup.string().min(6).required()
    }),

    onSubmit: async (values) => {
      setLoading(true)
      authService.forgotPassword(values).then((data: _Object) => {
        data.error === false
        // && navigate(`${routes.login}`)
        setLoading(false)
        formik.resetForm();
      })
    },
  })

  return (
    <>
      <SEOHeader title="Forgot password" />
      <div className="col-8 mx-auto vh-100 pos-justify pos-align pos-center flex-column login login-form-vh">
        <div className="card border-0 position-relative">
          <div className="text-center">
            <img src="/assets/images/logo.svg" alt="" className="img-fluid w-50 mb-4" />
            <h4>{t('login.reset_your_password')}</h4>
            <p>{t('login.send_link')}</p>
          </div>
          <form className="row align-items-center"
            onSubmit={formik.handleSubmit}>
            <InputField
              className="col-9"
              type="text"
              name="username"
              label={t('common.username')}
              required={true}
              value={formik.values.username}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik?.touched.username && formik.errors.username ? true : false}
            />

            <div className="form-group mb-3 col-3">
              <label className="label-form mb-1">&nbsp;</label>

              <Button
                type="submit"
                name={t('login.send')}
                className="primary w-100"
                loading={loading}
              />
            </div>
            {formik?.touched.username && formik.errors.username && <span className="invalid-feedback text-danger d-block mt-1">{formik.errors.username}</span>}

          </form>

          <div className="text-center mt-4">
            <p className="mb-0 pos-justify pos-center align-items-baseline">
              {t('login.already_account')} <LinkButton label={t('login.sign_in')} path="/login" className="link text-decoration-underline fw-normal ps-1 p-0" />
            </p>
          </div>
        </div>
      </div>
    </>
  )
}
export default Forgot