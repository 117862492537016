import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as Yup from 'yup'
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'redux/store';

import { _Object } from 'utils/interfaces';
import { Breadcrumbs, Button, InputField, RadioButton, SEOHeader, SelectField } from 'views/components';
import { authService } from 'services';
import { setGlobalSettingas } from 'redux/slices/session.slice';

const Settings = () => {
  const { t } = useTranslation()

  const dispatch = useDispatch<AppDispatch>()
  const { globalSettings } = useSelector((state: _Object) => state.session)
  const [loading, setLoading] = useState(false)
  const [pageLoading, setPageLoading] = useState(false)

  useEffect(() => {
    setPageLoading(true)
    dispatch(setGlobalSettingas())
    setTimeout(() => {
      setPageLoading(false)
    }, 800)
  }, [])


  const formik = useFormik({
    initialValues: {
      store_name: globalSettings?.store_name,
      store_email: globalSettings?.store_email,
      store_gst: globalSettings?.store_gst,
      store_upi: globalSettings?.store_upi_qr,
      store_signature: globalSettings?.store_sealsignature,
      phone_number: globalSettings?.store_phone_number,
      address: globalSettings?.store_address,
      bank_name: globalSettings?.store_bankdetails?.banck,
      branch: globalSettings?.store_bankdetails?.branch,
      account_number: globalSettings?.store_bankdetails?.account_number,
      account_holder_name: globalSettings?.store_bankdetails?.account_holder_name,
      ifsc_code: globalSettings?.store_bankdetails?.ifsc,
      disabled_printer: globalSettings?.disabled_printer,
      receipt_size: globalSettings?.receipt_size

    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      store_name: Yup.string().required().label('Store name'),
      address: Yup.string().required().label('Address'),
      phone_number: Yup.string()
        .required()
        .label('Mobile number')
        .min(10, 'Mobile number must be at least 10 characters'),
      store_email: Yup.string().required().label('Email').email('Please enter valid email'),
      // store_gst: Yup.string().required().label('GSTIN'),
      // store_logo: Yup.string().required().label('Store logo'),
      // store_signature: Yup.string().required().label('Seal/Signature'),
      // account_holder_name: Yup.string().required().label('Account holder name'),
      // bank_name: Yup.string().required().label('Bank name'),
      // account_number: Yup.string().required().label('Account number'),
      // ifsc_code: Yup.string().required().label('IFSC code'),
      // store_upi: Yup.string().required().label('UPI'),
      // branch: Yup.string().required().label('Branch'),
      disabled_printer: Yup.string().required().label('Printer disabled'),
      // receipt_size: Yup.string().required().label('Receipt size'),
    }),
    onSubmit: (values) => {
      setLoading(true)

      const params = [
        {
          key: 'store_name',
          value: values.store_name
        },
        {
          key: 'store_gst',
          value: values.store_gst
        },
        {
          key: 'store_upi_qr',
          value: values.store_upi
        },
        {
          key: 'store_sealsignature',
          value: values.store_signature
        },
        {
          key: 'store_email',
          value: values.store_email
        },
        {
          key: 'store_phone_number',
          value: values.phone_number
        },
        {
          key: 'store_address',
          value: values.address
        },
        {
          key: 'receipt_size',
          value: values.receipt_size
        },
        {
          key: 'disabled_printer',
          value: values.disabled_printer
        },
        {
          key: 'store_bankdetails',
          value: {
            branch: values.branch,
            banck: values.bank_name,
            account_holder_name: values.account_holder_name,
            account_number: values.account_number,
            ifsc: values.ifsc_code
          }
        },
      ]
      authService.addGlobalSetting(params).then((data: _Object) => {
        setLoading(false)
        if (data.error == false) {
          dispatch(setGlobalSettingas())
          formik.resetForm()
        }
      })
    },
  });

  return (
    <>
      <SEOHeader title="Settings" />
      <Breadcrumbs
        trails={[
          {
            label: t('common.settings'),
            path: ''
          }
        ]}
      />

      <form className={`row row-cols-1 row-cols-md-2 row-cols-lg-3 mb-3 ${pageLoading === true ? 'is-loading' : ''}`} onSubmit={formik.handleSubmit}>
        <div className="col mb-md-3 mb-lg-0">
          <div className="card h-100">
            <div className="card-header p-3">
              <h4 className="mb-0">
                {t('store.details')}
              </h4>
            </div>
            <div className="card-body">
              <div className="row">
                <InputField
                  required={true}
                  label={t('store.name')}
                  name="store_name"
                  className="col-12 col-lg-4"
                  value={formik.values.store_name}
                  onChange={formik.handleChange}
                  error={formik?.touched.store_name && formik.errors.store_name}
                />
                <InputField
                  required={true}
                  type="email"
                  label={t('common.email')}
                  name="store_email"
                  className="col-12 col-lg-4"
                  value={formik.values.store_email}
                  onChange={formik.handleChange}
                  error={formik?.touched.store_email && formik.errors.store_email}
                />
                <InputField
                  required={true}
                  type="number"
                  name="phone_number"
                  label={t('common.mobile_number')}
                  className="col-12 col-lg-4"
                  value={formik.values.phone_number}
                  // onChange={formik.handleChange}
                  onChange={(e: any) => {
                    const inputValue = e.target.value;
                    const numericValue = inputValue.replace(/\D/g, '');  // Remove non-numeric characters
                    const trimmedValue = numericValue.slice(0, 10);  // Keep only the first 10 digits
                    formik.setFieldValue('phone_number', trimmedValue);
                  }}
                  error={formik?.touched.phone_number && formik.errors.phone_number && formik.errors.phone_number}
                />
                <InputField
                  required={true}
                  name="address"
                  label={t('common.address')}
                  className="col-12 col-lg-6"
                  onChange={formik.handleChange}
                  value={formik.values.address}
                  error={formik?.touched.address && formik.errors.address}

                />
                <InputField
                  // required={true}
                  name="store_gst"
                  label={t('store.gstin')}
                  className="col-12 col-lg-6"
                  onChange={formik.handleChange}
                  value={formik.values.store_gst}
                // error={formik?.touched.store_gst && formik.errors.store_gst}
                />

                <InputField
                  // required={true}
                  type="url"
                  placeholder="https://example.com"
                  name="store_signature"
                  label={t('store.signature')}
                  className="col-12 col-lg-12"
                  onChange={formik.handleChange}
                  value={formik.values.store_signature}
                // error={formik?.touched.store_signature && formik.errors.store_signature}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col">
          <div className="card h-100">
            <div className="card-header p-3">
              <h4 className="mb-0">
                {t('store.bank_details')}
              </h4>
            </div>
            <div className="card-body">
              <div className="row">
                <InputField
                  // required={true}
                  name="account_holder_name"
                  label={t('store.account_holder_name')}
                  className="col-12 col-lg-6"
                  value={formik.values.account_holder_name}
                  onChange={formik.handleChange}
                // error={formik?.touched.account_holder_name && formik.errors.account_holder_name}
                />

                <InputField
                  // required={true}
                  name="account_number"
                  type="number"
                  label={t('store.account_number')}
                  className="col-12 col-lg-6"
                  value={formik.values.account_number}
                  onChange={formik.handleChange}
                // error={formik?.touched.account_number && formik.errors.account_number}
                />

                <InputField
                  name="bank_name"
                  label={t('store.bank_name')}
                  // required={true}
                  className="col-12 col-lg-6"
                  value={formik.values.bank_name}
                  onChange={formik.handleChange}
                // error={formik?.touched.bank_name && formik.errors.bank_name}
                />

                <InputField
                  // required={true}
                  name="branch"
                  label={t('branch')}
                  className="col-12 col-lg-6"
                  value={formik.values.branch}
                  onChange={formik.handleChange}
                // error={formik?.touched.branch && formik.errors.branch}
                />

                <InputField
                  // required={true}
                  name="ifsc_code"
                  label={t('store.ifsc_code')}
                  className="col-12 col-lg-6"
                  value={formik.values.ifsc_code}
                  onChange={formik.handleChange}
                // error={formik?.touched.ifsc_code && formik.errors.ifsc_code}
                />

                <InputField
                  // required={true}
                  type="url"
                  placeholder="https://example.com"
                  name="store_upi"
                  label={t('store.upi')}
                  className="col-12 col-lg-6"
                  onChange={formik.handleChange}
                  value={formik.values.store_upi}
                // error={formik?.touched.store_upi && formik.errors.store_upi}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col">
          <div className="card h-100">
            <div className="card-header p-3">
              <h4 className="mb-0">
                {t('store.printer_details')}
              </h4>
            </div>
            <div className="card-body">
              <div className="row">
                <RadioButton
                  required={true}
                  name="disabled_printer"
                  className="col-5"
                  label={t('store.printer_disabled')}
                  value={formik.values.disabled_printer}
                  options={[
                    { label: t('common.yes'), value: 'true' },
                    { label: t('common.no'), value: 'false' },
                  ]}
                  onChange={formik.handleChange}
                  error={formik?.touched.disabled_printer && formik.errors.disabled_printer}
                />
                <SelectField
                  isClearable
                  label={t('store.receipt_size')}
                  // required={true}
                  className="col-7"
                  name="receipt_size"
                  options={[
                    { label: '2 Inch', value: '2' },
                    { label: '3 Inch', value: '3' },
                  ]}
                  value={{ value: formik?.values?.receipt_size }}
                  onChange={(e: _Object) => {
                    formik.setFieldValue('receipt_size', e?.value || null)
                  }}
                  getOptionLabel={(option: _Object) => option?.label}
                  getOptionValue={(option: _Object) => option?.value}
                // error={formik?.touched.receipt_size && formik.errors.receipt_size}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 gap-3 mt-3">
            <Button name={t('common.submit')} loading={loading} className="primary" />
          </div>
        </div>
      </form>

    </>
  )
}
export default Settings