
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import { _Object } from 'utils/interfaces';
import { userService } from 'services';
import { setCustomer } from 'redux/slices/cart.slice';
import { routes, strings, generateAvatar } from 'utils';

import Modal from './modal';
import { Button, LinkButton, Loading } from '../../../components';
import { AsyncSelectField } from 'views/components/form-inputs';

const Customer = () => {
  const { t } = useTranslation()
  const [searchText, setSearchText] = useState('')
  const location = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { cart } = useSelector((state: _Object) => state.cart)
  const selectRef = useRef<any>(null);

  useEffect(() => {
    if (selectRef.current && selectRef.current.state && !selectRef.current.state.isFocused) {
      selectRef.current.focus();
    }
  }, []);

  const [pageLoading, setPageLoading] = useState(false)
  const [users, setUsers] = useState<_Object>([])

  const formik = useFormik({
    initialValues: {
      q: ''
    },
    enableReinitialize: true,
    onSubmit: () => {
      // formik.resetForm()
    },
  })

  useEffect(() => {
    if (formik.values.q !== '') {
      setPageLoading(true)
      userService.getCustomerSearch({ per_page: 9999, q: formik.values.q.trim() }).then((data) => {
        setUsers(data)
        setPageLoading(false)
      })
    }
  }, [formik.values.q, location.pathname])

  const customerNameOptions = async (customerValue: string) => {
    formik.setFieldValue('q', customerValue || '')
    const data: any = formik.values.q !== '' && await userService.getCustomerSearch({ q: customerValue })
    return data.items?.map((item: _Object) => { return { label: generateAvatar(item.first_name, item.last_name), value: item.id, mobile: item.mobile_number } })
  }

  return (
    <div className="mt-3 customer-list">
      {location.pathname === `${routes.createCustomer}` &&
        <div className="d-flex justify-content-between mb-3">
          <LinkButton
            path={`${routes.sales}`}
            className="light"
            label="">
            <i className="fa fa-chevron-left me-0"></i>
          </LinkButton>

          <h5 className="my-auto">{t('customer.customers')}</h5>

          <Button
            className="light"
            data-bs-toggle="modal"
            data-bs-target="#staticBackdrop">
            <i className="fa fa-plus me-0"></i>
          </Button>
          <Modal value={formik.values.q} setSearchText={setSearchText} />
        </div>}

      {location.pathname === `${routes.createCustomer}` &&
        <form onSubmit={formik.handleSubmit} >
          <AsyncSelectField
            ref={selectRef}
            name="q"
            autoFocus
            isClearable
            cacheOptions
            iconSearch={true}
            isSearchable={true}
            inputValue={searchText}
            placeholder={t('customer.search_customer')}
            loadOptions={customerNameOptions}
            menuIsOpen={!formik.values.q || formik.values.q}
            // onInputChange={(newValue: any) => {
            //   if (searchText.length === 1 && !newValue) setSearchText(newValue)
            //   if (newValue) setSearchText(newValue)
            // }}
            onInputChange={(newValue: any) => {
              setSearchText(newValue || '');
              formik.setFieldValue('q', newValue || '')
            }}
            defaultOptions={formik.values.q !== '' && users?.items?.length > 0 ? users?.items?.map((item: _Object) => {
              return {
                label: generateAvatar(item?.first_name, item?.last_name),
                value: item?.id,
                mobile: item?.mobile_number
              }
            }) : []}
            onChange={(val: _Object) => {
              formik.setFieldValue('q', val?.label || '')
              const user = users?.items?.find((data: any) => data?.id === val?.value)
              dispatch(setCustomer(user))
              if (val?.label) {
                navigate(`${routes.sales}`);
                formik.setFieldValue('q', '')
              }
            }}
            onKeyDown={(e: _Object) => {
              if (e.key === ' ' && !searchText.trim()) {
                e.preventDefault();
              }
            }}
            noOptionsMessage={() => (
              pageLoading ? <Loading /> : users?.items?.length === 0 && <span>Customer not found</span>
            )}

            formatOptionLabel={({ label, mobile }: any) => {
              const words = label.split(' ');
              let name = '';
              for (const word of words) {
                name += word.charAt(0).toUpperCase();
              }
              return (
                <div onClick={() => { navigate(`${routes.sales}`), formik.resetForm() }} className="d-flex justify-content-between align-items-center avtar-wrap">
                  <div className="d-flex">
                    <div className="rounded-circle bg-light my-auto avtar">
                      <span className="mb-0">{name}</span>
                    </div>

                    <aside className="ms-2">
                      <span >{label}</span>
                      <h6 className="m-0">{mobile}</h6>
                    </aside>
                  </div>
                </div>
              )
            }}
          />
        </form>
      }

      <ul className="list-group list-group-flush mt-3 overflow-auto">
        {location.pathname === `${routes.createCustomer}` ?
          <>
            {formik.values.q && users?.items?.length === 0 &&
              <div>
                <p className="text-danger text-center">{strings.noRecordFound}</p>
              </div>}
          </>
          :
          <>
            <LinkButton
              path={`${routes.createCustomer}`}
              label=""
              className="light d-flex justify-content-between align-items-center w-100">
              <div className="bg-white rounded-circle avtar">
                <i className="fa-solid fa-user-plus m-0"></i>
              </div>
              <h5 className="mb-0 text-capitalize">
                {cart?.customer?.id ? `${generateAvatar(cart?.customer?.first_name, cart?.customer?.last_name)}` : t('customer.add_customer')}
              </h5>
              <i className="fa fa-chevron-right me-0"></i>
            </LinkButton>
          </>
        }
      </ul>

    </div>
  )
}
export default Customer
