import React, { useState, useEffect } from 'react'

import moment from 'moment'
import Highcharts from 'highcharts'
import { HighchartsReact } from 'highcharts-react-official'

import { doDateFormat, doAmountFormat, handleSeries } from 'utils'
import { reportService } from 'services'
import { _Object } from 'utils/interfaces'
import ReportSkeleton from './skelton'

const Expenses = (props: _Object) => {
  const { dateRange, isComparison } = props

  const [expenses, setExpense] = useState<_Object>([])
  const [compareExpense, setCompareExpense] = useState<_Object>([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true);

    reportService.getExpences(dateRange.date1).then((data: _Object) => {
      setLoading(false);
      if (data?.items?.length > 0) {
        setExpense(data);
      }else{
        setExpense([]);
      }
    });

    if (isComparison) {
      reportService.getExpences(dateRange.date2).then((data: _Object) => {
        if (data?.items?.length > 0) {
          setCompareExpense(data);
        }else{
          setCompareExpense([]);
        }
      });
    }
  }, [dateRange.date1.start_date, dateRange.date2.start_date, isComparison]);

  const sereis1 = expenses?.items?.length > 0 ? expenses.items.map((item: _Object) => [
    moment(item.date).valueOf(),
    item.total,
  ]) : []

  const sereis2 = compareExpense?.items?.length > 0 ? compareExpense.items.map((item: _Object) => [
    moment(item.date).valueOf(),
    item.total,
  ]) : []

  const is12HourFormat = true;
  const options = {
    title: {
      text: 'Total expenses',
      align: 'left',
      margin: 30
    },
    subtitle: {
      text: doAmountFormat(expenses?.total ? expenses?.total : 0),
      align: 'left',
    },
    xAxis: {
      type: 'datetime',
      startOnTick: true,
      endOnTick: true,
      labels: {
        style: {
          marginLeft: 5,
          align: 'left', 
        },
        
      },
      dateTimeLabelFormats: {
        hour: is12HourFormat ? '%I:%M%P' : '%H:%M',
        day: '%b %e',
        week: '%b %e',
        month: '%b \'%y',
        year: '%Y',
      },
    },

    yAxis: {
      title: {
        text: null
      },
      gridLineWidth: 1,
      lineWidth: 1,
    },
    tooltip: {
      crosshairs: true,
      shared: true,
      formatter: function (this: Highcharts.TooltipFormatterContextObject): string {
        const date1: any = this.points?.[0]?.x;
        const total1 = (this.points?.[0]?.y ?? 0).toFixed(2);

        let tooltipContent = 'Date: ' + doDateFormat(date1) +
           '<br /><b>Total: ' + doAmountFormat(total1) + '</b>';

        // Include series2 data in the tooltip if available
        if (this.points?.[1]) {
          const date2: any = this.points[1]?.x;
          const total2 = (this.points[1]?.y ?? 0).toFixed(2);

          tooltipContent += '<br /><br />' +
            'Date: ' + doDateFormat(date2) +
             '<br /><b>Total: ' + doAmountFormat(total2) + '</b>';
        }

        return tooltipContent;
      },
      style: {
        fontSize: '10px',
      },
    },
    series: handleSeries('chart', dateRange, sereis1, sereis2, isComparison),
  }
  return (
    <>
      {loading ? <ReportSkeleton />
        :
        <HighchartsReact allowChartUpdate={true} highcharts={Highcharts} options={options} />
      }
    </>
  );
};

export default Expenses;