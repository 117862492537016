import CommonService from './common.service';
import { generateQueryParams } from 'utils';
import { _Object } from 'utils/interfaces';

class ExpenseService extends CommonService {
  async getExpenses(params: _Object | string = '') {
    return await this.get(`expenses?${generateQueryParams(params)}`)
  }
  async getExpenseDetails(id: string) {
    return await this.get(`expenses/${id}`)
  }
  async createExpense(parmas: _Object) {
    return await this.post('expenses', parmas)
  }
  async updateExpense(id: string, params: _Object) {
    return await this.put(`expenses/${id}`, params)
  }
  async deleteExpense(id: string) {
    return await this.delete(`expenses/${id}`)
  }

  async getExpensesCategories(params: _Object | string = '') {
    return await this.get(`expenses/categories?${generateQueryParams(params)}`)
  }
  async getExpenseCategoroyDetails(id: string) {
    return await this.get(`expenses/categories/${id}`)
  }
  async createExpenseCategories(parmas: _Object) {
    return await this.post('expenses/categories', parmas)
  }
  async updateExpenseCategories(id: string, params: _Object) {
    return await this.put(`expenses/categories/${id}`, params)
  }
  async deleteExpenseCategories(id: string) {
    return await this.delete(`expenses/categories/${id}`)
  }

  async getExpenseReport() {
    return await this.get('report/expenses')
  }
}
export const expenseService = new ExpenseService();
