import CommonService from './common.service';
import { generateQueryParams } from 'utils';
import { _Object } from 'utils/interfaces';

class TaskService extends CommonService {
  async getTasks(params: _Object | string) {
    return await this.get(`tasks?${generateQueryParams(params)}`)
  }
  async getTaskDetails(id: string) {
    return await this.get(`tasks/${id}`)
  }
  async createTask(parmas: { [key: string]: boolean | string }) {
    return await this.post('tasks', parmas)
  }
  async updateTask(id: string, params: { [key: string]: boolean | string }) {
    return await this.put(`tasks/${id}`, params)
  }
  async deleteTask(id: string) {
    return await this.delete(`tasks/${id}`)
  }


  async getTaskCategories(params: _Object | string = '') {
    return await this.get(`tasks/categories?${generateQueryParams(params)}`)
  }
  async getTaskCategoroyDetails(id: string) {
    return await this.get(`tasks/categories/${id}`)
  }
  async createTaskCategories(parmas: _Object) {
    return await this.post('tasks/categories', parmas)
  }
  async updateTaskCategories(id: string, params: _Object) {
    return await this.put(`tasks/categories/${id}`, params)
  }
  async deleteTaskCategories(id: string) {
    return await this.delete(`tasks/categories/${id}`)
  }

}
export const taskService = new TaskService();