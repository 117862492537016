import React, { useState, useEffect } from 'react'

import moment from 'moment'
import Highcharts from 'highcharts'
import { HighchartsReact } from 'highcharts-react-official'

import { doDateFormat, doAmountFormat, handleSeries } from 'utils'
import { reportService } from 'services'
import { _Object } from 'utils/interfaces'
import ReportSkeleton from './skelton'

const AverageOrders = (props: _Object) => {
  const { dateRange, isComparison } = props

  const [averageOrders, setAverageOrders] = useState<_Object>([])
  const [compareAverageOrders, setCompareAverageOrders] = useState<_Object>([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true);

    reportService.getAverageOrders(dateRange.date1).then((data: _Object) => {
      setLoading(false);
      if (data?.items?.length > 0) {
        setAverageOrders(data);
      } else {
        setAverageOrders([]);
      }
    });

    if (isComparison) {
      reportService.getAverageOrders(dateRange.date2).then((data: _Object) => {
        if (data?.items?.length > 0) {
          setCompareAverageOrders(data);
        } else {
          setCompareAverageOrders([]);
        }
      });
    }

  }, [dateRange.date1.start_date, dateRange.date2.start_date, isComparison]);

  const sereis1 = averageOrders?.items?.length > 0 ? averageOrders.items.map((item: _Object) => [
    moment(item.date).valueOf(),
    item.total,
  ]) : []

  const sereis2 = compareAverageOrders?.items?.length > 0 ? compareAverageOrders.items.map((item: _Object) => [
    moment(item.date).valueOf(),
    item.total,
  ]) : []

  const is12HourFormat = true;
  const options = {
    title: {
      text: 'Average order value',
      align: 'left',
      margin: 30
    },
    subtitle: {
      text: doAmountFormat(averageOrders?.total ? averageOrders?.total : 0),
      align: 'left',
    },
    xAxis: {
      type: 'datetime',
      startOnTick: true,
      endOnTick: true,
      dateTimeLabelFormats: {
        hour: is12HourFormat ? '%I:%M%P' : '%H:%M',
        day: '%b %e',
        week: '%b %e',
        month: '%b %y',
        year: '%Y',
      },

    },
    yAxis: {
      title: {
        text: null
      },
      gridLineWidth: 1,
      lineWidth: 1,
    },
    tooltip: {
      crosshairs: true,
      shared: true,
      formatter: function (this: Highcharts.TooltipFormatterContextObject): string {
        const date1: any = this.points?.[0]?.x;
        const total1 = (this.points?.[0]?.y ?? 0).toFixed(2);
        const title1 =
          compareAverageOrders?.items?.find((item: _Object) => doDateFormat(item?.date) === doDateFormat(date1))?.order_number ||
          averageOrders?.items?.find((item: _Object) => doDateFormat(item?.date) === doDateFormat(date1))?.order_number;

        let tooltipContent = 'Date: ' + doDateFormat(date1) +
          '<br />Order #: ' + title1 + '<br /><b>Total: ' + doAmountFormat(total1) + '</b>';

        // Include series2 data in the tooltip if available
        if (this.points?.[1]) {
          const date2: any = this.points[1]?.x;
          const total2 = (this.points[1]?.y ?? 0).toFixed(2);
          const title2 =
            averageOrders?.items?.find((item: _Object) => doDateFormat(item?.date) === doDateFormat(date2))?.order_number ||
            compareAverageOrders?.items?.find((item: _Object) => doDateFormat(item?.date) === doDateFormat(date2))?.order_number;

          tooltipContent += '<br /><br />' +
            'Date: ' + doDateFormat(date2) +
            '<br />Order #: ' + title2 + '<br /><b>Total: ' + doAmountFormat(total2) + '</b>';
        }

        return tooltipContent;
      },
      style: {
        fontSize: '10px',
      },
    },
    series: handleSeries('chart', dateRange, sereis1, sereis2, isComparison),
  }
  return (
    <>
      {loading ? <ReportSkeleton />
        :
        <HighchartsReact allowChartUpdate={true} highcharts={Highcharts} options={options} />
      }
    </>
  );
};

export default AverageOrders;