import React from 'react';
import { _Object } from 'utils/interfaces';

const RadioButton = ({ name, value, onChange, options, required, className = '', label, error }: _Object) => {
  return (
    <div className={`form-group mb-3 ${className}`}>
      {label && <label className="label-form mb-1 d-block">{label} {required && <span className="text-danger">*</span>}</label>}

      {
        options && options?.map((option: { [key: string]: string }, i: number) => {
          return (
            <div className="form-check form-check-inline" key={i}>
              <input
                type="radio"
                name={name}
                onChange={onChange}
                value={option.value}
                checked={value == option.value}
                className={'form-check-input'}
                id={`${name}-${i}-${option.value}`}
              />
              <label htmlFor={`${name}-${i}-${option.value}`} className="form-check-label">{option.label}</label>
            </div>
          )
        })
      }

      {error &&<span className="invalid-feedback text-danger d-block mt-1">{error}</span>}
    </div>
  )
}
export default RadioButton