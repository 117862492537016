import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import * as Yup from 'yup'
import { useFormik } from 'formik'

import { routes } from 'utils'
import { _Object } from 'utils/interfaces'
import { taskService, taskTagService } from 'services'
import { InputField, SelectField, Button, LinkButton, TextArea } from 'views/components'

const CreateTask = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [isEditMode, setEditMode] = useState(false)
  const [tasks, setTasks] = useState<_Object>([])
  const [taskTags, setTaskTags] = useState<_Object>([])
  const [initialData, setInitialData] = useState<_Object>({})

  useEffect(() => {
    setEditMode(false)
    setInitialData({
      title: '',
      duration: '',
      parent_id: '',
      tag_ids: [],
      priority: '',
      description: '',
      repetition: '',
      remind_at: '',
      status: 'active'
    })
    taskService.getTasks('').then((data: _Object) => {
      setTasks(data.items)
    })
    taskTagService.getTaskTags('').then((data: _Object) => {
      setTaskTags(data.items)
    })
    if (id) {
      setEditMode(true)
      taskService.getTaskDetails(id).then((data: _Object) => {
        setInitialData(data)
      })
    }
  }, [])
  const formik = useFormik({
    initialValues: initialData,
    enableReinitialize: true,
    validationSchema: Yup.object({
      // title: Yup.string().label('Title').required(),
      // priority: Yup.string().label('Priority').required(),
      // duration: Yup.number().label('Duration').required(),
      // tag_ids: Yup.array().label('Tag ids').length(1).required(),
    }),
    onSubmit: (values) => {
      setLoading(true)
      if (id) {
        delete values.id
        delete values.activities
        delete values.media
        delete values.reminders
        delete values.tags
        delete values.user
        delete values.created_at
        delete values.updated_at
        taskService.updateTask(id, values).then((data: _Object) => {
          data.error === false && navigate(`${routes.tasks}`)
        })
      } else {
        taskService.createTask(values).then((data: _Object) => {
          data.error === false && navigate(`${routes.tasks}`)
        })
      }
      setLoading(false)
    }
  })

  return (
    <>
      <header className="my-2">
        <h2>{isEditMode ? 'Update task' : 'Add task '}</h2>
        <h4>{isEditMode ? 'Update task' : 'Create new task'}</h4>
      </header>

      <form className="row filters" onSubmit={formik.handleSubmit} >
        <InputField
          name="title"
          className="col-6"
          label="Title"
          required={true}
          value={formik.values.title}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        // error={formik.touched.title && formik.errors.title}
        />

        <InputField
          type="number"
          name="duration"
          className="col-6"
          label="Duration"
          required={true}
          value={formik.values.duration}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        // error={formik.touched.duration && formik.errors.duration}
        />

        <SelectField
          name="parent_id"
          className="col-6"
          label="Parent task"
          required={true}
          options={tasks}
          value={tasks?.filter((obj: _Object) => obj.id === formik.values.parent_id)}
          onChange={(data: _Object) => {
            formik.setFieldValue('parent_id', data.id)
          }}
          getOptionLabel={(option: _Object) => option.title}
          getOptionValue={(option: _Object) => option.id}
        // error={formik?.touched.parent_id && formik.errors.parent_id && formik.errors.parent_id}
        />

        <SelectField
          name="priority"
          className="col-4"
          label="Priority"
          required={true}
          options={[
            { label: 'Urgent', value: 'urgent' },
            { label: 'High', value: 'high' },
            { label: 'Medium', value: 'medium' },
            { label: 'Low', value: 'low' },
            { label: 'Lowest', value: 'lowest' },
          ]}
          onChange={(e: _Object) => {
            formik.setFieldValue('priority', e?.value || '')
          }}
          onBlur={formik.handleBlur}
          getOptionLabel={(option: _Object) => option.label}
          getOptionValue={(option: _Object) => option.value}
          // error={formik.touched.priority && formik.errors.priority}
          isClearable
        />

        <SelectField
          name="tag_ids"
          className="col-6"
          label="Tags"
          required={true}
          options={taskTags}
          value={taskTags.items?.filter((obj: { [key: string]: string, }) => obj.id === formik.values.tag_ids
          )}
          onChange={(val: _Object) => {
            formik.setFieldValue('tag_ids', val.map((data: { [key: string]: string }) => { return (data.id) }))
          }}
          getOptionLabel={(option: { [key: string]: string }) => option.title}
          getOptionValue={(option: { [key: string]: string }) => option.id}
          // error={formik.touched.tag_ids && formik.errors.tag_ids}
          isMulti
        />

        <SelectField
          name="remind_at"
          className="col-4"
          label="Reminders"
          options={[
            { label: '5 minutes before', value: 5 },
            { label: '10 minutes before', value: 10 },
            { label: '30 minutes before', value: 30 },
            { label: '1 hour before', value: 60 },
            { label: '1 day before', value: 1440 },
          ]}
          onChange={(e: _Object) => {
            formik.setFieldValue('remind_at', e?.value || '')
          }}
          onBlur={formik.handleBlur}
          getOptionLabel={(option: _Object) => option.label}
          getOptionValue={(option: _Object) => option.value}
          isClearable
        />

        <TextArea
          name="description"
          className="col-12"
          label="Description"
          placeholder="Enter description"
          rows={5}
          value={formik.values.description}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />

        <footer className="row my-2">
          <div className="col-12 d-flex gap-3">
            <Button name={isEditMode ? 'Update' : 'Submit'} className="primary" loading={loading} />
            <LinkButton
              path={routes.tasks}
              label="Cancel"
              className="link text-dark" />
          </div>
        </footer>
      </form>
    </>
  )
}
export default CreateTask