import React from 'react';

const ReportSkeleton = () => {
  return (
    <>

      <div style={{ height: '400px' }} aria-hidden="true">
        <p className="placeholder-glow" style={{ height: '14px', marginBottom: '11px' }}>
          <span className="placeholder col-6"></span>
        </p>
        <h5 className="card-title placeholder-glow" style={{ height: '22px' }}>
          <span className="placeholder col-4"></span>
        </h5>
        <p className="card-text placeholder-glow" style={{ height: '93%' }}>
          <span className="placeholder col-12" style={{ height: '93%' }}></span>
        </p>
      </div>
    </>
  )
}

export default ReportSkeleton;
