import CommonService from './common.service';
import { _Object } from 'utils/interfaces';
import { generateQueryParams } from 'utils';

class ReportService extends CommonService {
  async getOrders(params: _Object | '') {
    return await this.get(`analytics/orders?${generateQueryParams(params)}`)
  }

  async getTopSellingProducts(params: _Object | '') {
    return await this.get(`analytics/top-selling-products?${generateQueryParams(params)}`)
  }

  async getTopBuyers(params: _Object | '') {
    return await this.get(`analytics/top-buyers?${generateQueryParams(params)}`)
  }

  async getAverageOrders(params: _Object | '') {
    return await this.get(`analytics/order-average?${generateQueryParams(params)}`)
  }

  async getExpences(params: _Object | '') {
    return await this.get(`analytics/expense?${generateQueryParams(params)}`)
  }

  async getOrdersReport(params: _Object | '') {
    return await this.get(`reports/orders?${generateQueryParams(params)}`)
  }

  async getExpensesReport(params: _Object | '') {
    return await this.get(`reports/expenses?${generateQueryParams(params)}`)
  }

  async getTaxRatesReport(params: _Object | '') {
    return await this.get(`reports/tax-rates?${generateQueryParams(params)}`)
  }

  async getInventoryReport(params: _Object | '') {
    return await this.get(`reports/inventory?${generateQueryParams(params)}`)
  }
}

export const reportService = new ReportService();
