import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import * as Yup from 'yup'
import { useFormik } from 'formik';

import { brandService } from 'services';
import { routes } from 'utils';
import { _Object } from 'utils/interfaces';
import { Button, InputField, LinkButton, TextArea } from 'views/components';


const CreateBrand = () => {

  const { id } = useParams()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [isEditMode, setEditMode] = useState(false)
  const [initialData, setInitialData] = useState<_Object>({
    name: '',
    slug: '',
    description: ''
  })

  useEffect(() => {
    setEditMode(false)
    if (id) {
      setEditMode(true)
      brandService.getBrandDetails(id).then((data) => {
        setInitialData(data)
      })
    }
  }, [id])

  const formik = useFormik({
    initialValues: initialData,
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string().label('Brand').required(),
      slug: Yup.string().label('Slug').required(),
    }),

    onSubmit: (values) => {
      setLoading(true)
      if (id) {
        delete values.id,
          delete values.created_at
        delete values.updated_at
        brandService.updateBrand(id, values).then((data: _Object) => {
          setTimeout(() => {
            data.error === false && navigate(`${routes.brands}`)
          }, 1000)
        })
      } else {
        values.slug ? values.slug : delete values.slug
        brandService.createBrand(values).then((data: _Object) => {
          setTimeout(() => {
            data.error === false && navigate(`${routes.brands}`)
          }, 1000)
        })
      }
      setLoading(false)
    }
  })

  return (
    <>
      <header className="my-2">
        <h2>{isEditMode ? 'Update Brand' : 'Brand'}</h2>
        <h4>{isEditMode ? 'Update Brand' : 'Create new Brand'}</h4>
      </header>

      <form className="row filters" onSubmit={formik.handleSubmit}>
        <InputField
          name="name"
          className="col-6"
          label="Brand name"
          required={true}
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik?.touched.name && formik.errors.name && formik.errors.name}
        />

        <InputField
          name="slug"
          className="col-6"
          label="Slug"
          required={true}
          value={formik.values.slug}
          onChange={formik.handleChange}
          error={formik?.touched.slug && formik.errors.slug && formik.errors.slug}
        />

        <TextArea
          name="description"
          className="col-12"
          label="Description"
          placeholder="Enter description"
          rows={5}
          value={formik.values.description}
          onChange={formik.handleChange}
          error={formik.touched.description && formik.errors.description}
        />

        <footer className="row my-2">
          <div className="col-12 d-flex">
            <Button name={isEditMode ? 'Update' : 'Submit'} className="primary" loading={loading} />
            <LinkButton
              path={`${routes.brands}`}
              label="Cancel"
              className="link text-dark" />
          </div>
        </footer>
      </form>
    </>

  )
}
export default CreateBrand