import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { setDestroyAuth, setGlobalSettingas, setLoggedInUser } from 'redux/slices/session.slice'
import { closeModal, routes, } from 'utils'
import { _Object } from 'utils/interfaces'
import { Button, LinkButton } from './form-inputs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightArrowLeft, faBars, faBoxesStacked, faCashRegister, faCircleDollarToSlot } from '@fortawesome/free-solid-svg-icons';
import { addToCart } from 'redux/slices/cart.slice';
import { AppDispatch } from 'redux/store';

const Footer = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()
  const { isUserLoggedIn, me } = useSelector((state: _Object) => state.session);

  const location = useLocation()

  useEffect(() => {
    dispatch(setLoggedInUser())
    dispatch(setGlobalSettingas())

  }, [])

  const destroyAuth = () => {
    closeModal('moreModal')
    dispatch(setDestroyAuth())
    dispatch(addToCart([]))
    localStorage.clear()
    if (isUserLoggedIn === false) {
      navigate(`${routes.login}`)
    }
  }

  return (
    <>
      <footer className="main-footer">
        <div className="container-fluid">
          <div className="row">
            <div className="col-11 col-md-11">
              <nav className="navbar navbar-expand-sm d-flex justify-content-center">
                <ul className="navbar-nav">
                  <li className="nav-item mx-3">
                    <LinkButton
                      path={`${routes.sales}`}
                      className={`nav-link border-0 ${location.pathname === routes.sales ? 'active' : ''}`}
                      label={t('checkout.checkout')}>
                      <FontAwesomeIcon icon={faCashRegister} />
                    </LinkButton>
                  </li>

                  <li className="nav-item mx-3">
                    <LinkButton
                      path={`${routes.orders}`}
                      className={`nav-link border-0 ${location.pathname === routes.orders ? 'active' : ''}`}
                      label={t('order.orders')}>
                      <FontAwesomeIcon icon={faArrowRightArrowLeft} />
                    </LinkButton>
                  </li>

                  <li className="nav-item mx-3">
                    <LinkButton
                      path={`${routes.expense}`}
                      className={`nav-link border-0 ${location.pathname === routes.expense ? 'active' : ''}`}
                      label={t('common.expense')}>
                      <FontAwesomeIcon icon={faCircleDollarToSlot} />
                    </LinkButton>
                  </li>

                  <li className="nav-item mx-3">
                    <LinkButton
                      path={`${routes.products}`}
                      className={`nav-link border-0 ${location.pathname === routes.products ? 'active' : ''}`}
                      label={t('product.products')}>
                      <FontAwesomeIcon icon={faBoxesStacked} />
                    </LinkButton>
                  </li>

                  <li className="nav-item mx-3">
                    <button type="button" className="nav-link border-0" data-bs-toggle="modal" data-bs-target="#moreModal">
                      <FontAwesomeIcon icon={faBars} />
                    </button>
                  </li>
                </ul>
              </nav>
            </div>

            <div className="col-1 my-auto d-flex justify-content-end">
              <Button className="link text-dark p-0" onClick={() => destroyAuth()}>
                <i className="fa-solid fa-right-from-bracket fs-5"></i>
              </Button>
            </div>
          </div>
        </div>
      </footer>

      {/* More Modal */}
      <div className="more-modal">
        <div className="modal fade" id="moreModal" tabIndex={-1} aria-labelledby="moreModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-fullscreen">

            <form className="modal-content justify-content-center">
              <div className="container">
                <div className="modal-header border-0">
                  <button type="button" className="button-close btn-close fs-5" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <div className="modal-body">
                  <header>
                    <h2 className="mb-0">{t('login.welcome_back')}, {me?.first_name || 'Hey'}</h2>
                  </header>
                  <div className="orders-list">
                    <div className="row">
                      <div className="col-6">
                        <ul className="list-group">
                          <li className="list-item border-2 border-bottom d-block py-3">
                            <LinkButton
                              className={`link text-dark text-decoration-none ${location.pathname === routes.orders ? 'active' : ''}`}
                              path={`${routes.orders}`}
                              onClick={() => closeModal('moreModal')}
                              label={t('order.orders')}>
                              <i className="fa-solid fa-coins me-2"></i>
                            </LinkButton>
                          </li>

                          <li className="list-item d-block border-2 border-bottom py-3">
                            <LinkButton
                              className={`link text-dark text-decoration-none ${location.pathname === routes.inventory ? 'active' : ''}`}
                              path={`${routes.inventory}`}
                              onClick={() => closeModal('moreModal')}
                              label={t('inventory.inventory')}>
                              <i className="fa-solid fa-boxes-stacked me-2"></i>
                            </LinkButton>
                          </li>

                          <li className="list-item d-block border-2 border-bottom py-3">
                            <LinkButton
                              className={`link text-dark text-decoration-none ${location.pathname === routes.locations ? 'active' : ''}`}
                              path={`${routes.locations}`}
                              onClick={() => closeModal('moreModal')}
                              label={t('location.locations')}>
                              <i className="fa-solid fa-map-location me-2"></i>
                            </LinkButton>
                          </li>

                          <li className="list-item d-block border-2 border-bottom py-3">
                            <LinkButton
                              className={`link text-dark text-decoration-none ${location.pathname === routes.settings ? 'active' : ''}`}
                              path={`${routes.settings}`}
                              onClick={() => closeModal('moreModal')}
                              label={t('common.settings')}>
                              <i className="fa-solid fa-gear me-2"></i>
                            </LinkButton>
                          </li>

                          <li className="list-item d-block border-2 border-bottom py-3">
                            <LinkButton
                              className={`link text-dark text-decoration-none ${location.pathname === routes.expense ? 'active' : ''}`}
                              path={`${routes.expense}`}
                              onClick={() => closeModal('moreModal')}
                              label={t('common.expense')}>
                              <i className="fa-solid fa-circle-dollar-to-slot me-2"></i>
                            </LinkButton>
                          </li>

                        </ul>
                      </div>

                      <div className="col-6">
                        <ul className="list-group">
                          <li className="list-item border-2 border-bottom d-block py-3">
                            <LinkButton
                              className={`link text-dark text-decoration-none ${location.pathname === routes.analytics ? 'active' : ''}`}
                              path={`${routes.analytics}`}
                              onClick={() => closeModal('moreModal')}
                              label={t('report.analytics')}>
                              <i className="fa-solid fa-chart-pie me-2"></i>
                            </LinkButton>
                          </li>

                          <li className="list-item d-block border-2 border-bottom py-3">
                            <LinkButton
                              className={`link text-dark text-decoration-none ${location.pathname === routes.products ? 'active' : ''}`}
                              path={`${routes.products}`}
                              onClick={() => closeModal('moreModal')}
                              label={t('product.products')}>
                              <FontAwesomeIcon icon={faBoxesStacked} />
                            </LinkButton>
                          </li>

                          <li className="list-item border-2 border-bottom d-block py-3">
                            <LinkButton
                              className={`link text-dark text-decoration-none ${location.pathname === routes.users ? 'active' : ''}`}
                              path={`${routes.users}`}
                              onClick={() => closeModal('moreModal')}
                              label={t('user.users')}>
                              <i className="fa-solid fa-users me-2"></i>
                            </LinkButton>
                          </li>

                          <li className="list-item border-2 border-bottom d-block py-3">
                            <LinkButton
                              className={`link text-dark text-decoration-none ${location.pathname === routes.taxRates ? 'active' : ''}`}
                              path={`${routes.taxRates}`}
                              onClick={() => closeModal('moreModal')}
                              label={t('common.tax')}>
                              <i className="fa-solid fa-percent"></i>
                            </LinkButton>
                          </li>

                          {/* <li className="list-item border-2 border-bottom d-block py-3">
                            <LinkButton
                              className={`link text-dark text-decoration-none ${location.pathname === routes.tasks ? 'active' : ''}`}
                              path={`${routes.tasks}`}
                              onClick={() => closeModal('moreModal')}
                              label="Tasks">
                              <i className="fa-solid fa-tasks me-2"></i>
                            </LinkButton>
                          </li> */}

                          <li className="list-item border-2 border-bottom d-block py-3">
                            <LinkButton
                              className={`link text-dark text-decoration-none ${location.pathname === routes.customers ? 'active' : ''}`}
                              path={`${routes.customers}`}
                              onClick={() => closeModal('moreModal')}
                              label={t('customer.customers')}>
                              <i className="fa-solid fa-users me-2"></i>
                            </LinkButton>
                          </li>

                        </ul>
                      </div>
                    </div>
                    <div className="list-item border-2 border-bottom d-flex justify-content-center py-3">
                      <Button className="link text-decoration-none text-start text-danger align-center" onClick={() => destroyAuth()}>
                        <i className="fa-solid fa-right-from-bracket"></i>{t('login.logout')}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="modal-footer">
              <LinkButton path={`${routes.categories}`} label="Cancel" data-bs-dismiss="modal" className="link text-danger" />
              <Button name="Update" className="primary" />
            </div> */}
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default Footer

