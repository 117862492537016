import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import * as Yup from 'yup'
import { useFormik } from 'formik';

import { categoryService } from 'services';
import { routes } from 'utils';
import { _Object } from 'utils/interfaces';
import { Button, InputField, LinkButton, SelectField, TextArea } from 'views/components';

const CreateCategory = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [categories, setCategories] = useState<_Object>([])
  const [isEditMode, setEditMode] = useState(false)

  const [initialData, setInitialData] = useState<_Object>({
    name: '',
    parent_id: '',
    slug: '',
    description: ''
  })


  useEffect(() => {
    setEditMode(false)
    if (id) {
      setEditMode(true)
      categoryService.getCategoryDetails(id).then((data) => {
        setInitialData(data)
      })
    }
    categoryService.getCategories('').then((data: _Object) => {
      setCategories(data.items)
    })
  }, [id])

  const formik = useFormik({
    initialValues: initialData,
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string().label('Category').required(),
      slug: Yup.string().label('Slug').required(),
      parent_id: Yup.string().label('Parent id').required(),
    }),

    onSubmit: (value) => {
      setLoading(true)
      if (id) {
        delete value.id,
          delete value.created_at
        delete value.updated_at
        value.slug === '' && delete value.slug

        categoryService.updateCategory(id, value).then((data: _Object) => {
          setTimeout(() => {
            data.error === false && navigate(`${routes.categories}`)
          }, 1000)
        })
      } else {
        value.slug === '' && delete value.slug

        categoryService.createCategory(value).then((data: _Object) => {
          data.error === false && navigate(`${routes.categories}`)
        })
      }
      setLoading(false)

    }
  })

  return (
    <>

      <header className="my-2">
        <h2>{isEditMode ? 'Update Category' : 'Category'}</h2>
        <h4>{isEditMode ? 'Update Category' : 'Create new Category'}</h4>
      </header>

      <form className="row filters" onSubmit={formik.handleSubmit}>
        <InputField
          name="name"
          className="col-6"
          label="Category name"
          required={true}
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik?.touched.name && formik.errors.name && formik.errors.name}
        />

        <SelectField
          name="parent_id"
          className="col-6"
          label="Parent name"
          required={true}
          options={categories}
          value={categories?.filter((obj: _Object) => obj.id === formik.values.parent_id)}
          onChange={(data: _Object) => {
            formik.setFieldValue('parent_id', data.id)
          }}
          getOptionLabel={(option: _Object) => option.name}
          getOptionValue={(option: _Object) => option.id}
          error={formik?.touched.parent_id && formik.errors.parent_id && formik.errors.parent_id}
        />

        <InputField
          name="slug"
          className="col-6"
          label="Slug"
          required={true}
          value={formik.values.slug}
          onChange={formik.handleChange}
          error={formik?.touched.slug && formik.errors.slug && formik.errors.slug}
        />

        <TextArea
          name="description"
          className="col-12"
          label="Description"
          placeholder="Enter description"
          rows={5}
          value={formik.values.description}
          onChange={formik.handleChange}
          error={formik.touched.description && formik.errors.description}
        />

        <footer className="row my-2">
          <div className="col-12 d-flex">
            <Button name={isEditMode ? 'Update' : 'Submit'} className="primary" loading={loading} />
            <LinkButton
              path={`${routes.categories}`}
              label="Cancel"
              className="link text-dark" />
          </div>
        </footer>
      </form>

    </>
  )
}
export default CreateCategory