import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { routes, serialNumber, strings, doAmountFormat, doDateFormatWithTime, handleFilters, getSortIcon, stock_status } from 'utils';
import { _Object } from 'utils/interfaces';
import { productService, supplierService } from 'services';
import { Button, SearchField, LinkButton, Pagination, SelectField, TabsHeader, Breadcrumbs, ActiveFilters, SEOHeader } from 'views/components';
import { useFormik } from 'formik';

import { AsyncSelectField } from 'views/components/form-inputs';
import { Link } from 'react-router-dom';

const ProductsList = () => {
  const { t } = useTranslation()
  const selectAsyncSelect: any = useRef([])

  const [loading, setLoading] = useState<_Object>(
    {
      listing: false,
      edit: false,
      update: false
    });
  const [products, setProducts] = useState<_Object>([])
  const [filterdLabel, setFilteredLabel] = useState<_Object>({})
  const [filters, setFilters] = useState<_Object>({
    // tags: [],
    // brands: [],
    suppliers: [],
    // categories: []
  })
  const [filterData, setFilterData] = useState<_Object>({
    page: 1,
    per_page: process.env.REACT_APP_PER_PAGE,
    sort: '-created_at',
    filter: {
      stock_status: '',
      tag_id: '',
      brand_id: '',
      category_id: '',
      supplier_id: '',
    }
  })

  const handleCallApi = () => {
    setLoading({ listing: true });
    productService.getProducts({ ...filterData, q: formik.values.q }).then((data: _Object) => {
      setProducts(data)
      setLoading({ listing: false });
    })
  }

  const formik = useFormik({
    initialValues: { q: '' },
    enableReinitialize: true,
    onSubmit: () => {
      if (formik.values.q.trim() !== '') {
        handleCallApi()
      }
    }
  })

  useEffect(() => {
    if (formik.values.q === '') {
      handleCallApi()
    }
  }, [filterData, formik.values.q])

  useEffect(() => {
    supplierService.getSuppliers('').then((data: _Object) => {
      setFilters((e: _Object) => ({
        ...e,
        suppliers: data.items
      }))
    })
    // categoryService.getCategories('').then((data: _Object) => {
    //   setFilters((e: _Object) => ({
    //     ...e,
    //     categories: data.items
    //   }))
    // })
    // tagService.getTags('').then((data: _Object) => {
    //   setFilters((e: _Object) => ({
    //     ...e,
    //     tags: data.items
    //   }))
    // })
    // brandService.getBrands('').then((data: _Object) => {
    //   setFilters((e: _Object) => ({
    //     ...e,
    //     brands: data.items
    //   }))
    // })
  }, [])


  const deleteItem = (idToDelete: string) => {
    if (window.confirm('Are you sure to delete this item?')) {
      setLoading({ listing: true })
      productService.deleteProduct(idToDelete).then(() => {
        productService.getProducts(filterData).then((data: _Object) => {
          setLoading({ listing: false })
          if (data?.items) {
            setProducts(data)
          }
        })
      })
    }
  }

  // const categoriesOption = async (categoriesValue: string) => {
  //   const data: _Object = await categoryService.getCategories({ q: categoriesValue })
  //   return data.items?.map((item: _Object) => { return { label: item.name, value: item.id } })
  // }

  const supplierOption = async (supplierValue: string) => {
    const data: _Object = await supplierService.getSuppliers({ q: supplierValue })
    return data.items?.map((item: _Object) => { return { label: item.name, value: item.id } })
  }

  // const brandOption = async (brandValue: string) => {
  //   const data: _Object = await brandService.getBrands({ q: brandValue })
  //   return data.items?.map((item: _Object) => { return { label: item.name, value: item.id } })
  // }

  return (
    <>
      <SEOHeader title="Products" />
      <Breadcrumbs
        trails={[
          {
            label: t('product.products'),
            path: ''
          }
        ]}
      />

      <TabsHeader />

      <div className="pos-justify pos-between pos-align pos-center">
        <div className="d-flex header-loading">
          <form onSubmit={formik.handleSubmit}>
            <SearchField
              type="search"
              name="q"
              disabled={!formik.values.q}
              className="mb-md-0"
              value={formik.values.q}
              onChange={(e: _Object) => {
                formik.setFieldValue('q', e.target.value)
              }}
            />
          </form>
          <span className={` ${loading.listing === true ? 'is-loading ms-4' : ''}`} />
        </div>

        {Object.values(filterData.filter).some(value => !!value) && (
          <ActiveFilters filterdLabel={filterdLabel.filter} setFilteredLabel={setFilteredLabel} setFilterData={setFilterData} />
        )}

        <span className="d-flex align-items-center gap-3">
          <Link
            to={`${routes.products}/import`}
            className="primary text-decoration-none"
          >
            {t('product.import')}
          </Link>

          <LinkButton className="primary" path={`${routes.createProduct}`} label="">
            <i className="fa-solid fa-plus text-white"></i>
            {t('product.add')}
          </LinkButton>
        </span>
      </div>

      <div className="card border-0 my-3">
        <div className={`table-responsive ${loading.listing === false && products?.items?.length ? '' : 'table-not'}`}>
          <table className="card-body mb-0 table table-borderless table-striped order-listing">
            <thead>
              <tr className="bg-white">
                <th>#</th>
                {/* <th style={{ width: '8rem' }} className="sku">SKU</th> */}
                <th>
                  <button
                    className="btn p-0 border-0"
                    onClick={() => { handleFilters('sort', filterData.sort === '-name' ? 'name' : '-name', setFilterData) }}>
                    {t('common.title')}
                    {getSortIcon(filterData.sort.includes('name') ? filterData.sort : '')}
                  </button>
                </th>
                <th>{t('common.price')}</th>
                <th>
                  <button type="button" className={`btn btn-transparent dropdown-toggle p-0 border-0 ${filterData.filter?.stock_status?.length > 0 ? 'show filter-add' : 'show'}`}
                    data-bs-toggle="dropdown" aria-expanded="true" data-bs-auto-close="outside">
                    {t('common.stock')}
                    <i className="fa-solid fa-chevron-down"></i>
                  </button>
                  <div className="dropdown">
                    <div className="dropdown-menu dropdown-menu-end">
                      <SelectField
                        menuIsOpen
                        isSearchableHide={true}
                        name="stock_status"
                        className="col"
                        // hiddenTrue={true}
                        options={[
                          { label: t('product.in_stock'), value: 'instock' },
                          { label: t('product.out_of_stock'), value: 'outofstock' },
                        ]}
                        onChange={(e: _Object) => {
                          setFilterData((prev) => ({
                            ...prev,
                            page: 1,
                            filter: {
                              ...prev.filter,
                              stock_status: e?.value || ''
                            }
                          }));
                          setFilteredLabel((prevLabel) => ({
                            filter: {
                              ...prevLabel.filter,
                              stock_status: e?.label || '',
                            },
                          }));
                        }}
                        getOptionLabel={(option: _Object) => option.label}
                        getOptionValue={(option: _Object) => option.value}
                        isClearable
                      />
                    </div>
                  </div>
                </th>

                {/* <th>
                <button type="button" className={`btn btn-transparent dropdown-toggle p-0 border-0 ${filterData.filter?.category_id?.length > 0 ? 'show filter-add' : 'show'}`} data-bs-toggle="dropdown" aria-expanded="true" data-bs-auto-close="outside">
                  Category
                  <i className="fa-solid fa-chevron-down"></i>
                </button>
                <div className="dropdown">
                  <div className="dropdown-menu dropdown-menu-end">
                    <AsyncSelectField
                      isClearable
                      menuIsOpen={true}
                      cacheOptions
                      name="category_id"
                      // placeholder="Search"
                      loadOptions={categoriesOption}
                      ref={(ref: any) => (selectAsyncSelect.current = ref)}
                      defaultOptions={filters?.categories?.map((item: _Object) => {
                        return {
                          label: item.name, value: item.id
                        }
                      })}
                      onChange={(val: _Object) => {
                        setFilterData((e) => ({
                          ...e,
                          filter: {
                            ...e.filter,
                            category_id: val?.value || ''
                          }
                        }))
                      }}
                    />
                  </div>
                </div>
              </th>

              <th>
                <button type="button" className={`btn btn-transparent dropdown-toggle p-0 border-0 ${filterData.filter?.brand_id?.length > 0 ? 'show filter-add' : 'show'}`} data-bs-toggle="dropdown" aria-expanded="true" data-bs-auto-close="outside">
                  Brand
                  <i className="fa-solid fa-chevron-down"></i>
                </button>
                <div className="dropdown">
                  <div className="dropdown-menu dropdown-menu-end">
                    <AsyncSelectField
                      isClearable
                      menuIsOpen={true}
                      cacheOptions
                      name="brand_id"
                      // placeholder="Search"
                      loadOptions={brandOption}
                      ref={(ref: any) => (selectAsyncSelect.current = ref)}
                      defaultOptions={filters?.brands?.map((item: _Object) => {
                        return {
                          label: item.name, value: item.id
                        }
                      })}
                      onChange={(val: _Object) => {
                        setFilterData((e) => ({
                          ...e,
                          filter: {
                            ...e.filter,
                            brand_id: val?.value || ''
                          }
                        }))
                      }}
                    />
                  </div>
                </div>
              </th>  */}

                <th>
                  <button type="button" className={`btn btn-transparent dropdown-toggle p-0 border-0 ${filterData.filter?.supplier_id?.length > 0 ? 'show filter-add' : 'show'}`} data-bs-toggle="dropdown" aria-expanded="true" data-bs-auto-close="outside">
                    {t('supplier.supplier')}
                    <i className="fa-solid fa-chevron-down"></i>
                  </button>
                  <div className="dropdown">
                    <div className="dropdown-menu dropdown-menu-end">
                      <AsyncSelectField
                        isClearable
                        menuIsOpen={true}
                        cacheOptions
                        name="supplier_id"
                        // placeholder="Search"
                        loadOptions={supplierOption}
                        ref={(ref: any) => (selectAsyncSelect.current = ref)}
                        defaultOptions={filters?.suppliers?.map((item: _Object) => {
                          return {
                            label: item.name, value: item.id
                          }
                        })}
                        onChange={(val: _Object) => {
                          setFilterData((e) => ({
                            ...e,
                            filter: {
                              ...e.filter,
                              supplier_id: val?.value || ''
                            }
                          }))
                          setFilteredLabel((prevLabel) => ({
                            filter: {
                              ...prevLabel.filter,
                              supplier_id: val?.label || '',
                            },
                          }));
                        }}
                      />
                    </div>
                  </div>
                </th>

                <th>
                  <button
                    className="btn p-0 border-0"
                    onClick={() => { handleFilters('sort', filterData.sort === '-created_at' ? 'created_at' : '-created_at', setFilterData) }}>
                    {t('common.createDate')}
                    {getSortIcon(filterData.sort.includes('created_at') ? filterData.sort : '')}
                  </button>
                </th>

                <th>
                  <button
                    className="btn p-0 border-0"
                    onClick={() => { handleFilters('sort', filterData.sort === '-updated_at' ? 'updated_at' : '-updated_at', setFilterData) }}>
                    {t('common.updateDate')}
                    {getSortIcon(filterData.sort.includes('updated_at') ? filterData.sort : '')}
                  </button>
                </th>

                <th className="action">{t('common.action')}</th>
              </tr>
            </thead>

            <tbody>
              {products?.items?.map((item: _Object, i: number) => {
                return (
                  <tr key={i}>
                    <td>{serialNumber(filterData.per_page, products?.pagination?.current_page, i)}</td>
                    <td className="break-spaces">
                      {item?.name}
                      <small className="d-block text-secondary">Sku: {item?.sku}</small>
                    </td>
                    <td>
                      {item?.discounted_price ?
                        <>
                          <del>{doAmountFormat(item?.regular_price)}</del><br />
                          <span>{doAmountFormat(item?.discounted_price)}</span>
                        </>
                        :
                        doAmountFormat(item?.regular_price)
                      }
                    </td>

                    <td>{stock_status(item.stock_status)}{item.manage_stock == 'true' && item.stock_status == 'instock' && `: ${item.stock}`}</td>
                    {/* <td>
                    {item?.categories?.map((categoriesItem: _Object, k: number) => {
                      return (
                        <span className="d-block mb-1" key={k}>{categoriesItem?.name}</span>
                      )
                    })}
                  </td> */}
                    {/* <td>{item?.brand?.name}</td> */}

                    <td>
                      {item?.suppliers &&
                        item.suppliers.map((suppliersItem: _Object, l: number) => (
                          <span className="d-block mb-1" key={l}>{suppliersItem?.name}</span>
                        )) || '-'
                      }
                    </td>

                    <td>{doDateFormatWithTime(item.created_at)}</td>
                    <td>{doDateFormatWithTime(item.updated_at)}</td>
                    <td>
                      <ul className="list-inline">
                        <li className="list-inline-item">
                          <LinkButton
                            path={`${routes.products}/edit/${item.id}`}
                            className="link text-dark p-0"
                            label={''}>
                            <i className="fa fa-pen"></i>
                          </LinkButton>
                        </li>
                        <li className="list-inline-item">
                          <Button type="submit" className="link text-dark p-0" onClick={() => deleteItem(item.id)}>
                            <i className="fa fa-trash text-danger"></i>
                          </Button>
                        </li>
                      </ul>
                    </td>
                  </tr>
                );
              })}
              {loading.listing === false && products?.items?.length === 0 &&
                <tr>
                  <td colSpan={8} className="text-center no-entry">
                    <aside>
                      <i className="fa-solid fa-ban"></i>
                      {strings.noRecordFound}
                    </aside>
                  </td>
                </tr>
              }
            </tbody>
          </table>
        </div>
      </div>

      {products?.pagination?.total_pages > 1 &&
        <Pagination
          current_page={products?.pagination?.current_page}
          per_page={products?.pagination?.per_page}
          total_pages={products?.pagination?.total_pages}
          total_items={products?.pagination?.total_items}
          onClick={(i: { [key: string]: number }) =>
            setFilterData((prev) => ({
              ...prev,
              page: i.selected + 1,
            }))
          }
        />
      }
    </>
  )
}
export default ProductsList
