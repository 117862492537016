import React from 'react';
import { Link } from 'react-router-dom';
// import { linkProps } from 'utils/interfaces';

const LinkButton = ({ path, onClick, className = '', label, iconPosition = 'left', children, ...props }: any) => {
  return (
    <>
      <Link
        to={path}
        onClick={onClick}
        className={`btn btn-${className}`}
        {...props}
      >
        {iconPosition === 'right' && label}
        {children}
        {iconPosition === 'left' && label}
      </Link>
    </>
  )
}
export default LinkButton