import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import * as Yup from 'yup';
import { useFormik } from 'formik';

import { _Object } from 'utils/interfaces';
import { capitalizeFirstLetter, closeModal, doAmountFormat, handleCartTotal, routes } from 'utils';
import { addToCart, decrementQuantity, incrementQuantity, lineItemDiscount, removeCartItem, setQuantity } from 'redux/slices/cart.slice';

import { Button, Customer, Quantity } from 'views/components';
import SearchAndModel from './search';
import TodaySalesSummary from './summary';
import CartDiscountModal from './cart-discount-modal';
import { useLocation } from 'react-router';
import { locationService, productService } from 'services';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faXmark } from '@fortawesome/free-solid-svg-icons';

const SalesTemplate3 = ({ orderFormik, btnLoading }: _Object) => {

	const { t } = useTranslation()
	const dispatch = useDispatch()
	const location = useLocation()
	const { cart } = useSelector((state: _Object) => state.cart)
	const { globalSettings, me } = useSelector((state: _Object) => state.session)

	// const [clickedItem, setClickedItem] = useState<_Object>({});
	const [loading, setLoading] = useState<_Object>(
		{
			listing: false,
			edit: false,
			update: false
		});
	// const [products, setProducts] = useState<_Object>([])
	const [subLocationsData, setSubLocationsData] = useState<_Object>([])
	const [subLocations, setSubLocations] = useState<_Object>({})

	const handleCallApi = () => {
		setSubLocationsData([])
		setLoading({ listing: true });

		productService.getProducts({ per_page: 999, q: '', filters: { status: 'published' } }).then((data: any) => {
			// data.items.forEach((product: any) => {
			// 	// Extract the location relationship id
			// 	const locationId: string = product.product_location_relationships[0]?.sub_locationid;

			// 	// If the location id doesn't exist in the productsByLocationId object, create it
			// 	if (!productsByLocationId[locationId]) {
			// 		productsByLocationId[locationId] = {
			// 			key: locationId,
			// 			items: []
			// 		};
			// 	}

			// 	// Check if the product with the same location id already exists in the items array
			// 	const existingProductIndex: number = productsByLocationId[locationId].items.findIndex((item: any) => item.product_location_relationships[0]?.sub_locationid === product.product_location_relationships[0]?.sub_locationid);

			// 	// If the product exists, update it; otherwise, add it to the items array
			// 	if (existingProductIndex !== -1) {
			// 		productsByLocationId[locationId].items[existingProductIndex] = product;
			// 	} else {
			// 		productsByLocationId[locationId].items.push(product);
			// 	}
			// });
			data?.items?.forEach((item: any) => {
				const currentLocation = item?.locations?.find(
					(location: any) => location.location_id === me.location_id
				);

				if (currentLocation && currentLocation?.sub_locationid !== null) {
					setSubLocationsData((prev: any) => ({
						...prev,
						[currentLocation.sub_locationid]: [
							...(prev[currentLocation.sub_locationid] || []),
							item,
						],
					}));
				} else if (currentLocation) {
					setSubLocationsData((prev: any) => ({
						...prev,
						[currentLocation.sub_locationid]: [
							...(prev[currentLocation.sub_locationid] || []),
							item,
						],
					}));
				}
			});

			// setProducts(data);
			setLoading({ listing: false });
		});
	};



	useEffect(() => {
		const localdata: any = localStorage.getItem('cartLineItems')
		if (JSON.parse(localdata)?.length > 0 && cart?.lineItems?.length === 0) {
			dispatch(addToCart(JSON.parse(localdata)))
		}

		locationService.getLocationDetails(me.location_id).then((data) => {
			setSubLocations(data)
		})
		handleCallApi()
	}, [])

	const discountFormik = useFormik({
		initialValues: { label: '', amount: '' },
		validationSchema: Yup.object({
			amount: Yup.number()
				.label('Discount amount')
				.positive('Amount must be greater than zero')
				.required()

		}),
		enableReinitialize: true,
		onSubmit: (values) => {
			dispatch(lineItemDiscount({ values }));
			closeModal('discount-modal');
			discountFormik.resetForm();
		},
	});

	const cartTotal = handleCartTotal(cart, globalSettings);

	const decimalPart = orderFormik.values.amount.toString().split('.')[1];
	const decimalValue = !decimalPart || decimalPart.length <= 2 || decimalPart.slice(2).length === 0;

	useEffect(() => {
		if (cartTotal.total !== 0) {
			orderFormik.setFieldValue('amount', cartTotal.total)
		}
	}, [cartTotal.total])

	const handleAddToCart = (item: _Object) => {
		dispatch(addToCart({
			id: item?.id,
			sku: item?.sku,
			name: item?.name,
			stock: item?.stock,
			price: item?.discounted_price ? item?.discounted_price : item?.regular_price,
			tax_rate: item?.tax_rate,
			brand_name: item[0]?.brand?.name,
			tax_rate_id: item?.tax_rate_id,
			description: item?.description,
			attributes: item?.attributes,
		}))
	}

	return (
		<>
			<div className="row h-100">
				<div className="col-8 col-md-8 col-lg-8 col-xl-9 col-xxl-9 mt-3 sales-list">
					<>
						<SearchAndModel />
						<div>
							<h5 className="fw-semibold mb-3">{globalSettings.store_name ?? 'Cart'}</h5>
							<div className="row right-contnet-box">

								<span className={` ${loading.listing === true ? 'btn-loading m-5' : ''}`} />
								{!loading.listing &&
									Object.entries(subLocationsData).map(([key, value], index) => (
										<div className="col-4" key={index}>
											<div className="card">
												<div className="card-header border-bottom"><span className="fw-semibold">{subLocations?.sub_locations?.find((location: _Object) => location?.id === key)?.name || 'Others'}</span>&nbsp;
													<small>({value?.length ?? 0})</small>
												</div>

												<div className="card-body pb-0">
													<ul className="list-unstyled p-0">
														{value?.map((item: _Object, index: number) => {
															return (
																<li className="mb-2 p-2 bg-light rounded-3 task-box" key={index}>
																	<div className="to-do-list">
																		<div className="d-flex justify-content-between">
																			<div>
																				<p className="mb-0" style={{ paddingLeft: 2 }}>
																					<span className="d-block">{item?.name}</span>
																					{item?.sku && <small className="d-block">{t('common.sku')}: {item?.sku}</small>}
																				</p>
																			</div>

																			<div className="text-end card-icon-box">
																				<span className="fw-semibold fs-6">{doAmountFormat(item.price)}</span>
																				<button type="button" className="btn btn-link p-0" onClick={() => handleAddToCart(item)}><i className="fa fa-cart-shopping fs-5 me-0 mt-2"></i></button>
																			</div>

																		</div>
																	</div>
																</li>
															)
														})
														}
													</ul>
												</div>
											</div>
										</div>
									))
								}
							</div>
						</div>
					</>

					<TodaySalesSummary />
				</div>

				<div className="col-4 col-md-4 col-lg-4 col-xl-3 col-xxl-3 bg-white border-start">
					<Customer />

					<div className="mt-3 customer-list">
						{location.pathname === `${routes.sales}` &&
							<div className="">
								{/* cart?.customer?.id &&  */}
								{cart?.lineItems.length > 0 &&
									<form onSubmit={orderFormik.handleSubmit}>
										<div className="mt-2 h-100">
											<div className="row mb-2">
												<div className="border-bottom">
													<h6 className="fw-semibold">Product</h6>
												</div>
												<div className="customer-right-colum">
													{cart?.lineItems && [...cart.lineItems].reverse().map((item: _Object, index: number) => (
														<div className="col-12" key={index}>
															<div className="border-bottom py-1">
																<p className="mb-0">{capitalizeFirstLetter(item?.name)}</p>
																{item?.sku && <small className="text-secondary">{t('common.sku')}: {item?.sku}</small>}
																<div className="d-flex justify-content-between align-items-center my-2 product-list-items">
																	<div className="d-flex align-items-center gap-2">

																		<span className="fw-semibold">{doAmountFormat(item.price)}&nbsp;</span>

																		<div className="input-group quantity">
																			<Quantity
																				onChange={(e: _Object) => dispatch(setQuantity({ 'quantity': e?.target?.value, id: item?.id }))}
																				value={item?.quantity}
																				incrementQuantity={() => dispatch(incrementQuantity(item?.id))}
																				decrementQuantity={() => dispatch(decrementQuantity(item?.id))}
																			/>
																		</div>
																	</div>
																	<div>
																		<Button type="button" className="link text-danger p-0 float-end" onClick={() => dispatch(removeCartItem({ index: index, reverse: true }))}>
																			<i className="fa-solid fa-trash me-0"></i>
																		</Button>
																	</div>

																</div>
															</div>
														</div>
													))
													}
												</div>
											</div>

											<div className="payment-methods">
												<h6 className="mb-0 d-flex align-items-center">{t('checkout.payment_mode')}
													{orderFormik.values.payment_mode &&
														<Button type="button" onClick={() => orderFormik.setFieldValue('payment_mode', '')} className="transparent p-0 ms-2 border-0">
															<FontAwesomeIcon icon={faXmark} /></Button>
													}
												</h6>
												<ul className="list-inline w-100 d-inline-flex py-2">
													<li className="list-inline-item">
														<Button
															className={`outline-primary text-nowrap ${orderFormik.values.payment_mode === 'cash' && 'active'}`}
															label="Cash"
															type={'button'}
															onClick={() => {
																orderFormik.setFieldValue('payment_mode', 'cash')
															}}>
															{t('cash')}
														</Button>
													</li>
													<li className="list-inline-item">
														<Button
															className={`outline-primary text-nowrap ${orderFormik.values.payment_mode === 'card' && 'active'}`}
															label="Card"
															type={'button'}
															onClick={() => {
																orderFormik.setFieldValue('payment_mode', 'card')
															}}>
															{t('card')}
														</Button>
													</li>
													{/* <li className="list-inline-item">
														<Button
															className={`outline-primary text-nowrap ${orderFormik.values.payment_mode === 'phonepe' && 'active'}`}
															label="Phonepe"
															type={'button'}
															onClick={() => {
																orderFormik.setFieldValue('payment_mode', 'phonepe')
															}}>
															{t('phonepe')}
														</Button>
													</li>

													<li className="list-inline-item">
														<Button
															className={`outline-primary text-nowrap ${orderFormik.values.payment_mode === 'paytm' && 'active'}`}
															label="Paytm"
															type={'button'}
															onClick={() => {
																orderFormik.setFieldValue('payment_mode', 'paytm')
															}}>
															{t('paytm')}
														</Button>
													</li> */}

													{/* <li className="list-inline-item">
														<Button
															className={`outline-primary text-nowrap ${orderFormik.values.payment_mode === 'googlepay' && 'active'}`}
															label="Google pay"
															type={'button'}
															onClick={() => {
																orderFormik.setFieldValue('payment_mode', 'googlepay')
															}}>
															{t('googlepay')}
														</Button>
													</li> */}

													<li className="list-inline-item">
														<Button
															className={`outline-primary text-nowrap ${orderFormik.values.payment_mode === 'upi' && 'active'}`}
															label="Upi"
															type={'button'}
															onClick={() => {
																orderFormik.setFieldValue('payment_mode', 'upi')
															}}>
															{t('upi')}
														</Button>
													</li>

													{/* <li className="list-inline-item">
														<Button
															className={`outline-primary text-nowrap ${orderFormik.values.payment_mode === 'card' && 'active'}`}
															label="Credit card"
															type={'button'}
															onClick={() => {
																orderFormik.setFieldValue('payment_mode', 'card')
															}}>
															{t('debitCredit')}
														</Button>
													</li> */}
												</ul>
											</div>

											<div className="payment-mode border-top">
												<ul className="list-item ps-0 payable-amount">

													{/* <li className="list-item d-flex justify-content-between mb-3">
													<h6>{t('subTotal')}</h6>
													<span>{doAmountFormat(cartTotal.subTotal)}</span>
												</li> */}

													{globalSettings.store_enable_discount === true &&
														<li className="list-item d-flex justify-content-between mb-3">
															<h6>{t('discount')}</h6>
															<span>{doAmountFormat(cartTotal.discount)}</span>
														</li>}

													{globalSettings.store_prices_entered_with_tax === 'excluding' &&
														<li className="list-item d-flex justify-content-between mb-3">
															<h6>{t('tax')}</h6>
															<span>{doAmountFormat(cartTotal.tax)}</span>
														</li>}

													<li className="list-item d-flex justify-content-between">
														<h6>{t('common.total')}&nbsp;</h6>
														{globalSettings.store_enable_discount === true &&
															<Button
																type="button"
																data-bs-toggle="modal"
																data-bs-target="#cartitem-modal"
																className="text-decoration-none border-none p-0"
															>
																<i className="fa-solid fa-tags"></i>&nbsp;{cart?.discounts &&
																	<small >({capitalizeFirstLetter(cart?.discounts.label)}: {cart?.discounts.amount})</small>
																}
															</Button>
														}
														<span>
															<strong>{doAmountFormat(cartTotal?.total)}</strong>
														</span>
													</li>

													<li className="list-item d-flex justify-content-end mb-3">
														<small className="mb-0">
															{/* ({t('includes')}{' '} */}
															{globalSettings.store_prices_entered_with_tax === 'including' &&
																cart.lineItems.map((item: any, i: number) => {
																	return (
																		item.tax_rate?.title && item.tax_rate?.rate && (
																			<small key={i}>
																				{item.tax_rate.title?.toUpperCase()} {item.tax_rate.rate}%&nbsp;
																				{doAmountFormat(
																					(item.tax_rate.rate / 100) * item.price * item.quantity
																				)}
																				{i !== cart.lineItems.length - 1 ? ', ' : ''}
																			</small>
																		)
																	);
																})}

														</small>
													</li>

													{/* <li className="list-item d-flex justify-content-between mb-3 align-items-center">
													<h6>{t('payAmount')}</h6>
													<InputGroup
														type="number"
														required="true"
														name="amount"
														leftIcon={true}
														value={orderFormik.values.amount}
														onBlur={orderFormik.handleBlur}
														onChange={orderFormik.handleChange}
														className="pay-input"
														error={orderFormik.touched.amount && orderFormik.errors.amount}
													/>
												</li> */}
												</ul>
												<Button
													type={'submit'}
													className="primary w-100"
													disabled={
														!(
															// cart?.customer?.id &&
															orderFormik?.values?.payment_mode?.length > 0 &&
															cart?.lineItems?.length !== 0 &&
															(orderFormik.values.amount <= cartTotal.total) &&
															decimalValue
														)
													}
													name={t('placeOrder')}
													loading={btnLoading}
													onClick={() => {
														localStorage.removeItem('cartLineItems');
													}}
												/>
											</div>
										</div>
									</form>
								}

								<CartDiscountModal amount={orderFormik.values.amount} />
							</div>
						}
					</div>
				</div>
			</div>
		</>
	)
}
export default SalesTemplate3