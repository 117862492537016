import React from 'react'
import { _Object } from 'utils/interfaces'
import ReactPaginate from 'react-paginate'

const Pagination = ({ total_pages, onClick }: _Object) => {

  // useEffect(() => {
  //   window.scrollBy(0, 0);
  // }, [current_page]);

  return (
    <>
      <div className="pagination-wrap">
        <ReactPaginate
          breakLabel=""
          nextLabel=">"
          previousLabel="<"
          onPageChange={onClick}
          pageCount={total_pages}
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link previous-arrow"
          nextClassName="page-item"
          nextLinkClassName="page-link next-arrow"
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination"
          activeClassName="active"
        />
      </div>
    </>
  )
}
export default Pagination


