// import React from 'react'
// import { StylesConfig } from 'react-select'
// import AsyncSelect from 'react-select/async'
// import { _Object } from 'utils/interfaces'

// const AsyncSelectField = ({ label = '', ref, className, disabled, icon, onKeyDown, placeholder = 'Choose option', iconSearch, values, defaultOptions, loadOptions, onchange, required = false, error, ...props }: _Object) => {

// 	const customStyles: StylesConfig = {
// 		control: (provided: Record<string, unknown>) => ({
// 			...provided,
// 			border: error && '1px solid #FF0000',
// 		})
// 	};

// 	return (
// 		<>
// 			<div className={`form-group mb-3 ${iconSearch ? 'iconSearch' : ''}`}>
// 				{label && <label className="label-form mb-1">{label}{required && <span className="text-danger"> *</span>}</label>}

// 				{iconSearch ?
// 					<div className="customer-text-search">
// 						{icon ? <i className={icon}></i> : <i className="fa fa-magnifying-glass"></i>}
// 					</div>
// 					: null}

// 				<AsyncSelect
// 					ref={ref}
// 					className={`react-dropdown ${className}`}
// 					name={'items input_itemx'}
// 					classNamePrefix="react-select"
// 					cacheOptions
// 					value={values}
// 					isDisabled={disabled}
// 					onKeyDown={onKeyDown}
// 					placeholder={placeholder}
// 					defaultOptions={defaultOptions}
// 					loadOptions={loadOptions}
// 					onChange={onchange}
// 					onMenuClose={() => { document.getElementById('root')?.click() }}
// 					styles={customStyles}
// 					{...props}
// 				/>
// 				{error && <span className="invalid-feedback text-danger d-block mt-1">{error}</span>}
// 			</div>
// 		</>
// 	)
// }

// export default AsyncSelectField

import React, { forwardRef } from 'react';
import { StylesConfig } from 'react-select';
import AsyncSelect from 'react-select/async';
import { _Object } from 'utils/interfaces';

const AsyncSelectField = forwardRef<HTMLDivElement, _Object>(
  ({ label = '', className, disabled, icon, onBlur, inputValue, onKeyDown, placeholder = 'Choose option', iconSearch, values, defaultOptions, loadOptions, onchange, required = false, error, ...props }, ref) => {
    const customStyles: StylesConfig = {
      control: (provided: Record<string, unknown>) => ({
        ...provided,
        border: error && '1px solid #FF0000',
      }),
    };

    return (
      <>
        <div className={`form-group mb-3 ${iconSearch ? 'iconSearch' : ''}`} ref={ref}>
          {label && (
            <label className="label-form mb-1">
              {label}
              {required && <span className="text-danger"> *</span>}
            </label>
          )}

          {iconSearch ? (
            <div className="customer-text-search">
              {icon ? <i className={icon}></i> : <i className="fa fa-magnifying-glass"></i>}
            </div>
          ) : null}

          <AsyncSelect
            className={`react-dropdown ${className}`}
            name={'items input_itemx'}
            classNamePrefix="react-select"
            menuPlacement="auto"
            cacheOptions
            value={values}
            onBlur={onBlur}
            inputValue={inputValue}
            isDisabled={disabled}
            onKeyDown={onKeyDown}
            placeholder={placeholder}
            defaultOptions={defaultOptions}
            loadOptions={loadOptions}
            onChange={onchange}
            onMenuClose={() => {
              document.getElementById('root')?.click();
            }}
            styles={customStyles}
            {...props}
          />
          {error && <span className="invalid-feedback text-danger d-block mt-1">{error}</span>}
        </div>
      </>
    );
  }
);

AsyncSelectField.displayName = 'AsyncSelectField';

export default AsyncSelectField;

