import CommonService from './common.service';
import { generateQueryParams } from 'utils';
import { _Object } from 'utils/interfaces';

class OrderService extends CommonService {
  async getOrders(params: _Object | string) {
    return await this.get(`orders?${generateQueryParams(params)}`)
  }
  async getOrderDetails(id: string | undefined) {
    return await this.get(`orders/${id}`)
  }
  async createOrder(parmas: _Object) {
    return await this.post('orders', parmas)
  }
  async getOrdersReport() {
    return await this.get('report/orders')
  }
  async getOrderSummary(params: _Object) {
    return await this.get(`analytics/orders/summary?${generateQueryParams(params)}`)
  }
  async deleteOrder(id: _Object | string) {
    return await this.delete(`orders/${id}`)
  }
  async recalculateOrder(id: string | undefined) {
    return await this.post(`orders/${id}/recalculate`)
  }
}
export const orderService = new OrderService();
