import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import english from './en/translation.json';
import hindi from './hi/translation.json';

const resources = {
  en: {
    translation: english,
  },
  hi: {
    translation: hindi,
  }
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
