import React, { useState } from 'react'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { routes } from 'utils'
import { Breadcrumbs, Button, LinkButton, Loading, SEOHeader } from 'views/components'
import { productService } from 'services';
import { _Object } from 'utils/interfaces';

const ImportCSV = () => {

  const [loading, setLoading] = useState(false)
  const [steps, setSteps] = useState<any>({
    step1: true,
    step2: false,
    step3: false 
  })

  const initialValues: any = {
    file: null,
  };

  const formik: any = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,

    validationSchema: Yup.object({
      file: Yup.mixed()
        .required('Please select a CSV file')
        .test('fileType', 'Invalid file type. Please select a CSV file.', (value: any) => {
          return value && value[0]?.type === 'text/csv';
        })
        .test('fileCount', 'Only one file is allowed', (value: any) => {
          return value && value?.length === 1;
        }),
    }),

    onSubmit: (values) => {
      setSteps((prev: any) => ({ ...prev, step2: true }));
      setLoading(true)
      productService.importCSV(values.file).then((data: _Object) => {
        if(data.error === false){
        setSteps((prev: any) => ({ ...prev, step3: true }));
        setLoading(false)
        formik.resetForm()
        }
      })
    },
  })

  return (
    <>
      <SEOHeader title="Products" />
      <Breadcrumbs
        trails={[
          {
            label: 'Products',
            path: `${routes.products}`
          },
          {
            label: 'Bulk Import',
            path: ''
          }
        ]}
        pageTitle={'Import Products'}
      />

      <div className="container">
        <div className="row justify-content-center">
          <div className="col-11 col-sm-9 col-md-7 col-lg-6 col-xl-7 text-center p-0 mt-3 mb-2">
            <div>
              <ul id="progressbar">
                <li className="active" id="step1"><span>Upload CSV file</span></li>
                <li className={`${steps.step2 && 'active'}`} id="step2"><span>Import</span></li>
                <li className={`${steps.step3 && 'active'}`} id="step3"><span>Done!</span></li>
              </ul>

              {steps.step2 === false &&
                <form onSubmit={formik.handleSubmit} className="card border-0 px-0 py-4">
                  <div className="card-header bg-transparent">
                    <h2>Import products from a CSV file</h2>
                    <p>This tool allows you to import (or merge) product data to your store from a CSV or TXT file.</p>
                  </div>

                  <div className="card-body p-4">
                    <div className="row text-start">
                      <div className="col-6">
                        <p>Choose a CSV file from your computer:</p>
                      </div>
                      <div className="col-6">
                      <input
                        type="file"
                        id="file"
                        name="file"
                        accept=".csv"
                        onChange={(event:any) => {
                          formik.setFieldValue('file', event.target.files);
                        }}
                      /><br />
                      {formik.errors.file && formik.touched.file && (
                        <div style={{ color: 'red' }}>{formik.errors.file}</div>
                      )}
                        <small>Maximum size: 50 MB</small>
                      </div>
                    </div>
                  </div>

                  <div className="card-footer bg-transparent d-flex justify-content-between align-items-center pt-4">
                    <a className="text-decoration-none" href={`${process.env.REACT_APP_BASE_URL}/Sample-product.csv`}>Sample File</a>
                    <Button
                      className="primary text-capitalize"
                    >
                      Continue
                    </Button>
                  </div>
                </form>
              }

              {steps.step2 && steps.step3 === false && 
                <div className="card border-0 px-0 py-4">
                  <div className="card-header bg-transparent">
                    <h2>Importing</h2>

                    <p>Your products are now being imported...</p>
                  </div>

                  <div className="card-body p-4">
                    {loading && <Loading />}
                  </div>
                </div>
              } 

              {steps.step3 &&
                <div className="card border-0 px-0 py-4">
                  <div className="card-header bg-transparent">
                    <h2>Import done</h2>
                  </div>

                  <div className="card-body p-4">
                    <FontAwesomeIcon icon={faCircleCheck} style={{ fontSize: '100px' , color: '#2003ff'}} />

                    <p className="mt-3">This tool allows you to import (or merge) product data to your store from a CSV or TXT file.</p>
                  </div>

                  <div className="card-footer bg-transparent d-flex justify-content-center pt-4">
                    <LinkButton
                      className="primary text-capitalize"
                      path={`${routes.products}`}
                    >
                      View products
                    </LinkButton>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ImportCSV