import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import * as Yup from 'yup'
import { useFormik } from 'formik';

import { productService } from 'services';
import { closeModal, doDateFormatWithTime, getSortIcon, handleFilters, routes, serialNumber, strings } from 'utils';
import { _Object } from 'utils/interfaces';
import { Breadcrumbs, Button, InputField, LinkButton, Pagination, SearchField } from 'views/components';

const ProductAttributeValue = () => {
  const { id }: any = useParams()

  const [modalId, setModalId] = useState('')
  const [loading, setLoading] = useState(false)
  const [productAttributeValues, setProductAttributeValues] = useState<_Object>([])
  const [filterData, setFilterData] = useState<_Object>({
    q: '',
    page: 1,
    per_page: process.env.REACT_APP_PER_PAGE,
    sort: '-created_at',
  })

  const [initialData, setInitialData] = useState<_Object>({
    data: [
      {
        name: '',
      }
    ]
  })

  useEffect(() => {
    if (id) {
      setLoading(true)
      productService.getProductAttributeValues(filterData, id).then((data) => {
        setProductAttributeValues(data)
        setLoading(false)
      })
    }
  }, [filterData, id])

  useEffect(() => {
    if (modalId) {
      setLoading(true)
      productService.getProductAttributeValueDetails(id).then((data: _Object) => {
        setInitialData({
          data: [
            {
              name: data?.product_attribute_values?.find((item: any) => item.id === modalId).name,
            }
          ]
        })
        setLoading(false)
      })
    }
  }, [modalId])

  const deleteItem = (attributeId: string) => {
    if (window.confirm('Are you sure to delete this item?')) {
      setLoading(true);
      productService.deleteProductAttributeValue(id, attributeId).then(() => {
        productService.getProductAttributeValues(filterData, id).then((data) => {
          setProductAttributeValues(data)
          setLoading(false)
        })
      })
    }
  }

  const formik: any = useFormik({
    initialValues: initialData,
    enableReinitialize: true,

    validationSchema: Yup.object().shape({
      data: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().required('Title is required'),
        })
      ),
    }),

    onSubmit: (values) => {
      setLoading(true)

      if (modalId.length > 0) {
        delete values.id,
          delete values.created_at
        delete values.updated_at
        const payload = { data: [{ name: values?.data[0]?.name, id: modalId }] }

        productService.updateProductAttributeValue(id, payload).then((data: _Object) => {
          setTimeout(() => {
            if (data.error === false) {
              setLoading(true)
              formik.resetForm();
              productService.getProductAttributeValues(filterData, id).then((data: _Object) => {
                setProductAttributeValues(data)
                setLoading(false)
              })
              closeModal('productAttributeValueModal');
            } else {
              throw new Error(data?.error);
            }
          }, 1000);
        }).catch((error) => {
          error && setLoading((false))
        })

      } else {
        productService.createProductAttributeValue(`${id}`, values).then((data: _Object) => {
          if (data.error === false) {
            setLoading(true)
            formik.resetForm();
            productService.getProductAttributeValues(filterData, `${id}`).then((data: _Object) => {
              setProductAttributeValues(data)
              setLoading(false)
            })
            closeModal('productAttributeValueModal');
          } else {
            throw new Error(data?.error);
          }
        }).catch((error) => {
          error && setLoading((false))
        })
      }
    }
  })

  return (
    <>
      <header className="row my-2">
        <Breadcrumbs
          trails={[
            {
              label: 'Products',
              path: `${routes.products}`
            },
            {
              label: 'Attributes',
              path: `${routes.attributes}`
            },
            {
              label: `${productAttributeValues?.name}`,
              path: ''
            },
            {
              label: 'Options',
              path: ''
            }
          ]}
        />
      </header>

      <div className="pos-justify pos-between pos-align pos-center">
        <form className="row filters">
          <SearchField
            type="search"
            name="q"
            className="mb-md-0"
            value={filterData.q}
            onChange={(e: _Object) => {
              setFilterData((prev) => ({
                ...prev,
                page: 1,
                q: e.target.value,
              }))
            }}
          />
        </form>

        <button
          onClick={() => {
            formik.resetForm(); setModalId('');
            setInitialData({ data: [{ name: '', }] })
          }}
          type="button"
          className="btn btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#productAttributeValueModal">
          Add New
        </button>

      </div>

      {/* Modal */}
      <div className="modal fade" id="productAttributeValueModal" aria-labelledby="productAttributeValueModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <form className="modal-content" onSubmit={formik.handleSubmit}>
            <div className="modal-header">
              <h4 className="modal-title" id="productAttributeValueModalLabel">{modalId ? 'Update attribute option' : 'Create attribute option'}</h4>

              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { formik.resetForm(); setModalId(''); setInitialData({ data: [{ name: '', }] }) }}></button>
            </div>

            <div className={`modal-body ${loading === true ? 'is-loading' : ''}`}>
              <InputField
                name="name"
                label="Title"
                required={true}
                value={formik.values?.data[0]?.name}
                onChange={(e: any) => formik.setFieldValue('data[0].name', e.target.value)}
                error={formik?.touched?.data && formik?.touched?.data[0]?.name && formik?.errors?.data && formik?.errors?.data[0]?.name}
              />
            </div>

            <div className="modal-footer">
              <LinkButton path={`${routes.attributesValues}`} label="Cancel" data-bs-dismiss="modal" className="link text-danger fw-medium" />
              <Button className="primary" name={modalId ? 'Update' : 'Submit'} loading={loading} />
            </div>
          </form>
        </div>
      </div>

      <div className="card border-0 my-3">
        <table className="card-body mb-0 table table-borderless table-striped order-listing">
          <thead>
            <tr className="bg-white">
              <th>#</th>
              <th>Title</th>
              <th>
                <button
                  className="btn p-0 border-0"
                  onClick={() => { handleFilters('sort', filterData.sort === '-created_at' ? 'created_at' : '-created_at', setFilterData) }}>
                  Create date
                  {getSortIcon(filterData.sort.includes('created_at') ? filterData.sort : '')}
                </button>
              </th>
              <th>
                <button
                  className="btn p-0 border-0"
                  onClick={() => { handleFilters('sort', filterData.sort === '-updated_at' ? 'updated_at' : '-updated_at', setFilterData) }}>
                  Update date
                  {getSortIcon(filterData.sort.includes('updated_at') ? filterData.sort : '')}
                </button>
              </th>
              <th>Action</th>
            </tr>
          </thead>

          <tbody className={loading === true ? 'is-loading' : ''}>
            {productAttributeValues?.product_attribute_values?.map((data: { [key: string]: string }, i: number) => {
              return (
                <tr key={i}>
                  <td>{serialNumber(filterData.per_page, filterData.page, i)}</td>
                  <td>{data?.name}</td>
                  <td>{doDateFormatWithTime(data?.created_at)}</td>
                  <td>{doDateFormatWithTime(data?.updated_at)}</td>
                  <td>
                    <ul className="list-inline">
                      <li className="list-inline-item">
                        <button
                          onClick={() => setModalId(data?.id)}
                          id="productAttributeValueModal" type="button" className="btn btn-transparent p-0 border-0" data-bs-toggle="modal" data-bs-target="#productAttributeValueModal">
                          <i className="fa fa-pen"></i>
                        </button>
                      </li>
                      <li className="list-inline-item">
                        <Button type="submit" className="border-0 p-0" onClick={() => deleteItem(data?.id)}>
                          <i className="fa fa-trash text-danger"></i>
                        </Button>
                      </li>
                    </ul>
                  </td>
                </tr>
              )
            })}

            {loading === false && productAttributeValues?.product_attribute_values?.length === 0 && <tr>
              <td colSpan={12} className="text-center no-entry">
                <aside>
                  <i className="fa-solid fa-ban"></i>
                  {strings.noRecordFound}
                </aside>
              </td>
            </tr>
            }
          </tbody>
        </table>
      </div>

      {
        productAttributeValues?.pagination?.total_pages > 1 &&
        <Pagination
          current_page={productAttributeValues?.pagination?.current_page}
          per_page={productAttributeValues?.pagination?.per_page}
          total_pages={productAttributeValues?.pagination?.total_pages}
          total_items={productAttributeValues?.pagination?.total_items}
          onClick={(i: { [key: string]: number }) =>
            setFilterData((prev) => ({
              ...prev,
              page: i.selected + 1,
            }))
          }
        />
      }
    </>
  )
}
export default ProductAttributeValue