import React from 'react'
import { useTranslation } from 'react-i18next';

import { LinkButton } from './form-inputs'
import { routes } from 'utils'
import { useLocation } from 'react-router'

const TabsHeader = () => {
  const { t } = useTranslation()

  const location = useLocation()

  return (
    <ul className="nav tabs">
      <li className="nav-item">
        <LinkButton className={`link ${location.pathname == '/app/products' && 'active'}`} path={`${routes.products}`} label={t('product.products')} />
      </li>
      <li className="nav-item">
        <LinkButton className={`link ${location.pathname == '/app/inventory' && 'active'}`} path={`${routes.inventory}`} label={t('inventory.inventory')} />
      </li>
      <li className="nav-item">
        <LinkButton className={`link ${location.pathname.includes('/app/products/categorie') && 'active'}`} path={`${routes.categories}`} label={t('category.categories')} />
      </li>
      <li className="nav-item">
        <LinkButton className={`link ${location.pathname.includes('/app/products/tags') && 'active'}`} path={`${routes.tags}`} label={t('common.tags')} />
      </li>
      {/* <li className="nav-item">
        <LinkButton className={`link ${location.pathname.includes('/app/products/attributes') && 'active'}`} path={`${routes.attributes}`} label="Attributes" />
      </li> */}
      <li className="nav-item">
        <LinkButton className={`link ${location.pathname.includes('/app/products/brands') && 'active'}`} path={`${routes.brands}`} label={t('common.brands')} />
      </li>
      <li className="nav-item">
        <LinkButton className={`link ${location.pathname.includes('/app/products/suppliers') && 'active'}`} path={`${routes.suppilers}`} label={t('supplier.suppliers')} />
      </li>
    </ul>
  )
}

export default TabsHeader