import { routes } from 'utils';
import { Forgot, Login, ResetPassword } from 'views/pages/auth';

const PublicRoutes = [
  { path: `${routes.login}`, exact: true, name: 'Login', component: Login },
  { path: `${routes.forgotPassword}`, exact: true, name: 'Forgot', component: Forgot },
  { path: `${routes.resetPassword}`, exact: true, name: 'Reset', component: ResetPassword },
];

export default PublicRoutes;
