import CommonService from './common.service';
import { generateQueryParams } from 'utils';
import { _Object } from 'utils/interfaces';

class TaxRateService extends CommonService {
  async getTaxRates(params: _Object | string) {
    return await this.get(`tax-rates?${generateQueryParams(params)}`)
  }
  async getTaxRatesDetails(id: string) {
    return await this.get(`tax-rates/${id}`)
  }
  async createTaxRates(parmas: _Object) {
    return await this.post('tax-rates', parmas)
  }
  async updateTaxRates(id: string, params: _Object) {
    return await this.put(`tax-rates/${id}`, params)
  }
  async deleteTaxRates(id: string) {
    return await this.delete(`tax-rates/${id}`)
  }
}
export const taxRateService = new TaxRateService();
