/**
 * @link https://countriesnow.space/api/v0.1/countries/states
 */
export const countries = [
    {
        'label': 'Afghanistan',
        'iso3': 'AFG',
        'value': 'AF',
        'states': [
            {
                'label': 'Badakhshan',
                'value': 'BDS'
            },
            {
                'label': 'Badghis',
                'value': 'BDG'
            },
            {
                'label': 'Baghlan',
                'value': 'BGL'
            },
            {
                'label': 'Balkh',
                'value': 'BAL'
            },
            {
                'label': 'Bamyan',
                'value': 'BAM'
            },
            {
                'label': 'Daykundi',
                'value': 'DAY'
            },
            {
                'label': 'Farah',
                'value': 'FRA'
            },
            {
                'label': 'Faryab',
                'value': 'FYB'
            },
            {
                'label': 'Ghazni',
                'value': 'GHA'
            },
            {
                'label': 'Ghōr',
                'value': 'GHO'
            },
            {
                'label': 'Helmand',
                'value': 'HEL'
            },
            {
                'label': 'Herat',
                'value': 'HER'
            },
            {
                'label': 'Jowzjan',
                'value': 'JOW'
            },
            {
                'label': 'Kabul',
                'value': 'KAB'
            },
            {
                'label': 'Kandahar',
                'value': 'KAN'
            },
            {
                'label': 'Kapisa',
                'value': 'KAP'
            },
            {
                'label': 'Khost',
                'value': 'KHO'
            },
            {
                'label': 'Kunar',
                'value': 'KNR'
            },
            {
                'label': 'Kunduz Province',
                'value': 'KDZ'
            },
            {
                'label': 'Laghman',
                'value': 'LAG'
            },
            {
                'label': 'Logar',
                'value': 'LOG'
            },
            {
                'label': 'Nangarhar',
                'value': 'NAN'
            },
            {
                'label': 'Nimruz',
                'value': 'NIM'
            },
            {
                'label': 'Nuristan',
                'value': 'NUR'
            },
            {
                'label': 'Paktia',
                'value': 'PIA'
            },
            {
                'label': 'Paktika',
                'value': 'PKA'
            },
            {
                'label': 'Panjshir',
                'value': 'PAN'
            },
            {
                'label': 'Parwan',
                'value': 'PAR'
            },
            {
                'label': 'Samangan',
                'value': 'SAM'
            },
            {
                'label': 'Sar-e Pol',
                'value': 'SAR'
            },
            {
                'label': 'Takhar',
                'value': 'TAK'
            },
            {
                'label': 'Urozgan',
                'value': 'URU'
            },
            {
                'label': 'Zabul',
                'value': 'ZAB'
            }
        ]
    },
    {
        'label': 'Albania',
        'iso3': 'ALB',
        'value': 'AL',
        'states': [
            {
                'label': 'Berat County',
                'value': '01'
            },
            {
                'label': 'Berat District',
                'value': 'BR'
            },
            {
                'label': 'Bulqizë District',
                'value': 'BU'
            },
            {
                'label': 'Delvinë District',
                'value': 'DL'
            },
            {
                'label': 'Devoll District',
                'value': 'DV'
            },
            {
                'label': 'Dibër County',
                'value': '09'
            },
            {
                'label': 'Dibër District',
                'value': 'DI'
            },
            {
                'label': 'Durrës County',
                'value': '02'
            },
            {
                'label': 'Durrës District',
                'value': 'DR'
            },
            {
                'label': 'Elbasan County',
                'value': '03'
            },
            {
                'label': 'Fier County',
                'value': '04'
            },
            {
                'label': 'Fier District',
                'value': 'FR'
            },
            {
                'label': 'Gjirokastër County',
                'value': '05'
            },
            {
                'label': 'Gjirokastër District',
                'value': 'GJ'
            },
            {
                'label': 'Gramsh District',
                'value': 'GR'
            },
            {
                'label': 'Has District',
                'value': 'HA'
            },
            {
                'label': 'Kavajë District',
                'value': 'KA'
            },
            {
                'label': 'Kolonjë District',
                'value': 'ER'
            },
            {
                'label': 'Korçë County',
                'value': '06'
            },
            {
                'label': 'Korçë District',
                'value': 'KO'
            },
            {
                'label': 'Krujë District',
                'value': 'KR'
            },
            {
                'label': 'Kuçovë District',
                'value': 'KC'
            },
            {
                'label': 'Kukës County',
                'value': '07'
            },
            {
                'label': 'Kukës District',
                'value': 'KU'
            },
            {
                'label': 'Kurbin District',
                'value': 'KB'
            },
            {
                'label': 'Lezhë County',
                'value': '08'
            },
            {
                'label': 'Lezhë District',
                'value': 'LE'
            },
            {
                'label': 'Librazhd District',
                'value': 'LB'
            },
            {
                'label': 'Lushnjë District',
                'value': 'LU'
            },
            {
                'label': 'Malësi e Madhe District',
                'value': 'MM'
            },
            {
                'label': 'Mallakastër District',
                'value': 'MK'
            },
            {
                'label': 'Mat District',
                'value': 'MT'
            },
            {
                'label': 'Mirditë District',
                'value': 'MR'
            },
            {
                'label': 'Peqin District',
                'value': 'PQ'
            },
            {
                'label': 'Përmet District',
                'value': 'PR'
            },
            {
                'label': 'Pogradec District',
                'value': 'PG'
            },
            {
                'label': 'Pukë District',
                'value': 'PU'
            },
            {
                'label': 'Sarandë District',
                'value': 'SR'
            },
            {
                'label': 'Shkodër County',
                'value': '10'
            },
            {
                'label': 'Shkodër District',
                'value': 'SH'
            },
            {
                'label': 'Skrapar District',
                'value': 'SK'
            },
            {
                'label': 'Tepelenë District',
                'value': 'TE'
            },
            {
                'label': 'Tirana County',
                'value': '11'
            },
            {
                'label': 'Tirana District',
                'value': 'TR'
            },
            {
                'label': 'Tropojë District',
                'value': 'TP'
            },
            {
                'label': 'Vlorë County',
                'value': '12'
            },
            {
                'label': 'Vlorë District',
                'value': 'VL'
            }
        ]
    },
    {
        'label': 'Algeria',
        'iso3': 'DZA',
        'value': 'DZ',
        'states': [
            {
                'label': 'Adrar Province',
                'value': '01'
            },
            {
                'label': 'Aïn Defla Province',
                'value': '44'
            },
            {
                'label': 'Aïn Témouchent Province',
                'value': '46'
            },
            {
                'label': 'Algiers Province',
                'value': '16'
            },
            {
                'label': 'Annaba Province',
                'value': '23'
            },
            {
                'label': 'Batna Province',
                'value': '05'
            },
            {
                'label': 'Béchar Province',
                'value': '08'
            },
            {
                'label': 'Béjaïa Province',
                'value': '06'
            },
            {
                'label': 'Biskra',
                'value': '07'
            },
            {
                'label': 'Blida Province',
                'value': '09'
            },
            {
                'label': 'Bordj Bou Arréridj Province',
                'value': '34'
            },
            {
                'label': 'Bouïra Province',
                'value': '10'
            },
            {
                'label': 'Boumerdès Province',
                'value': '35'
            },
            {
                'label': 'Chlef Province',
                'value': '02'
            },
            {
                'label': 'Constantine Province',
                'value': '25'
            },
            {
                'label': 'Djelfa Province',
                'value': '17'
            },
            {
                'label': 'El Bayadh Province',
                'value': '32'
            },
            {
                'label': 'El Oued Province',
                'value': '39'
            },
            {
                'label': 'El Tarf Province',
                'value': '36'
            },
            {
                'label': 'Ghardaïa Province',
                'value': '47'
            },
            {
                'label': 'Guelma Province',
                'value': '24'
            },
            {
                'label': 'Illizi Province',
                'value': '33'
            },
            {
                'label': 'Jijel Province',
                'value': '18'
            },
            {
                'label': 'Khenchela Province',
                'value': '40'
            },
            {
                'label': 'Laghouat Province',
                'value': '03'
            },
            {
                'label': 'M\'Sila Province',
                'value': '28'
            },
            {
                'label': 'Mascara Province',
                'value': '29'
            },
            {
                'label': 'Médéa Province',
                'value': '26'
            },
            {
                'label': 'Mila Province',
                'value': '43'
            },
            {
                'label': 'Mostaganem Province',
                'value': '27'
            },
            {
                'label': 'Naama Province',
                'value': '45'
            },
            {
                'label': 'Oran Province',
                'value': '31'
            },
            {
                'label': 'Ouargla Province',
                'value': '30'
            },
            {
                'label': 'Oum El Bouaghi Province',
                'value': '04'
            },
            {
                'label': 'Relizane Province',
                'value': '48'
            },
            {
                'label': 'Saïda Province',
                'value': '20'
            },
            {
                'label': 'Sétif Province',
                'value': '19'
            },
            {
                'label': 'Skikda Province',
                'value': '21'
            },
            {
                'label': 'Souk Ahras Province',
                'value': '41'
            },
            {
                'label': 'Tamanghasset Province',
                'value': '11'
            },
            {
                'label': 'Tébessa Province',
                'value': '12'
            },
            {
                'label': 'Tiaret Province',
                'value': '14'
            },
            {
                'label': 'Tindouf Province',
                'value': '37'
            },
            {
                'label': 'Tipasa Province',
                'value': '42'
            },
            {
                'label': 'Tissemsilt Province',
                'value': '38'
            },
            {
                'label': 'Tizi Ouzou Province',
                'value': '15'
            },
            {
                'label': 'Tlemcen Province',
                'value': '13'
            }
        ]
    },
    {
        'label': 'Andorra',
        'iso3': 'AND',
        'value': 'AD',
        'states': [
            {
                'label': 'Andorra la Vella',
                'value': '07'
            },
            {
                'label': 'Canillo',
                'value': '02'
            },
            {
                'label': 'Encamp',
                'value': '03'
            },
            {
                'label': 'Escaldes-Engordany',
                'value': '08'
            },
            {
                'label': 'La Massana',
                'value': '04'
            },
            {
                'label': 'Ordino',
                'value': '05'
            },
            {
                'label': 'Sant Julià de Lòria',
                'value': '06'
            }
        ]
    },
    {
        'label': 'Angola',
        'iso3': 'AGO',
        'value': 'AO',
        'states': [
            {
                'label': 'Bengo Province',
                'value': 'BGO'
            },
            {
                'label': 'Benguela Province',
                'value': 'BGU'
            },
            {
                'label': 'Bié Province',
                'value': 'BIE'
            },
            {
                'label': 'Cabinda Province',
                'value': 'CAB'
            },
            {
                'label': 'Cuando Cubango Province',
                'value': 'CCU'
            },
            {
                'label': 'Cuanza Norte Province',
                'value': 'CNO'
            },
            {
                'label': 'Cuanza Sul',
                'value': 'CUS'
            },
            {
                'label': 'Cunene Province',
                'value': 'CNN'
            },
            {
                'label': 'Huambo Province',
                'value': 'HUA'
            },
            {
                'label': 'Huíla Province',
                'value': 'HUI'
            },
            {
                'label': 'Luanda Province',
                'value': 'LUA'
            },
            {
                'label': 'Lunda Norte Province',
                'value': 'LNO'
            },
            {
                'label': 'Lunda Sul Province',
                'value': 'LSU'
            },
            {
                'label': 'Malanje Province',
                'value': 'MAL'
            },
            {
                'label': 'Moxico Province',
                'value': 'MOX'
            },
            {
                'label': 'Uíge Province',
                'value': 'UIG'
            },
            {
                'label': 'Zaire Province',
                'value': 'ZAI'
            }
        ]
    },
    {
        'label': 'Antigua And Barbuda',
        'iso3': 'ATG',
        'value': 'AG',
        'states': [
            {
                'label': 'Barbuda',
                'value': '10'
            },
            {
                'label': 'Redonda',
                'value': '11'
            },
            {
                'label': 'Saint George Parish',
                'value': '03'
            },
            {
                'label': 'Saint John Parish',
                'value': '04'
            },
            {
                'label': 'Saint Mary Parish',
                'value': '05'
            },
            {
                'label': 'Saint Paul Parish',
                'value': '06'
            },
            {
                'label': 'Saint Peter Parish',
                'value': '07'
            },
            {
                'label': 'Saint Philip Parish',
                'value': '08'
            }
        ]
    },
    {
        'label': 'Argentina',
        'iso3': 'ARG',
        'value': 'AR',
        'states': [
            {
                'label': 'Autonomous City Of Buenos Aires',
                'value': 'C'
            },
            {
                'label': 'Buenos Aires Province',
                'value': 'B'
            },
            {
                'label': 'Catamarca Province',
                'value': 'K'
            },
            {
                'label': 'Chaco Province',
                'value': 'H'
            },
            {
                'label': 'Chubut Province',
                'value': 'U'
            },
            {
                'label': 'Córdoba Province',
                'value': 'X'
            },
            {
                'label': 'Corrientes',
                'value': 'W'
            },
            {
                'label': 'Entre Ríos Province',
                'value': 'E'
            },
            {
                'label': 'Formosa Province',
                'value': 'P'
            },
            {
                'label': 'Jujuy Province',
                'value': 'Y'
            },
            {
                'label': 'La Pampa',
                'value': 'L'
            },
            {
                'label': 'La Rioja Province',
                'value': 'F'
            },
            {
                'label': 'Mendoza',
                'value': 'M'
            },
            {
                'label': 'Misiones Province',
                'value': 'N'
            },
            {
                'label': 'Neuquén Province',
                'value': 'Q'
            },
            {
                'label': 'Río Negro Province',
                'value': 'R'
            },
            {
                'label': 'Salta Province',
                'value': 'A'
            },
            {
                'label': 'San Juan Province',
                'value': 'J'
            },
            {
                'label': 'San Luis Province',
                'value': 'D'
            },
            {
                'label': 'Santa Cruz Province',
                'value': 'Z'
            },
            {
                'label': 'Santa Fe Province',
                'value': 'S'
            },
            {
                'label': 'Santiago del Estero Province',
                'value': 'G'
            },
            {
                'label': 'Tierra del Fuego Province',
                'value': 'V'
            },
            {
                'label': 'Tucumán Province',
                'value': 'T'
            }
        ]
    },
    {
        'label': 'Armenia',
        'iso3': 'ARM',
        'value': 'AM',
        'states': [
            {
                'label': 'Aragatsotn Region',
                'value': 'AG'
            },
            {
                'label': 'Ararat Province',
                'value': 'AR'
            },
            {
                'label': 'Armavir Region',
                'value': 'AV'
            },
            {
                'label': 'Gegharkunik Province',
                'value': 'GR'
            },
            {
                'label': 'Kotayk Region',
                'value': 'KT'
            },
            {
                'label': 'Lori Region',
                'value': 'LO'
            },
            {
                'label': 'Shirak Region',
                'value': 'SH'
            },
            {
                'label': 'Syunik Province',
                'value': 'SU'
            },
            {
                'label': 'Tavush Region',
                'value': 'TV'
            },
            {
                'label': 'Vayots Dzor Region',
                'value': 'VD'
            },
            {
                'label': 'Yerevan',
                'value': 'ER'
            }
        ]
    },
    {
        'label': 'Australia',
        'iso3': 'AUS',
        'value': 'AU',
        'states': [
            {
                'label': 'Australian Capital Territory',
                'value': 'ACT'
            },
            {
                'label': 'New South Wales',
                'value': 'NSW'
            },
            {
                'label': 'Northern Territory',
                'value': 'NT'
            },
            {
                'label': 'Queensland',
                'value': 'QLD'
            },
            {
                'label': 'South Australia',
                'value': 'SA'
            },
            {
                'label': 'Tasmania',
                'value': 'TAS'
            },
            {
                'label': 'Victoria',
                'value': 'VIC'
            },
            {
                'label': 'Western Australia',
                'value': 'WA'
            }
        ]
    },
    {
        'label': 'Austria',
        'iso3': 'AUT',
        'value': 'AT',
        'states': [
            {
                'label': 'Burgenland',
                'value': '1'
            },
            {
                'label': 'Carinthia',
                'value': '2'
            },
            {
                'label': 'Lower Austria',
                'value': '3'
            },
            {
                'label': 'Salzburg',
                'value': '5'
            },
            {
                'label': 'Styria',
                'value': '6'
            },
            {
                'label': 'Tyrol',
                'value': '7'
            },
            {
                'label': 'Upper Austria',
                'value': '4'
            },
            {
                'label': 'Vienna',
                'value': '9'
            },
            {
                'label': 'Vorarlberg',
                'value': '8'
            }
        ]
    },
    {
        'label': 'Azerbaijan',
        'iso3': 'AZE',
        'value': 'AZ',
        'states': [
            {
                'label': 'Absheron District',
                'value': 'ABS'
            },
            {
                'label': 'Agdam District',
                'value': 'AGM'
            },
            {
                'label': 'Agdash District',
                'value': 'AGS'
            },
            {
                'label': 'Aghjabadi District',
                'value': 'AGC'
            },
            {
                'label': 'Agstafa District',
                'value': 'AGA'
            },
            {
                'label': 'Agsu District',
                'value': 'AGU'
            },
            {
                'label': 'Astara District',
                'value': 'AST'
            },
            {
                'label': 'Babek District',
                'value': 'BAB'
            },
            {
                'label': 'Baku',
                'value': 'BA'
            },
            {
                'label': 'Balakan District',
                'value': 'BAL'
            },
            {
                'label': 'Barda District',
                'value': 'BAR'
            },
            {
                'label': 'Beylagan District',
                'value': 'BEY'
            },
            {
                'label': 'Bilasuvar District',
                'value': 'BIL'
            },
            {
                'label': 'Dashkasan District',
                'value': 'DAS'
            },
            {
                'label': 'Fizuli District',
                'value': 'FUZ'
            },
            {
                'label': 'Ganja',
                'value': 'GA'
            },
            {
                'label': 'Gədəbəy',
                'value': 'GAD'
            },
            {
                'label': 'Gobustan District',
                'value': 'QOB'
            },
            {
                'label': 'Goranboy District',
                'value': 'GOR'
            },
            {
                'label': 'Goychay',
                'value': 'GOY'
            },
            {
                'label': 'Goygol District',
                'value': 'GYG'
            },
            {
                'label': 'Hajigabul District',
                'value': 'HAC'
            },
            {
                'label': 'Imishli District',
                'value': 'IMI'
            },
            {
                'label': 'Ismailli District',
                'value': 'ISM'
            },
            {
                'label': 'Jabrayil District',
                'value': 'CAB'
            },
            {
                'label': 'Jalilabad District',
                'value': 'CAL'
            },
            {
                'label': 'Julfa District',
                'value': 'CUL'
            },
            {
                'label': 'Kalbajar District',
                'value': 'KAL'
            },
            {
                'label': 'Kangarli District',
                'value': 'KAN'
            },
            {
                'label': 'Khachmaz District',
                'value': 'XAC'
            },
            {
                'label': 'Khizi District',
                'value': 'XIZ'
            },
            {
                'label': 'Khojali District',
                'value': 'XCI'
            },
            {
                'label': 'Kurdamir District',
                'value': 'KUR'
            },
            {
                'label': 'Lachin District',
                'value': 'LAC'
            },
            {
                'label': 'Lankaran',
                'value': 'LAN'
            },
            {
                'label': 'Lankaran District',
                'value': 'LA'
            },
            {
                'label': 'Lerik District',
                'value': 'LER'
            },
            {
                'label': 'Martuni',
                'value': 'XVD'
            },
            {
                'label': 'Masally District',
                'value': 'MAS'
            },
            {
                'label': 'Mingachevir',
                'value': 'MI'
            },
            {
                'label': 'Nakhchivan Autonomous Republic',
                'value': 'NX'
            },
            {
                'label': 'Neftchala District',
                'value': 'NEF'
            },
            {
                'label': 'Oghuz District',
                'value': 'OGU'
            },
            {
                'label': 'Ordubad District',
                'value': 'ORD'
            },
            {
                'label': 'Qabala District',
                'value': 'QAB'
            },
            {
                'label': 'Qakh District',
                'value': 'QAX'
            },
            {
                'label': 'Qazakh District',
                'value': 'QAZ'
            },
            {
                'label': 'Quba District',
                'value': 'QBA'
            },
            {
                'label': 'Qubadli District',
                'value': 'QBI'
            },
            {
                'label': 'Qusar District',
                'value': 'QUS'
            },
            {
                'label': 'Saatly District',
                'value': 'SAT'
            },
            {
                'label': 'Sabirabad District',
                'value': 'SAB'
            },
            {
                'label': 'Sadarak District',
                'value': 'SAD'
            },
            {
                'label': 'Salyan District',
                'value': 'SAL'
            },
            {
                'label': 'Samukh District',
                'value': 'SMX'
            },
            {
                'label': 'Shabran District',
                'value': 'SBN'
            },
            {
                'label': 'Shahbuz District',
                'value': 'SAH'
            },
            {
                'label': 'Shaki',
                'value': 'SA'
            },
            {
                'label': 'Shaki District',
                'value': 'SAK'
            },
            {
                'label': 'Shamakhi District',
                'value': 'SMI'
            },
            {
                'label': 'Shamkir District',
                'value': 'SKR'
            },
            {
                'label': 'Sharur District',
                'value': 'SAR'
            },
            {
                'label': 'Shirvan',
                'value': 'SR'
            },
            {
                'label': 'Shusha District',
                'value': 'SUS'
            },
            {
                'label': 'Siazan District',
                'value': 'SIY'
            },
            {
                'label': 'Sumqayit',
                'value': 'SM'
            },
            {
                'label': 'Tartar District',
                'value': 'TAR'
            },
            {
                'label': 'Tovuz District',
                'value': 'TOV'
            },
            {
                'label': 'Ujar District',
                'value': 'UCA'
            },
            {
                'label': 'Yardymli District',
                'value': 'YAR'
            },
            {
                'label': 'Yevlakh',
                'value': 'YE'
            },
            {
                'label': 'Yevlakh District',
                'value': 'YEV'
            },
            {
                'label': 'Zangilan District',
                'value': 'ZAN'
            },
            {
                'label': 'Zaqatala District',
                'value': 'ZAQ'
            },
            {
                'label': 'Zardab District',
                'value': 'ZAR'
            }
        ]
    },
    {
        'label': 'Bahamas The',
        'iso3': 'BHS',
        'value': 'BS',
        'states': [
            {
                'label': 'Acklins',
                'value': 'AK'
            },
            {
                'label': 'Acklins and Crooked Islands',
                'value': 'AC'
            },
            {
                'label': 'Berry Islands',
                'value': 'BY'
            },
            {
                'label': 'Bimini',
                'value': 'BI'
            },
            {
                'label': 'Black Point',
                'value': 'BP'
            },
            {
                'label': 'Cat Island',
                'value': 'CI'
            },
            {
                'label': 'Central Abaco',
                'value': 'CO'
            },
            {
                'label': 'Central Andros',
                'value': 'CS'
            },
            {
                'label': 'Central Eleuthera',
                'value': 'CE'
            },
            {
                'label': 'Crooked Island',
                'value': 'CK'
            },
            {
                'label': 'East Grand Bahama',
                'value': 'EG'
            },
            {
                'label': 'Exuma',
                'value': 'EX'
            },
            {
                'label': 'Freeport',
                'value': 'FP'
            },
            {
                'label': 'Fresh Creek',
                'value': 'FC'
            },
            {
                'label': 'Governor\'s Harbour',
                'value': 'GH'
            },
            {
                'label': 'Grand Cay',
                'value': 'GC'
            },
            {
                'label': 'Green Turtle Cay',
                'value': 'GT'
            },
            {
                'label': 'Harbour Island',
                'value': 'HI'
            },
            {
                'label': 'High Rock',
                'value': 'HR'
            },
            {
                'label': 'Hope Town',
                'value': 'HT'
            },
            {
                'label': 'Inagua',
                'value': 'IN'
            },
            {
                'label': 'Kemps Bay',
                'value': 'KB'
            },
            {
                'label': 'Long Island',
                'value': 'LI'
            },
            {
                'label': 'Mangrove Cay',
                'value': 'MC'
            },
            {
                'label': 'Marsh Harbour',
                'value': 'MH'
            },
            {
                'label': 'Mayaguana District',
                'value': 'MG'
            },
            {
                'label': 'Nichollstown and Berry Islands',
                'value': 'NB'
            },
            {
                'label': 'North Abaco',
                'value': 'NO'
            },
            {
                'label': 'North Andros',
                'value': 'NS'
            },
            {
                'label': 'North Eleuthera',
                'value': 'NE'
            },
            {
                'label': 'Ragged Island',
                'value': 'RI'
            },
            {
                'label': 'Rock Sound',
                'value': 'RS'
            },
            {
                'label': 'Rum Cay District',
                'value': 'RC'
            },
            {
                'label': 'San Salvador and Rum Cay',
                'value': 'SR'
            },
            {
                'label': 'San Salvador Island',
                'value': 'SS'
            },
            {
                'label': 'Sandy Point',
                'value': 'SP'
            },
            {
                'label': 'South Abaco',
                'value': 'SO'
            },
            {
                'label': 'South Andros',
                'value': 'SA'
            },
            {
                'label': 'South Eleuthera',
                'value': 'SE'
            },
            {
                'label': 'Spanish Wells',
                'value': 'SW'
            },
            {
                'label': 'West Grand Bahama',
                'value': 'WG'
            }
        ]
    },
    {
        'label': 'Bahrain',
        'iso3': 'BHR',
        'value': 'BH',
        'states': [
            {
                'label': 'Capital Governorate',
                'value': '13'
            },
            {
                'label': 'Central Governorate',
                'value': '16'
            },
            {
                'label': 'Muharraq Governorate',
                'value': '15'
            },
            {
                'label': 'Northern Governorate',
                'value': '17'
            },
            {
                'label': 'Southern Governorate',
                'value': '14'
            }
        ]
    },
    {
        'label': 'Bangladesh',
        'iso3': 'BGD',
        'value': 'BD',
        'states': [
            {
                'label': 'Bagerhat District',
                'value': '05'
            },
            {
                'label': 'Bahadia',
                'value': '33'
            },
            {
                'label': 'Bandarban District',
                'value': '01'
            },
            {
                'label': 'Barguna District',
                'value': '02'
            },
            {
                'label': 'Barisal District',
                'value': '06'
            },
            {
                'label': 'Barisal Division',
                'value': 'A'
            },
            {
                'label': 'Bhola District',
                'value': '07'
            },
            {
                'label': 'Bogra District',
                'value': '03'
            },
            {
                'label': 'Brahmanbaria District',
                'value': '04'
            },
            {
                'label': 'Chandpur District',
                'value': '09'
            },
            {
                'label': 'Chapai Nawabganj District',
                'value': '45'
            },
            {
                'label': 'Chittagong District',
                'value': '10'
            },
            {
                'label': 'Chittagong Division',
                'value': 'B'
            },
            {
                'label': 'Chuadanga District',
                'value': '12'
            },
            {
                'label': 'Comilla District',
                'value': '08'
            },
            {
                'label': 'Cox\'s Bazar District',
                'value': '11'
            },
            {
                'label': 'Dhaka District',
                'value': '13'
            },
            {
                'label': 'Dhaka Division',
                'value': 'C'
            },
            {
                'label': 'Dinajpur District',
                'value': '14'
            },
            {
                'label': 'Faridpur District',
                'value': '15'
            },
            {
                'label': 'Feni District',
                'value': '16'
            },
            {
                'label': 'Gaibandha District',
                'value': '19'
            },
            {
                'label': 'Gazipur District',
                'value': '18'
            },
            {
                'label': 'Gopalganj District',
                'value': '17'
            },
            {
                'label': 'Habiganj District',
                'value': '20'
            },
            {
                'label': 'Jamalpur District',
                'value': '21'
            },
            {
                'label': 'Jessore District',
                'value': '22'
            },
            {
                'label': 'Jhalokati District',
                'value': '25'
            },
            {
                'label': 'Jhenaidah District',
                'value': '23'
            },
            {
                'label': 'Joypurhat District',
                'value': '24'
            },
            {
                'label': 'Khagrachari District',
                'value': '29'
            },
            {
                'label': 'Khulna District',
                'value': '27'
            },
            {
                'label': 'Khulna Division',
                'value': 'D'
            },
            {
                'label': 'Kishoreganj District',
                'value': '26'
            },
            {
                'label': 'Kurigram District',
                'value': '28'
            },
            {
                'label': 'Kushtia District',
                'value': '30'
            },
            {
                'label': 'Lakshmipur District',
                'value': '31'
            },
            {
                'label': 'Lalmonirhat District',
                'value': '32'
            },
            {
                'label': 'Madaripur District',
                'value': '36'
            },
            {
                'label': 'Meherpur District',
                'value': '39'
            },
            {
                'label': 'Moulvibazar District',
                'value': '38'
            },
            {
                'label': 'Munshiganj District',
                'value': '35'
            },
            {
                'label': 'Mymensingh District',
                'value': '34'
            },
            {
                'label': 'Mymensingh Division',
                'value': 'H'
            },
            {
                'label': 'Naogaon District',
                'value': '48'
            },
            {
                'label': 'Narail District',
                'value': '43'
            },
            {
                'label': 'Narayanganj District',
                'value': '40'
            },
            {
                'label': 'Natore District',
                'value': '44'
            },
            {
                'label': 'Netrokona District',
                'value': '41'
            },
            {
                'label': 'Nilphamari District',
                'value': '46'
            },
            {
                'label': 'Noakhali District',
                'value': '47'
            },
            {
                'label': 'Pabna District',
                'value': '49'
            },
            {
                'label': 'Panchagarh District',
                'value': '52'
            },
            {
                'label': 'Patuakhali District',
                'value': '51'
            },
            {
                'label': 'Pirojpur District',
                'value': '50'
            },
            {
                'label': 'Rajbari District',
                'value': '53'
            },
            {
                'label': 'Rajshahi District',
                'value': '54'
            },
            {
                'label': 'Rajshahi Division',
                'value': 'E'
            },
            {
                'label': 'Rangamati Hill District',
                'value': '56'
            },
            {
                'label': 'Rangpur District',
                'value': '55'
            },
            {
                'label': 'Rangpur Division',
                'value': 'F'
            },
            {
                'label': 'Satkhira District',
                'value': '58'
            },
            {
                'label': 'Shariatpur District',
                'value': '62'
            },
            {
                'label': 'Sherpur District',
                'value': '57'
            },
            {
                'label': 'Sirajganj District',
                'value': '59'
            },
            {
                'label': 'Sunamganj District',
                'value': '61'
            },
            {
                'label': 'Sylhet District',
                'value': '60'
            },
            {
                'label': 'Sylhet Division',
                'value': 'G'
            },
            {
                'label': 'Tangail District',
                'value': '63'
            },
            {
                'label': 'Thakurgaon District',
                'value': '64'
            }
        ]
    },
    {
        'label': 'Barbados',
        'iso3': 'BRB',
        'value': 'BB',
        'states': [
            {
                'label': 'Christ Church',
                'value': '01'
            },
            {
                'label': 'Saint Andrew',
                'value': '02'
            },
            {
                'label': 'Saint George',
                'value': '03'
            },
            {
                'label': 'Saint James',
                'value': '04'
            },
            {
                'label': 'Saint John',
                'value': '05'
            },
            {
                'label': 'Saint Joseph',
                'value': '06'
            },
            {
                'label': 'Saint Lucy',
                'value': '07'
            },
            {
                'label': 'Saint Michael',
                'value': '08'
            },
            {
                'label': 'Saint Peter',
                'value': '09'
            },
            {
                'label': 'Saint Philip',
                'value': '10'
            },
            {
                'label': 'Saint Thomas',
                'value': '11'
            }
        ]
    },
    {
        'label': 'Belarus',
        'iso3': 'BLR',
        'value': 'BY',
        'states': [
            {
                'label': 'Brest Region',
                'value': 'BR'
            },
            {
                'label': 'Gomel Region',
                'value': 'HO'
            },
            {
                'label': 'Grodno Region',
                'value': 'HR'
            },
            {
                'label': 'Minsk',
                'value': 'HM'
            },
            {
                'label': 'Minsk Region',
                'value': 'MI'
            },
            {
                'label': 'Mogilev Region',
                'value': 'MA'
            },
            {
                'label': 'Vitebsk Region',
                'value': 'VI'
            }
        ]
    },
    {
        'label': 'Belgium',
        'iso3': 'BEL',
        'value': 'BE',
        'states': [
            {
                'label': 'Antwerp',
                'value': 'VAN'
            },
            {
                'label': 'Brussels-Capital Region',
                'value': 'BRU'
            },
            {
                'label': 'East Flanders',
                'value': 'VOV'
            },
            {
                'label': 'Flanders',
                'value': 'VLG'
            },
            {
                'label': 'Flemish Brabant',
                'value': 'VBR'
            },
            {
                'label': 'Hainaut',
                'value': 'WHT'
            },
            {
                'label': 'Liège',
                'value': 'WLG'
            },
            {
                'label': 'Limburg',
                'value': 'VLI'
            },
            {
                'label': 'Luxembourg',
                'value': 'WLX'
            },
            {
                'label': 'Namur',
                'value': 'WNA'
            },
            {
                'label': 'Wallonia',
                'value': 'WAL'
            },
            {
                'label': 'Walloon Brabant',
                'value': 'WBR'
            },
            {
                'label': 'West Flanders',
                'value': 'VWV'
            }
        ]
    },
    {
        'label': 'Belize',
        'iso3': 'BLZ',
        'value': 'BZ',
        'states': [
            {
                'label': 'Belize District',
                'value': 'BZ'
            },
            {
                'label': 'Cayo District',
                'value': 'CY'
            },
            {
                'label': 'Corozal District',
                'value': 'CZL'
            },
            {
                'label': 'Orange Walk District',
                'value': 'OW'
            },
            {
                'label': 'Stann Creek District',
                'value': 'SC'
            },
            {
                'label': 'Toledo District',
                'value': 'TOL'
            }
        ]
    },
    {
        'label': 'Benin',
        'iso3': 'BEN',
        'value': 'BJ',
        'states': [
            {
                'label': 'Alibori Department',
                'value': 'AL'
            },
            {
                'label': 'Atakora Department',
                'value': 'AK'
            },
            {
                'label': 'Atlantique Department',
                'value': 'AQ'
            },
            {
                'label': 'Borgou Department',
                'value': 'BO'
            },
            {
                'label': 'Collines Department',
                'value': 'CO'
            },
            {
                'label': 'Donga Department',
                'value': 'DO'
            },
            {
                'label': 'Kouffo Department',
                'value': 'KO'
            },
            {
                'label': 'Littoral Department',
                'value': 'LI'
            },
            {
                'label': 'Mono Department',
                'value': 'MO'
            },
            {
                'label': 'Ouémé Department',
                'value': 'OU'
            },
            {
                'label': 'Plateau Department',
                'value': 'PL'
            },
            {
                'label': 'Zou Department',
                'value': 'ZO'
            }
        ]
    },
    {
        'label': 'Bermuda',
        'iso3': 'BMU',
        'value': 'BM',
        'states': [
            {
                'label': 'Devonshire Parish',
                'value': 'DEV'
            },
            {
                'label': 'Hamilton Municipality',
                'value': 'HAM'
            },
            {
                'label': 'Hamilton Parish',
                'value': 'HA'
            },
            {
                'label': 'Paget Parish',
                'value': 'PAG'
            },
            {
                'label': 'Pembroke Parish',
                'value': 'PEM'
            },
            {
                'label': 'Saint George\'s Municipality',
                'value': 'SG'
            },
            {
                'label': 'Saint George\'s Parish',
                'value': 'SGE'
            },
            {
                'label': 'Sandys Parish',
                'value': 'SAN'
            },
            {
                'label': 'Smith\'s Parish, ',
                'value': 'SMI'
            },
            {
                'label': 'Southampton Parish',
                'value': 'SOU'
            },
            {
                'label': 'Warwick Parish',
                'value': 'WAR'
            }
        ]
    },
    {
        'label': 'Bhutan',
        'iso3': 'BTN',
        'value': 'BT',
        'states': [
            {
                'label': 'Bumthang District',
                'value': '33'
            },
            {
                'label': 'Chukha District',
                'value': '12'
            },
            {
                'label': 'Dagana District',
                'value': '22'
            },
            {
                'label': 'Gasa District',
                'value': 'GA'
            },
            {
                'label': 'Haa District',
                'value': '13'
            },
            {
                'label': 'Lhuntse District',
                'value': '44'
            },
            {
                'label': 'Mongar District',
                'value': '42'
            },
            {
                'label': 'Paro District',
                'value': '11'
            },
            {
                'label': 'Pemagatshel District',
                'value': '43'
            },
            {
                'label': 'Punakha District',
                'value': '23'
            },
            {
                'label': 'Samdrup Jongkhar District',
                'value': '45'
            },
            {
                'label': 'Samtse District',
                'value': '14'
            },
            {
                'label': 'Sarpang District',
                'value': '31'
            },
            {
                'label': 'Thimphu District',
                'value': '15'
            },
            {
                'label': 'Trashigang District',
                'value': '41'
            },
            {
                'label': 'Trongsa District',
                'value': '32'
            },
            {
                'label': 'Tsirang District',
                'value': '21'
            },
            {
                'label': 'Wangdue Phodrang District',
                'value': '24'
            },
            {
                'label': 'Zhemgang District',
                'value': '34'
            }
        ]
    },
    {
        'label': 'Bolivia',
        'iso3': 'BOL',
        'value': 'BO',
        'states': [
            {
                'label': 'Beni Department',
                'value': 'B'
            },
            {
                'label': 'Chuquisaca Department',
                'value': 'H'
            },
            {
                'label': 'Cochabamba Department',
                'value': 'C'
            },
            {
                'label': 'La Paz Department',
                'value': 'L'
            },
            {
                'label': 'Oruro Department',
                'value': 'O'
            },
            {
                'label': 'Pando Department',
                'value': 'N'
            },
            {
                'label': 'Potosí Department',
                'value': 'P'
            },
            {
                'label': 'Santa Cruz Department',
                'value': 'S'
            },
            {
                'label': 'Tarija Department',
                'value': 'T'
            }
        ]
    },
    {
        'label': 'Bosnia and Herzegovina',
        'iso3': 'BIH',
        'value': 'BA',
        'states': [
            {
                'label': 'Bosnian Podrinje Canton',
                'value': '05'
            },
            {
                'label': 'Brčko District',
                'value': 'BRC'
            },
            {
                'label': 'Canton 10',
                'value': '10'
            },
            {
                'label': 'Central Bosnia Canton',
                'value': '06'
            },
            {
                'label': 'Federation of Bosnia and Herzegovina',
                'value': 'BIH'
            },
            {
                'label': 'Herzegovina-Neretva Canton',
                'value': '07'
            },
            {
                'label': 'Posavina Canton',
                'value': '02'
            },
            {
                'label': 'Republika Srpska',
                'value': 'SRP'
            },
            {
                'label': 'Sarajevo Canton',
                'value': '09'
            },
            {
                'label': 'Tuzla Canton',
                'value': '03'
            },
            {
                'label': 'Una-Sana Canton',
                'value': '01'
            },
            {
                'label': 'West Herzegovina Canton',
                'value': '08'
            },
            {
                'label': 'Zenica-Doboj Canton',
                'value': '04'
            }
        ]
    },
    {
        'label': 'Botswana',
        'iso3': 'BWA',
        'value': 'BW',
        'states': [
            {
                'label': 'Central District',
                'value': 'CE'
            },
            {
                'label': 'Ghanzi District',
                'value': 'GH'
            },
            {
                'label': 'Kgalagadi District',
                'value': 'KG'
            },
            {
                'label': 'Kgatleng District',
                'value': 'KL'
            },
            {
                'label': 'Kweneng District',
                'value': 'KW'
            },
            {
                'label': 'Ngamiland',
                'value': 'NG'
            },
            {
                'label': 'North-East District',
                'value': 'NE'
            },
            {
                'label': 'North-West District',
                'value': 'NW'
            },
            {
                'label': 'South-East District',
                'value': 'SE'
            },
            {
                'label': 'Southern District',
                'value': 'SO'
            }
        ]
    },
    {
        'label': 'Brazil',
        'iso3': 'BRA',
        'value': 'BR',
        'states': [
            {
                'label': 'Acre',
                'value': 'AC'
            },
            {
                'label': 'Alagoas',
                'value': 'AL'
            },
            {
                'label': 'Amapá',
                'value': 'AP'
            },
            {
                'label': 'Amazonas',
                'value': 'AM'
            },
            {
                'label': 'Bahia',
                'value': 'BA'
            },
            {
                'label': 'Ceará',
                'value': 'CE'
            },
            {
                'label': 'Espírito Santo',
                'value': 'ES'
            },
            {
                'label': 'Federal District',
                'value': 'DF'
            },
            {
                'label': 'Goiás',
                'value': 'GO'
            },
            {
                'label': 'Maranhão',
                'value': 'MA'
            },
            {
                'label': 'Mato Grosso',
                'value': 'MT'
            },
            {
                'label': 'Mato Grosso do Sul',
                'value': 'MS'
            },
            {
                'label': 'Minas Gerais',
                'value': 'MG'
            },
            {
                'label': 'Pará',
                'value': 'PA'
            },
            {
                'label': 'Paraíba',
                'value': 'PB'
            },
            {
                'label': 'Paraná',
                'value': 'PR'
            },
            {
                'label': 'Pernambuco',
                'value': 'PE'
            },
            {
                'label': 'Piauí',
                'value': 'PI'
            },
            {
                'label': 'Rio de Janeiro',
                'value': 'RJ'
            },
            {
                'label': 'Rio Grande do Norte',
                'value': 'RN'
            },
            {
                'label': 'Rio Grande do Sul',
                'value': 'RS'
            },
            {
                'label': 'Rondônia',
                'value': 'RO'
            },
            {
                'label': 'Roraima',
                'value': 'RR'
            },
            {
                'label': 'Santa Catarina',
                'value': 'SC'
            },
            {
                'label': 'São Paulo',
                'value': 'SP'
            },
            {
                'label': 'Sergipe',
                'value': 'SE'
            },
            {
                'label': 'Tocantins',
                'value': 'TO'
            }
        ]
    },
    {
        'label': 'Brunei',
        'iso3': 'BRN',
        'value': 'BN',
        'states': [
            {
                'label': 'Belait District',
                'value': 'BE'
            },
            {
                'label': 'Brunei-Muara District',
                'value': 'BM'
            },
            {
                'label': 'Temburong District',
                'value': 'TE'
            },
            {
                'label': 'Tutong District',
                'value': 'TU'
            }
        ]
    },
    {
        'label': 'Bulgaria',
        'iso3': 'BGR',
        'value': 'BG',
        'states': [
            {
                'label': 'Blagoevgrad Province',
                'value': '01'
            },
            {
                'label': 'Burgas Province',
                'value': '02'
            },
            {
                'label': 'Dobrich Province',
                'value': '08'
            },
            {
                'label': 'Gabrovo Province',
                'value': '07'
            },
            {
                'label': 'Haskovo Province',
                'value': '26'
            },
            {
                'label': 'Kardzhali Province',
                'value': '09'
            },
            {
                'label': 'Kyustendil Province',
                'value': '10'
            },
            {
                'label': 'Lovech Province',
                'value': '11'
            },
            {
                'label': 'Montana Province',
                'value': '12'
            },
            {
                'label': 'Pazardzhik Province',
                'value': '13'
            },
            {
                'label': 'Pernik Province',
                'value': '14'
            },
            {
                'label': 'Pleven Province',
                'value': '15'
            },
            {
                'label': 'Plovdiv Province',
                'value': '16'
            },
            {
                'label': 'Razgrad Province',
                'value': '17'
            },
            {
                'label': 'Ruse Province',
                'value': '18'
            },
            {
                'label': 'Silistra Province',
                'value': '19'
            },
            {
                'label': 'Sliven Province',
                'value': '20'
            },
            {
                'label': 'Smolyan Province',
                'value': '21'
            },
            {
                'label': 'Sofia City Province',
                'value': '22'
            },
            {
                'label': 'Sofia Province',
                'value': '23'
            },
            {
                'label': 'Stara Zagora Province',
                'value': '24'
            },
            {
                'label': 'Targovishte Province',
                'value': '25'
            },
            {
                'label': 'Varna Province',
                'value': '03'
            },
            {
                'label': 'Veliko Tarnovo Province',
                'value': '04'
            },
            {
                'label': 'Vidin Province',
                'value': '05'
            },
            {
                'label': 'Vratsa Province',
                'value': '06'
            },
            {
                'label': 'Yambol Province',
                'value': '28'
            }
        ]
    },
    {
        'label': 'Burkina Faso',
        'iso3': 'BFA',
        'value': 'BF',
        'states': [
            {
                'label': 'Balé Province',
                'value': 'BAL'
            },
            {
                'label': 'Bam Province',
                'value': 'BAM'
            },
            {
                'label': 'Banwa Province',
                'value': 'BAN'
            },
            {
                'label': 'Bazèga Province',
                'value': 'BAZ'
            },
            {
                'label': 'Boucle du Mouhoun Region',
                'value': '01'
            },
            {
                'label': 'Bougouriba Province',
                'value': 'BGR'
            },
            {
                'label': 'Boulgou',
                'value': 'BLG'
            },
            {
                'label': 'Cascades Region',
                'value': '02'
            },
            {
                'label': 'Centre',
                'value': '03'
            },
            {
                'label': 'Centre-Est Region',
                'value': '04'
            },
            {
                'label': 'Centre-Nord Region',
                'value': '05'
            },
            {
                'label': 'Centre-Ouest Region',
                'value': '06'
            },
            {
                'label': 'Centre-Sud Region',
                'value': '07'
            },
            {
                'label': 'Comoé Province',
                'value': 'COM'
            },
            {
                'label': 'Est Region',
                'value': '08'
            },
            {
                'label': 'Ganzourgou Province',
                'value': 'GAN'
            },
            {
                'label': 'Gnagna Province',
                'value': 'GNA'
            },
            {
                'label': 'Gourma Province',
                'value': 'GOU'
            },
            {
                'label': 'Hauts-Bassins Region',
                'value': '09'
            },
            {
                'label': 'Houet Province',
                'value': 'HOU'
            },
            {
                'label': 'Ioba Province',
                'value': 'IOB'
            },
            {
                'label': 'Kadiogo Province',
                'value': 'KAD'
            },
            {
                'label': 'Kénédougou Province',
                'value': 'KEN'
            },
            {
                'label': 'Komondjari Province',
                'value': 'KMD'
            },
            {
                'label': 'Kompienga Province',
                'value': 'KMP'
            },
            {
                'label': 'Kossi Province',
                'value': 'KOS'
            },
            {
                'label': 'Koulpélogo Province',
                'value': 'KOP'
            },
            {
                'label': 'Kouritenga Province',
                'value': 'KOT'
            },
            {
                'label': 'Kourwéogo Province',
                'value': 'KOW'
            },
            {
                'label': 'Léraba Province',
                'value': 'LER'
            },
            {
                'label': 'Loroum Province',
                'value': 'LOR'
            },
            {
                'label': 'Mouhoun',
                'value': 'MOU'
            },
            {
                'label': 'Nahouri Province',
                'value': 'NAO'
            },
            {
                'label': 'labelntenga Province',
                'value': 'NAM'
            },
            {
                'label': 'Nayala Province',
                'value': 'NAY'
            },
            {
                'label': 'Nord Region, Burkina Faso',
                'value': '10'
            },
            {
                'label': 'Noumbiel Province',
                'value': 'NOU'
            },
            {
                'label': 'Oubritenga Province',
                'value': 'OUB'
            },
            {
                'label': 'Oudalan Province',
                'value': 'OUD'
            },
            {
                'label': 'Passoré Province',
                'value': 'PAS'
            },
            {
                'label': 'Plateau-Central Region',
                'value': '11'
            },
            {
                'label': 'Poni Province',
                'value': 'PON'
            },
            {
                'label': 'Sahel Region',
                'value': '12'
            },
            {
                'label': 'Sanguié Province',
                'value': 'SNG'
            },
            {
                'label': 'Sanmatenga Province',
                'value': 'SMT'
            },
            {
                'label': 'Séno Province',
                'value': 'SEN'
            },
            {
                'label': 'Sissili Province',
                'value': 'SIS'
            },
            {
                'label': 'Soum Province',
                'value': 'SOM'
            },
            {
                'label': 'Sourou Province',
                'value': 'SOR'
            },
            {
                'label': 'Sud-Ouest Region',
                'value': '13'
            },
            {
                'label': 'Tapoa Province',
                'value': 'TAP'
            },
            {
                'label': 'Tuy Province',
                'value': 'TUI'
            },
            {
                'label': 'Yagha Province',
                'value': 'YAG'
            },
            {
                'label': 'Yatenga Province',
                'value': 'YAT'
            },
            {
                'label': 'Ziro Province',
                'value': 'ZIR'
            },
            {
                'label': 'Zondoma Province',
                'value': 'ZON'
            },
            {
                'label': 'Zoundwéogo Province',
                'value': 'ZOU'
            }
        ]
    },
    {
        'label': 'Burundi',
        'iso3': 'BDI',
        'value': 'BI',
        'states': [
            {
                'label': 'Bubanza Province',
                'value': 'BB'
            },
            {
                'label': 'Bujumbura Mairie Province',
                'value': 'BM'
            },
            {
                'label': 'Bujumbura Rural Province',
                'value': 'BL'
            },
            {
                'label': 'Bururi Province',
                'value': 'BR'
            },
            {
                'label': 'Cankuzo Province',
                'value': 'CA'
            },
            {
                'label': 'Cibitoke Province',
                'value': 'CI'
            },
            {
                'label': 'Gitega Province',
                'value': 'GI'
            },
            {
                'label': 'Karuzi Province',
                'value': 'KR'
            },
            {
                'label': 'Kayanza Province',
                'value': 'KY'
            },
            {
                'label': 'Kirundo Province',
                'value': 'KI'
            },
            {
                'label': 'Makamba Province',
                'value': 'MA'
            },
            {
                'label': 'Muramvya Province',
                'value': 'MU'
            },
            {
                'label': 'Muyinga Province',
                'value': 'MY'
            },
            {
                'label': 'Mwaro Province',
                'value': 'MW'
            },
            {
                'label': 'Ngozi Province',
                'value': 'NG'
            },
            {
                'label': 'Rumonge Province',
                'value': 'RM'
            },
            {
                'label': 'Rutana Province',
                'value': 'RT'
            },
            {
                'label': 'Ruyigi Province',
                'value': 'RY'
            }
        ]
    },
    {
        'label': 'Cambodia',
        'iso3': 'KHM',
        'value': 'KH',
        'states': [
            {
                'label': 'Banteay Meanchey Province',
                'value': '1'
            },
            {
                'label': 'Battambang Province',
                'value': '2'
            },
            {
                'label': 'Kampong Cham Province',
                'value': '3'
            },
            {
                'label': 'Kampong Chhnang Province',
                'value': '4'
            },
            {
                'label': 'Kampong Speu Province',
                'value': '5'
            },
            {
                'label': 'Kampot Province',
                'value': '7'
            },
            {
                'label': 'Kandal Province',
                'value': '8'
            },
            {
                'label': 'Kep Province',
                'value': '23'
            },
            {
                'label': 'Koh Kong Province',
                'value': '9'
            },
            {
                'label': 'Kratié Province',
                'value': '10'
            },
            {
                'label': 'Mondulkiri Province',
                'value': '11'
            },
            {
                'label': 'Oddar Meanchey Province',
                'value': '22'
            },
            {
                'label': 'Pailin Province',
                'value': '24'
            },
            {
                'label': 'Phnom Penh',
                'value': '12'
            },
            {
                'label': 'Preah Vihear Province',
                'value': '13'
            },
            {
                'label': 'Prey Veng Province',
                'value': '14'
            },
            {
                'label': 'Pursat Province',
                'value': '15'
            },
            {
                'label': 'Ratanakiri Province',
                'value': '16'
            },
            {
                'label': 'Siem Reap Province',
                'value': '17'
            },
            {
                'label': 'Sihanoukville Province',
                'value': '18'
            },
            {
                'label': 'Stung Treng Province',
                'value': '19'
            },
            {
                'label': 'Svay Rieng Province',
                'value': '20'
            },
            {
                'label': 'Takéo Province',
                'value': '21'
            }
        ]
    },
    {
        'label': 'Cameroon',
        'iso3': 'CMR',
        'value': 'CM',
        'states': [
            {
                'label': 'Adamawa',
                'value': 'AD'
            },
            {
                'label': 'Centre',
                'value': 'CE'
            },
            {
                'label': 'East',
                'value': 'ES'
            },
            {
                'label': 'Far North',
                'value': 'EN'
            },
            {
                'label': 'Littoral',
                'value': 'LT'
            },
            {
                'label': 'North',
                'value': 'NO'
            },
            {
                'label': 'Northwest',
                'value': 'NW'
            },
            {
                'label': 'South',
                'value': 'SU'
            },
            {
                'label': 'Southwest',
                'value': 'SW'
            },
            {
                'label': 'West',
                'value': 'OU'
            }
        ]
    },
    {
        'label': 'Canada',
        'iso3': 'CAN',
        'value': 'CA',
        'states': [
            {
                'label': 'Alberta',
                'value': 'AB'
            },
            {
                'label': 'British Columbia',
                'value': 'BC'
            },
            {
                'label': 'Manitoba',
                'value': 'MB'
            },
            {
                'label': 'New Brunswick',
                'value': 'NB'
            },
            {
                'label': 'Newfoundland and Labrador',
                'value': 'NL'
            },
            {
                'label': 'Northwest Territories',
                'value': 'NT'
            },
            {
                'label': 'Nova Scotia',
                'value': 'NS'
            },
            {
                'label': 'Nunavut',
                'value': 'NU'
            },
            {
                'label': 'Ontario',
                'value': 'ON'
            },
            {
                'label': 'Prince Edward Island',
                'value': 'PE'
            },
            {
                'label': 'Quebec',
                'value': 'QC'
            },
            {
                'label': 'Saskatchewan',
                'value': 'SK'
            },
            {
                'label': 'Yukon',
                'value': 'YT'
            }
        ]
    },
    {
        'label': 'Cape Verde',
        'iso3': 'CPV',
        'value': 'CV',
        'states': [
            {
                'label': 'Barlavento Islands',
                'value': 'B'
            },
            {
                'label': 'Boa Vista',
                'value': 'BV'
            },
            {
                'label': 'Brava',
                'value': 'BR'
            },
            {
                'label': 'Maio Municipality',
                'value': 'MA'
            },
            {
                'label': 'Mosteiros',
                'value': 'MO'
            },
            {
                'label': 'Paul',
                'value': 'PA'
            },
            {
                'label': 'Porto Novo',
                'value': 'PN'
            },
            {
                'label': 'Praia',
                'value': 'PR'
            },
            {
                'label': 'Ribeira Brava Municipality',
                'value': 'RB'
            },
            {
                'label': 'Ribeira Grande',
                'value': 'RG'
            },
            {
                'label': 'Ribeira Grande de Santiago',
                'value': 'RS'
            },
            {
                'label': 'Sal',
                'value': 'SL'
            },
            {
                'label': 'Santa Catarina',
                'value': 'CA'
            },
            {
                'label': 'Santa Catarina do Fogo',
                'value': 'CF'
            },
            {
                'label': 'Santa Cruz',
                'value': 'CR'
            },
            {
                'label': 'São Domingos',
                'value': 'SD'
            },
            {
                'label': 'São Filipe',
                'value': 'SF'
            },
            {
                'label': 'São Lourenço dos Órgãos',
                'value': 'SO'
            },
            {
                'label': 'São Miguel',
                'value': 'SM'
            },
            {
                'label': 'São Vicente',
                'value': 'SV'
            },
            {
                'label': 'Sotavento Islands',
                'value': 'S'
            },
            {
                'label': 'Tarrafal',
                'value': 'TA'
            },
            {
                'label': 'Tarrafal de São Nicolau',
                'value': 'TS'
            }
        ]
    },
    {
        'label': 'Central African Republic',
        'iso3': 'CAF',
        'value': 'CF',
        'states': [
            {
                'label': 'Bamingui-Bangoran Prefecture',
                'value': 'BB'
            },
            {
                'label': 'Bangui',
                'value': 'BGF'
            },
            {
                'label': 'Basse-Kotto Prefecture',
                'value': 'BK'
            },
            {
                'label': 'Haut-Mbomou Prefecture',
                'value': 'HM'
            },
            {
                'label': 'Haute-Kotto Prefecture',
                'value': 'HK'
            },
            {
                'label': 'Kémo Prefecture',
                'value': 'KG'
            },
            {
                'label': 'Lobaye Prefecture',
                'value': 'LB'
            },
            {
                'label': 'Mambéré-Kadéï',
                'value': 'HS'
            },
            {
                'label': 'Mbomou Prefecture',
                'value': 'MB'
            },
            {
                'label': 'Nana-Grébizi Economic Prefecture',
                'value': 'KB'
            },
            {
                'label': 'Nana-Mambéré Prefecture',
                'value': 'NM'
            },
            {
                'label': 'Ombella-M\'Poko Prefecture',
                'value': 'MP'
            },
            {
                'label': 'Ouaka Prefecture',
                'value': 'UK'
            },
            {
                'label': 'Ouham Prefecture',
                'value': 'AC'
            },
            {
                'label': 'Ouham-Pendé Prefecture',
                'value': 'OP'
            },
            {
                'label': 'Sangha-Mbaéré',
                'value': 'SE'
            },
            {
                'label': 'Vakaga Prefecture',
                'value': 'VK'
            }
        ]
    },
    {
        'label': 'Chad',
        'iso3': 'TCD',
        'value': 'TD',
        'states': [
            {
                'label': 'Bahr el Gazel',
                'value': 'BG'
            },
            {
                'label': 'Batha Region',
                'value': 'BA'
            },
            {
                'label': 'Borkou',
                'value': 'BO'
            },
            {
                'label': 'Ennedi Region',
                'value': 'EN'
            },
            {
                'label': 'Ennedi-Est',
                'value': 'EE'
            },
            {
                'label': 'Ennedi-Ouest',
                'value': 'EO'
            },
            {
                'label': 'Guéra Region',
                'value': 'GR'
            },
            {
                'label': 'Hadjer-Lamis',
                'value': 'HL'
            },
            {
                'label': 'Kanem Region',
                'value': 'KA'
            },
            {
                'label': 'Lac Region',
                'value': 'LC'
            },
            {
                'label': 'Logone Occidental Region',
                'value': 'LO'
            },
            {
                'label': 'Logone Oriental Region',
                'value': 'LR'
            },
            {
                'label': 'Mandoul Region',
                'value': 'MA'
            },
            {
                'label': 'Mayo-Kebbi Est Region',
                'value': 'ME'
            },
            {
                'label': 'Mayo-Kebbi Ouest Region',
                'value': 'MO'
            },
            {
                'label': 'Moyen-Chari Region',
                'value': 'MC'
            },
            {
                'label': 'N\'Djamena',
                'value': 'ND'
            },
            {
                'label': 'Ouaddaï Region',
                'value': 'OD'
            },
            {
                'label': 'Salamat Region',
                'value': 'SA'
            },
            {
                'label': 'Sila Region',
                'value': 'SI'
            },
            {
                'label': 'Tandjilé Region',
                'value': 'TA'
            },
            {
                'label': 'Tibesti Region',
                'value': 'TI'
            },
            {
                'label': 'Wadi Fira Region',
                'value': 'WF'
            }
        ]
    },
    {
        'label': 'Chile',
        'iso3': 'CHL',
        'value': 'CL',
        'states': [
            {
                'label': 'Antofagasta Region',
                'value': 'AN'
            },
            {
                'label': 'Araucanía Region',
                'value': 'AR'
            },
            {
                'label': 'Arica y Parinacota Region',
                'value': 'AP'
            },
            {
                'label': 'Atacama Region',
                'value': 'AT'
            },
            {
                'label': 'Aysén Region',
                'value': 'AI'
            },
            {
                'label': 'Bío Bío Region',
                'value': 'BI'
            },
            {
                'label': 'Coquimbo Region',
                'value': 'CO'
            },
            {
                'label': 'Los Lagos Region',
                'value': 'LL'
            },
            {
                'label': 'Los Ríos Region',
                'value': 'LR'
            },
            {
                'label': 'Magellan and the Chilean Antarctic Region',
                'value': 'MA'
            },
            {
                'label': 'Maule Region',
                'value': 'ML'
            },
            {
                'label': 'Ñuble Region',
                'value': 'NB'
            },
            {
                'label': 'O\'Higgins',
                'value': 'LI'
            },
            {
                'label': 'Santiago Metropolitan Region',
                'value': 'RM'
            },
            {
                'label': 'Tarapacá Region',
                'value': 'TA'
            },
            {
                'label': 'Valparaíso',
                'value': 'VS'
            }
        ]
    },
    {
        'label': 'China',
        'iso3': 'CHN',
        'value': 'CN',
        'states': [
            {
                'label': 'Anhui',
                'value': 'AH'
            },
            {
                'label': 'Beijing',
                'value': 'BJ'
            },
            {
                'label': 'Chongqing',
                'value': 'CQ'
            },
            {
                'label': 'Fujian',
                'value': 'FJ'
            },
            {
                'label': 'Gansu',
                'value': 'GS'
            },
            {
                'label': 'Guangdong',
                'value': 'GD'
            },
            {
                'label': 'Guangxi Zhuang Autonomous Region',
                'value': 'GX'
            },
            {
                'label': 'Guizhou',
                'value': 'GZ'
            },
            {
                'label': 'Hainan',
                'value': 'HI'
            },
            {
                'label': 'Hebei',
                'value': 'HE'
            },
            {
                'label': 'Heilongjiang',
                'value': 'HL'
            },
            {
                'label': 'Henan',
                'value': 'HA'
            },
            {
                'label': 'Hong Kong',
                'value': 'HK'
            },
            {
                'label': 'Hubei',
                'value': 'HB'
            },
            {
                'label': 'Hunan',
                'value': 'HN'
            },
            {
                'label': 'Inner Mongolia',
                'value': 'NM'
            },
            {
                'label': 'Jiangsu',
                'value': 'JS'
            },
            {
                'label': 'Jiangxi',
                'value': 'JX'
            },
            {
                'label': 'Jilin',
                'value': 'JL'
            },
            {
                'label': 'Keelung',
                'value': 'TW-KEE'
            },
            {
                'label': 'Liaoning',
                'value': 'LN'
            },
            {
                'label': 'Macau',
                'value': 'MO'
            },
            {
                'label': 'Ningxia Hui Autonomous Region',
                'value': 'NX'
            },
            {
                'label': 'Qinghai',
                'value': 'QH'
            },
            {
                'label': 'Shaanxi',
                'value': 'SN'
            },
            {
                'label': 'Shandong',
                'value': 'SD'
            },
            {
                'label': 'Shanghai',
                'value': 'SH'
            },
            {
                'label': 'Shanxi',
                'value': 'SX'
            },
            {
                'label': 'Sichuan',
                'value': 'SC'
            },
            {
                'label': 'Taiwan Province, People\'s Republic of China',
                'value': 'TW'
            },
            {
                'label': 'Tibet Autonomous Region',
                'value': 'XZ'
            },
            {
                'label': 'Xinjiang',
                'value': 'XJ'
            },
            {
                'label': 'Yunnan',
                'value': 'YN'
            },
            {
                'label': 'Zhejiang',
                'value': 'ZJ'
            }
        ]
    },
    {
        'label': 'Colombia',
        'iso3': 'COL',
        'value': 'CO',
        'states': [
            {
                'label': 'Amazonas Department',
                'value': 'AMA'
            },
            {
                'label': 'Antioquia Department',
                'value': 'ANT'
            },
            {
                'label': 'Arauca Department',
                'value': 'ARA'
            },
            {
                'label': 'Archipelago of Saint Andréws, Providence and Saint Catalina',
                'value': 'SAP'
            },
            {
                'label': 'Atlántico Department',
                'value': 'ATL'
            },
            {
                'label': 'Bolívar Department',
                'value': 'BOL'
            },
            {
                'label': 'Boyacá Department',
                'value': 'BOY'
            },
            {
                'label': 'Caldas Department',
                'value': 'CAL'
            },
            {
                'label': 'Caquetá Department',
                'value': 'CAQ'
            },
            {
                'label': 'Casanare Department',
                'value': 'CAS'
            },
            {
                'label': 'Cauca Department',
                'value': 'CAU'
            },
            {
                'label': 'Cesar Department',
                'value': 'CES'
            },
            {
                'label': 'Chocó Department',
                'value': 'CHO'
            },
            {
                'label': 'Córdoba Department',
                'value': 'COR'
            },
            {
                'label': 'Cundinamarca Department',
                'value': 'CUN'
            },
            {
                'label': 'Guainía Department',
                'value': 'GUA'
            },
            {
                'label': 'Guaviare Department',
                'value': 'GUV'
            },
            {
                'label': 'Huila Department',
                'value': 'HUI'
            },
            {
                'label': 'La Guajira Department',
                'value': 'LAG'
            },
            {
                'label': 'Magdalena Department',
                'value': 'MAG'
            },
            {
                'label': 'Meta',
                'value': 'MET'
            },
            {
                'label': 'Nariño Department',
                'value': 'NAR'
            },
            {
                'label': 'Norte de Santander Department',
                'value': 'NSA'
            },
            {
                'label': 'Putumayo Department',
                'value': 'PUT'
            },
            {
                'label': 'Quindío Department',
                'value': 'QUI'
            },
            {
                'label': 'Risaralda Department',
                'value': 'RIS'
            },
            {
                'label': 'Santander Department',
                'value': 'SAN'
            },
            {
                'label': 'Sucre Department',
                'value': 'SUC'
            },
            {
                'label': 'Tolima Department',
                'value': 'TOL'
            },
            {
                'label': 'Valle del Cauca Department',
                'value': 'VAC'
            },
            {
                'label': 'Vaupés Department',
                'value': 'VAU'
            },
            {
                'label': 'Vichada Department',
                'value': 'VID'
            }
        ]
    },
    {
        'label': 'Comoros',
        'iso3': 'COM',
        'value': 'KM',
        'states': [
            {
                'label': 'Anjouan',
                'value': 'A'
            },
            {
                'label': 'Grande Comore',
                'value': 'G'
            },
            {
                'label': 'Mohéli',
                'value': 'M'
            }
        ]
    },
    {
        'label': 'Congo',
        'iso3': 'COG',
        'value': 'CG',
        'states': [
            {
                'label': 'Bouenza Department',
                'value': '11'
            },
            {
                'label': 'Brazzaville',
                'value': 'BZV'
            },
            {
                'label': 'Cuvette Department',
                'value': '8'
            },
            {
                'label': 'Cuvette-Ouest Department',
                'value': '15'
            },
            {
                'label': 'Kouilou Department',
                'value': '5'
            },
            {
                'label': 'Lékoumou Department',
                'value': '2'
            },
            {
                'label': 'Likouala Department',
                'value': '7'
            },
            {
                'label': 'Niari Department',
                'value': '9'
            },
            {
                'label': 'Plateaux Department',
                'value': '14'
            },
            {
                'label': 'Pointe-Noire',
                'value': '16'
            },
            {
                'label': 'Pool Department',
                'value': '12'
            },
            {
                'label': 'Sangha Department',
                'value': '13'
            }
        ]
    },
    {
        'label': 'Congo',
        'iso3': 'COD',
        'value': 'CD',
        'states': [
            {
                'label': 'Bandundu Province',
                'value': 'BN'
            },
            {
                'label': 'Bas-Congo province',
                'value': 'BC'
            },
            {
                'label': 'Bas-Uele',
                'value': 'BU'
            },
            {
                'label': 'Équateur',
                'value': 'EQ'
            },
            {
                'label': 'Haut-Katanga Province',
                'value': 'HK'
            },
            {
                'label': 'Haut-Lomami District',
                'value': 'HL'
            },
            {
                'label': 'Haut-Uele',
                'value': 'HU'
            },
            {
                'label': 'Ituri Interim Administration',
                'value': 'IT'
            },
            {
                'label': 'Kasaï District',
                'value': 'KS'
            },
            {
                'label': 'Kasaï-Occidental',
                'value': 'KW'
            },
            {
                'label': 'Kasaï-Oriental',
                'value': 'KE'
            },
            {
                'label': 'Katanga Province',
                'value': 'KA'
            },
            {
                'label': 'Kinshasa',
                'value': 'KN'
            },
            {
                'label': 'Kwango District',
                'value': 'KG'
            },
            {
                'label': 'Kwilu District',
                'value': 'KL'
            },
            {
                'label': 'Lomami Province',
                'value': 'LO'
            },
            {
                'label': 'Mai-Ndombe Province',
                'value': 'MN'
            },
            {
                'label': 'Maniema',
                'value': 'MA'
            },
            {
                'label': 'Mongala District',
                'value': 'MO'
            },
            {
                'label': 'Nord-Ubangi District',
                'value': 'NU'
            },
            {
                'label': 'North Kivu',
                'value': 'NK'
            },
            {
                'label': 'Orientale Province',
                'value': 'OR'
            },
            {
                'label': 'Sankuru District',
                'value': 'SA'
            },
            {
                'label': 'South Kivu',
                'value': 'SK'
            },
            {
                'label': 'Sud-Ubangi',
                'value': 'SU'
            },
            {
                'label': 'Tanganyika Province',
                'value': 'TA'
            },
            {
                'label': 'Tshopo District',
                'value': 'TO'
            },
            {
                'label': 'Tshuapa District',
                'value': 'TU'
            }
        ]
    },
    {
        'label': 'Costa Rica',
        'iso3': 'CRI',
        'value': 'CR',
        'states': [
            {
                'label': 'Alajuela Province',
                'value': 'A'
            },
            {
                'label': 'Guanacaste Province',
                'value': 'G'
            },
            {
                'label': 'Heredia Province',
                'value': 'H'
            },
            {
                'label': 'Limón Province',
                'value': 'L'
            },
            {
                'label': 'Provincia de Cartago',
                'value': 'C'
            },
            {
                'label': 'Puntarenas Province',
                'value': 'P'
            },
            {
                'label': 'San José Province',
                'value': 'SJ'
            }
        ]
    },
    {
        'label': 'Ivory Coast',
        'iso3': 'CIV',
        'value': 'CI',
        'states': [
            {
                'label': 'Abidjan',
                'value': 'AB'
            },
            {
                'label': 'Agnéby',
                'value': '16'
            },
            {
                'label': 'Bafing Region',
                'value': '17'
            },
            {
                'label': 'Bas-Sassandra District',
                'value': 'BS'
            },
            {
                'label': 'Bas-Sassandra Region',
                'value': '09'
            },
            {
                'label': 'Comoé District',
                'value': 'CM'
            },
            {
                'label': 'Denguélé District',
                'value': 'DN'
            },
            {
                'label': 'Denguélé Region',
                'value': '10'
            },
            {
                'label': 'Dix-Huit Montagnes',
                'value': '06'
            },
            {
                'label': 'Fromager',
                'value': '18'
            },
            {
                'label': 'Gôh-Djiboua District',
                'value': 'GD'
            },
            {
                'label': 'Haut-Sassandra',
                'value': '02'
            },
            {
                'label': 'Lacs District',
                'value': 'LC'
            },
            {
                'label': 'Lacs Region',
                'value': '07'
            },
            {
                'label': 'Lagunes District',
                'value': 'LG'
            },
            {
                'label': 'Lagunes region',
                'value': '01'
            },
            {
                'label': 'Marahoué Region',
                'value': '12'
            },
            {
                'label': 'Montagnes District',
                'value': 'MG'
            },
            {
                'label': 'Moyen-Cavally',
                'value': '19'
            },
            {
                'label': 'Moyen-Comoé',
                'value': '05'
            },
            {
                'label': 'N\'zi- Comoé',
                'value': '11'
            },
            {
                'label': 'Sassandra-Marahoué District',
                'value': 'SM'
            },
            {
                'label': 'Savanes Region',
                'value': '03'
            },
            {
                'label': 'Sud-Bandama',
                'value': '15'
            },
            {
                'label': 'Sud-Comoé',
                'value': '13'
            },
            {
                'label': 'Vallée du Bandama District',
                'value': 'VB'
            },
            {
                'label': 'Vallée du Bandama Region',
                'value': '04'
            },
            {
                'label': 'Woroba District',
                'value': 'WR'
            },
            {
                'label': 'Worodougou',
                'value': '14'
            },
            {
                'label': 'Yamoussoukro',
                'value': 'YM'
            },
            {
                'label': 'Zanzan Region',
                'value': 'ZZ'
            }
        ]
    },
    {
        'label': 'Croatia (Hrvatska)',
        'iso3': 'HRV',
        'value': 'HR',
        'states': [
            {
                'label': 'Bjelovar-Bilogora County',
                'value': '07'
            },
            {
                'label': 'Brod-Posavina County',
                'value': '12'
            },
            {
                'label': 'Dubrovnik-Neretva County',
                'value': '19'
            },
            {
                'label': 'Istria County',
                'value': '18'
            },
            {
                'label': 'Koprivnica-Križevci County',
                'value': '06'
            },
            {
                'label': 'Krapina-Zagorje County',
                'value': '02'
            },
            {
                'label': 'Lika-Senj County',
                'value': '09'
            },
            {
                'label': 'Međimurje County',
                'value': '20'
            },
            {
                'label': 'Osijek-Baranja County',
                'value': '14'
            },
            {
                'label': 'Požega-Slavonia County',
                'value': '11'
            },
            {
                'label': 'Primorje-Gorski Kotar County',
                'value': '08'
            },
            {
                'label': 'Šibenik-Knin County',
                'value': '15'
            },
            {
                'label': 'Sisak-Moslavina County',
                'value': '03'
            },
            {
                'label': 'Split-Dalmatia County',
                'value': '17'
            },
            {
                'label': 'Varaždin County',
                'value': '05'
            },
            {
                'label': 'Virovitica-Podravina County',
                'value': '10'
            },
            {
                'label': 'Vukovar-Syrmia County',
                'value': '16'
            },
            {
                'label': 'Zadar County',
                'value': '13'
            },
            {
                'label': 'Zagreb',
                'value': '21'
            },
            {
                'label': 'Zagreb County',
                'value': '01'
            }
        ]
    },
    {
        'label': 'Cuba',
        'iso3': 'CUB',
        'value': 'CU',
        'states': [
            {
                'label': 'Artemisa Province',
                'value': '15'
            },
            {
                'label': 'Camagüey Province',
                'value': '09'
            },
            {
                'label': 'Ciego de Ávila Province',
                'value': '08'
            },
            {
                'label': 'Cienfuegos Province',
                'value': '06'
            },
            {
                'label': 'Granma Province',
                'value': '12'
            },
            {
                'label': 'Guantánamo Province',
                'value': '14'
            },
            {
                'label': 'Havana Province',
                'value': '03'
            },
            {
                'label': 'Holguín Province',
                'value': '11'
            },
            {
                'label': 'Isla de la Juventud',
                'value': '99'
            },
            {
                'label': 'Las Tunas Province',
                'value': '10'
            },
            {
                'label': 'Matanzas Province',
                'value': '04'
            },
            {
                'label': 'Mayabeque Province',
                'value': '16'
            },
            {
                'label': 'Pinar del Río Province',
                'value': '01'
            },
            {
                'label': 'Sancti Spíritus Province',
                'value': '07'
            },
            {
                'label': 'Santiago de Cuba Province',
                'value': '13'
            },
            {
                'label': 'Villa Clara Province',
                'value': '05'
            }
        ]
    },
    {
        'label': 'Cyprus',
        'iso3': 'CYP',
        'value': 'CY',
        'states': [
            {
                'label': 'Famagusta District',
                'value': '04'
            },
            {
                'label': 'Kyrenia District',
                'value': '06'
            },
            {
                'label': 'Larnaca District',
                'value': '03'
            },
            {
                'label': 'Limassol District',
                'value': '02'
            },
            {
                'label': 'Nicosia District',
                'value': '01'
            },
            {
                'label': 'Paphos District',
                'value': '05'
            }
        ]
    },
    {
        'label': 'Czech Republic',
        'iso3': 'CZE',
        'value': 'CZ',
        'states': [
            {
                'label': 'Benešov District',
                'value': '201'
            },
            {
                'label': 'Beroun District',
                'value': '202'
            },
            {
                'label': 'Blansko District',
                'value': '641'
            },
            {
                'label': 'Břeclav District',
                'value': '644'
            },
            {
                'label': 'Brno-City District',
                'value': '642'
            },
            {
                'label': 'Brno-Country District',
                'value': '643'
            },
            {
                'label': 'Bruntál District',
                'value': '801'
            },
            {
                'label': 'Central Bohemian Region',
                'value': '20'
            },
            {
                'label': 'Česká Lípa District',
                'value': '511'
            },
            {
                'label': 'České Budějovice District',
                'value': '311'
            },
            {
                'label': 'Český Krumlov District',
                'value': '312'
            },
            {
                'label': 'Cheb District',
                'value': '411'
            },
            {
                'label': 'Chomutov District',
                'value': '422'
            },
            {
                'label': 'Chrudim District',
                'value': '531'
            },
            {
                'label': 'Děčín District',
                'value': '421'
            },
            {
                'label': 'Domažlice District',
                'value': '321'
            },
            {
                'label': 'Frýdek-Místek District',
                'value': '802'
            },
            {
                'label': 'Havlíčkův Brod District',
                'value': '631'
            },
            {
                'label': 'Hodonín District',
                'value': '645'
            },
            {
                'label': 'Horní Počernice',
                'value': '120'
            },
            {
                'label': 'Hradec Králové District',
                'value': '521'
            },
            {
                'label': 'Hradec Králové Region',
                'value': '52'
            },
            {
                'label': 'Jablonec nad Nisou District',
                'value': '512'
            },
            {
                'label': 'Jeseník District',
                'value': '711'
            },
            {
                'label': 'Jičín District',
                'value': '522'
            },
            {
                'label': 'Jihlava District',
                'value': '632'
            },
            {
                'label': 'Jindřichův Hradec District',
                'value': '313'
            },
            {
                'label': 'Karlovy Vary District',
                'value': '412'
            },
            {
                'label': 'Karlovy Vary Region',
                'value': '41'
            },
            {
                'label': 'Karviná District',
                'value': '803'
            },
            {
                'label': 'Kladno District',
                'value': '203'
            },
            {
                'label': 'Klatovy District',
                'value': '322'
            },
            {
                'label': 'Kolín District',
                'value': '204'
            },
            {
                'label': 'Kroměříž District',
                'value': '721'
            },
            {
                'label': 'Liberec District',
                'value': '513'
            },
            {
                'label': 'Liberec Region',
                'value': '51'
            },
            {
                'label': 'Litoměřice District',
                'value': '423'
            },
            {
                'label': 'Louny District',
                'value': '424'
            },
            {
                'label': 'Mělník District',
                'value': '206'
            },
            {
                'label': 'Mladá Boleslav District',
                'value': '207'
            },
            {
                'label': 'Moravian-Silesian Region',
                'value': '80'
            },
            {
                'label': 'Most District',
                'value': '425'
            },
            {
                'label': 'Náchod District',
                'value': '523'
            },
            {
                'label': 'Nový Jičín District',
                'value': '804'
            },
            {
                'label': 'Nymburk District',
                'value': '208'
            },
            {
                'label': 'Olomouc District',
                'value': '712'
            },
            {
                'label': 'Olomouc Region',
                'value': '71'
            },
            {
                'label': 'Opava District',
                'value': '805'
            },
            {
                'label': 'Ostrava-City District',
                'value': '806'
            },
            {
                'label': 'Pardubice District',
                'value': '532'
            },
            {
                'label': 'Pardubice Region',
                'value': '53'
            },
            {
                'label': 'Pelhřimov District',
                'value': '633'
            },
            {
                'label': 'Písek District',
                'value': '314'
            },
            {
                'label': 'Plzeň Region',
                'value': '32'
            },
            {
                'label': 'Plzeň-City District',
                'value': '323'
            },
            {
                'label': 'Plzeň-North District',
                'value': '325'
            },
            {
                'label': 'Plzeň-South District',
                'value': '324'
            },
            {
                'label': 'Prachatice District',
                'value': '315'
            },
            {
                'label': 'Prague',
                'value': '10'
            },
            {
                'label': 'Prague 1',
                'value': '101'
            },
            {
                'label': 'Prague 10',
                'value': '110'
            },
            {
                'label': 'Prague 11',
                'value': '111'
            },
            {
                'label': 'Prague 12',
                'value': '112'
            },
            {
                'label': 'Prague 13',
                'value': '113'
            },
            {
                'label': 'Prague 14',
                'value': '114'
            },
            {
                'label': 'Prague 15',
                'value': '115'
            },
            {
                'label': 'Prague 16',
                'value': '116'
            },
            {
                'label': 'Prague 2',
                'value': '102'
            },
            {
                'label': 'Prague 21',
                'value': '121'
            },
            {
                'label': 'Prague 3',
                'value': '103'
            },
            {
                'label': 'Prague 4',
                'value': '104'
            },
            {
                'label': 'Prague 5',
                'value': '105'
            },
            {
                'label': 'Prague 6',
                'value': '106'
            },
            {
                'label': 'Prague 7',
                'value': '107'
            },
            {
                'label': 'Prague 8',
                'value': '108'
            },
            {
                'label': 'Prague 9',
                'value': '109'
            },
            {
                'label': 'Prague-East District',
                'value': '209'
            },
            {
                'label': 'Prague-West District',
                'value': '20A'
            },
            {
                'label': 'Přerov District',
                'value': '714'
            },
            {
                'label': 'Příbram District',
                'value': '20B'
            },
            {
                'label': 'Prostějov District',
                'value': '713'
            },
            {
                'label': 'Rakovník District',
                'value': '20C'
            },
            {
                'label': 'Rokycany District',
                'value': '326'
            },
            {
                'label': 'Rychnov nad Kněžnou District',
                'value': '524'
            },
            {
                'label': 'Semily District',
                'value': '514'
            },
            {
                'label': 'Sokolov District',
                'value': '413'
            },
            {
                'label': 'South Bohemian Region',
                'value': '31'
            },
            {
                'label': 'South Moravian Region',
                'value': '64'
            },
            {
                'label': 'Strakonice District',
                'value': '316'
            },
            {
                'label': 'Šumperk District',
                'value': '715'
            },
            {
                'label': 'Svitavy District',
                'value': '533'
            },
            {
                'label': 'Tábor District',
                'value': '317'
            },
            {
                'label': 'Tachov District',
                'value': '327'
            },
            {
                'label': 'Teplice District',
                'value': '426'
            },
            {
                'label': 'Třebíč District',
                'value': '634'
            },
            {
                'label': 'Trutnov District',
                'value': '525'
            },
            {
                'label': 'Uherské Hradiště District',
                'value': '722'
            },
            {
                'label': 'Ústí nad Labem District',
                'value': '427'
            },
            {
                'label': 'Ústí nad Labem Region',
                'value': '42'
            },
            {
                'label': 'Ústí nad Orlicí District',
                'value': '534'
            },
            {
                'label': 'Vsetín District',
                'value': '723'
            },
            {
                'label': 'Vyškov District',
                'value': '646'
            },
            {
                'label': 'Vysočina Region',
                'value': '63'
            },
            {
                'label': 'Žďár nad Sázavou District',
                'value': '635'
            },
            {
                'label': 'Zlín District',
                'value': '724'
            },
            {
                'label': 'Zlín Region',
                'value': '72'
            },
            {
                'label': 'Znojmo District',
                'value': '647'
            }
        ]
    },
    {
        'label': 'Denmark',
        'iso3': 'DNK',
        'value': 'DK',
        'states': [
            {
                'label': 'Capital Region of Denmark',
                'value': '84'
            },
            {
                'label': 'Central Denmark Region',
                'value': '82'
            },
            {
                'label': 'North Denmark Region',
                'value': '81'
            },
            {
                'label': 'Region of Southern Denmark',
                'value': '83'
            },
            {
                'label': 'Region Zealand',
                'value': '85'
            }
        ]
    },
    {
        'label': 'Djibouti',
        'iso3': 'DJI',
        'value': 'DJ',
        'states': [
            {
                'label': 'Ali Sabieh Region',
                'value': 'AS'
            },
            {
                'label': 'Arta Region',
                'value': 'AR'
            },
            {
                'label': 'Dikhil Region',
                'value': 'DI'
            },
            {
                'label': 'Djibouti',
                'value': 'DJ'
            },
            {
                'label': 'Obock Region',
                'value': 'OB'
            },
            {
                'label': 'Tadjourah Region',
                'value': 'TA'
            }
        ]
    },
    {
        'label': 'Dominica',
        'iso3': 'DMA',
        'value': 'DM',
        'states': [
            {
                'label': 'Saint Andrew Parish',
                'value': '02'
            },
            {
                'label': 'Saint David Parish',
                'value': '03'
            },
            {
                'label': 'Saint George Parish',
                'value': '04'
            },
            {
                'label': 'Saint John Parish',
                'value': '05'
            },
            {
                'label': 'Saint Joseph Parish',
                'value': '06'
            },
            {
                'label': 'Saint Luke Parish',
                'value': '07'
            },
            {
                'label': 'Saint Mark Parish',
                'value': '08'
            },
            {
                'label': 'Saint Patrick Parish',
                'value': '09'
            },
            {
                'label': 'Saint Paul Parish',
                'value': '10'
            },
            {
                'label': 'Saint Peter Parish',
                'value': '11'
            }
        ]
    },
    {
        'label': 'Dominican Republic',
        'iso3': 'DOM',
        'value': 'DO',
        'states': [
            {
                'label': 'Azua Province',
                'value': '02'
            },
            {
                'label': 'Baoruco Province',
                'value': '03'
            },
            {
                'label': 'Barahona Province',
                'value': '04'
            },
            {
                'label': 'Dajabón Province',
                'value': '05'
            },
            {
                'label': 'Distrito Nacional',
                'value': '01'
            },
            {
                'label': 'Duarte Province',
                'value': '06'
            },
            {
                'label': 'El Seibo Province',
                'value': '08'
            },
            {
                'label': 'Espaillat Province',
                'value': '09'
            },
            {
                'label': 'Hato Mayor Province',
                'value': '30'
            },
            {
                'label': 'Hermanas Mirabal Province',
                'value': '19'
            },
            {
                'label': 'Independencia',
                'value': '10'
            },
            {
                'label': 'La Altagracia Province',
                'value': '11'
            },
            {
                'label': 'La Romana Province',
                'value': '12'
            },
            {
                'label': 'La Vega Province',
                'value': '13'
            },
            {
                'label': 'María Trinidad Sánchez Province',
                'value': '14'
            },
            {
                'label': 'Monseñor Nouel Province',
                'value': '28'
            },
            {
                'label': 'Monte Cristi Province',
                'value': '15'
            },
            {
                'label': 'Monte Plata Province',
                'value': '29'
            },
            {
                'label': 'Pedernales Province',
                'value': '16'
            },
            {
                'label': 'Peravia Province',
                'value': '17'
            },
            {
                'label': 'Puerto Plata Province',
                'value': '18'
            },
            {
                'label': 'Samaná Province',
                'value': '20'
            },
            {
                'label': 'San Cristóbal Province',
                'value': '21'
            },
            {
                'label': 'San José de Ocoa Province',
                'value': '31'
            },
            {
                'label': 'San Juan Province',
                'value': '22'
            },
            {
                'label': 'San Pedro de Macorís',
                'value': '23'
            },
            {
                'label': 'Sánchez Ramírez Province',
                'value': '24'
            },
            {
                'label': 'Santiago Province',
                'value': '25'
            },
            {
                'label': 'Santiago Rodríguez Province',
                'value': '26'
            },
            {
                'label': 'Santo Domingo Province',
                'value': '32'
            },
            {
                'label': 'Valverde Province',
                'value': '27'
            }
        ]
    },
    {
        'label': 'Timor-Leste',
        'iso3': 'TLS',
        'value': 'TL',
        'states': [
            {
                'label': 'Aileu municipality',
                'value': 'AL'
            },
            {
                'label': 'Ainaro Municipality',
                'value': 'AN'
            },
            {
                'label': 'Baucau Municipality',
                'value': 'BA'
            },
            {
                'label': 'Bobonaro Municipality',
                'value': 'BO'
            },
            {
                'label': 'Cova Lima Municipality',
                'value': 'CO'
            },
            {
                'label': 'Dili municipality',
                'value': 'DI'
            },
            {
                'label': 'Ermera District',
                'value': 'ER'
            },
            {
                'label': 'Lautém Municipality',
                'value': 'LA'
            },
            {
                'label': 'Liquiçá Municipality',
                'value': 'LI'
            },
            {
                'label': 'Manatuto District',
                'value': 'MT'
            },
            {
                'label': 'Manufahi Municipality',
                'value': 'MF'
            },
            {
                'label': 'Viqueque Municipality',
                'value': 'VI'
            }
        ]
    },
    {
        'label': 'Ecuador',
        'iso3': 'ECU',
        'value': 'EC',
        'states': [
            {
                'label': 'Azuay Province',
                'value': 'A'
            },
            {
                'label': 'Bolívar Province',
                'value': 'B'
            },
            {
                'label': 'Cañar Province',
                'value': 'F'
            },
            {
                'label': 'Carchi Province',
                'value': 'C'
            },
            {
                'label': 'Chimborazo Province',
                'value': 'H'
            },
            {
                'label': 'Cotopaxi Province',
                'value': 'X'
            },
            {
                'label': 'El Oro Province',
                'value': 'O'
            },
            {
                'label': 'Esmeraldas',
                'value': 'E'
            },
            {
                'label': 'Galápagos Province',
                'value': 'W'
            },
            {
                'label': 'Guayas Province',
                'value': 'G'
            },
            {
                'label': 'Imbabura Province',
                'value': 'I'
            },
            {
                'label': 'Los Ríos Province',
                'value': 'R'
            },
            {
                'label': 'Manabí Province',
                'value': 'M'
            },
            {
                'label': 'Morona-Santiago Province',
                'value': 'S'
            },
            {
                'label': 'Napo Province',
                'value': 'N'
            },
            {
                'label': 'Orellana Province',
                'value': 'D'
            },
            {
                'label': 'Pastaza Province',
                'value': 'Y'
            },
            {
                'label': 'Pichincha Province',
                'value': 'P'
            },
            {
                'label': 'Santa Elena Province',
                'value': 'SE'
            },
            {
                'label': 'Santo Domingo de los Tsáchilas Province',
                'value': 'SD'
            },
            {
                'label': 'Sucumbíos Province',
                'value': 'U'
            },
            {
                'label': 'Tungurahua Province',
                'value': 'T'
            },
            {
                'label': 'Zamora-Chinchipe Province',
                'value': 'Z'
            }
        ]
    },
    {
        'label': 'Egypt',
        'iso3': 'EGY',
        'value': 'EG',
        'states': [
            {
                'label': 'Alexandria Governorate',
                'value': 'ALX'
            },
            {
                'label': 'Aswan Governorate',
                'value': 'ASN'
            },
            {
                'label': 'Asyut Governorate',
                'value': 'AST'
            },
            {
                'label': 'Beheira Governorate',
                'value': 'BH'
            },
            {
                'label': 'Beni Suef Governorate',
                'value': 'BNS'
            },
            {
                'label': 'Cairo Governorate',
                'value': 'C'
            },
            {
                'label': 'Dakahlia Governorate',
                'value': 'DK'
            },
            {
                'label': 'Damietta Governorate',
                'value': 'DT'
            },
            {
                'label': 'Faiyum Governorate',
                'value': 'FYM'
            },
            {
                'label': 'Gharbia Governorate',
                'value': 'GH'
            },
            {
                'label': 'Giza Governorate',
                'value': 'GZ'
            },
            {
                'label': 'Ismailia Governorate',
                'value': 'IS'
            },
            {
                'label': 'Kafr el-Sheikh Governorate',
                'value': 'KFS'
            },
            {
                'label': 'Luxor Governorate',
                'value': 'LX'
            },
            {
                'label': 'Matrouh Governorate',
                'value': 'MT'
            },
            {
                'label': 'Minya Governorate',
                'value': 'MN'
            },
            {
                'label': 'Monufia Governorate',
                'value': 'MNF'
            },
            {
                'label': 'New Valley Governorate',
                'value': 'WAD'
            },
            {
                'label': 'North Sinai Governorate',
                'value': 'SIN'
            },
            {
                'label': 'Port Said Governorate',
                'value': 'PTS'
            },
            {
                'label': 'Qalyubia Governorate',
                'value': 'KB'
            },
            {
                'label': 'Qena Governorate',
                'value': 'KN'
            },
            {
                'label': 'Red Sea Governorate',
                'value': 'BA'
            },
            {
                'label': 'Sohag Governorate',
                'value': 'SHG'
            },
            {
                'label': 'South Sinai Governorate',
                'value': 'JS'
            },
            {
                'label': 'Suez Governorate',
                'value': 'SUZ'
            }
        ]
    },
    {
        'label': 'El Salvador',
        'iso3': 'SLV',
        'value': 'SV',
        'states': [
            {
                'label': 'Ahuachapán Department',
                'value': 'AH'
            },
            {
                'label': 'Cabañas Department',
                'value': 'CA'
            },
            {
                'label': 'Chalatenango Department',
                'value': 'CH'
            },
            {
                'label': 'Cuscatlán Department',
                'value': 'CU'
            },
            {
                'label': 'La Libertad Department',
                'value': 'LI'
            },
            {
                'label': 'La Paz Department',
                'value': 'PA'
            },
            {
                'label': 'La Unión Department',
                'value': 'UN'
            },
            {
                'label': 'Morazán Department',
                'value': 'MO'
            },
            {
                'label': 'San Miguel Department',
                'value': 'SM'
            },
            {
                'label': 'San Salvador Department',
                'value': 'SS'
            },
            {
                'label': 'San Vicente Department',
                'value': 'SV'
            },
            {
                'label': 'Santa Ana Department',
                'value': 'SA'
            },
            {
                'label': 'Sonsonate Department',
                'value': 'SO'
            },
            {
                'label': 'Usulután Department',
                'value': 'US'
            }
        ]
    },
    {
        'label': 'Equatorial Guinea',
        'iso3': 'GNQ',
        'value': 'GQ',
        'states': [
            {
                'label': 'Annobón Province',
                'value': 'AN'
            },
            {
                'label': 'Bioko Norte Province',
                'value': 'BN'
            },
            {
                'label': 'Bioko Sur Province',
                'value': 'BS'
            },
            {
                'label': 'Centro Sur Province',
                'value': 'CS'
            },
            {
                'label': 'Insular Region',
                'value': 'I'
            },
            {
                'label': 'Kié-Ntem Province',
                'value': 'KN'
            },
            {
                'label': 'Litoral Province',
                'value': 'LI'
            },
            {
                'label': 'Río Muni',
                'value': 'C'
            },
            {
                'label': 'Wele-Nzas Province',
                'value': 'WN'
            }
        ]
    },
    {
        'label': 'Eritrea',
        'iso3': 'ERI',
        'value': 'ER',
        'states': [
            {
                'label': 'Anseba Region',
                'value': 'AN'
            },
            {
                'label': 'Debub Region',
                'value': 'DU'
            },
            {
                'label': 'Gash-Barka Region',
                'value': 'GB'
            },
            {
                'label': 'Maekel Region',
                'value': 'MA'
            },
            {
                'label': 'Northern Red Sea Region',
                'value': 'SK'
            },
            {
                'label': 'Southern Red Sea Region',
                'value': 'DK'
            }
        ]
    },
    {
        'label': 'Estonia',
        'iso3': 'EST',
        'value': 'EE',
        'states': [
            {
                'label': 'Harju County',
                'value': '37'
            },
            {
                'label': 'Hiiu County',
                'value': '39'
            },
            {
                'label': 'Ida-Viru County',
                'value': '44'
            },
            {
                'label': 'Järva County',
                'value': '51'
            },
            {
                'label': 'Jõgeva County',
                'value': '49'
            },
            {
                'label': 'Lääne County',
                'value': '57'
            },
            {
                'label': 'Lääne-Viru County',
                'value': '59'
            },
            {
                'label': 'Pärnu County',
                'value': '67'
            },
            {
                'label': 'Põlva County',
                'value': '65'
            },
            {
                'label': 'Rapla County',
                'value': '70'
            },
            {
                'label': 'Saare County',
                'value': '74'
            },
            {
                'label': 'Tartu County',
                'value': '78'
            },
            {
                'label': 'Valga County',
                'value': '82'
            },
            {
                'label': 'Viljandi County',
                'value': '84'
            },
            {
                'label': 'Võru County',
                'value': '86'
            }
        ]
    },
    {
        'label': 'Ethiopia',
        'iso3': 'ETH',
        'value': 'ET',
        'states': [
            {
                'label': 'Addis Ababa',
                'value': 'AA'
            },
            {
                'label': 'Afar Region',
                'value': 'AF'
            },
            {
                'label': 'Amhara Region',
                'value': 'AM'
            },
            {
                'label': 'Benishangul-Gumuz Region',
                'value': 'BE'
            },
            {
                'label': 'Dire Dawa',
                'value': 'DD'
            },
            {
                'label': 'Gambela Region',
                'value': 'GA'
            },
            {
                'label': 'Harari Region',
                'value': 'HA'
            },
            {
                'label': 'Oromia Region',
                'value': 'OR'
            },
            {
                'label': 'Somali Region',
                'value': 'SO'
            },
            {
                'label': 'Southern Nations, Nationalities, and Peoples\' Region',
                'value': 'SN'
            },
            {
                'label': 'Tigray Region',
                'value': 'TI'
            }
        ]
    },
    {
        'label': 'Fiji Islands',
        'iso3': 'FJI',
        'value': 'FJ',
        'states': [
            {
                'label': 'Ba',
                'value': '01'
            },
            {
                'label': 'Bua',
                'value': '02'
            },
            {
                'label': 'Cakaudrove',
                'value': '03'
            },
            {
                'label': 'Central Division',
                'value': 'C'
            },
            {
                'label': 'Eastern Division',
                'value': 'E'
            },
            {
                'label': 'Kadavu',
                'value': '04'
            },
            {
                'label': 'Lau',
                'value': '05'
            },
            {
                'label': 'Lomaiviti',
                'value': '06'
            },
            {
                'label': 'Macuata',
                'value': '07'
            },
            {
                'label': 'Nadroga-Navosa',
                'value': '08'
            },
            {
                'label': 'Naitasiri',
                'value': '09'
            },
            {
                'label': 'Namosi',
                'value': '10'
            },
            {
                'label': 'Northern Division',
                'value': 'N'
            },
            {
                'label': 'Ra',
                'value': '11'
            },
            {
                'label': 'Rewa',
                'value': '12'
            },
            {
                'label': 'Rotuma',
                'value': 'R'
            },
            {
                'label': 'Serua',
                'value': '13'
            },
            {
                'label': 'Tailevu',
                'value': '14'
            },
            {
                'label': 'Western Division',
                'value': 'W'
            }
        ]
    },
    {
        'label': 'Finland',
        'iso3': 'FIN',
        'value': 'FI',
        'states': [
            {
                'label': 'Åland Islands',
                'value': '01'
            },
            {
                'label': 'Central Finland',
                'value': '08'
            },
            {
                'label': 'Central Ostrobothnia',
                'value': '07'
            },
            {
                'label': 'Eastern Finland Province',
                'value': 'IS'
            },
            {
                'label': 'Finland Proper',
                'value': '19'
            },
            {
                'label': 'Kainuu',
                'value': '05'
            },
            {
                'label': 'Kymenlaakso',
                'value': '09'
            },
            {
                'label': 'Lapland',
                'value': 'LL'
            },
            {
                'label': 'North Karelia',
                'value': '13'
            },
            {
                'label': 'Northern Ostrobothnia',
                'value': '14'
            },
            {
                'label': 'Northern Savonia',
                'value': '15'
            },
            {
                'label': 'Ostrobothnia',
                'value': '12'
            },
            {
                'label': 'Oulu Province',
                'value': 'OL'
            },
            {
                'label': 'Päijänne Tavastia',
                'value': '16'
            },
            {
                'label': 'Pirkanmaa',
                'value': '11'
            },
            {
                'label': 'Satakunta',
                'value': '17'
            },
            {
                'label': 'South Karelia',
                'value': '02'
            },
            {
                'label': 'Southern Ostrobothnia',
                'value': '03'
            },
            {
                'label': 'Southern Savonia',
                'value': '04'
            },
            {
                'label': 'Tavastia Proper',
                'value': '06'
            },
            {
                'label': 'Uusimaa',
                'value': '18'
            }
        ]
    },
    {
        'label': 'France',
        'iso3': 'FRA',
        'value': 'FR',
        'states': [
            {
                'label': 'Alo',
                'value': 'WF-AL'
            },
            {
                'label': 'Alsace',
                'value': 'A'
            },
            {
                'label': 'Aquitaine',
                'value': 'B'
            },
            {
                'label': 'Auvergne',
                'value': 'C'
            },
            {
                'label': 'Auvergne-Rhône-Alpes',
                'value': 'ARA'
            },
            {
                'label': 'Bourgogne-Franche-Comté',
                'value': 'BFC'
            },
            {
                'label': 'Brittany',
                'value': 'BRE'
            },
            {
                'label': 'Burgundy',
                'value': 'D'
            },
            {
                'label': 'Centre-Val de Loire',
                'value': 'CVL'
            },
            {
                'label': 'Champagne-Ardenne',
                'value': 'G'
            },
            {
                'label': 'Corsica',
                'value': 'COR'
            },
            {
                'label': 'Franche-Comté',
                'value': 'I'
            },
            {
                'label': 'French Guiana',
                'value': 'GF'
            },
            {
                'label': 'French Polynesia',
                'value': 'PF'
            },
            {
                'label': 'Grand Est',
                'value': 'GES'
            },
            {
                'label': 'Guadeloupe',
                'value': 'GP'
            },
            {
                'label': 'Hauts-de-France',
                'value': 'HDF'
            },
            {
                'label': 'Île-de-France',
                'value': 'IDF'
            },
            {
                'label': 'Languedoc-Roussillon',
                'value': 'K'
            },
            {
                'label': 'Limousin',
                'value': 'L'
            },
            {
                'label': 'Lorraine',
                'value': 'M'
            },
            {
                'label': 'Lower Normandy',
                'value': 'P'
            },
            {
                'label': 'Martinique',
                'value': 'MQ'
            },
            {
                'label': 'Mayotte',
                'value': 'YT'
            },
            {
                'label': 'Nord-Pas-de-Calais',
                'value': 'O'
            },
            {
                'label': 'Normandy',
                'value': 'NOR'
            },
            {
                'label': 'Nouvelle-Aquitaine',
                'value': 'NAQ'
            },
            {
                'label': 'Occitania',
                'value': 'OCC'
            },
            {
                'label': 'Paris',
                'value': '75'
            },
            {
                'label': 'Pays de la Loire',
                'value': 'PDL'
            },
            {
                'label': 'Picardy',
                'value': 'S'
            },
            {
                'label': 'Poitou-Charentes',
                'value': 'T'
            },
            {
                'label': 'Provence-Alpes-Côte d\'Azur',
                'value': 'PAC'
            },
            {
                'label': 'Réunion',
                'value': 'RE'
            },
            {
                'label': 'Rhône-Alpes',
                'value': 'V'
            },
            {
                'label': 'Saint Barthélemy',
                'value': 'BL'
            },
            {
                'label': 'Saint Martin',
                'value': 'MF'
            },
            {
                'label': 'Saint Pierre and Miquelon',
                'value': 'PM'
            },
            {
                'label': 'Sigave',
                'value': 'WF-SG'
            },
            {
                'label': 'Upper Normandy',
                'value': 'Q'
            },
            {
                'label': 'Uvea',
                'value': 'WF-UV'
            },
            {
                'label': 'Wallis and Futuna',
                'value': 'WF'
            }
        ]
    },
    {
        'label': 'Gabon',
        'iso3': 'GAB',
        'value': 'GA',
        'states': [
            {
                'label': 'Estuaire Province',
                'value': '1'
            },
            {
                'label': 'Haut-Ogooué Province',
                'value': '2'
            },
            {
                'label': 'Moyen-Ogooué Province',
                'value': '3'
            },
            {
                'label': 'Ngounié Province',
                'value': '4'
            },
            {
                'label': 'Nyanga Province',
                'value': '5'
            },
            {
                'label': 'Ogooué-Ivindo Province',
                'value': '6'
            },
            {
                'label': 'Ogooué-Lolo Province',
                'value': '7'
            },
            {
                'label': 'Ogooué-Maritime Province',
                'value': '8'
            },
            {
                'label': 'Woleu-Ntem Province',
                'value': '9'
            }
        ]
    },
    {
        'label': 'Gambia The',
        'iso3': 'GMB',
        'value': 'GM',
        'states': [
            {
                'label': 'Banjul',
                'value': 'B'
            },
            {
                'label': 'Central River Division',
                'value': 'M'
            },
            {
                'label': 'Lower River Division',
                'value': 'L'
            },
            {
                'label': 'North Bank Division',
                'value': 'N'
            },
            {
                'label': 'Upper River Division',
                'value': 'U'
            },
            {
                'label': 'West Coast Division',
                'value': 'W'
            }
        ]
    },
    {
        'label': 'Georgia',
        'iso3': 'GEO',
        'value': 'GE',
        'states': [
            {
                'label': 'Adjara',
                'value': 'AJ'
            },
            {
                'label': 'Autonomous Republic of Abkhazia',
                'value': 'AB'
            },
            {
                'label': 'Guria',
                'value': 'GU'
            },
            {
                'label': 'Imereti',
                'value': 'IM'
            },
            {
                'label': 'Kakheti',
                'value': 'KA'
            },
            {
                'label': 'Khelvachauri Municipality',
                'value': '29'
            },
            {
                'label': 'Kvemo Kartli',
                'value': 'KK'
            },
            {
                'label': 'Mtskheta-Mtianeti',
                'value': 'MM'
            },
            {
                'label': 'Racha-Lechkhumi and Kvemo Svaneti',
                'value': 'RL'
            },
            {
                'label': 'Samegrelo-Zemo Svaneti',
                'value': 'SZ'
            },
            {
                'label': 'Samtskhe-Javakheti',
                'value': 'SJ'
            },
            {
                'label': 'Senaki Municipality',
                'value': '50'
            },
            {
                'label': 'Shida Kartli',
                'value': 'SK'
            },
            {
                'label': 'Tbilisi',
                'value': 'TB'
            }
        ]
    },
    {
        'label': 'Germany',
        'iso3': 'DEU',
        'value': 'DE',
        'states': [
            {
                'label': 'Baden-Württemberg',
                'value': 'BW'
            },
            {
                'label': 'Bavaria',
                'value': 'BY'
            },
            {
                'label': 'Berlin',
                'value': 'BE'
            },
            {
                'label': 'Brandenburg',
                'value': 'BB'
            },
            {
                'label': 'Bremen',
                'value': 'HB'
            },
            {
                'label': 'Hamburg',
                'value': 'HH'
            },
            {
                'label': 'Hesse',
                'value': 'HE'
            },
            {
                'label': 'Lower Saxony',
                'value': 'NI'
            },
            {
                'label': 'Mecklenburg-Vorpommern',
                'value': 'MV'
            },
            {
                'label': 'North Rhine-Westphalia',
                'value': 'NW'
            },
            {
                'label': 'Rhineland-Palatinate',
                'value': 'RP'
            },
            {
                'label': 'Saarland',
                'value': 'SL'
            },
            {
                'label': 'Saxony',
                'value': 'SN'
            },
            {
                'label': 'Saxony-Anhalt',
                'value': 'ST'
            },
            {
                'label': 'Schleswig-Holstein',
                'value': 'SH'
            },
            {
                'label': 'Thuringia',
                'value': 'TH'
            }
        ]
    },
    {
        'label': 'Ghana',
        'iso3': 'GHA',
        'value': 'GH',
        'states': [
            {
                'label': 'Ashanti Region',
                'value': 'AH'
            },
            {
                'label': 'Brong-Ahafo Region',
                'value': 'BA'
            },
            {
                'label': 'Central Region',
                'value': 'CP'
            },
            {
                'label': 'Eastern Region',
                'value': 'EP'
            },
            {
                'label': 'Greater Accra Region',
                'value': 'AA'
            },
            {
                'label': 'Northern Region',
                'value': 'NP'
            },
            {
                'label': 'Upper East Region',
                'value': 'UE'
            },
            {
                'label': 'Upper West Region',
                'value': 'UW'
            },
            {
                'label': 'Volta Region',
                'value': 'TV'
            },
            {
                'label': 'Western Region',
                'value': 'WP'
            }
        ]
    },
    {
        'label': 'Greece',
        'iso3': 'GRC',
        'value': 'GR',
        'states': [
            {
                'label': 'Achaea Regional Unit',
                'value': '13'
            },
            {
                'label': 'Aetolia-Acarnania Regional Unit',
                'value': '01'
            },
            {
                'label': 'Arcadia Prefecture',
                'value': '12'
            },
            {
                'label': 'Argolis Regional Unit',
                'value': '11'
            },
            {
                'label': 'Attica Region',
                'value': 'I'
            },
            {
                'label': 'Boeotia Regional Unit',
                'value': '03'
            },
            {
                'label': 'Central Greece Region',
                'value': 'H'
            },
            {
                'label': 'Central Macedonia',
                'value': 'B'
            },
            {
                'label': 'Chania Regional Unit',
                'value': '94'
            },
            {
                'label': 'Corfu Prefecture',
                'value': '22'
            },
            {
                'label': 'Corinthia Regional Unit',
                'value': '15'
            },
            {
                'label': 'Crete Region',
                'value': 'M'
            },
            {
                'label': 'Drama Regional Unit',
                'value': '52'
            },
            {
                'label': 'East Attica Regional Unit',
                'value': 'A2'
            },
            {
                'label': 'East Macedonia and Thrace',
                'value': 'A'
            },
            {
                'label': 'Epirus Region',
                'value': 'D'
            },
            {
                'label': 'Euboea',
                'value': '04'
            },
            {
                'label': 'Grevena Prefecture',
                'value': '51'
            },
            {
                'label': 'Imathia Regional Unit',
                'value': '53'
            },
            {
                'label': 'Ioannina Regional Unit',
                'value': '33'
            },
            {
                'label': 'Ionian Islands Region',
                'value': 'F'
            },
            {
                'label': 'Karditsa Regional Unit',
                'value': '41'
            },
            {
                'label': 'Kastoria Regional Unit',
                'value': '56'
            },
            {
                'label': 'Kefalonia Prefecture',
                'value': '23'
            },
            {
                'label': 'Kilkis Regional Unit',
                'value': '57'
            },
            {
                'label': 'Kozani Prefecture',
                'value': '58'
            },
            {
                'label': 'Laconia',
                'value': '16'
            },
            {
                'label': 'Larissa Prefecture',
                'value': '42'
            },
            {
                'label': 'Lefkada Regional Unit',
                'value': '24'
            },
            {
                'label': 'Pella Regional Unit',
                'value': '59'
            },
            {
                'label': 'Peloponnese Region',
                'value': 'J'
            },
            {
                'label': 'Phthiotis Prefecture',
                'value': '06'
            },
            {
                'label': 'Preveza Prefecture',
                'value': '34'
            },
            {
                'label': 'Serres Prefecture',
                'value': '62'
            },
            {
                'label': 'South Aegean',
                'value': 'L'
            },
            {
                'label': 'Thessaloniki Regional Unit',
                'value': '54'
            },
            {
                'label': 'West Greece Region',
                'value': 'G'
            },
            {
                'label': 'West Macedonia Region',
                'value': 'C'
            }
        ]
    },
    {
        'label': 'Grenada',
        'iso3': 'GRD',
        'value': 'GD',
        'states': [
            {
                'label': 'Carriacou and Petite Martinique',
                'value': '10'
            },
            {
                'label': 'Saint Andrew Parish',
                'value': '01'
            },
            {
                'label': 'Saint David Parish',
                'value': '02'
            },
            {
                'label': 'Saint George Parish',
                'value': '03'
            },
            {
                'label': 'Saint John Parish',
                'value': '04'
            },
            {
                'label': 'Saint Mark Parish',
                'value': '05'
            },
            {
                'label': 'Saint Patrick Parish',
                'value': '06'
            }
        ]
    },
    {
        'label': 'Guatemala',
        'iso3': 'GTM',
        'value': 'GT',
        'states': [
            {
                'label': 'Alta Verapaz Department',
                'value': 'AV'
            },
            {
                'label': 'Baja Verapaz Department',
                'value': 'BV'
            },
            {
                'label': 'Chimaltenango Department',
                'value': 'CM'
            },
            {
                'label': 'Chiquimula Department',
                'value': 'CQ'
            },
            {
                'label': 'El Progreso Department',
                'value': 'PR'
            },
            {
                'label': 'Escuintla Department',
                'value': 'ES'
            },
            {
                'label': 'Guatemala Department',
                'value': 'GU'
            },
            {
                'label': 'Huehuetenango Department',
                'value': 'HU'
            },
            {
                'label': 'Izabal Department',
                'value': 'IZ'
            },
            {
                'label': 'Jalapa Department',
                'value': 'JA'
            },
            {
                'label': 'Jutiapa Department',
                'value': 'JU'
            },
            {
                'label': 'Petén Department',
                'value': 'PE'
            },
            {
                'label': 'Quetzaltenango Department',
                'value': 'QZ'
            },
            {
                'label': 'Quiché Department',
                'value': 'QC'
            },
            {
                'label': 'Retalhuleu Department',
                'value': 'RE'
            },
            {
                'label': 'Sacatepéquez Department',
                'value': 'SA'
            },
            {
                'label': 'San Marcos Department',
                'value': 'SM'
            },
            {
                'label': 'Santa Rosa Department',
                'value': 'SR'
            },
            {
                'label': 'Sololá Department',
                'value': 'SO'
            },
            {
                'label': 'Suchitepéquez Department',
                'value': 'SU'
            },
            {
                'label': 'Totonicapán Department',
                'value': 'TO'
            }
        ]
    },
    {
        'label': 'Guinea',
        'iso3': 'GIN',
        'value': 'GN',
        'states': [
            {
                'label': 'Beyla Prefecture',
                'value': 'BE'
            },
            {
                'label': 'Boffa Prefecture',
                'value': 'BF'
            },
            {
                'label': 'Boké Prefecture',
                'value': 'BK'
            },
            {
                'label': 'Boké Region',
                'value': 'B'
            },
            {
                'label': 'Conakry',
                'value': 'C'
            },
            {
                'label': 'Coyah Prefecture',
                'value': 'CO'
            },
            {
                'label': 'Dabola Prefecture',
                'value': 'DB'
            },
            {
                'label': 'Dalaba Prefecture',
                'value': 'DL'
            },
            {
                'label': 'Dinguiraye Prefecture',
                'value': 'DI'
            },
            {
                'label': 'Dubréka Prefecture',
                'value': 'DU'
            },
            {
                'label': 'Faranah Prefecture',
                'value': 'FA'
            },
            {
                'label': 'Forécariah Prefecture',
                'value': 'FO'
            },
            {
                'label': 'Fria Prefecture',
                'value': 'FR'
            },
            {
                'label': 'Gaoual Prefecture',
                'value': 'GA'
            },
            {
                'label': 'Guéckédou Prefecture',
                'value': 'GU'
            },
            {
                'label': 'Kankan Prefecture',
                'value': 'KA'
            },
            {
                'label': 'Kankan Region',
                'value': 'K'
            },
            {
                'label': 'Kérouané Prefecture',
                'value': 'KE'
            },
            {
                'label': 'Kindia Prefecture',
                'value': 'KD'
            },
            {
                'label': 'Kindia Region',
                'value': 'D'
            },
            {
                'label': 'Kissidougou Prefecture',
                'value': 'KS'
            },
            {
                'label': 'Koubia Prefecture',
                'value': 'KB'
            },
            {
                'label': 'Koundara Prefecture',
                'value': 'KN'
            },
            {
                'label': 'Kouroussa Prefecture',
                'value': 'KO'
            },
            {
                'label': 'Labé Prefecture',
                'value': 'LA'
            },
            {
                'label': 'Labé Region',
                'value': 'L'
            },
            {
                'label': 'Lélouma Prefecture',
                'value': 'LE'
            },
            {
                'label': 'Lola Prefecture',
                'value': 'LO'
            },
            {
                'label': 'Macenta Prefecture',
                'value': 'MC'
            },
            {
                'label': 'Mali Prefecture',
                'value': 'ML'
            },
            {
                'label': 'Mamou Prefecture',
                'value': 'MM'
            },
            {
                'label': 'Mamou Region',
                'value': 'M'
            },
            {
                'label': 'Mandiana Prefecture',
                'value': 'MD'
            },
            {
                'label': 'Nzérékoré Prefecture',
                'value': 'NZ'
            },
            {
                'label': 'Nzérékoré Region',
                'value': 'N'
            },
            {
                'label': 'Pita Prefecture',
                'value': 'PI'
            },
            {
                'label': 'Siguiri Prefecture',
                'value': 'SI'
            },
            {
                'label': 'Télimélé Prefecture',
                'value': 'TE'
            },
            {
                'label': 'Tougué Prefecture',
                'value': 'TO'
            },
            {
                'label': 'Yomou Prefecture',
                'value': 'YO'
            }
        ]
    },
    {
        'label': 'Guinea-Bissau',
        'iso3': 'GNB',
        'value': 'GW',
        'states': [
            {
                'label': 'Bafatá',
                'value': 'BA'
            },
            {
                'label': 'Biombo Region',
                'value': 'BM'
            },
            {
                'label': 'Bolama Region',
                'value': 'BL'
            },
            {
                'label': 'Cacheu Region',
                'value': 'CA'
            },
            {
                'label': 'Gabú Region',
                'value': 'GA'
            },
            {
                'label': 'Leste Province',
                'value': 'L'
            },
            {
                'label': 'Norte Province',
                'value': 'N'
            },
            {
                'label': 'Oio Region',
                'value': 'OI'
            },
            {
                'label': 'Quinara Region',
                'value': 'QU'
            },
            {
                'label': 'Sul Province',
                'value': 'S'
            },
            {
                'label': 'Tombali Region',
                'value': 'TO'
            }
        ]
    },
    {
        'label': 'Guyana',
        'iso3': 'GUY',
        'value': 'GY',
        'states': [
            {
                'label': 'Barima-Waini',
                'value': 'BA'
            },
            {
                'label': 'Cuyuni-Mazaruni',
                'value': 'CU'
            },
            {
                'label': 'Demerara-Mahaica',
                'value': 'DE'
            },
            {
                'label': 'East Berbice-Corentyne',
                'value': 'EB'
            },
            {
                'label': 'Essequibo Islands-West Demerara',
                'value': 'ES'
            },
            {
                'label': 'Mahaica-Berbice',
                'value': 'MA'
            },
            {
                'label': 'Pomeroon-Supenaam',
                'value': 'PM'
            },
            {
                'label': 'Potaro-Siparuni',
                'value': 'PT'
            },
            {
                'label': 'Upper Demerara-Berbice',
                'value': 'UD'
            },
            {
                'label': 'Upper Takutu-Upper Essequibo',
                'value': 'UT'
            }
        ]
    },
    {
        'label': 'Haiti',
        'iso3': 'HTI',
        'value': 'HT',
        'states': [
            {
                'label': 'Artibonite',
                'value': 'AR'
            },
            {
                'label': 'Centre',
                'value': 'CE'
            },
            {
                'label': 'Grand\'Anse',
                'value': 'GA'
            },
            {
                'label': 'Nippes',
                'value': 'NI'
            },
            {
                'label': 'Nord',
                'value': 'ND'
            },
            {
                'label': 'Nord-Est',
                'value': 'NE'
            },
            {
                'label': 'Nord-Ouest',
                'value': 'NO'
            },
            {
                'label': 'Ouest',
                'value': 'OU'
            },
            {
                'label': 'Sud',
                'value': 'SD'
            },
            {
                'label': 'Sud-Est',
                'value': 'SE'
            }
        ]
    },
    {
        'label': 'Honduras',
        'iso3': 'HND',
        'value': 'HN',
        'states': [
            {
                'label': 'Atlántida Department',
                'value': 'AT'
            },
            {
                'label': 'Bay Islands Department',
                'value': 'IB'
            },
            {
                'label': 'Choluteca Department',
                'value': 'CH'
            },
            {
                'label': 'Colón Department',
                'value': 'CL'
            },
            {
                'label': 'Comayagua Department',
                'value': 'CM'
            },
            {
                'label': 'Copán Department',
                'value': 'CP'
            },
            {
                'label': 'Cortés Department',
                'value': 'CR'
            },
            {
                'label': 'El Paraíso Department',
                'value': 'EP'
            },
            {
                'label': 'Francisco Morazán Department',
                'value': 'FM'
            },
            {
                'label': 'Gracias a Dios Department',
                'value': 'GD'
            },
            {
                'label': 'Intibucá Department',
                'value': 'IN'
            },
            {
                'label': 'La Paz Department',
                'value': 'LP'
            },
            {
                'label': 'Lempira Department',
                'value': 'LE'
            },
            {
                'label': 'Ocotepeque Department',
                'value': 'OC'
            },
            {
                'label': 'Olancho Department',
                'value': 'OL'
            },
            {
                'label': 'Santa Bárbara Department',
                'value': 'SB'
            },
            {
                'label': 'Valle Department',
                'value': 'VA'
            },
            {
                'label': 'Yoro Department',
                'value': 'YO'
            }
        ]
    },
    {
        'label': 'Hungary',
        'iso3': 'HUN',
        'value': 'HU',
        'states': [
            {
                'label': 'Bács-Kiskun County',
                'value': 'BK'
            },
            {
                'label': 'Baranya County',
                'value': 'BA'
            },
            {
                'label': 'Békés County',
                'value': 'BE'
            },
            {
                'label': 'Békéscsaba',
                'value': 'BC'
            },
            {
                'label': 'Borsod-Abaúj-Zemplén County',
                'value': 'BZ'
            },
            {
                'label': 'Budapest',
                'value': 'BU'
            },
            {
                'label': 'Csongrád County',
                'value': 'CS'
            },
            {
                'label': 'Debrecen',
                'value': 'DE'
            },
            {
                'label': 'Dunaújváros',
                'value': 'DU'
            },
            {
                'label': 'Eger',
                'value': 'EG'
            },
            {
                'label': 'Érd',
                'value': 'ER'
            },
            {
                'label': 'Fejér County',
                'value': 'FE'
            },
            {
                'label': 'Győr',
                'value': 'GY'
            },
            {
                'label': 'Győr-Moson-Sopron County',
                'value': 'GS'
            },
            {
                'label': 'Hajdú-Bihar County',
                'value': 'HB'
            },
            {
                'label': 'Heves County',
                'value': 'HE'
            },
            {
                'label': 'Hódmezővásárhely',
                'value': 'HV'
            },
            {
                'label': 'Jász-Nagykun-Szolnok County',
                'value': 'JN'
            },
            {
                'label': 'Kaposvár',
                'value': 'KV'
            },
            {
                'label': 'Kecskemét',
                'value': 'KM'
            },
            {
                'label': 'Miskolc',
                'value': 'MI'
            },
            {
                'label': 'Nagykanizsa',
                'value': 'NK'
            },
            {
                'label': 'Nógrád County',
                'value': 'NO'
            },
            {
                'label': 'Nyíregyháza',
                'value': 'NY'
            },
            {
                'label': 'Pécs',
                'value': 'PS'
            },
            {
                'label': 'Pest County',
                'value': 'PE'
            },
            {
                'label': 'Salgótarján',
                'value': 'ST'
            },
            {
                'label': 'Somogy County',
                'value': 'SO'
            },
            {
                'label': 'Sopron',
                'value': 'SN'
            },
            {
                'label': 'Szabolcs-Szatmár-Bereg County',
                'value': 'SZ'
            },
            {
                'label': 'Szeged',
                'value': 'SD'
            },
            {
                'label': 'Székesfehérvár',
                'value': 'SF'
            },
            {
                'label': 'Szekszárd',
                'value': 'SS'
            },
            {
                'label': 'Szolnok',
                'value': 'SK'
            },
            {
                'label': 'Szombathely',
                'value': 'SH'
            },
            {
                'label': 'Tatabánya',
                'value': 'TB'
            },
            {
                'label': 'Tolna County',
                'value': 'TO'
            },
            {
                'label': 'Vas County',
                'value': 'VA'
            },
            {
                'label': 'Veszprém',
                'value': 'VM'
            },
            {
                'label': 'Veszprém County',
                'value': 'VE'
            },
            {
                'label': 'Zala County',
                'value': 'ZA'
            },
            {
                'label': 'Zalaegerszeg',
                'value': 'ZE'
            }
        ]
    },
    {
        'label': 'Iceland',
        'iso3': 'ISL',
        'value': 'IS',
        'states': [
            {
                'label': 'Capital Region',
                'value': '1'
            },
            {
                'label': 'Eastern Region',
                'value': '7'
            },
            {
                'label': 'Northeastern Region',
                'value': '6'
            },
            {
                'label': 'Northwestern Region',
                'value': '5'
            },
            {
                'label': 'Southern Peninsula Region',
                'value': '2'
            },
            {
                'label': 'Southern Region',
                'value': '8'
            },
            {
                'label': 'Western Region',
                'value': '3'
            },
            {
                'label': 'Westfjords',
                'value': '4'
            }
        ]
    },
    {
        'label': 'India',
        'iso3': 'IND',
        'value': 'IN',
        'states': [
            {
                'label': 'Andaman and Nicobar Islands',
                'value': 'AN'
            },
            {
                'label': 'Andhra Pradesh',
                'value': 'AP'
            },
            {
                'label': 'Arunachal Pradesh',
                'value': 'AR'
            },
            {
                'label': 'Assam',
                'value': 'AS'
            },
            {
                'label': 'Bihar',
                'value': 'BR'
            },
            {
                'label': 'Chandigarh',
                'value': 'CH'
            },
            {
                'label': 'Chhattisgarh',
                'value': 'CT'
            },
            {
                'label': 'Dadra and Nagar Haveli',
                'value': 'DN'
            },
            {
                'label': 'Daman and Diu',
                'value': 'DD'
            },
            {
                'label': 'Delhi',
                'value': 'DL'
            },
            {
                'label': 'Goa',
                'value': 'GA'
            },
            {
                'label': 'Gujarat',
                'value': 'GJ'
            },
            {
                'label': 'Haryana',
                'value': 'HR'
            },
            {
                'label': 'Himachal Pradesh',
                'value': 'HP'
            },
            {
                'label': 'Jammu and Kashmir',
                'value': 'JK'
            },
            {
                'label': 'Jharkhand',
                'value': 'JH'
            },
            {
                'label': 'Karnataka',
                'value': 'KA'
            },
            {
                'label': 'Kerala',
                'value': 'KL'
            },
            {
                'label': 'Ladakh',
                'value': 'LA'
            },
            {
                'label': 'Lakshadweep',
                'value': 'LD'
            },
            {
                'label': 'Madhya Pradesh',
                'value': 'MP'
            },
            {
                'label': 'Maharashtra',
                'value': 'MH'
            },
            {
                'label': 'Manipur',
                'value': 'MN'
            },
            {
                'label': 'Meghalaya',
                'value': 'ML'
            },
            {
                'label': 'Mizoram',
                'value': 'MZ'
            },
            {
                'label': 'Nagaland',
                'value': 'NL'
            },
            {
                'label': 'Odisha',
                'value': 'OR'
            },
            {
                'label': 'Puducherry',
                'value': 'PY'
            },
            {
                'label': 'Punjab',
                'value': 'PB'
            },
            {
                'label': 'Rajasthan',
                'value': 'RJ'
            },
            {
                'label': 'Sikkim',
                'value': 'SK'
            },
            {
                'label': 'Tamil Nadu',
                'value': 'TN'
            },
            {
                'label': 'Telangana',
                'value': 'TG'
            },
            {
                'label': 'Tripura',
                'value': 'TR'
            },
            {
                'label': 'Uttar Pradesh',
                'value': 'UP'
            },
            {
                'label': 'Uttarakhand',
                'value': 'UT'
            },
            {
                'label': 'West Bengal',
                'value': 'WB'
            }
        ]
    },
    {
        'label': 'Indonesia',
        'iso3': 'IDN',
        'value': 'ID',
        'states': [
            {
                'label': 'Aceh',
                'value': 'AC'
            },
            {
                'label': 'Bali',
                'value': 'BA'
            },
            {
                'label': 'Bangka Belitung Islands',
                'value': 'BB'
            },
            {
                'label': 'Banten',
                'value': 'BT'
            },
            {
                'label': 'Bengkulu',
                'value': 'BE'
            },
            {
                'label': 'Central Java',
                'value': 'JT'
            },
            {
                'label': 'Central Kalimantan',
                'value': 'KT'
            },
            {
                'label': 'Central Sulawesi',
                'value': 'ST'
            },
            {
                'label': 'East Java',
                'value': 'JI'
            },
            {
                'label': 'East Kalimantan',
                'value': 'KI'
            },
            {
                'label': 'East Nusa Tenggara',
                'value': 'NT'
            },
            {
                'label': 'Gorontalo',
                'value': 'GO'
            },
            {
                'label': 'Jakarta',
                'value': 'JK'
            },
            {
                'label': 'Jambi',
                'value': 'JA'
            },
            {
                'label': 'Kalimantan',
                'value': 'KA'
            },
            {
                'label': 'Lampung',
                'value': 'LA'
            },
            {
                'label': 'Lesser Sunda Islands',
                'value': 'NU'
            },
            {
                'label': 'Maluku',
                'value': 'MA'
            },
            {
                'label': 'Maluku Islands',
                'value': 'ML'
            },
            {
                'label': 'North Kalimantan',
                'value': 'KU'
            },
            {
                'label': 'North Maluku',
                'value': 'MU'
            },
            {
                'label': 'North Sulawesi',
                'value': 'SA'
            },
            {
                'label': 'North Sumatra',
                'value': 'SU'
            },
            {
                'label': 'Papua',
                'value': 'PA'
            },
            {
                'label': 'Riau',
                'value': 'RI'
            },
            {
                'label': 'Riau Islands',
                'value': 'KR'
            },
            {
                'label': 'South Kalimantan',
                'value': 'KS'
            },
            {
                'label': 'South Sulawesi',
                'value': 'SN'
            },
            {
                'label': 'South Sumatra',
                'value': 'SS'
            },
            {
                'label': 'Southeast Sulawesi',
                'value': 'SG'
            },
            {
                'label': 'Special Region of Yogyakarta',
                'value': 'YO'
            },
            {
                'label': 'Sulawesi',
                'value': 'SL'
            },
            {
                'label': 'Sumatra',
                'value': 'SM'
            },
            {
                'label': 'West Java',
                'value': 'JB'
            },
            {
                'label': 'West Nusa Tenggara',
                'value': 'NB'
            },
            {
                'label': 'West Papua',
                'value': 'PB'
            },
            {
                'label': 'West Sulawesi',
                'value': 'SR'
            },
            {
                'label': 'West Sumatra',
                'value': 'SB'
            }
        ]
    },
    {
        'label': 'Iran',
        'iso3': 'IRN',
        'value': 'IR',
        'states': [
            {
                'label': 'Alborz Province',
                'value': '32'
            },
            {
                'label': 'Ardabil Province',
                'value': '03'
            },
            {
                'label': 'Bushehr Province',
                'value': '06'
            },
            {
                'label': 'Chaharmahal and Bakhtiari Province',
                'value': '08'
            },
            {
                'label': 'East Azerbaijan Province',
                'value': '01'
            },
            {
                'label': 'Fars Province',
                'value': '14'
            },
            {
                'label': 'Gilan Province',
                'value': '19'
            },
            {
                'label': 'Golestan Province',
                'value': '27'
            },
            {
                'label': 'Hormozgan Province',
                'value': '23'
            },
            {
                'label': 'Ilam Province',
                'value': '05'
            },
            {
                'label': 'Isfahan Province',
                'value': '04'
            },
            {
                'label': 'Kerman Province',
                'value': '15'
            },
            {
                'label': 'Kermanshah Province',
                'value': '17'
            },
            {
                'label': 'Khuzestan Province',
                'value': '10'
            },
            {
                'label': 'Kohgiluyeh and Boyer-Ahmad Province',
                'value': '18'
            },
            {
                'label': 'Kurdistan Province',
                'value': '16'
            },
            {
                'label': 'Lorestan Province',
                'value': '20'
            },
            {
                'label': 'Markazi Province',
                'value': '22'
            },
            {
                'label': 'Mazandaran Province',
                'value': '21'
            },
            {
                'label': 'North Khorasan Province',
                'value': '31'
            },
            {
                'label': 'Qazvin Province',
                'value': '28'
            },
            {
                'label': 'Qom Province',
                'value': '26'
            },
            {
                'label': 'Razavi Khorasan Province',
                'value': '30'
            },
            {
                'label': 'Semnan Province',
                'value': '12'
            },
            {
                'label': 'Sistan and Baluchestan',
                'value': '13'
            },
            {
                'label': 'South Khorasan Province',
                'value': '29'
            },
            {
                'label': 'Tehran Province',
                'value': '07'
            },
            {
                'label': 'West Azarbaijan Province',
                'value': '02'
            },
            {
                'label': 'Yazd Province',
                'value': '25'
            },
            {
                'label': 'Zanjan Province',
                'value': '11'
            }
        ]
    },
    {
        'label': 'Iraq',
        'iso3': 'IRQ',
        'value': 'IQ',
        'states': [
            {
                'label': 'Al Anbar Governorate',
                'value': 'AN'
            },
            {
                'label': 'Al Muthanna Governorate',
                'value': 'MU'
            },
            {
                'label': 'Al-Qādisiyyah Governorate',
                'value': 'QA'
            },
            {
                'label': 'Babylon Governorate',
                'value': 'BB'
            },
            {
                'label': 'Baghdad Governorate',
                'value': 'BG'
            },
            {
                'label': 'Basra Governorate',
                'value': 'BA'
            },
            {
                'label': 'Dhi Qar Governorate',
                'value': 'DQ'
            },
            {
                'label': 'Diyala Governorate',
                'value': 'DI'
            },
            {
                'label': 'Dohuk Governorate',
                'value': 'DA'
            },
            {
                'label': 'Erbil Governorate',
                'value': 'AR'
            },
            {
                'label': 'Karbala Governorate',
                'value': 'KA'
            },
            {
                'label': 'Kirkuk Governorate',
                'value': 'KI'
            },
            {
                'label': 'Maysan Governorate',
                'value': 'MA'
            },
            {
                'label': 'Najaf Governorate',
                'value': 'NA'
            },
            {
                'label': 'Nineveh Governorate',
                'value': 'NI'
            },
            {
                'label': 'Saladin Governorate',
                'value': 'SD'
            },
            {
                'label': 'Sulaymaniyah Governorate',
                'value': 'SU'
            },
            {
                'label': 'Wasit Governorate',
                'value': 'WA'
            }
        ]
    },
    {
        'label': 'Ireland',
        'iso3': 'IRL',
        'value': 'IE',
        'states': [
            {
                'label': 'Connacht',
                'value': 'C'
            },
            {
                'label': 'County Carlow',
                'value': 'CW'
            },
            {
                'label': 'County Cavan',
                'value': 'CN'
            },
            {
                'label': 'County Clare',
                'value': 'CE'
            },
            {
                'label': 'County Cork',
                'value': 'CO'
            },
            {
                'label': 'County Donegal',
                'value': 'DL'
            },
            {
                'label': 'County Dublin',
                'value': 'D'
            },
            {
                'label': 'County Galway',
                'value': 'G'
            },
            {
                'label': 'County Kerry',
                'value': 'KY'
            },
            {
                'label': 'County Kildare',
                'value': 'KE'
            },
            {
                'label': 'County Kilkenny',
                'value': 'KK'
            },
            {
                'label': 'County Laois',
                'value': 'LS'
            },
            {
                'label': 'County Limerick',
                'value': 'LK'
            },
            {
                'label': 'County Longford',
                'value': 'LD'
            },
            {
                'label': 'County Louth',
                'value': 'LH'
            },
            {
                'label': 'County Mayo',
                'value': 'MO'
            },
            {
                'label': 'County Meath',
                'value': 'MH'
            },
            {
                'label': 'County Monaghan',
                'value': 'MN'
            },
            {
                'label': 'County Offaly',
                'value': 'OY'
            },
            {
                'label': 'County Roscommon',
                'value': 'RN'
            },
            {
                'label': 'County Sligo',
                'value': 'SO'
            },
            {
                'label': 'County Tipperary',
                'value': 'TA'
            },
            {
                'label': 'County Waterford',
                'value': 'WD'
            },
            {
                'label': 'County Westmeath',
                'value': 'WH'
            },
            {
                'label': 'County Wexford',
                'value': 'WX'
            },
            {
                'label': 'County Wicklow',
                'value': 'WW'
            },
            {
                'label': 'Leinster',
                'value': 'L'
            },
            {
                'label': 'Munster',
                'value': 'M'
            },
            {
                'label': 'Ulster',
                'value': 'U'
            }
        ]
    },
    {
        'label': 'Israel',
        'iso3': 'ISR',
        'value': 'IL',
        'states': [
            {
                'label': 'Central District',
                'value': 'M'
            },
            {
                'label': 'Haifa District',
                'value': 'HA'
            },
            {
                'label': 'Jerusalem District',
                'value': 'JM'
            },
            {
                'label': 'Northern District',
                'value': 'Z'
            },
            {
                'label': 'Southern District',
                'value': 'D'
            },
            {
                'label': 'Tel Aviv District',
                'value': 'TA'
            }
        ]
    },
    {
        'label': 'Italy',
        'iso3': 'ITA',
        'value': 'IT',
        'states': [
            {
                'label': 'Abruzzo',
                'value': '65'
            },
            {
                'label': 'Aosta Valley',
                'value': '23'
            },
            {
                'label': 'Apulia',
                'value': '75'
            },
            {
                'label': 'Basilicata',
                'value': '77'
            },
            {
                'label': 'Benevento Province',
                'value': 'BN'
            },
            {
                'label': 'Calabria',
                'value': '78'
            },
            {
                'label': 'Campania',
                'value': '72'
            },
            {
                'label': 'Emilia-Romagna',
                'value': '45'
            },
            {
                'label': 'Friuli–Venezia Giulia',
                'value': '36'
            },
            {
                'label': 'Lazio',
                'value': '62'
            },
            {
                'label': 'Libero consorzio comunale di Agrigento',
                'value': 'AG'
            },
            {
                'label': 'Libero consorzio comunale di Caltanissetta',
                'value': 'CL'
            },
            {
                'label': 'Libero consorzio comunale di Enna',
                'value': 'EN'
            },
            {
                'label': 'Libero consorzio comunale di Ragusa',
                'value': 'RG'
            },
            {
                'label': 'Libero consorzio comunale di Siracusa',
                'value': 'SR'
            },
            {
                'label': 'Libero consorzio comunale di Trapani',
                'value': 'TP'
            },
            {
                'label': 'Liguria',
                'value': '42'
            },
            {
                'label': 'Lombardy',
                'value': '25'
            },
            {
                'label': 'Marche',
                'value': '57'
            },
            {
                'label': 'Metropolitan City of Bari',
                'value': 'BA'
            },
            {
                'label': 'Metropolitan City of Bologna',
                'value': 'BO'
            },
            {
                'label': 'Metropolitan City of Cagliari',
                'value': 'CA'
            },
            {
                'label': 'Metropolitan City of Catania',
                'value': 'CT'
            },
            {
                'label': 'Metropolitan City of Florence',
                'value': 'FI'
            },
            {
                'label': 'Metropolitan City of Genoa',
                'value': 'GE'
            },
            {
                'label': 'Metropolitan City of Messina',
                'value': 'ME'
            },
            {
                'label': 'Metropolitan City of Milan',
                'value': 'MI'
            },
            {
                'label': 'Metropolitan City of Naples',
                'value': 'NA'
            },
            {
                'label': 'Metropolitan City of Palermo',
                'value': 'PA'
            },
            {
                'label': 'Metropolitan City of Reggio Calabria',
                'value': 'RC'
            },
            {
                'label': 'Metropolitan City of Rome',
                'value': 'RM'
            },
            {
                'label': 'Metropolitan City of Turin',
                'value': 'TO'
            },
            {
                'label': 'Metropolitan City of Venice',
                'value': 'VE'
            },
            {
                'label': 'Molise',
                'value': '67'
            },
            {
                'label': 'Pesaro and Urbino Province',
                'value': 'PU'
            },
            {
                'label': 'Piedmont',
                'value': '21'
            },
            {
                'label': 'Province of Alessandria',
                'value': 'AL'
            },
            {
                'label': 'Province of Ancona',
                'value': 'AN'
            },
            {
                'label': 'Province of Ascoli Piceno',
                'value': 'AP'
            },
            {
                'label': 'Province of Asti',
                'value': 'AT'
            },
            {
                'label': 'Province of Avellino',
                'value': 'AV'
            },
            {
                'label': 'Province of Barletta-Andria-Trani',
                'value': 'BT'
            },
            {
                'label': 'Province of Belluno',
                'value': 'BL'
            },
            {
                'label': 'Province of Bergamo',
                'value': 'BG'
            },
            {
                'label': 'Province of Biella',
                'value': 'BI'
            },
            {
                'label': 'Province of Brescia',
                'value': 'BS'
            },
            {
                'label': 'Province of Brindisi',
                'value': 'BR'
            },
            {
                'label': 'Province of Campobasso',
                'value': 'CB'
            },
            {
                'label': 'Province of Carbonia-Iglesias',
                'value': 'CI'
            },
            {
                'label': 'Province of Caserta',
                'value': 'CE'
            },
            {
                'label': 'Province of Catanzaro',
                'value': 'CZ'
            },
            {
                'label': 'Province of Chieti',
                'value': 'CH'
            },
            {
                'label': 'Province of Como',
                'value': 'CO'
            },
            {
                'label': 'Province of Cosenza',
                'value': 'CS'
            },
            {
                'label': 'Province of Cremona',
                'value': 'CR'
            },
            {
                'label': 'Province of Crotone',
                'value': 'KR'
            },
            {
                'label': 'Province of Cuneo',
                'value': 'CN'
            },
            {
                'label': 'Province of Fermo',
                'value': 'FM'
            },
            {
                'label': 'Province of Ferrara',
                'value': 'FE'
            },
            {
                'label': 'Province of Foggia',
                'value': 'FG'
            },
            {
                'label': 'Province of Forlì-Cesena',
                'value': 'FC'
            },
            {
                'label': 'Province of Frosinone',
                'value': 'FR'
            },
            {
                'label': 'Province of Gorizia',
                'value': 'GO'
            },
            {
                'label': 'Province of Grosseto',
                'value': 'GR'
            },
            {
                'label': 'Province of Imperia',
                'value': 'IM'
            },
            {
                'label': 'Province of Isernia',
                'value': 'IS'
            },
            {
                'label': 'Province of L\'Aquila',
                'value': 'AQ'
            },
            {
                'label': 'Province of La Spezia',
                'value': 'SP'
            },
            {
                'label': 'Province of Latina',
                'value': 'LT'
            },
            {
                'label': 'Province of Lecce',
                'value': 'LE'
            },
            {
                'label': 'Province of Lecco',
                'value': 'LC'
            },
            {
                'label': 'Province of Livorno',
                'value': 'LI'
            },
            {
                'label': 'Province of Lodi',
                'value': 'LO'
            },
            {
                'label': 'Province of Lucca',
                'value': 'LU'
            },
            {
                'label': 'Province of Macerata',
                'value': 'MC'
            },
            {
                'label': 'Province of Mantua',
                'value': 'MN'
            },
            {
                'label': 'Province of Massa and Carrara',
                'value': 'MS'
            },
            {
                'label': 'Province of Matera',
                'value': 'MT'
            },
            {
                'label': 'Province of Medio Campidano',
                'value': 'VS'
            },
            {
                'label': 'Province of Modena',
                'value': 'MO'
            },
            {
                'label': 'Province of Monza and Brianza',
                'value': 'MB'
            },
            {
                'label': 'Province of Novara',
                'value': 'NO'
            },
            {
                'label': 'Province of Nuoro',
                'value': 'NU'
            },
            {
                'label': 'Province of Ogliastra',
                'value': 'OG'
            },
            {
                'label': 'Province of Olbia-Tempio',
                'value': 'OT'
            },
            {
                'label': 'Province of Oristano',
                'value': 'OR'
            },
            {
                'label': 'Province of Padua',
                'value': 'PD'
            },
            {
                'label': 'Province of Parma',
                'value': 'PR'
            },
            {
                'label': 'Province of Pavia',
                'value': 'PV'
            },
            {
                'label': 'Province of Perugia',
                'value': 'PG'
            },
            {
                'label': 'Province of Pescara',
                'value': 'PE'
            },
            {
                'label': 'Province of Piacenza',
                'value': 'PC'
            },
            {
                'label': 'Province of Pisa',
                'value': 'PI'
            },
            {
                'label': 'Province of Pistoia',
                'value': 'PT'
            },
            {
                'label': 'Province of Pordenone',
                'value': 'PN'
            },
            {
                'label': 'Province of Potenza',
                'value': 'PZ'
            },
            {
                'label': 'Province of Prato',
                'value': 'PO'
            },
            {
                'label': 'Province of Ravenna',
                'value': 'RA'
            },
            {
                'label': 'Province of Reggio Emilia',
                'value': 'RE'
            },
            {
                'label': 'Province of Rieti',
                'value': 'RI'
            },
            {
                'label': 'Province of Rimini',
                'value': 'RN'
            },
            {
                'label': 'Province of Rovigo',
                'value': 'RO'
            },
            {
                'label': 'Province of Salerno',
                'value': 'SA'
            },
            {
                'label': 'Province of Sassari',
                'value': 'SS'
            },
            {
                'label': 'Province of Savona',
                'value': 'SV'
            },
            {
                'label': 'Province of Siena',
                'value': 'SI'
            },
            {
                'label': 'Province of Sondrio',
                'value': 'SO'
            },
            {
                'label': 'Province of Taranto',
                'value': 'TA'
            },
            {
                'label': 'Province of Teramo',
                'value': 'TE'
            },
            {
                'label': 'Province of Terni',
                'value': 'TR'
            },
            {
                'label': 'Province of Treviso',
                'value': 'TV'
            },
            {
                'label': 'Province of Trieste',
                'value': 'TS'
            },
            {
                'label': 'Province of Udine',
                'value': 'UD'
            },
            {
                'label': 'Province of Varese',
                'value': 'VA'
            },
            {
                'label': 'Province of Verbano-Cusio-Ossola',
                'value': 'VB'
            },
            {
                'label': 'Province of Vercelli',
                'value': 'VC'
            },
            {
                'label': 'Province of Verona',
                'value': 'VR'
            },
            {
                'label': 'Province of Vibo Valentia',
                'value': 'VV'
            },
            {
                'label': 'Province of Vicenza',
                'value': 'VI'
            },
            {
                'label': 'Province of Viterbo',
                'value': 'VT'
            },
            {
                'label': 'Sardinia',
                'value': '88'
            },
            {
                'label': 'Sicily',
                'value': '82'
            },
            {
                'label': 'South Tyrol',
                'value': 'BZ'
            },
            {
                'label': 'Trentino',
                'value': 'TN'
            },
            {
                'label': 'Trentino-South Tyrol',
                'value': '32'
            },
            {
                'label': 'Tuscany',
                'value': '52'
            },
            {
                'label': 'Umbria',
                'value': '55'
            },
            {
                'label': 'Veneto',
                'value': '34'
            }
        ]
    },
    {
        'label': 'Jamaica',
        'iso3': 'JAM',
        'value': 'JM',
        'states': [
            {
                'label': 'Clarendon Parish',
                'value': '13'
            },
            {
                'label': 'Hanover Parish',
                'value': '09'
            },
            {
                'label': 'Kingston Parish',
                'value': '01'
            },
            {
                'label': 'Manchester Parish',
                'value': '12'
            },
            {
                'label': 'Portland Parish',
                'value': '04'
            },
            {
                'label': 'Saint Andrew',
                'value': '02'
            },
            {
                'label': 'Saint Ann Parish',
                'value': '06'
            },
            {
                'label': 'Saint Catherine Parish',
                'value': '14'
            },
            {
                'label': 'Saint Elizabeth Parish',
                'value': '11'
            },
            {
                'label': 'Saint James Parish',
                'value': '08'
            },
            {
                'label': 'Saint Mary Parish',
                'value': '05'
            },
            {
                'label': 'Saint Thomas Parish',
                'value': '03'
            },
            {
                'label': 'Trelawny Parish',
                'value': '07'
            },
            {
                'label': 'Westmoreland Parish',
                'value': '10'
            }
        ]
    },
    {
        'label': 'Japan',
        'iso3': 'JPN',
        'value': 'JP',
        'states': [
            {
                'label': 'Aichi Prefecture',
                'value': '23'
            },
            {
                'label': 'Akita Prefecture',
                'value': '05'
            },
            {
                'label': 'Aomori Prefecture',
                'value': '02'
            },
            {
                'label': 'Chiba Prefecture',
                'value': '12'
            },
            {
                'label': 'Ehime Prefecture',
                'value': '38'
            },
            {
                'label': 'Fukui Prefecture',
                'value': '18'
            },
            {
                'label': 'Fukuoka Prefecture',
                'value': '40'
            },
            {
                'label': 'Fukushima Prefecture',
                'value': '07'
            },
            {
                'label': 'Gifu Prefecture',
                'value': '21'
            },
            {
                'label': 'Gunma Prefecture',
                'value': '10'
            },
            {
                'label': 'Hiroshima Prefecture',
                'value': '34'
            },
            {
                'label': 'Hokkaidō Prefecture',
                'value': '01'
            },
            {
                'label': 'Hyōgo Prefecture',
                'value': '28'
            },
            {
                'label': 'Ibaraki Prefecture',
                'value': '08'
            },
            {
                'label': 'Ishikawa Prefecture',
                'value': '17'
            },
            {
                'label': 'Iwate Prefecture',
                'value': '03'
            },
            {
                'label': 'Kagawa Prefecture',
                'value': '37'
            },
            {
                'label': 'Kagoshima Prefecture',
                'value': '46'
            },
            {
                'label': 'Kanagawa Prefecture',
                'value': '14'
            },
            {
                'label': 'Kumamoto Prefecture',
                'value': '43'
            },
            {
                'label': 'Kyōto Prefecture',
                'value': '26'
            },
            {
                'label': 'Mie Prefecture',
                'value': '24'
            },
            {
                'label': 'Miyagi Prefecture',
                'value': '04'
            },
            {
                'label': 'Miyazaki Prefecture',
                'value': '45'
            },
            {
                'label': 'Nagano Prefecture',
                'value': '20'
            },
            {
                'label': 'Nagasaki Prefecture',
                'value': '42'
            },
            {
                'label': 'Nara Prefecture',
                'value': '29'
            },
            {
                'label': 'Niigata Prefecture',
                'value': '15'
            },
            {
                'label': 'Ōita Prefecture',
                'value': '44'
            },
            {
                'label': 'Okayama Prefecture',
                'value': '33'
            },
            {
                'label': 'Okinawa Prefecture',
                'value': '47'
            },
            {
                'label': 'Ōsaka Prefecture',
                'value': '27'
            },
            {
                'label': 'Saga Prefecture',
                'value': '41'
            },
            {
                'label': 'Saitama Prefecture',
                'value': '11'
            },
            {
                'label': 'Shiga Prefecture',
                'value': '25'
            },
            {
                'label': 'Shimane Prefecture',
                'value': '32'
            },
            {
                'label': 'Shizuoka Prefecture',
                'value': '22'
            },
            {
                'label': 'Tochigi Prefecture',
                'value': '09'
            },
            {
                'label': 'Tokushima Prefecture',
                'value': '36'
            },
            {
                'label': 'Tokyo',
                'value': '13'
            },
            {
                'label': 'Tottori Prefecture',
                'value': '31'
            },
            {
                'label': 'Toyama Prefecture',
                'value': '16'
            },
            {
                'label': 'Wakayama Prefecture',
                'value': '30'
            },
            {
                'label': 'Yamagata Prefecture',
                'value': '06'
            },
            {
                'label': 'Yamaguchi Prefecture',
                'value': '35'
            },
            {
                'label': 'Yamanashi Prefecture',
                'value': '19'
            }
        ]
    },
    {
        'label': 'Jordan',
        'iso3': 'JOR',
        'value': 'JO',
        'states': [
            {
                'label': 'Ajloun Governorate',
                'value': 'AJ'
            },
            {
                'label': 'Amman Governorate',
                'value': 'AM'
            },
            {
                'label': 'Aqaba Governorate',
                'value': 'AQ'
            },
            {
                'label': 'Balqa Governorate',
                'value': 'BA'
            },
            {
                'label': 'Irbid Governorate',
                'value': 'IR'
            },
            {
                'label': 'Jerash Governorate',
                'value': 'JA'
            },
            {
                'label': 'Karak Governorate',
                'value': 'KA'
            },
            {
                'label': 'Ma\'an Governorate',
                'value': 'MN'
            },
            {
                'label': 'Madaba Governorate',
                'value': 'MD'
            },
            {
                'label': 'Mafraq Governorate',
                'value': 'MA'
            },
            {
                'label': 'Tafilah Governorate',
                'value': 'AT'
            },
            {
                'label': 'Zarqa Governorate',
                'value': 'AZ'
            }
        ]
    },
    {
        'label': 'Kazakhstan',
        'iso3': 'KAZ',
        'value': 'KZ',
        'states': [
            {
                'label': 'Akmola Region',
                'value': 'AKM'
            },
            {
                'label': 'Aktobe Region',
                'value': 'AKT'
            },
            {
                'label': 'Almaty',
                'value': 'ALA'
            },
            {
                'label': 'Almaty Region',
                'value': 'ALM'
            },
            {
                'label': 'Atyrau Region',
                'value': 'ATY'
            },
            {
                'label': 'Baikonur',
                'value': 'BAY'
            },
            {
                'label': 'East Kazakhstan Region',
                'value': 'VOS'
            },
            {
                'label': 'Jambyl Region',
                'value': 'ZHA'
            },
            {
                'label': 'Karaganda Region',
                'value': 'KAR'
            },
            {
                'label': 'Kostanay Region',
                'value': 'KUS'
            },
            {
                'label': 'Kyzylorda Region',
                'value': 'KZY'
            },
            {
                'label': 'Mangystau Region',
                'value': 'MAN'
            },
            {
                'label': 'North Kazakhstan Region',
                'value': 'SEV'
            },
            {
                'label': 'Nur-Sultan',
                'value': 'AST'
            },
            {
                'label': 'Pavlodar Region',
                'value': 'PAV'
            },
            {
                'label': 'Turkestan Region',
                'value': 'YUZ'
            },
            {
                'label': 'West Kazakhstan Province',
                'value': 'ZAP'
            }
        ]
    },
    {
        'label': 'Kenya',
        'iso3': 'KEN',
        'value': 'KE',
        'states': [
            {
                'label': 'Baringo County',
                'value': '01'
            },
            {
                'label': 'Bomet County',
                'value': '02'
            },
            {
                'label': 'Bungoma County',
                'value': '03'
            },
            {
                'label': 'Busia County',
                'value': '04'
            },
            {
                'label': 'Central Province',
                'value': '200'
            },
            {
                'label': 'Coast Province',
                'value': '300'
            },
            {
                'label': 'Eastern Province',
                'value': '400'
            },
            {
                'label': 'Elgeyo-Marakwet County',
                'value': '05'
            },
            {
                'label': 'Embu County',
                'value': '06'
            },
            {
                'label': 'Garissa County',
                'value': '07'
            },
            {
                'label': 'Homa Bay County',
                'value': '08'
            },
            {
                'label': 'Isiolo County',
                'value': '09'
            },
            {
                'label': 'Kajiado County',
                'value': '10'
            },
            {
                'label': 'Kakamega County',
                'value': '11'
            },
            {
                'label': 'Kericho County',
                'value': '12'
            },
            {
                'label': 'Kiambu County',
                'value': '13'
            },
            {
                'label': 'Kilifi County',
                'value': '14'
            },
            {
                'label': 'Kirinyaga County',
                'value': '15'
            },
            {
                'label': 'Kisii County',
                'value': '16'
            },
            {
                'label': 'Kisumu County',
                'value': '17'
            },
            {
                'label': 'Kitui County',
                'value': '18'
            },
            {
                'label': 'Kwale County',
                'value': '19'
            },
            {
                'label': 'Laikipia County',
                'value': '20'
            },
            {
                'label': 'Lamu County',
                'value': '21'
            },
            {
                'label': 'Machakos County',
                'value': '22'
            },
            {
                'label': 'Makueni County',
                'value': '23'
            },
            {
                'label': 'Mandera County',
                'value': '24'
            },
            {
                'label': 'Marsabit County',
                'value': '25'
            },
            {
                'label': 'Meru County',
                'value': '26'
            },
            {
                'label': 'Migori County',
                'value': '27'
            },
            {
                'label': 'Mombasa County',
                'value': '28'
            },
            {
                'label': 'Muranga County',
                'value': '29'
            },
            {
                'label': 'Nairobi',
                'value': '110'
            },
            {
                'label': 'Nakuru District',
                'value': '31'
            },
            {
                'label': 'Nandi District',
                'value': '32'
            },
            {
                'label': 'Narok County',
                'value': '33'
            },
            {
                'label': 'North Eastern Province',
                'value': '500'
            },
            {
                'label': 'Nyamira District',
                'value': '34'
            },
            {
                'label': 'Nyandarua County',
                'value': '35'
            },
            {
                'label': 'Nyanza Province',
                'value': '600'
            },
            {
                'label': 'Nyeri County',
                'value': '36'
            },
            {
                'label': 'Rift Valley Province',
                'value': '700'
            },
            {
                'label': 'Samburu County',
                'value': '37'
            },
            {
                'label': 'Siaya County',
                'value': '38'
            },
            {
                'label': 'Taita–Taveta County',
                'value': '39'
            },
            {
                'label': 'Tana River County',
                'value': '40'
            },
            {
                'label': 'Tharaka Nithi County',
                'value': '41'
            },
            {
                'label': 'Trans-Nzoia District',
                'value': '42'
            },
            {
                'label': 'Turkana County',
                'value': '43'
            },
            {
                'label': 'Uasin Gishu District',
                'value': '44'
            },
            {
                'label': 'Vihiga District',
                'value': '45'
            },
            {
                'label': 'Wajir County',
                'value': '46'
            },
            {
                'label': 'West Pokot County',
                'value': '47'
            },
            {
                'label': 'Western Province',
                'value': '800'
            }
        ]
    },
    {
        'label': 'Kiribati',
        'iso3': 'KIR',
        'value': 'KI',
        'states': [
            {
                'label': 'Gilbert Islands',
                'value': 'G'
            },
            {
                'label': 'Line Islands',
                'value': 'L'
            },
            {
                'label': 'Phoenix Islands',
                'value': 'P'
            }
        ]
    },
    {
        'label': 'North Korea',
        'iso3': 'PRK',
        'value': 'KP',
        'states': [
            {
                'label': 'Chagang Province',
                'value': '04'
            },
            {
                'label': 'Kangwon Province',
                'value': '07'
            },
            {
                'label': 'North Hamgyong Province',
                'value': '09'
            },
            {
                'label': 'North Hwanghae Province',
                'value': '06'
            },
            {
                'label': 'North Pyongan Province',
                'value': '03'
            },
            {
                'label': 'Pyongyang',
                'value': '01'
            },
            {
                'label': 'Rason',
                'value': '13'
            },
            {
                'label': 'Ryanggang Province',
                'value': '10'
            },
            {
                'label': 'South Hamgyong Province',
                'value': '08'
            },
            {
                'label': 'South Hwanghae Province',
                'value': '05'
            },
            {
                'label': 'South Pyongan Province',
                'value': '02'
            }
        ]
    },
    {
        'label': 'South Korea',
        'iso3': 'KOR',
        'value': 'KR',
        'states': [
            {
                'label': 'Busan',
                'value': '26'
            },
            {
                'label': 'Daegu',
                'value': '27'
            },
            {
                'label': 'Daejeon',
                'value': '30'
            },
            {
                'label': 'Gangwon Province',
                'value': '42'
            },
            {
                'label': 'Gwangju',
                'value': '29'
            },
            {
                'label': 'Gyeonggi Province',
                'value': '41'
            },
            {
                'label': 'Incheon',
                'value': '28'
            },
            {
                'label': 'Jeju',
                'value': '49'
            },
            {
                'label': 'North Chungcheong Province',
                'value': '43'
            },
            {
                'label': 'North Gyeongsang Province',
                'value': '47'
            },
            {
                'label': 'North Jeolla Province',
                'value': '45'
            },
            {
                'label': 'Sejong City',
                'value': '50'
            },
            {
                'label': 'Seoul',
                'value': '11'
            },
            {
                'label': 'South Chungcheong Province',
                'value': '44'
            },
            {
                'label': 'South Gyeongsang Province',
                'value': '48'
            },
            {
                'label': 'South Jeolla Province',
                'value': '46'
            },
            {
                'label': 'Ulsan',
                'value': '31'
            }
        ]
    },
    {
        'label': 'Kosovo',
        'iso3': 'XKX',
        'value': 'XK',
        'states': [
            {
                'label': 'Đakovica District (Gjakove)',
                'value': null
            },
            {
                'label': 'Gjilan District',
                'value': null
            },
            {
                'label': 'Kosovska Mitrovica District',
                'value': null
            },
            {
                'label': 'Peć District',
                'value': null
            },
            {
                'label': 'Pristina (Priştine)',
                'value': null
            },
            {
                'label': 'Prizren District',
                'value': null
            },
            {
                'label': 'Uroševac District (Ferizaj)',
                'value': null
            }
        ]
    },
    {
        'label': 'Kuwait',
        'iso3': 'KWT',
        'value': 'KW',
        'states': [
            {
                'label': 'Al Ahmadi Governorate',
                'value': 'AH'
            },
            {
                'label': 'Al Farwaniyah Governorate',
                'value': 'FA'
            },
            {
                'label': 'Al Jahra Governorate',
                'value': 'JA'
            },
            {
                'label': 'Capital Governorate',
                'value': 'KU'
            },
            {
                'label': 'Hawalli Governorate',
                'value': 'HA'
            },
            {
                'label': 'Mubarak Al-Kabeer Governorate',
                'value': 'MU'
            }
        ]
    },
    {
        'label': 'Kyrgyzstan',
        'iso3': 'KGZ',
        'value': 'KG',
        'states': [
            {
                'label': 'Batken Region',
                'value': 'B'
            },
            {
                'label': 'Bishkek',
                'value': 'GB'
            },
            {
                'label': 'Chuy Region',
                'value': 'C'
            },
            {
                'label': 'Issyk-Kul Region',
                'value': 'Y'
            },
            {
                'label': 'Jalal-Abad Region',
                'value': 'J'
            },
            {
                'label': 'Naryn Region',
                'value': 'N'
            },
            {
                'label': 'Osh',
                'value': 'GO'
            },
            {
                'label': 'Osh Region',
                'value': 'O'
            },
            {
                'label': 'Talas Region',
                'value': 'T'
            }
        ]
    },
    {
        'label': 'Laos',
        'iso3': 'LAO',
        'value': 'LA',
        'states': [
            {
                'label': 'Attapeu Province',
                'value': 'AT'
            },
            {
                'label': 'Bokeo Province',
                'value': 'BK'
            },
            {
                'label': 'Bolikhamsai Province',
                'value': 'BL'
            },
            {
                'label': 'Champasak Province',
                'value': 'CH'
            },
            {
                'label': 'Houaphanh Province',
                'value': 'HO'
            },
            {
                'label': 'Khammouane Province',
                'value': 'KH'
            },
            {
                'label': 'Luang Namtha Province',
                'value': 'LM'
            },
            {
                'label': 'Luang Prabang Province',
                'value': 'LP'
            },
            {
                'label': 'Oudomxay Province',
                'value': 'OU'
            },
            {
                'label': 'Phongsaly Province',
                'value': 'PH'
            },
            {
                'label': 'Sainyabuli Province',
                'value': 'XA'
            },
            {
                'label': 'Salavan Province',
                'value': 'SL'
            },
            {
                'label': 'Savannakhet Province',
                'value': 'SV'
            },
            {
                'label': 'Sekong Province',
                'value': 'XE'
            },
            {
                'label': 'Vientiane Prefecture',
                'value': 'VT'
            },
            {
                'label': 'Vientiane Province',
                'value': 'VI'
            },
            {
                'label': 'Xaisomboun',
                'value': 'XN'
            },
            {
                'label': 'Xaisomboun Province',
                'value': 'XS'
            },
            {
                'label': 'Xiangkhouang Province',
                'value': 'XI'
            }
        ]
    },
    {
        'label': 'Latvia',
        'iso3': 'LVA',
        'value': 'LV',
        'states': [
            {
                'label': 'Aglona Municipality',
                'value': '001'
            },
            {
                'label': 'Aizkraukle Municipality',
                'value': '002'
            },
            {
                'label': 'Aizpute Municipality',
                'value': '003'
            },
            {
                'label': 'Aknīste Municipality',
                'value': '004'
            },
            {
                'label': 'Aloja Municipality',
                'value': '005'
            },
            {
                'label': 'Alsunga Municipality',
                'value': '006'
            },
            {
                'label': 'Alūksne Municipality',
                'value': '007'
            },
            {
                'label': 'Amata Municipality',
                'value': '008'
            },
            {
                'label': 'Ape Municipality',
                'value': '009'
            },
            {
                'label': 'Auce Municipality',
                'value': '010'
            },
            {
                'label': 'Babīte Municipality',
                'value': '012'
            },
            {
                'label': 'Baldone Municipality',
                'value': '013'
            },
            {
                'label': 'Baltinava Municipality',
                'value': '014'
            },
            {
                'label': 'Balvi Municipality',
                'value': '015'
            },
            {
                'label': 'Bauska Municipality',
                'value': '016'
            },
            {
                'label': 'Beverīna Municipality',
                'value': '017'
            },
            {
                'label': 'Brocēni Municipality',
                'value': '018'
            },
            {
                'label': 'Burtnieki Municipality',
                'value': '019'
            },
            {
                'label': 'Carnikava Municipality',
                'value': '020'
            },
            {
                'label': 'Cēsis Municipality',
                'value': '022'
            },
            {
                'label': 'Cesvaine Municipality',
                'value': '021'
            },
            {
                'label': 'Cibla Municipality',
                'value': '023'
            },
            {
                'label': 'Dagda Municipality',
                'value': '024'
            },
            {
                'label': 'Daugavpils',
                'value': 'DGV'
            },
            {
                'label': 'Daugavpils Municipality',
                'value': '025'
            },
            {
                'label': 'Dobele Municipality',
                'value': '026'
            },
            {
                'label': 'Dundaga Municipality',
                'value': '027'
            },
            {
                'label': 'Durbe Municipality',
                'value': '028'
            },
            {
                'label': 'Engure Municipality',
                'value': '029'
            },
            {
                'label': 'Ērgļi Municipality',
                'value': '030'
            },
            {
                'label': 'Garkalne Municipality',
                'value': '031'
            },
            {
                'label': 'Grobiņa Municipality',
                'value': '032'
            },
            {
                'label': 'Gulbene Municipality',
                'value': '033'
            },
            {
                'label': 'Iecava Municipality',
                'value': '034'
            },
            {
                'label': 'Ikšķile Municipality',
                'value': '035'
            },
            {
                'label': 'Ilūkste Municipality',
                'value': '036'
            },
            {
                'label': 'Inčukalns Municipality',
                'value': '037'
            },
            {
                'label': 'Jaunjelgava Municipality',
                'value': '038'
            },
            {
                'label': 'Jaunpiebalga Municipality',
                'value': '039'
            },
            {
                'label': 'Jaunpils Municipality',
                'value': '040'
            },
            {
                'label': 'Jēkabpils',
                'value': 'JKB'
            },
            {
                'label': 'Jēkabpils Municipality',
                'value': '042'
            },
            {
                'label': 'Jelgava',
                'value': 'JEL'
            },
            {
                'label': 'Jelgava Municipality',
                'value': '041'
            },
            {
                'label': 'Jūrmala',
                'value': 'JUR'
            },
            {
                'label': 'Kandava Municipality',
                'value': '043'
            },
            {
                'label': 'Kārsava Municipality',
                'value': '044'
            },
            {
                'label': 'Ķegums Municipality',
                'value': '051'
            },
            {
                'label': 'Ķekava Municipality',
                'value': '052'
            },
            {
                'label': 'Kocēni Municipality',
                'value': '045'
            },
            {
                'label': 'Koknese Municipality',
                'value': '046'
            },
            {
                'label': 'Krāslava Municipality',
                'value': '047'
            },
            {
                'label': 'Krimulda Municipality',
                'value': '048'
            },
            {
                'label': 'Krustpils Municipality',
                'value': '049'
            },
            {
                'label': 'Kuldīga Municipality',
                'value': '050'
            },
            {
                'label': 'Lielvārde Municipality',
                'value': '053'
            },
            {
                'label': 'Liepāja',
                'value': 'LPX'
            },
            {
                'label': 'Līgatne Municipality',
                'value': '055'
            },
            {
                'label': 'Limbaži Municipality',
                'value': '054'
            },
            {
                'label': 'Līvāni Municipality',
                'value': '056'
            },
            {
                'label': 'Lubāna Municipality',
                'value': '057'
            },
            {
                'label': 'Ludza Municipality',
                'value': '058'
            },
            {
                'label': 'Madona Municipality',
                'value': '059'
            },
            {
                'label': 'Mālpils Municipality',
                'value': '061'
            },
            {
                'label': 'Mārupe Municipality',
                'value': '062'
            },
            {
                'label': 'Mazsalaca Municipality',
                'value': '060'
            },
            {
                'label': 'Mērsrags Municipality',
                'value': '063'
            },
            {
                'label': 'Naukšēni Municipality',
                'value': '064'
            },
            {
                'label': 'Nereta Municipality',
                'value': '065'
            },
            {
                'label': 'Nīca Municipality',
                'value': '066'
            },
            {
                'label': 'Ogre Municipality',
                'value': '067'
            },
            {
                'label': 'Olaine Municipality',
                'value': '068'
            },
            {
                'label': 'Ozolnieki Municipality',
                'value': '069'
            },
            {
                'label': 'Pārgauja Municipality',
                'value': '070'
            },
            {
                'label': 'Pāvilosta Municipality',
                'value': '071'
            },
            {
                'label': 'Pļaviņas Municipality',
                'value': '072'
            },
            {
                'label': 'Preiļi Municipality',
                'value': '073'
            },
            {
                'label': 'Priekule Municipality',
                'value': '074'
            },
            {
                'label': 'Priekuļi Municipality',
                'value': '075'
            },
            {
                'label': 'Rauna Municipality',
                'value': '076'
            },
            {
                'label': 'Rēzekne',
                'value': 'REZ'
            },
            {
                'label': 'Rēzekne Municipality',
                'value': '077'
            },
            {
                'label': 'Riebiņi Municipality',
                'value': '078'
            },
            {
                'label': 'Riga',
                'value': 'RIX'
            },
            {
                'label': 'Roja Municipality',
                'value': '079'
            },
            {
                'label': 'Ropaži Municipality',
                'value': '080'
            },
            {
                'label': 'Rucava Municipality',
                'value': '081'
            },
            {
                'label': 'Rugāji Municipality',
                'value': '082'
            },
            {
                'label': 'Rūjiena Municipality',
                'value': '084'
            },
            {
                'label': 'Rundāle Municipality',
                'value': '083'
            },
            {
                'label': 'Sala Municipality',
                'value': '085'
            },
            {
                'label': 'Salacgrīva Municipality',
                'value': '086'
            },
            {
                'label': 'Salaspils Municipality',
                'value': '087'
            },
            {
                'label': 'Saldus Municipality',
                'value': '088'
            },
            {
                'label': 'Saulkrasti Municipality',
                'value': '089'
            },
            {
                'label': 'Sēja Municipality',
                'value': '090'
            },
            {
                'label': 'Sigulda Municipality',
                'value': '091'
            },
            {
                'label': 'Skrīveri Municipality',
                'value': '092'
            },
            {
                'label': 'Skrunda Municipality',
                'value': '093'
            },
            {
                'label': 'Smiltene Municipality',
                'value': '094'
            },
            {
                'label': 'Stopiņi Municipality',
                'value': '095'
            },
            {
                'label': 'Strenči Municipality',
                'value': '096'
            },
            {
                'label': 'Talsi Municipality',
                'value': '097'
            },
            {
                'label': 'Tērvete Municipality',
                'value': '098'
            },
            {
                'label': 'Tukums Municipality',
                'value': '099'
            },
            {
                'label': 'Vaiņode Municipality',
                'value': '100'
            },
            {
                'label': 'Valka Municipality',
                'value': '101'
            },
            {
                'label': 'Valmiera',
                'value': 'VMR'
            },
            {
                'label': 'Varakļāni Municipality',
                'value': '102'
            },
            {
                'label': 'Vārkava Municipality',
                'value': '103'
            },
            {
                'label': 'Vecpiebalga Municipality',
                'value': '104'
            },
            {
                'label': 'Vecumnieki Municipality',
                'value': '105'
            },
            {
                'label': 'Ventspils',
                'value': 'VEN'
            },
            {
                'label': 'Ventspils Municipality',
                'value': '106'
            },
            {
                'label': 'Viesīte Municipality',
                'value': '107'
            },
            {
                'label': 'Viļaka Municipality',
                'value': '108'
            },
            {
                'label': 'Viļāni Municipality',
                'value': '109'
            },
            {
                'label': 'Zilupe Municipality',
                'value': '110'
            }
        ]
    },
    {
        'label': 'Lebanon',
        'iso3': 'LBN',
        'value': 'LB',
        'states': [
            {
                'label': 'Akkar Governorate',
                'value': 'AK'
            },
            {
                'label': 'Baalbek-Hermel Governorate',
                'value': 'BH'
            },
            {
                'label': 'Beirut Governorate',
                'value': 'BA'
            },
            {
                'label': 'Beqaa Governorate',
                'value': 'BI'
            },
            {
                'label': 'Mount Lebanon Governorate',
                'value': 'JL'
            },
            {
                'label': 'Nabatieh Governorate',
                'value': 'NA'
            },
            {
                'label': 'North Governorate',
                'value': 'AS'
            },
            {
                'label': 'South Governorate',
                'value': 'JA'
            }
        ]
    },
    {
        'label': 'Lesotho',
        'iso3': 'LSO',
        'value': 'LS',
        'states': [
            {
                'label': 'Berea District',
                'value': 'D'
            },
            {
                'label': 'Butha-Buthe District',
                'value': 'B'
            },
            {
                'label': 'Leribe District',
                'value': 'C'
            },
            {
                'label': 'Mafeteng District',
                'value': 'E'
            },
            {
                'label': 'Maseru District',
                'value': 'A'
            },
            {
                'label': 'Mohale\'s Hoek District',
                'value': 'F'
            },
            {
                'label': 'Mokhotlong District',
                'value': 'J'
            },
            {
                'label': 'Qacha\'s Nek District',
                'value': 'H'
            },
            {
                'label': 'Quthing District',
                'value': 'G'
            },
            {
                'label': 'Thaba-Tseka District',
                'value': 'K'
            }
        ]
    },
    {
        'label': 'Liberia',
        'iso3': 'LBR',
        'value': 'LR',
        'states': [
            {
                'label': 'Bomi County',
                'value': 'BM'
            },
            {
                'label': 'Bong County',
                'value': 'BG'
            },
            {
                'label': 'Gbarpolu County',
                'value': 'GP'
            },
            {
                'label': 'Grand Bassa County',
                'value': 'GB'
            },
            {
                'label': 'Grand Cape Mount County',
                'value': 'CM'
            },
            {
                'label': 'Grand Gedeh County',
                'value': 'GG'
            },
            {
                'label': 'Grand Kru County',
                'value': 'GK'
            },
            {
                'label': 'Lofa County',
                'value': 'LO'
            },
            {
                'label': 'Margibi County',
                'value': 'MG'
            },
            {
                'label': 'Maryland County',
                'value': 'MY'
            },
            {
                'label': 'Montserrado County',
                'value': 'MO'
            },
            {
                'label': 'Nimba',
                'value': 'NI'
            },
            {
                'label': 'River Cess County',
                'value': 'RI'
            },
            {
                'label': 'River Gee County',
                'value': 'RG'
            },
            {
                'label': 'Sinoe County',
                'value': 'SI'
            }
        ]
    },
    {
        'label': 'Libya',
        'iso3': 'LBY',
        'value': 'LY',
        'states': [
            {
                'label': 'Al Wahat District',
                'value': 'WA'
            },
            {
                'label': 'Benghazi',
                'value': 'BA'
            },
            {
                'label': 'Derna District',
                'value': 'DR'
            },
            {
                'label': 'Ghat District',
                'value': 'GT'
            },
            {
                'label': 'Jabal al Akhdar',
                'value': 'JA'
            },
            {
                'label': 'Jabal al Gharbi District',
                'value': 'JG'
            },
            {
                'label': 'Jafara',
                'value': 'JI'
            },
            {
                'label': 'Jufra',
                'value': 'JU'
            },
            {
                'label': 'Kufra District',
                'value': 'KF'
            },
            {
                'label': 'Marj District',
                'value': 'MJ'
            },
            {
                'label': 'Misrata District',
                'value': 'MI'
            },
            {
                'label': 'Murqub',
                'value': 'MB'
            },
            {
                'label': 'Murzuq District',
                'value': 'MQ'
            },
            {
                'label': 'Nalut District',
                'value': 'NL'
            },
            {
                'label': 'Nuqat al Khams',
                'value': 'NQ'
            },
            {
                'label': 'Sabha District',
                'value': 'SB'
            },
            {
                'label': 'Sirte District',
                'value': 'SR'
            },
            {
                'label': 'Tripoli District',
                'value': 'TB'
            },
            {
                'label': 'Wadi al Hayaa District',
                'value': 'WD'
            },
            {
                'label': 'Wadi al Shatii District',
                'value': 'WS'
            },
            {
                'label': 'Zawiya District',
                'value': 'ZA'
            }
        ]
    },
    {
        'label': 'Liechtenstein',
        'iso3': 'LIE',
        'value': 'LI',
        'states': [
            {
                'label': 'Balzers',
                'value': '01'
            },
            {
                'label': 'Eschen',
                'value': '02'
            },
            {
                'label': 'Gamprin',
                'value': '03'
            },
            {
                'label': 'Mauren',
                'value': '04'
            },
            {
                'label': 'Planken',
                'value': '05'
            },
            {
                'label': 'Ruggell',
                'value': '06'
            },
            {
                'label': 'Schaan',
                'value': '07'
            },
            {
                'label': 'Schellenberg',
                'value': '08'
            },
            {
                'label': 'Triesen',
                'value': '09'
            },
            {
                'label': 'Triesenberg',
                'value': '10'
            },
            {
                'label': 'Vaduz',
                'value': '11'
            }
        ]
    },
    {
        'label': 'Lithuania',
        'iso3': 'LTU',
        'value': 'LT',
        'states': [
            {
                'label': 'Akmenė District Municipality',
                'value': '01'
            },
            {
                'label': 'Alytus City Municipality',
                'value': '02'
            },
            {
                'label': 'Alytus County',
                'value': 'AL'
            },
            {
                'label': 'Alytus District Municipality',
                'value': '03'
            },
            {
                'label': 'Birštonas Municipality',
                'value': '05'
            },
            {
                'label': 'Biržai District Municipality',
                'value': '06'
            },
            {
                'label': 'Druskininkai municipality',
                'value': '07'
            },
            {
                'label': 'Elektrėnai municipality',
                'value': '08'
            },
            {
                'label': 'Ignalina District Municipality',
                'value': '09'
            },
            {
                'label': 'Jonava District Municipality',
                'value': '10'
            },
            {
                'label': 'Joniškis District Municipality',
                'value': '11'
            },
            {
                'label': 'Jurbarkas District Municipality',
                'value': '12'
            },
            {
                'label': 'Kaišiadorys District Municipality',
                'value': '13'
            },
            {
                'label': 'Kalvarija municipality',
                'value': '14'
            },
            {
                'label': 'Kaunas City Municipality',
                'value': '15'
            },
            {
                'label': 'Kaunas County',
                'value': 'KU'
            },
            {
                'label': 'Kaunas District Municipality',
                'value': '16'
            },
            {
                'label': 'Kazlų Rūda municipality',
                'value': '17'
            },
            {
                'label': 'Kėdainiai District Municipality',
                'value': '18'
            },
            {
                'label': 'Kelmė District Municipality',
                'value': '19'
            },
            {
                'label': 'Klaipeda City Municipality',
                'value': '20'
            },
            {
                'label': 'Klaipėda County',
                'value': 'KL'
            },
            {
                'label': 'Klaipėda District Municipality',
                'value': '21'
            },
            {
                'label': 'Kretinga District Municipality',
                'value': '22'
            },
            {
                'label': 'Kupiškis District Municipality',
                'value': '23'
            },
            {
                'label': 'Lazdijai District Municipality',
                'value': '24'
            },
            {
                'label': 'Marijampolė County',
                'value': 'MR'
            },
            {
                'label': 'Marijampolė Municipality',
                'value': '25'
            },
            {
                'label': 'Mažeikiai District Municipality',
                'value': '26'
            },
            {
                'label': 'Molėtai District Municipality',
                'value': '27'
            },
            {
                'label': 'Neringa Municipality',
                'value': '28'
            },
            {
                'label': 'Pagėgiai municipality',
                'value': '29'
            },
            {
                'label': 'Pakruojis District Municipality',
                'value': '30'
            },
            {
                'label': 'Palanga City Municipality',
                'value': '31'
            },
            {
                'label': 'Panevėžys City Municipality',
                'value': '32'
            },
            {
                'label': 'Panevėžys County',
                'value': 'PN'
            },
            {
                'label': 'Panevėžys District Municipality',
                'value': '33'
            },
            {
                'label': 'Pasvalys District Municipality',
                'value': '34'
            },
            {
                'label': 'Plungė District Municipality',
                'value': '35'
            },
            {
                'label': 'Prienai District Municipality',
                'value': '36'
            },
            {
                'label': 'Radviliškis District Municipality',
                'value': '37'
            },
            {
                'label': 'Raseiniai District Municipality',
                'value': '38'
            },
            {
                'label': 'Rietavas municipality',
                'value': '39'
            },
            {
                'label': 'Rokiškis District Municipality',
                'value': '40'
            },
            {
                'label': 'Šakiai District Municipality',
                'value': '41'
            },
            {
                'label': 'Šalčininkai District Municipality',
                'value': '42'
            },
            {
                'label': 'Šiauliai City Municipality',
                'value': '43'
            },
            {
                'label': 'Šiauliai County',
                'value': 'SA'
            },
            {
                'label': 'Šiauliai District Municipality',
                'value': '44'
            },
            {
                'label': 'Šilalė District Municipality',
                'value': '45'
            },
            {
                'label': 'Šilutė District Municipality',
                'value': '46'
            },
            {
                'label': 'Širvintos District Municipality',
                'value': '47'
            },
            {
                'label': 'Skuodas District Municipality',
                'value': '48'
            },
            {
                'label': 'Švenčionys District Municipality',
                'value': '49'
            },
            {
                'label': 'Tauragė County',
                'value': 'TA'
            },
            {
                'label': 'Tauragė District Municipality',
                'value': '50'
            },
            {
                'label': 'Telšiai County',
                'value': 'TE'
            },
            {
                'label': 'Telšiai District Municipality',
                'value': '51'
            },
            {
                'label': 'Trakai District Municipality',
                'value': '52'
            },
            {
                'label': 'Ukmergė District Municipality',
                'value': '53'
            },
            {
                'label': 'Utena County',
                'value': 'UT'
            },
            {
                'label': 'Utena District Municipality',
                'value': '54'
            },
            {
                'label': 'Varėna District Municipality',
                'value': '55'
            },
            {
                'label': 'Vilkaviškis District Municipality',
                'value': '56'
            },
            {
                'label': 'Vilnius City Municipality',
                'value': '57'
            },
            {
                'label': 'Vilnius County',
                'value': 'VL'
            },
            {
                'label': 'Vilnius District Municipality',
                'value': '58'
            },
            {
                'label': 'Visaginas Municipality',
                'value': '59'
            },
            {
                'label': 'Zarasai District Municipality',
                'value': '60'
            }
        ]
    },
    {
        'label': 'Luxembourg',
        'iso3': 'LUX',
        'value': 'LU',
        'states': [
            {
                'label': 'Canton of Capellen',
                'value': 'CA'
            },
            {
                'label': 'Canton of Clervaux',
                'value': 'CL'
            },
            {
                'label': 'Canton of Diekirch',
                'value': 'DI'
            },
            {
                'label': 'Canton of Echternach',
                'value': 'EC'
            },
            {
                'label': 'Canton of Esch-sur-Alzette',
                'value': 'ES'
            },
            {
                'label': 'Canton of Grevenmacher',
                'value': 'GR'
            },
            {
                'label': 'Canton of Luxembourg',
                'value': 'LU'
            },
            {
                'label': 'Canton of Mersch',
                'value': 'ME'
            },
            {
                'label': 'Canton of Redange',
                'value': 'RD'
            },
            {
                'label': 'Canton of Remich',
                'value': 'RM'
            },
            {
                'label': 'Canton of Vianden',
                'value': 'VD'
            },
            {
                'label': 'Canton of Wiltz',
                'value': 'WI'
            },
            {
                'label': 'Diekirch District',
                'value': 'D'
            },
            {
                'label': 'Grevenmacher District',
                'value': 'G'
            },
            {
                'label': 'Luxembourg District',
                'value': 'L'
            }
        ]
    },
    {
        'label': 'Macedonia',
        'iso3': 'MKD',
        'value': 'MK',
        'states': [
            {
                'label': 'Aerodrom Municipality',
                'value': '01'
            },
            {
                'label': 'Aračinovo Municipality',
                'value': '02'
            },
            {
                'label': 'Berovo Municipality',
                'value': '03'
            },
            {
                'label': 'Bitola Municipality',
                'value': '04'
            },
            {
                'label': 'Bogdanci Municipality',
                'value': '05'
            },
            {
                'label': 'Bogovinje Municipality',
                'value': '06'
            },
            {
                'label': 'Bosilovo Municipality',
                'value': '07'
            },
            {
                'label': 'Brvenica Municipality',
                'value': '08'
            },
            {
                'label': 'Butel Municipality',
                'value': '09'
            },
            {
                'label': 'Čair Municipality',
                'value': '79'
            },
            {
                'label': 'Čaška Municipality',
                'value': '80'
            },
            {
                'label': 'Centar Municipality',
                'value': '77'
            },
            {
                'label': 'Centar Župa Municipality',
                'value': '78'
            },
            {
                'label': 'Češinovo-Obleševo Municipality',
                'value': '81'
            },
            {
                'label': 'Čučer-Sandevo Municipality',
                'value': '82'
            },
            {
                'label': 'Debarca Municipality',
                'value': '22'
            },
            {
                'label': 'Delčevo Municipality',
                'value': '23'
            },
            {
                'label': 'Demir Hisar Municipality',
                'value': '25'
            },
            {
                'label': 'Demir Kapija Municipality',
                'value': '24'
            },
            {
                'label': 'Dojran Municipality',
                'value': '26'
            },
            {
                'label': 'Dolneni Municipality',
                'value': '27'
            },
            {
                'label': 'Drugovo Municipality',
                'value': '28'
            },
            {
                'label': 'Gazi Baba Municipality',
                'value': '17'
            },
            {
                'label': 'Gevgelija Municipality',
                'value': '18'
            },
            {
                'label': 'Gjorče Petrov Municipality',
                'value': '29'
            },
            {
                'label': 'Gostivar Municipality',
                'value': '19'
            },
            {
                'label': 'Gradsko Municipality',
                'value': '20'
            },
            {
                'label': 'Greater Skopje',
                'value': '85'
            },
            {
                'label': 'Ilinden Municipality',
                'value': '34'
            },
            {
                'label': 'Jegunovce Municipality',
                'value': '35'
            },
            {
                'label': 'Karbinci',
                'value': '37'
            },
            {
                'label': 'Karpoš Municipality',
                'value': '38'
            },
            {
                'label': 'Kavadarci Municipality',
                'value': '36'
            },
            {
                'label': 'Kičevo Municipality',
                'value': '40'
            },
            {
                'label': 'Kisela Voda Municipality',
                'value': '39'
            },
            {
                'label': 'Kočani Municipality',
                'value': '42'
            },
            {
                'label': 'Konče Municipality',
                'value': '41'
            },
            {
                'label': 'Kratovo Municipality',
                'value': '43'
            },
            {
                'label': 'Kriva Palanka Municipality',
                'value': '44'
            },
            {
                'label': 'Krivogaštani Municipality',
                'value': '45'
            },
            {
                'label': 'Kruševo Municipality',
                'value': '46'
            },
            {
                'label': 'Kumanovo Municipality',
                'value': '47'
            },
            {
                'label': 'Lipkovo Municipality',
                'value': '48'
            },
            {
                'label': 'Lozovo Municipality',
                'value': '49'
            },
            {
                'label': 'Makedonska Kamenica Municipality',
                'value': '51'
            },
            {
                'label': 'Makedonski Brod Municipality',
                'value': '52'
            },
            {
                'label': 'Mavrovo and Rostuša Municipality',
                'value': '50'
            },
            {
                'label': 'Mogila Municipality',
                'value': '53'
            },
            {
                'label': 'Negotino Municipality',
                'value': '54'
            },
            {
                'label': 'Novaci Municipality',
                'value': '55'
            },
            {
                'label': 'Novo Selo Municipality',
                'value': '56'
            },
            {
                'label': 'Ohrid Municipality',
                'value': '58'
            },
            {
                'label': 'Oslomej Municipality',
                'value': '57'
            },
            {
                'label': 'Pehčevo Municipality',
                'value': '60'
            },
            {
                'label': 'Petrovec Municipality',
                'value': '59'
            },
            {
                'label': 'Plasnica Municipality',
                'value': '61'
            },
            {
                'label': 'Prilep Municipality',
                'value': '62'
            },
            {
                'label': 'Probištip Municipality',
                'value': '63'
            },
            {
                'label': 'Radoviš Municipality',
                'value': '64'
            },
            {
                'label': 'Rankovce Municipality',
                'value': '65'
            },
            {
                'label': 'Resen Municipality',
                'value': '66'
            },
            {
                'label': 'Rosoman Municipality',
                'value': '67'
            },
            {
                'label': 'Saraj Municipality',
                'value': '68'
            },
            {
                'label': 'Sopište Municipality',
                'value': '70'
            },
            {
                'label': 'Staro Nagoričane Municipality',
                'value': '71'
            },
            {
                'label': 'Štip Municipality',
                'value': '83'
            },
            {
                'label': 'Struga Municipality',
                'value': '72'
            },
            {
                'label': 'Strumica Municipality',
                'value': '73'
            },
            {
                'label': 'Studeničani Municipality',
                'value': '74'
            },
            {
                'label': 'Šuto Orizari Municipality',
                'value': '84'
            },
            {
                'label': 'Sveti Nikole Municipality',
                'value': '69'
            },
            {
                'label': 'Tearce Municipality',
                'value': '75'
            },
            {
                'label': 'Tetovo Municipality',
                'value': '76'
            },
            {
                'label': 'Valandovo Municipality',
                'value': '10'
            },
            {
                'label': 'Vasilevo Municipality',
                'value': '11'
            },
            {
                'label': 'Veles Municipality',
                'value': '13'
            },
            {
                'label': 'Vevčani Municipality',
                'value': '12'
            },
            {
                'label': 'Vinica Municipality',
                'value': '14'
            },
            {
                'label': 'Vraneštica Municipality',
                'value': '15'
            },
            {
                'label': 'Vrapčište Municipality',
                'value': '16'
            },
            {
                'label': 'Zajas Municipality',
                'value': '31'
            },
            {
                'label': 'Zelenikovo Municipality',
                'value': '32'
            },
            {
                'label': 'Želino Municipality',
                'value': '30'
            },
            {
                'label': 'Zrnovci Municipality',
                'value': '33'
            }
        ]
    },
    {
        'label': 'Madagascar',
        'iso3': 'MDG',
        'value': 'MG',
        'states': [
            {
                'label': 'Antananarivo Province',
                'value': 'T'
            },
            {
                'label': 'Antsiranana Province',
                'value': 'D'
            },
            {
                'label': 'Fianarantsoa Province',
                'value': 'F'
            },
            {
                'label': 'Mahajanga Province',
                'value': 'M'
            },
            {
                'label': 'Toamasina Province',
                'value': 'A'
            },
            {
                'label': 'Toliara Province',
                'value': 'U'
            }
        ]
    },
    {
        'label': 'Malawi',
        'iso3': 'MWI',
        'value': 'MW',
        'states': [
            {
                'label': 'Balaka District',
                'value': 'BA'
            },
            {
                'label': 'Blantyre District',
                'value': 'BL'
            },
            {
                'label': 'Central Region',
                'value': 'C'
            },
            {
                'label': 'Chikwawa District',
                'value': 'CK'
            },
            {
                'label': 'Chiradzulu District',
                'value': 'CR'
            },
            {
                'label': 'Chitipa district',
                'value': 'CT'
            },
            {
                'label': 'Dedza District',
                'value': 'DE'
            },
            {
                'label': 'Dowa District',
                'value': 'DO'
            },
            {
                'label': 'Karonga District',
                'value': 'KR'
            },
            {
                'label': 'Kasungu District',
                'value': 'KS'
            },
            {
                'label': 'Likoma District',
                'value': 'LK'
            },
            {
                'label': 'Lilongwe District',
                'value': 'LI'
            },
            {
                'label': 'Machinga District',
                'value': 'MH'
            },
            {
                'label': 'Mangochi District',
                'value': 'MG'
            },
            {
                'label': 'Mchinji District',
                'value': 'MC'
            },
            {
                'label': 'Mulanje District',
                'value': 'MU'
            },
            {
                'label': 'Mwanza District',
                'value': 'MW'
            },
            {
                'label': 'Mzimba District',
                'value': 'MZ'
            },
            {
                'label': 'Nkhata Bay District',
                'value': 'NB'
            },
            {
                'label': 'Nkhotakota District',
                'value': 'NK'
            },
            {
                'label': 'Northern Region',
                'value': 'N'
            },
            {
                'label': 'Nsanje District',
                'value': 'NS'
            },
            {
                'label': 'Ntcheu District',
                'value': 'NU'
            },
            {
                'label': 'Ntchisi District',
                'value': 'NI'
            },
            {
                'label': 'Phalombe District',
                'value': 'PH'
            },
            {
                'label': 'Rumphi District',
                'value': 'RU'
            },
            {
                'label': 'Salima District',
                'value': 'SA'
            },
            {
                'label': 'Southern Region',
                'value': 'S'
            },
            {
                'label': 'Thyolo District',
                'value': 'TH'
            },
            {
                'label': 'Zomba District',
                'value': 'ZO'
            }
        ]
    },
    {
        'label': 'Malaysia',
        'iso3': 'MYS',
        'value': 'MY',
        'states': [
            {
                'label': 'Johor',
                'value': '01'
            },
            {
                'label': 'Kedah',
                'value': '02'
            },
            {
                'label': 'Kelantan',
                'value': '03'
            },
            {
                'label': 'Kuala Lumpur',
                'value': '14'
            },
            {
                'label': 'Labuan',
                'value': '15'
            },
            {
                'label': 'Melaka',
                'value': '04'
            },
            {
                'label': 'Negeri Sembilan',
                'value': '05'
            },
            {
                'label': 'Pahang',
                'value': '06'
            },
            {
                'label': 'Penang',
                'value': '07'
            },
            {
                'label': 'Perak',
                'value': '08'
            },
            {
                'label': 'Perlis',
                'value': '09'
            },
            {
                'label': 'Putrajaya',
                'value': '16'
            },
            {
                'label': 'Sabah',
                'value': '12'
            },
            {
                'label': 'Sarawak',
                'value': '13'
            },
            {
                'label': 'Selangor',
                'value': '10'
            },
            {
                'label': 'Terengganu',
                'value': '11'
            }
        ]
    },
    {
        'label': 'Maldives',
        'iso3': 'MDV',
        'value': 'MV',
        'states': [
            {
                'label': 'Addu Atoll',
                'value': '01'
            },
            {
                'label': 'Alif Alif Atoll',
                'value': '02'
            },
            {
                'label': 'Alif Dhaal Atoll',
                'value': '00'
            },
            {
                'label': 'Central Province',
                'value': 'CE'
            },
            {
                'label': 'Dhaalu Atoll',
                'value': '17'
            },
            {
                'label': 'Faafu Atoll',
                'value': '14'
            },
            {
                'label': 'Gaafu Alif Atoll',
                'value': '27'
            },
            {
                'label': 'Gaafu Dhaalu Atoll',
                'value': '28'
            },
            {
                'label': 'Gnaviyani Atoll',
                'value': '29'
            },
            {
                'label': 'Haa Alif Atoll',
                'value': '07'
            },
            {
                'label': 'Haa Dhaalu Atoll',
                'value': '23'
            },
            {
                'label': 'Kaafu Atoll',
                'value': '26'
            },
            {
                'label': 'Laamu Atoll',
                'value': '05'
            },
            {
                'label': 'Lhaviyani Atoll',
                'value': '03'
            },
            {
                'label': 'Malé',
                'value': 'MLE'
            },
            {
                'label': 'Meemu Atoll',
                'value': '12'
            },
            {
                'label': 'Noonu Atoll',
                'value': '25'
            },
            {
                'label': 'North Central Province',
                'value': 'NC'
            },
            {
                'label': 'North Province',
                'value': 'NO'
            },
            {
                'label': 'Raa Atoll',
                'value': '13'
            },
            {
                'label': 'Shaviyani Atoll',
                'value': '24'
            },
            {
                'label': 'South Central Province',
                'value': 'SC'
            },
            {
                'label': 'South Province',
                'value': 'SU'
            },
            {
                'label': 'Thaa Atoll',
                'value': '08'
            },
            {
                'label': 'Upper South Province',
                'value': 'US'
            },
            {
                'label': 'Vaavu Atoll',
                'value': '04'
            }
        ]
    },
    {
        'label': 'Mali',
        'iso3': 'MLI',
        'value': 'ML',
        'states': [
            {
                'label': 'Bamako',
                'value': 'BKO'
            },
            {
                'label': 'Gao Region',
                'value': '7'
            },
            {
                'label': 'Kayes Region',
                'value': '1'
            },
            {
                'label': 'Kidal Region',
                'value': '8'
            },
            {
                'label': 'Koulikoro Region',
                'value': '2'
            },
            {
                'label': 'Ménaka Region',
                'value': '9'
            },
            {
                'label': 'Mopti Region',
                'value': '5'
            },
            {
                'label': 'Ségou Region',
                'value': '4'
            },
            {
                'label': 'Sikasso Region',
                'value': '3'
            },
            {
                'label': 'Taoudénit Region',
                'value': '10'
            },
            {
                'label': 'Tombouctou Region',
                'value': '6'
            }
        ]
    },
    {
        'label': 'Malta',
        'iso3': 'MLT',
        'value': 'MT',
        'states': [
            {
                'label': 'Attard',
                'value': '01'
            },
            {
                'label': 'Balzan',
                'value': '02'
            },
            {
                'label': 'Birgu',
                'value': '03'
            },
            {
                'label': 'Birkirkara',
                'value': '04'
            },
            {
                'label': 'Birżebbuġa',
                'value': '05'
            },
            {
                'label': 'Cospicua',
                'value': '06'
            },
            {
                'label': 'Dingli',
                'value': '07'
            },
            {
                'label': 'Fgura',
                'value': '08'
            },
            {
                'label': 'Floriana',
                'value': '09'
            },
            {
                'label': 'Fontana',
                'value': '10'
            },
            {
                'label': 'Għajnsielem',
                'value': '13'
            },
            {
                'label': 'Għarb',
                'value': '14'
            },
            {
                'label': 'Għargħur',
                'value': '15'
            },
            {
                'label': 'Għasri',
                'value': '16'
            },
            {
                'label': 'Għaxaq',
                'value': '17'
            },
            {
                'label': 'Gudja',
                'value': '11'
            },
            {
                'label': 'Gżira',
                'value': '12'
            },
            {
                'label': 'Ħamrun',
                'value': '18'
            },
            {
                'label': 'Iklin',
                'value': '19'
            },
            {
                'label': 'Kalkara',
                'value': '21'
            },
            {
                'label': 'Kerċem',
                'value': '22'
            },
            {
                'label': 'Kirkop',
                'value': '23'
            },
            {
                'label': 'Lija',
                'value': '24'
            },
            {
                'label': 'Luqa',
                'value': '25'
            },
            {
                'label': 'Marsa',
                'value': '26'
            },
            {
                'label': 'Marsaskala',
                'value': '27'
            },
            {
                'label': 'Marsaxlokk',
                'value': '28'
            },
            {
                'label': 'Mdina',
                'value': '29'
            },
            {
                'label': 'Mellieħa',
                'value': '30'
            },
            {
                'label': 'Mġarr',
                'value': '31'
            },
            {
                'label': 'Mosta',
                'value': '32'
            },
            {
                'label': 'Mqabba',
                'value': '33'
            },
            {
                'label': 'Msida',
                'value': '34'
            },
            {
                'label': 'Mtarfa',
                'value': '35'
            },
            {
                'label': 'Munxar',
                'value': '36'
            },
            {
                'label': 'Nadur',
                'value': '37'
            },
            {
                'label': 'Naxxar',
                'value': '38'
            },
            {
                'label': 'Paola',
                'value': '39'
            },
            {
                'label': 'Pembroke',
                'value': '40'
            },
            {
                'label': 'Pietà',
                'value': '41'
            },
            {
                'label': 'Qala',
                'value': '42'
            },
            {
                'label': 'Qormi',
                'value': '43'
            },
            {
                'label': 'Qrendi',
                'value': '44'
            },
            {
                'label': 'Rabat',
                'value': '46'
            },
            {
                'label': 'Saint Lawrence',
                'value': '50'
            },
            {
                'label': 'San Ġwann',
                'value': '49'
            },
            {
                'label': 'Sannat',
                'value': '52'
            },
            {
                'label': 'Santa Luċija',
                'value': '53'
            },
            {
                'label': 'Santa Venera',
                'value': '54'
            },
            {
                'label': 'Senglea',
                'value': '20'
            },
            {
                'label': 'Siġġiewi',
                'value': '55'
            },
            {
                'label': 'Sliema',
                'value': '56'
            },
            {
                'label': 'St. Julian\'s',
                'value': '48'
            },
            {
                'label': 'St. Paul\'s Bay',
                'value': '51'
            },
            {
                'label': 'Swieqi',
                'value': '57'
            },
            {
                'label': 'Ta\' Xbiex',
                'value': '58'
            },
            {
                'label': 'Tarxien',
                'value': '59'
            },
            {
                'label': 'Valletta',
                'value': '60'
            },
            {
                'label': 'Victoria',
                'value': '45'
            },
            {
                'label': 'Xagħra',
                'value': '61'
            },
            {
                'label': 'Xewkija',
                'value': '62'
            },
            {
                'label': 'Xgħajra',
                'value': '63'
            },
            {
                'label': 'Żabbar',
                'value': '64'
            },
            {
                'label': 'Żebbuġ Gozo',
                'value': '65'
            },
            {
                'label': 'Żebbuġ Malta',
                'value': '66'
            },
            {
                'label': 'Żejtun',
                'value': '67'
            },
            {
                'label': 'Żurrieq',
                'value': '68'
            }
        ]
    },
    {
        'label': 'Marshall Islands',
        'iso3': 'MHL',
        'value': 'MH',
        'states': [
            {
                'label': 'Ralik Chain',
                'value': 'L'
            },
            {
                'label': 'Ratak Chain',
                'value': 'T'
            }
        ]
    },
    {
        'label': 'Mauritania',
        'iso3': 'MRT',
        'value': 'MR',
        'states': [
            {
                'label': 'Adrar Region',
                'value': '07'
            },
            {
                'label': 'Assaba Region',
                'value': '03'
            },
            {
                'label': 'Brakna Region',
                'value': '05'
            },
            {
                'label': 'Dakhlet Nouadhibou',
                'value': '08'
            },
            {
                'label': 'Gorgol Region',
                'value': '04'
            },
            {
                'label': 'Guidimaka Region',
                'value': '10'
            },
            {
                'label': 'Hodh Ech Chargui Region',
                'value': '01'
            },
            {
                'label': 'Hodh El Gharbi Region',
                'value': '02'
            },
            {
                'label': 'Inchiri Region',
                'value': '12'
            },
            {
                'label': 'Nouakchott-Nord Region',
                'value': '14'
            },
            {
                'label': 'Nouakchott-Ouest Region',
                'value': '13'
            },
            {
                'label': 'Nouakchott-Sud Region',
                'value': '15'
            },
            {
                'label': 'Tagant Region',
                'value': '09'
            },
            {
                'label': 'Tiris Zemmour Region',
                'value': '11'
            },
            {
                'label': 'Trarza Region',
                'value': '06'
            }
        ]
    },
    {
        'label': 'Mauritius',
        'iso3': 'MUS',
        'value': 'MU',
        'states': [
            {
                'label': 'Agaléga',
                'value': 'AG'
            },
            {
                'label': 'Beau Bassin-Rose Hill',
                'value': 'BR'
            },
            {
                'label': 'Cargados Carajos',
                'value': 'CC'
            },
            {
                'label': 'Curepipe',
                'value': 'CU'
            },
            {
                'label': 'Flacq District',
                'value': 'FL'
            },
            {
                'label': 'Grand Port District',
                'value': 'GP'
            },
            {
                'label': 'Moka District',
                'value': 'MO'
            },
            {
                'label': 'Pamplemousses District',
                'value': 'PA'
            },
            {
                'label': 'Plaines Wilhems District',
                'value': 'PW'
            },
            {
                'label': 'Port Louis',
                'value': 'PU'
            },
            {
                'label': 'Port Louis District',
                'value': 'PL'
            },
            {
                'label': 'Quatre Bornes',
                'value': 'QB'
            },
            {
                'label': 'Rivière du Rempart District',
                'value': 'RR'
            },
            {
                'label': 'Rivière Noire District',
                'value': 'BL'
            },
            {
                'label': 'Rodrigues',
                'value': 'RO'
            },
            {
                'label': 'Savanne District',
                'value': 'SA'
            },
            {
                'label': 'Vacoas-Phoenix',
                'value': 'VP'
            }
        ]
    },
    {
        'label': 'Mexico',
        'iso3': 'MEX',
        'value': 'MX',
        'states': [
            {
                'label': 'Aguascalientes',
                'value': 'AGU'
            },
            {
                'label': 'Baja California',
                'value': 'BCN'
            },
            {
                'label': 'Baja California Sur',
                'value': 'BCS'
            },
            {
                'label': 'Campeche',
                'value': 'CAM'
            },
            {
                'label': 'Chiapas',
                'value': 'CHP'
            },
            {
                'label': 'Chihuahua',
                'value': 'CHH'
            },
            {
                'label': 'Coahuila',
                'value': 'COA'
            },
            {
                'label': 'Colima',
                'value': 'COL'
            },
            {
                'label': 'Durango',
                'value': 'DUR'
            },
            {
                'label': 'Guanajuato',
                'value': 'GUA'
            },
            {
                'label': 'Guerrero',
                'value': 'GRO'
            },
            {
                'label': 'Hidalgo',
                'value': 'HID'
            },
            {
                'label': 'Jalisco',
                'value': 'JAL'
            },
            {
                'label': 'México',
                'value': 'MEX'
            },
            {
                'label': 'Mexico City',
                'value': 'CMX'
            },
            {
                'label': 'Michoacán',
                'value': 'MIC'
            },
            {
                'label': 'Morelos',
                'value': 'MOR'
            },
            {
                'label': 'Nayarit',
                'value': 'NAY'
            },
            {
                'label': 'Nuevo León',
                'value': 'NLE'
            },
            {
                'label': 'Oaxaca',
                'value': 'OAX'
            },
            {
                'label': 'Puebla',
                'value': 'PUE'
            },
            {
                'label': 'Querétaro',
                'value': 'QUE'
            },
            {
                'label': 'Quintana Roo',
                'value': 'ROO'
            },
            {
                'label': 'San Luis Potosí',
                'value': 'SLP'
            },
            {
                'label': 'Sinaloa',
                'value': 'SIN'
            },
            {
                'label': 'Sonora',
                'value': 'SON'
            },
            {
                'label': 'Tabasco',
                'value': 'TAB'
            },
            {
                'label': 'Tamaulipas',
                'value': 'TAM'
            },
            {
                'label': 'Tlaxcala',
                'value': 'TLA'
            },
            {
                'label': 'Veracruz',
                'value': 'VER'
            },
            {
                'label': 'Yucatán',
                'value': 'YUC'
            },
            {
                'label': 'Zacatecas',
                'value': 'ZAC'
            }
        ]
    },
    {
        'label': 'Micronesia',
        'iso3': 'FSM',
        'value': 'FM',
        'states': [
            {
                'label': 'Chuuk State',
                'value': 'TRK'
            },
            {
                'label': 'Kosrae State',
                'value': 'KSA'
            },
            {
                'label': 'Pohnpei State',
                'value': 'PNI'
            },
            {
                'label': 'Yap State',
                'value': 'YAP'
            }
        ]
    },
    {
        'label': 'Moldova',
        'iso3': 'MDA',
        'value': 'MD',
        'states': [
            {
                'label': 'Anenii Noi District',
                'value': 'AN'
            },
            {
                'label': 'Bălți Municipality',
                'value': 'BA'
            },
            {
                'label': 'Basarabeasca District',
                'value': 'BS'
            },
            {
                'label': 'Bender Municipality',
                'value': 'BD'
            },
            {
                'label': 'Briceni District',
                'value': 'BR'
            },
            {
                'label': 'Cahul District',
                'value': 'CA'
            },
            {
                'label': 'Călărași District',
                'value': 'CL'
            },
            {
                'label': 'Cantemir District',
                'value': 'CT'
            },
            {
                'label': 'Căușeni District',
                'value': 'CS'
            },
            {
                'label': 'Chișinău Municipality',
                'value': 'CU'
            },
            {
                'label': 'Cimișlia District',
                'value': 'CM'
            },
            {
                'label': 'Criuleni District',
                'value': 'CR'
            },
            {
                'label': 'Dondușeni District',
                'value': 'DO'
            },
            {
                'label': 'Drochia District',
                'value': 'DR'
            },
            {
                'label': 'Dubăsari District',
                'value': 'DU'
            },
            {
                'label': 'Edineț District',
                'value': 'ED'
            },
            {
                'label': 'Fălești District',
                'value': 'FA'
            },
            {
                'label': 'Florești District',
                'value': 'FL'
            },
            {
                'label': 'Gagauzia',
                'value': 'GA'
            },
            {
                'label': 'Glodeni District',
                'value': 'GL'
            },
            {
                'label': 'Hîncești District',
                'value': 'HI'
            },
            {
                'label': 'Ialoveni District',
                'value': 'IA'
            },
            {
                'label': 'Nisporeni District',
                'value': 'NI'
            },
            {
                'label': 'Ocnița District',
                'value': 'OC'
            },
            {
                'label': 'Orhei District',
                'value': 'OR'
            },
            {
                'label': 'Rezina District',
                'value': 'RE'
            },
            {
                'label': 'Rîșcani District',
                'value': 'RI'
            },
            {
                'label': 'Sîngerei District',
                'value': 'SI'
            },
            {
                'label': 'Șoldănești District',
                'value': 'SD'
            },
            {
                'label': 'Soroca District',
                'value': 'SO'
            },
            {
                'label': 'Ștefan Vodă District',
                'value': 'SV'
            },
            {
                'label': 'Strășeni District',
                'value': 'ST'
            },
            {
                'label': 'Taraclia District',
                'value': 'TA'
            },
            {
                'label': 'Telenești District',
                'value': 'TE'
            },
            {
                'label': 'Transnistria autonomous territorial unit',
                'value': 'SN'
            },
            {
                'label': 'Ungheni District',
                'value': 'UN'
            }
        ]
    },
    {
        'label': 'Mongolia',
        'iso3': 'MNG',
        'value': 'MN',
        'states': [
            {
                'label': 'Arkhangai Province',
                'value': '073'
            },
            {
                'label': 'Bayan-Ölgii Province',
                'value': '071'
            },
            {
                'label': 'Bayankhongor Province',
                'value': '069'
            },
            {
                'label': 'Bulgan Province',
                'value': '067'
            },
            {
                'label': 'Darkhan-Uul Province',
                'value': '037'
            },
            {
                'label': 'Dornod Province',
                'value': '061'
            },
            {
                'label': 'Dornogovi Province',
                'value': '063'
            },
            {
                'label': 'Dundgovi Province',
                'value': '059'
            },
            {
                'label': 'Govi-Altai Province',
                'value': '065'
            },
            {
                'label': 'Govisümber Province',
                'value': '064'
            },
            {
                'label': 'Khentii Province',
                'value': '039'
            },
            {
                'label': 'Khovd Province',
                'value': '043'
            },
            {
                'label': 'Khövsgöl Province',
                'value': '041'
            },
            {
                'label': 'Ömnögovi Province',
                'value': '053'
            },
            {
                'label': 'Orkhon Province',
                'value': '035'
            },
            {
                'label': 'Övörkhangai Province',
                'value': '055'
            },
            {
                'label': 'Selenge Province',
                'value': '049'
            },
            {
                'label': 'Sükhbaatar Province',
                'value': '051'
            },
            {
                'label': 'Töv Province',
                'value': '047'
            },
            {
                'label': 'Uvs Province',
                'value': '046'
            },
            {
                'label': 'Zavkhan Province',
                'value': '057'
            }
        ]
    },
    {
        'label': 'Montenegro',
        'iso3': 'MNE',
        'value': 'ME',
        'states': [
            {
                'label': 'Andrijevica Municipality',
                'value': '01'
            },
            {
                'label': 'Bar Municipality',
                'value': '02'
            },
            {
                'label': 'Berane Municipality',
                'value': '03'
            },
            {
                'label': 'Bijelo Polje Municipality',
                'value': '04'
            },
            {
                'label': 'Budva Municipality',
                'value': '05'
            },
            {
                'label': 'Danilovgrad Municipality',
                'value': '07'
            },
            {
                'label': 'Gusinje Municipality',
                'value': '22'
            },
            {
                'label': 'Kolašin Municipality',
                'value': '09'
            },
            {
                'label': 'Kotor Municipality',
                'value': '10'
            },
            {
                'label': 'Mojkovac Municipality',
                'value': '11'
            },
            {
                'label': 'Nikšić Municipality',
                'value': '12'
            },
            {
                'label': 'Old Royal Capital Cetinje',
                'value': '06'
            },
            {
                'label': 'Petnjica Municipality',
                'value': '23'
            },
            {
                'label': 'Plav Municipality',
                'value': '13'
            },
            {
                'label': 'Pljevlja Municipality',
                'value': '14'
            },
            {
                'label': 'Plužine Municipality',
                'value': '15'
            },
            {
                'label': 'Podgorica Municipality',
                'value': '16'
            },
            {
                'label': 'Rožaje Municipality',
                'value': '17'
            },
            {
                'label': 'Šavnik Municipality',
                'value': '18'
            },
            {
                'label': 'Tivat Municipality',
                'value': '19'
            },
            {
                'label': 'Ulcinj Municipality',
                'value': '20'
            },
            {
                'label': 'Žabljak Municipality',
                'value': '21'
            }
        ]
    },
    {
        'label': 'Morocco',
        'iso3': 'MAR',
        'value': 'MA',
        'states': [
            {
                'label': 'Al Haouz Province',
                'value': 'HAO'
            },
            {
                'label': 'Al Hoceïma Province',
                'value': 'HOC'
            },
            {
                'label': 'Aousserd Province',
                'value': 'AOU'
            },
            {
                'label': 'Assa-Zag Province',
                'value': 'ASZ'
            },
            {
                'label': 'Azilal Province',
                'value': 'AZI'
            },
            {
                'label': 'Ben Slimane Province',
                'value': 'BES'
            },
            {
                'label': 'Béni Mellal-Khénifra',
                'value': '05'
            },
            {
                'label': 'Béni-Mellal Province',
                'value': 'BEM'
            },
            {
                'label': 'Berkane Province',
                'value': 'BER'
            },
            {
                'label': 'Boujdour Province',
                'value': 'BOD'
            },
            {
                'label': 'Boulemane Province',
                'value': 'BOM'
            },
            {
                'label': 'Casablanca-Settat',
                'value': '06'
            },
            {
                'label': 'Chefchaouen Province',
                'value': 'CHE'
            },
            {
                'label': 'Chichaoua Province',
                'value': 'CHI'
            },
            {
                'label': 'Dakhla-Oued Ed-Dahab',
                'value': '12'
            },
            {
                'label': 'Drâa-Tafilalet',
                'value': '08'
            },
            {
                'label': 'El Hajeb Province',
                'value': 'HAJ'
            },
            {
                'label': 'El Jadida Province',
                'value': 'JDI'
            },
            {
                'label': 'Errachidia Province',
                'value': 'ERR'
            },
            {
                'label': 'Es Semara Province',
                'value': 'ESM'
            },
            {
                'label': 'Essaouira Province',
                'value': 'ESI'
            },
            {
                'label': 'Fahs Anjra Province',
                'value': 'FAH'
            },
            {
                'label': 'Fès-Meknès',
                'value': '03'
            },
            {
                'label': 'Figuig Province',
                'value': 'FIG'
            },
            {
                'label': 'Guelmim Province',
                'value': 'GUE'
            },
            {
                'label': 'Guelmim-Oued Noun',
                'value': '10'
            },
            {
                'label': 'Ifrane Province',
                'value': 'IFR'
            },
            {
                'label': 'Inezgane-Aït Melloul Prefecture',
                'value': 'INE'
            },
            {
                'label': 'Jerada Province',
                'value': 'JRA'
            },
            {
                'label': 'Kelaat Sraghna Province',
                'value': 'KES'
            },
            {
                'label': 'Kénitra Province',
                'value': 'KEN'
            },
            {
                'label': 'Khémisset Province',
                'value': 'KHE'
            },
            {
                'label': 'Khénifra Province',
                'value': 'KHN'
            },
            {
                'label': 'Khouribga Province',
                'value': 'KHO'
            },
            {
                'label': 'Laâyoune Province',
                'value': 'LAA'
            },
            {
                'label': 'Laâyoune-Sakia El Hamra',
                'value': '11'
            },
            {
                'label': 'Larache Province',
                'value': 'LAR'
            },
            {
                'label': 'Marrakesh-Safi',
                'value': '07'
            },
            {
                'label': 'Mediouna Province',
                'value': 'MED'
            },
            {
                'label': 'Moulay Yacoub Province',
                'value': 'MOU'
            },
            {
                'label': 'Nador Province',
                'value': 'NAD'
            },
            {
                'label': 'Nouaceur Province',
                'value': 'NOU'
            },
            {
                'label': 'Oriental',
                'value': '02'
            },
            {
                'label': 'Ouarzazate Province',
                'value': 'OUA'
            },
            {
                'label': 'Oued Ed-Dahab Province',
                'value': 'OUD'
            },
            {
                'label': 'Safi Province',
                'value': 'SAF'
            },
            {
                'label': 'Sefrou Province',
                'value': 'SEF'
            },
            {
                'label': 'Settat Province',
                'value': 'SET'
            },
            {
                'label': 'Shtouka Ait Baha Province',
                'value': 'CHT'
            },
            {
                'label': 'Sidi Kacem Province',
                'value': 'SIK'
            },
            {
                'label': 'Sidi Youssef Ben Ali',
                'value': 'SYB'
            },
            {
                'label': 'Souss-Massa',
                'value': '09'
            },
            {
                'label': 'Tan-Tan Province',
                'value': 'TNT'
            },
            {
                'label': 'Tanger-Tétouan-Al Hoceïma',
                'value': '01'
            },
            {
                'label': 'Taounate Province',
                'value': 'TAO'
            },
            {
                'label': 'Taourirt Province',
                'value': 'TAI'
            },
            {
                'label': 'Taroudant Province',
                'value': 'TAR'
            },
            {
                'label': 'Tata Province',
                'value': 'TAT'
            },
            {
                'label': 'Taza Province',
                'value': 'TAZ'
            },
            {
                'label': 'Tétouan Province',
                'value': 'TET'
            },
            {
                'label': 'Tiznit Province',
                'value': 'TIZ'
            },
            {
                'label': 'Zagora Province',
                'value': 'ZAG'
            }
        ]
    },
    {
        'label': 'Mozambique',
        'iso3': 'MOZ',
        'value': 'MZ',
        'states': [
            {
                'label': 'Cabo Delgado Province',
                'value': 'P'
            },
            {
                'label': 'Gaza Province',
                'value': 'G'
            },
            {
                'label': 'Inhambane Province',
                'value': 'I'
            },
            {
                'label': 'Manica Province',
                'value': 'B'
            },
            {
                'label': 'Maputo',
                'value': 'MPM'
            },
            {
                'label': 'Maputo Province',
                'value': 'L'
            },
            {
                'label': 'Nampula Province',
                'value': 'N'
            },
            {
                'label': 'Niassa Province',
                'value': 'A'
            },
            {
                'label': 'Sofala Province',
                'value': 'S'
            },
            {
                'label': 'Tete Province',
                'value': 'T'
            },
            {
                'label': 'Zambezia Province',
                'value': 'Q'
            }
        ]
    },
    {
        'label': 'Myanmar',
        'iso3': 'MMR',
        'value': 'MM',
        'states': [
            {
                'label': 'Ayeyarwady Region',
                'value': '07'
            },
            {
                'label': 'Bago',
                'value': '02'
            },
            {
                'label': 'Chin State',
                'value': '14'
            },
            {
                'label': 'Kachin State',
                'value': '11'
            },
            {
                'label': 'Kayah State',
                'value': '12'
            },
            {
                'label': 'Kayin State',
                'value': '13'
            },
            {
                'label': 'Magway Region',
                'value': '03'
            },
            {
                'label': 'Mandalay Region',
                'value': '04'
            },
            {
                'label': 'Mon State',
                'value': '15'
            },
            {
                'label': 'Naypyidaw Union Territory',
                'value': '18'
            },
            {
                'label': 'Rakhine State',
                'value': '16'
            },
            {
                'label': 'Sagaing Region',
                'value': '01'
            },
            {
                'label': 'Shan State',
                'value': '17'
            },
            {
                'label': 'Tanintharyi Region',
                'value': '05'
            },
            {
                'label': 'Yangon Region',
                'value': '06'
            }
        ]
    },
    {
        'label': 'Namibia',
        'iso3': 'NAM',
        'value': 'NA',
        'states': [
            {
                'label': 'Erongo Region',
                'value': 'ER'
            },
            {
                'label': 'Hardap Region',
                'value': 'HA'
            },
            {
                'label': 'Karas Region',
                'value': 'KA'
            },
            {
                'label': 'Kavango East Region',
                'value': 'KE'
            },
            {
                'label': 'Kavango West Region',
                'value': 'KW'
            },
            {
                'label': 'Khomas Region',
                'value': 'KH'
            },
            {
                'label': 'Kunene Region',
                'value': 'KU'
            },
            {
                'label': 'Ohangwena Region',
                'value': 'OW'
            },
            {
                'label': 'Omaheke Region',
                'value': 'OH'
            },
            {
                'label': 'Omusati Region',
                'value': 'OS'
            },
            {
                'label': 'Oshana Region',
                'value': 'ON'
            },
            {
                'label': 'Oshikoto Region',
                'value': 'OT'
            },
            {
                'label': 'Otjozondjupa Region',
                'value': 'OD'
            },
            {
                'label': 'Zambezi Region',
                'value': 'CA'
            }
        ]
    },
    {
        'label': 'Nauru',
        'iso3': 'NRU',
        'value': 'NR',
        'states': [
            {
                'label': 'Aiwo District',
                'value': '01'
            },
            {
                'label': 'Anabar District',
                'value': '02'
            },
            {
                'label': 'Anetan District',
                'value': '03'
            },
            {
                'label': 'Anibare District',
                'value': '04'
            },
            {
                'label': 'Baiti District',
                'value': '05'
            },
            {
                'label': 'Boe District',
                'value': '06'
            },
            {
                'label': 'Buada District',
                'value': '07'
            },
            {
                'label': 'Denigomodu District',
                'value': '08'
            },
            {
                'label': 'Ewa District',
                'value': '09'
            },
            {
                'label': 'Ijuw District',
                'value': '10'
            },
            {
                'label': 'Meneng District',
                'value': '11'
            },
            {
                'label': 'Nibok District',
                'value': '12'
            },
            {
                'label': 'Uaboe District',
                'value': '13'
            },
            {
                'label': 'Yaren District',
                'value': '14'
            }
        ]
    },
    {
        'label': 'Nepal',
        'iso3': 'NPL',
        'value': 'NP',
        'states': [
            {
                'label': 'Bagmati Zone',
                'value': 'BA'
            },
            {
                'label': 'Bheri Zone',
                'value': 'BH'
            },
            {
                'label': 'Central Region',
                'value': '1'
            },
            {
                'label': 'Dhaulagiri Zone',
                'value': 'DH'
            },
            {
                'label': 'Eastern Development Region',
                'value': '4'
            },
            {
                'label': 'Far-Western Development Region',
                'value': '5'
            },
            {
                'label': 'Gandaki Zone',
                'value': 'GA'
            },
            {
                'label': 'Janakpur Zone',
                'value': 'JA'
            },
            {
                'label': 'Karnali Zone',
                'value': 'KA'
            },
            {
                'label': 'Kathmandu',
                'value': 'KM'
            },
            {
                'label': 'Kosi Zone',
                'value': 'KO'
            },
            {
                'label': 'Lumbini Zone',
                'value': 'LU'
            },
            {
                'label': 'Mahakali Zone',
                'value': 'MA'
            },
            {
                'label': 'Mechi Zone',
                'value': 'ME'
            },
            {
                'label': 'Mid-Western Region',
                'value': '2'
            },
            {
                'label': 'Narayani Zone',
                'value': 'NA'
            },
            {
                'label': 'Rapti Zone',
                'value': 'RA'
            },
            {
                'label': 'Sagarmatha Zone',
                'value': 'SA'
            },
            {
                'label': 'Seti Zone',
                'value': 'SE'
            },
            {
                'label': 'Western Region',
                'value': '3'
            }
        ]
    },
    {
        'label': 'Netherlands Antilles',
        'iso3': 'ANT',
        'value': 'AN',
        'states': [
            {
                'label': 'Bonaire',
                'value': 'BQ1'
            },
            {
                'label': 'Saba',
                'value': 'BQ2'
            },
            {
                'label': 'Sint Eustatius',
                'value': 'BQ3'
            }
        ]
    },
    {
        'label': 'Netherlands',
        'iso3': 'NLD',
        'value': 'NL',
        'states': [
            {
                'label': 'Drenthe',
                'value': 'DR'
            },
            {
                'label': 'Flevoland',
                'value': 'FL'
            },
            {
                'label': 'Friesland',
                'value': 'FR'
            },
            {
                'label': 'Gelderland',
                'value': 'GE'
            },
            {
                'label': 'Groningen',
                'value': 'GR'
            },
            {
                'label': 'Limburg',
                'value': 'LI'
            },
            {
                'label': 'North Brabant',
                'value': 'NB'
            },
            {
                'label': 'North Holland',
                'value': 'NH'
            },
            {
                'label': 'Overijssel',
                'value': 'OV'
            },
            {
                'label': 'South Holland',
                'value': 'ZH'
            },
            {
                'label': 'Utrecht',
                'value': 'UT'
            },
            {
                'label': 'Zeeland',
                'value': 'ZE'
            }
        ]
    },
    {
        'label': 'New Zealand',
        'iso3': 'NZL',
        'value': 'NZ',
        'states': [
            {
                'label': 'Auckland Region',
                'value': 'AUK'
            },
            {
                'label': 'Bay of Plenty Region',
                'value': 'BOP'
            },
            {
                'label': 'Canterbury Region',
                'value': 'CAN'
            },
            {
                'label': 'Chatham Islands',
                'value': 'CIT'
            },
            {
                'label': 'Gisborne District',
                'value': 'GIS'
            },
            {
                'label': 'Hawke\'s Bay Region',
                'value': 'HKB'
            },
            {
                'label': 'Manawatu-Wanganui Region',
                'value': 'MWT'
            },
            {
                'label': 'Marlborough Region',
                'value': 'MBH'
            },
            {
                'label': 'Nelson Region',
                'value': 'NSN'
            },
            {
                'label': 'Northland Region',
                'value': 'NTL'
            },
            {
                'label': 'Otago Region',
                'value': 'OTA'
            },
            {
                'label': 'Southland Region',
                'value': 'STL'
            },
            {
                'label': 'Taranaki Region',
                'value': 'TKI'
            },
            {
                'label': 'Tasman District',
                'value': 'TAS'
            },
            {
                'label': 'Waikato Region',
                'value': 'WKO'
            },
            {
                'label': 'Wellington Region',
                'value': 'WGN'
            },
            {
                'label': 'West Coast Region',
                'value': 'WTC'
            }
        ]
    },
    {
        'label': 'Nicaragua',
        'iso3': 'NIC',
        'value': 'NI',
        'states': [
            {
                'label': 'Boaco Department',
                'value': 'BO'
            },
            {
                'label': 'Carazo Department',
                'value': 'CA'
            },
            {
                'label': 'Chinandega Department',
                'value': 'CI'
            },
            {
                'label': 'Chontales Department',
                'value': 'CO'
            },
            {
                'label': 'Estelí Department',
                'value': 'ES'
            },
            {
                'label': 'Granada Department',
                'value': 'GR'
            },
            {
                'label': 'Jinotega Department',
                'value': 'JI'
            },
            {
                'label': 'León Department',
                'value': 'LE'
            },
            {
                'label': 'Madriz Department',
                'value': 'MD'
            },
            {
                'label': 'Managua Department',
                'value': 'MN'
            },
            {
                'label': 'Masaya Department',
                'value': 'MS'
            },
            {
                'label': 'Matagalpa Department',
                'value': 'MT'
            },
            {
                'label': 'North Caribbean Coast Autonomous Region',
                'value': 'AN'
            },
            {
                'label': 'Río San Juan Department',
                'value': 'SJ'
            },
            {
                'label': 'Rivas Department',
                'value': 'RI'
            },
            {
                'label': 'South Caribbean Coast Autonomous Region',
                'value': 'AS'
            }
        ]
    },
    {
        'label': 'Niger',
        'iso3': 'NER',
        'value': 'NE',
        'states': [
            {
                'label': 'Agadez Region',
                'value': '1'
            },
            {
                'label': 'Diffa Region',
                'value': '2'
            },
            {
                'label': 'Dosso Region',
                'value': '3'
            },
            {
                'label': 'Maradi Region',
                'value': '4'
            },
            {
                'label': 'Tahoua Region',
                'value': '5'
            },
            {
                'label': 'Tillabéri Region',
                'value': '6'
            },
            {
                'label': 'Zinder Region',
                'value': '7'
            }
        ]
    },
    {
        'label': 'Nigeria',
        'iso3': 'NGA',
        'value': 'NG',
        'states': [
            {
                'label': 'Abia State',
                'value': 'AB'
            },
            {
                'label': 'Adamawa State',
                'value': 'AD'
            },
            {
                'label': 'Akwa Ibom State',
                'value': 'AK'
            },
            {
                'label': 'Anambra State',
                'value': 'AN'
            },
            {
                'label': 'Bauchi State',
                'value': 'BA'
            },
            {
                'label': 'Bayelsa State',
                'value': 'BY'
            },
            {
                'label': 'Benue State',
                'value': 'BE'
            },
            {
                'label': 'Borno State',
                'value': 'BO'
            },
            {
                'label': 'Cross River State',
                'value': 'CR'
            },
            {
                'label': 'Delta State',
                'value': 'DE'
            },
            {
                'label': 'Ebonyi State',
                'value': 'EB'
            },
            {
                'label': 'Edo State',
                'value': 'ED'
            },
            {
                'label': 'Ekiti State',
                'value': 'EK'
            },
            {
                'label': 'Enugu State',
                'value': 'EN'
            },
            {
                'label': 'Federal Capital Territory',
                'value': 'FC'
            },
            {
                'label': 'Gombe State',
                'value': 'GO'
            },
            {
                'label': 'Imo State',
                'value': 'IM'
            },
            {
                'label': 'Jigawa State',
                'value': 'JI'
            },
            {
                'label': 'Kaduna State',
                'value': 'KD'
            },
            {
                'label': 'Kano State',
                'value': 'KN'
            },
            {
                'label': 'Katsina State',
                'value': 'KT'
            },
            {
                'label': 'Kebbi State',
                'value': 'KE'
            },
            {
                'label': 'Kogi State',
                'value': 'KO'
            },
            {
                'label': 'Kwara State',
                'value': 'KW'
            },
            {
                'label': 'Lagos State',
                'value': 'LA'
            },
            {
                'label': 'Nasarawa State',
                'value': 'NA'
            },
            {
                'label': 'Niger State',
                'value': 'NI'
            },
            {
                'label': 'Ogun State',
                'value': 'OG'
            },
            {
                'label': 'Ondo State',
                'value': 'ON'
            },
            {
                'label': 'Osun State',
                'value': 'OS'
            },
            {
                'label': 'Oyo State',
                'value': 'OY'
            },
            {
                'label': 'Plateau State',
                'value': 'PL'
            },
            {
                'label': 'Sokoto State',
                'value': 'SO'
            },
            {
                'label': 'Taraba State',
                'value': 'TA'
            },
            {
                'label': 'Yobe State',
                'value': 'YO'
            },
            {
                'label': 'Zamfara State',
                'value': 'ZA'
            }
        ]
    },
    {
        'label': 'Norway',
        'iso3': 'NOR',
        'value': 'NO',
        'states': [
            {
                'label': 'Akershus',
                'value': '02'
            },
            {
                'label': 'Buskerud',
                'value': '06'
            },
            {
                'label': 'Finnmark',
                'value': '20'
            },
            {
                'label': 'Hedmark',
                'value': '04'
            },
            {
                'label': 'Hordaland',
                'value': '12'
            },
            {
                'label': 'Jan Mayen',
                'value': '22'
            },
            {
                'label': 'Møre og Romsdal',
                'value': '15'
            },
            {
                'label': 'Nord-Trøndelag',
                'value': '17'
            },
            {
                'label': 'Nordland',
                'value': '18'
            },
            {
                'label': 'Oppland',
                'value': '05'
            },
            {
                'label': 'Oslo',
                'value': '03'
            },
            {
                'label': 'Østfold',
                'value': '01'
            },
            {
                'label': 'Rogaland',
                'value': '11'
            },
            {
                'label': 'Sogn og Fjordane',
                'value': '14'
            },
            {
                'label': 'Sør-Trøndelag',
                'value': '16'
            },
            {
                'label': 'Svalbard',
                'value': '21'
            },
            {
                'label': 'Telemark',
                'value': '08'
            },
            {
                'label': 'Troms',
                'value': '19'
            },
            {
                'label': 'Trøndelag',
                'value': '50'
            },
            {
                'label': 'Vest-Agder',
                'value': '10'
            },
            {
                'label': 'Vestfold',
                'value': '07'
            }
        ]
    },
    {
        'label': 'Oman',
        'iso3': 'OMN',
        'value': 'OM',
        'states': [
            {
                'label': 'Ad Dakhiliyah Governorate',
                'value': 'DA'
            },
            {
                'label': 'Ad Dhahirah Governorate',
                'value': 'ZA'
            },
            {
                'label': 'Al Batinah North Governorate',
                'value': 'BS'
            },
            {
                'label': 'Al Batinah Region',
                'value': 'BA'
            },
            {
                'label': 'Al Batinah South Governorate',
                'value': 'BJ'
            },
            {
                'label': 'Al Buraimi Governorate',
                'value': 'BU'
            },
            {
                'label': 'Al Wusta Governorate',
                'value': 'WU'
            },
            {
                'label': 'Ash Sharqiyah North Governorate',
                'value': 'SS'
            },
            {
                'label': 'Ash Sharqiyah Region',
                'value': 'SH'
            },
            {
                'label': 'Ash Sharqiyah South Governorate',
                'value': 'SJ'
            },
            {
                'label': 'Dhofar Governorate',
                'value': 'ZU'
            },
            {
                'label': 'Musandam Governorate',
                'value': 'MU'
            },
            {
                'label': 'Muscat Governorate',
                'value': 'MA'
            }
        ]
    },
    {
        'label': 'Pakistan',
        'iso3': 'PAK',
        'value': 'PK',
        'states': [
            {
                'label': 'Azad Kashmir',
                'value': 'JK'
            },
            {
                'label': 'Balochistan',
                'value': 'BA'
            },
            {
                'label': 'Federally Administered Tribal Areas',
                'value': 'TA'
            },
            {
                'label': 'Gilgit-Baltistan',
                'value': 'GB'
            },
            {
                'label': 'Islamabad Capital Territory',
                'value': 'IS'
            },
            {
                'label': 'Khyber Pakhtunkhwa',
                'value': 'KP'
            },
            {
                'label': 'Punjab',
                'value': 'PB'
            },
            {
                'label': 'Sindh',
                'value': 'SD'
            }
        ]
    },
    {
        'label': 'Palau',
        'iso3': 'PLW',
        'value': 'PW',
        'states': [
            {
                'label': 'Aimeliik',
                'value': '002'
            },
            {
                'label': 'Airai',
                'value': '004'
            },
            {
                'label': 'Angaur',
                'value': '010'
            },
            {
                'label': 'Hatohobei',
                'value': '050'
            },
            {
                'label': 'Kayangel',
                'value': '100'
            },
            {
                'label': 'Koror',
                'value': '150'
            },
            {
                'label': 'Melekeok',
                'value': '212'
            },
            {
                'label': 'Ngaraard',
                'value': '214'
            },
            {
                'label': 'Ngarchelong',
                'value': '218'
            },
            {
                'label': 'Ngardmau',
                'value': '222'
            },
            {
                'label': 'Ngatpang',
                'value': '224'
            },
            {
                'label': 'Ngchesar',
                'value': '226'
            },
            {
                'label': 'Ngeremlengui',
                'value': '227'
            },
            {
                'label': 'Ngiwal',
                'value': '228'
            },
            {
                'label': 'Peleliu',
                'value': '350'
            },
            {
                'label': 'Sonsorol',
                'value': '370'
            }
        ]
    },
    {
        'label': 'Panama',
        'iso3': 'PAN',
        'value': 'PA',
        'states': [
            {
                'label': 'Bocas del Toro Province',
                'value': '1'
            },
            {
                'label': 'Chiriquí Province',
                'value': '4'
            },
            {
                'label': 'Coclé Province',
                'value': '2'
            },
            {
                'label': 'Colón Province',
                'value': '3'
            },
            {
                'label': 'Darién Province',
                'value': '5'
            },
            {
                'label': 'Emberá-Wounaan Comarca',
                'value': 'EM'
            },
            {
                'label': 'Guna Yala',
                'value': 'KY'
            },
            {
                'label': 'Herrera Province',
                'value': '6'
            },
            {
                'label': 'Los Santos Province',
                'value': '7'
            },
            {
                'label': 'Ngöbe-Buglé Comarca',
                'value': 'NB'
            },
            {
                'label': 'Panamá Oeste Province',
                'value': '10'
            },
            {
                'label': 'Panamá Province',
                'value': '8'
            },
            {
                'label': 'Veraguas Province',
                'value': '9'
            }
        ]
    },
    {
        'label': 'Papua new Guinea',
        'iso3': 'PNG',
        'value': 'PG',
        'states': [
            {
                'label': 'Bougainville',
                'value': 'NSB'
            },
            {
                'label': 'Central Province',
                'value': 'CPM'
            },
            {
                'label': 'Chimbu Province',
                'value': 'CPK'
            },
            {
                'label': 'East New Britain',
                'value': 'EBR'
            },
            {
                'label': 'Eastern Highlands Province',
                'value': 'EHG'
            },
            {
                'label': 'Enga Province',
                'value': 'EPW'
            },
            {
                'label': 'Gulf',
                'value': 'GPK'
            },
            {
                'label': 'Hela',
                'value': 'HLA'
            },
            {
                'label': 'Jiwaka Province',
                'value': 'JWK'
            },
            {
                'label': 'Madang Province',
                'value': 'MPM'
            },
            {
                'label': 'Manus Province',
                'value': 'MRL'
            },
            {
                'label': 'Milne Bay Province',
                'value': 'MBA'
            },
            {
                'label': 'Morobe Province',
                'value': 'MPL'
            },
            {
                'label': 'New Ireland Province',
                'value': 'NIK'
            },
            {
                'label': 'Oro Province',
                'value': 'NPP'
            },
            {
                'label': 'Port Moresby',
                'value': 'NCD'
            },
            {
                'label': 'Sandaun Province',
                'value': 'SAN'
            },
            {
                'label': 'Southern Highlands Province',
                'value': 'SHM'
            },
            {
                'label': 'West New Britain Province',
                'value': 'WBK'
            },
            {
                'label': 'Western Highlands Province',
                'value': 'WHM'
            },
            {
                'label': 'Western Province',
                'value': 'WPD'
            }
        ]
    },
    {
        'label': 'Paraguay',
        'iso3': 'PRY',
        'value': 'PY',
        'states': [
            {
                'label': 'Alto Paraguay Department',
                'value': '16'
            },
            {
                'label': 'Alto Paraná Department',
                'value': '10'
            },
            {
                'label': 'Amambay Department',
                'value': '13'
            },
            {
                'label': 'Boquerón Department',
                'value': '19'
            },
            {
                'label': 'Caaguazú',
                'value': '5'
            },
            {
                'label': 'Caazapá',
                'value': '6'
            },
            {
                'label': 'Canindeyú',
                'value': '14'
            },
            {
                'label': 'Central Department',
                'value': '11'
            },
            {
                'label': 'Concepción Department',
                'value': '1'
            },
            {
                'label': 'Cordillera Department',
                'value': '3'
            },
            {
                'label': 'Guairá Department',
                'value': '4'
            },
            {
                'label': 'Itapúa',
                'value': '7'
            },
            {
                'label': 'Misiones Department',
                'value': '8'
            },
            {
                'label': 'Ñeembucú Department',
                'value': '12'
            },
            {
                'label': 'Paraguarí Department',
                'value': '9'
            },
            {
                'label': 'Presidente Hayes Department',
                'value': '15'
            },
            {
                'label': 'San Pedro Department',
                'value': '2'
            }
        ]
    },
    {
        'label': 'Peru',
        'iso3': 'PER',
        'value': 'PE',
        'states': [
            {
                'label': 'Amazonas',
                'value': 'AMA'
            },
            {
                'label': 'Áncash',
                'value': 'ANC'
            },
            {
                'label': 'Apurímac',
                'value': 'APU'
            },
            {
                'label': 'Arequipa',
                'value': 'ARE'
            },
            {
                'label': 'Ayacucho',
                'value': 'AYA'
            },
            {
                'label': 'Cajamarca',
                'value': 'CAJ'
            },
            {
                'label': 'Callao',
                'value': 'CAL'
            },
            {
                'label': 'Cusco',
                'value': 'CUS'
            },
            {
                'label': 'Huancavelica',
                'value': 'HUV'
            },
            {
                'label': 'Huanuco',
                'value': 'HUC'
            },
            {
                'label': 'Ica',
                'value': 'ICA'
            },
            {
                'label': 'Junín',
                'value': 'JUN'
            },
            {
                'label': 'La Libertad',
                'value': 'LAL'
            },
            {
                'label': 'Lambayeque',
                'value': 'LAM'
            },
            {
                'label': 'Lima',
                'value': 'LIM'
            },
            {
                'label': 'Madre de Dios',
                'value': 'MDD'
            },
            {
                'label': 'Moquegua',
                'value': 'MOQ'
            },
            {
                'label': 'Pasco',
                'value': 'PAS'
            },
            {
                'label': 'Piura',
                'value': 'PIU'
            },
            {
                'label': 'Puno',
                'value': 'PUN'
            },
            {
                'label': 'San Martín',
                'value': 'SAM'
            },
            {
                'label': 'Tacna',
                'value': 'TAC'
            },
            {
                'label': 'Tumbes',
                'value': 'TUM'
            },
            {
                'label': 'Ucayali',
                'value': 'UCA'
            }
        ]
    },
    {
        'label': 'Philippines',
        'iso3': 'PHL',
        'value': 'PH',
        'states': [
            {
                'label': 'Abra',
                'value': 'ABR'
            },
            {
                'label': 'Agusan del Norte',
                'value': 'AGN'
            },
            {
                'label': 'Agusan del Sur',
                'value': 'AGS'
            },
            {
                'label': 'Aklan',
                'value': 'AKL'
            },
            {
                'label': 'Albay',
                'value': 'ALB'
            },
            {
                'label': 'Antique',
                'value': 'ANT'
            },
            {
                'label': 'Apayao',
                'value': 'APA'
            },
            {
                'label': 'Aurora',
                'value': 'AUR'
            },
            {
                'label': 'Autonomous Region in Muslim Mindanao',
                'value': '14'
            },
            {
                'label': 'Basilan',
                'value': 'BAS'
            },
            {
                'label': 'Bataan',
                'value': 'BAN'
            },
            {
                'label': 'Batanes',
                'value': 'BTN'
            },
            {
                'label': 'Batangas',
                'value': 'BTG'
            },
            {
                'label': 'Benguet',
                'value': 'BEN'
            },
            {
                'label': 'Bicol Region',
                'value': '05'
            },
            {
                'label': 'Biliran',
                'value': 'BIL'
            },
            {
                'label': 'Bohol',
                'value': 'BOH'
            },
            {
                'label': 'Bukidnon',
                'value': 'BUK'
            },
            {
                'label': 'Bulacan',
                'value': 'BUL'
            },
            {
                'label': 'Cagayan',
                'value': 'CAG'
            },
            {
                'label': 'Cagayan Valley',
                'value': '02'
            },
            {
                'label': 'Calabarzon',
                'value': '40'
            },
            {
                'label': 'Camarines Norte',
                'value': 'CAN'
            },
            {
                'label': 'Camarines Sur',
                'value': 'CAS'
            },
            {
                'label': 'Camiguin',
                'value': 'CAM'
            },
            {
                'label': 'Capiz',
                'value': 'CAP'
            },
            {
                'label': 'Caraga',
                'value': '13'
            },
            {
                'label': 'Catanduanes',
                'value': 'CAT'
            },
            {
                'label': 'Cavite',
                'value': 'CAV'
            },
            {
                'label': 'Cebu',
                'value': 'CEB'
            },
            {
                'label': 'Central Luzon',
                'value': '03'
            },
            {
                'label': 'Central Visayas',
                'value': '07'
            },
            {
                'label': 'Compostela Valley',
                'value': 'COM'
            },
            {
                'label': 'Cordillera Administrative Region',
                'value': '15'
            },
            {
                'label': 'Cotabato',
                'value': 'NCO'
            },
            {
                'label': 'Davao del Norte',
                'value': 'DAV'
            },
            {
                'label': 'Davao del Sur',
                'value': 'DAS'
            },
            {
                'label': 'Davao Occidental',
                'value': 'DVO'
            },
            {
                'label': 'Davao Oriental',
                'value': 'DAO'
            },
            {
                'label': 'Davao Region',
                'value': '11'
            },
            {
                'label': 'Dinagat Islands',
                'value': 'DIN'
            },
            {
                'label': 'Eastern Samar',
                'value': 'EAS'
            },
            {
                'label': 'Eastern Visayas',
                'value': '08'
            },
            {
                'label': 'Guimaras',
                'value': 'GUI'
            },
            {
                'label': 'Ifugao',
                'value': 'IFU'
            },
            {
                'label': 'Ilocos Norte',
                'value': 'ILN'
            },
            {
                'label': 'Ilocos Region',
                'value': '01'
            },
            {
                'label': 'Ilocos Sur',
                'value': 'ILS'
            },
            {
                'label': 'Iloilo',
                'value': 'ILI'
            },
            {
                'label': 'Isabela',
                'value': 'ISA'
            },
            {
                'label': 'Kalinga',
                'value': 'KAL'
            },
            {
                'label': 'La Union',
                'value': 'LUN'
            },
            {
                'label': 'Laguna',
                'value': 'LAG'
            },
            {
                'label': 'Lanao del Norte',
                'value': 'LAN'
            },
            {
                'label': 'Lanao del Sur',
                'value': 'LAS'
            },
            {
                'label': 'Leyte',
                'value': 'LEY'
            },
            {
                'label': 'Maguindanao',
                'value': 'MAG'
            },
            {
                'label': 'Marinduque',
                'value': 'MAD'
            },
            {
                'label': 'Masbate',
                'value': 'MAS'
            },
            {
                'label': 'Metro Manila',
                'value': '00'
            },
            {
                'label': 'Mimaropa',
                'value': '41'
            },
            {
                'label': 'Misamis Occidental',
                'value': 'MSC'
            },
            {
                'label': 'Misamis Oriental',
                'value': 'MSR'
            },
            {
                'label': 'Mountain Province',
                'value': 'MOU'
            },
            {
                'label': 'Negros Occidental',
                'value': 'NEC'
            },
            {
                'label': 'Negros Oriental',
                'value': 'NER'
            },
            {
                'label': 'Northern Mindanao',
                'value': '10'
            },
            {
                'label': 'Northern Samar',
                'value': 'NSA'
            },
            {
                'label': 'Nueva Ecija',
                'value': 'NUE'
            },
            {
                'label': 'Nueva Vizcaya',
                'value': 'NUV'
            },
            {
                'label': 'Occidental Mindoro',
                'value': 'MDC'
            },
            {
                'label': 'Oriental Mindoro',
                'value': 'MDR'
            },
            {
                'label': 'Palawan',
                'value': 'PLW'
            },
            {
                'label': 'Pampanga',
                'value': 'PAM'
            },
            {
                'label': 'Pangasinan',
                'value': 'PAN'
            },
            {
                'label': 'Quezon',
                'value': 'QUE'
            },
            {
                'label': 'Quirino',
                'value': 'QUI'
            },
            {
                'label': 'Rizal',
                'value': 'RIZ'
            },
            {
                'label': 'Romblon',
                'value': 'ROM'
            },
            {
                'label': 'Sarangani',
                'value': 'SAR'
            },
            {
                'label': 'Siquijor',
                'value': 'SIG'
            },
            {
                'label': 'Soccsksargen',
                'value': '12'
            },
            {
                'label': 'Sorsogon',
                'value': 'SOR'
            },
            {
                'label': 'South Cotabato',
                'value': 'SCO'
            },
            {
                'label': 'Southern Leyte',
                'value': 'SLE'
            },
            {
                'label': 'Sultan Kudarat',
                'value': 'SUK'
            },
            {
                'label': 'Sulu',
                'value': 'SLU'
            },
            {
                'label': 'Surigao del Norte',
                'value': 'SUN'
            },
            {
                'label': 'Surigao del Sur',
                'value': 'SUR'
            },
            {
                'label': 'Tarlac',
                'value': 'TAR'
            },
            {
                'label': 'Tawi-Tawi',
                'value': 'TAW'
            },
            {
                'label': 'Western Visayas',
                'value': '06'
            },
            {
                'label': 'Zambales',
                'value': 'ZMB'
            },
            {
                'label': 'Zamboanga del Norte',
                'value': 'ZAN'
            },
            {
                'label': 'Zamboanga del Sur',
                'value': 'ZAS'
            },
            {
                'label': 'Zamboanga Peninsula',
                'value': '09'
            },
            {
                'label': 'Zamboanga Sibugay',
                'value': 'ZSI'
            }
        ]
    },
    {
        'label': 'Poland',
        'iso3': 'POL',
        'value': 'PL',
        'states': [
            {
                'label': 'Greater Poland Voivodeship',
                'value': 'WP'
            },
            {
                'label': 'Kielce',
                'value': 'KI'
            },
            {
                'label': 'Kuyavian-Pomeranian Voivodeship',
                'value': 'KP'
            },
            {
                'label': 'Lesser Poland Voivodeship',
                'value': 'MA'
            },
            {
                'label': 'Lower Silesian Voivodeship',
                'value': 'DS'
            },
            {
                'label': 'Lublin Voivodeship',
                'value': 'LU'
            },
            {
                'label': 'Lubusz Voivodeship',
                'value': 'LB'
            },
            {
                'label': 'Łódź Voivodeship',
                'value': 'LD'
            },
            {
                'label': 'Masovian Voivodeship',
                'value': 'MZ'
            },
            {
                'label': 'Opole Voivodeship',
                'value': 'OP'
            },
            {
                'label': 'Podkarpackie Voivodeship',
                'value': 'PK'
            },
            {
                'label': 'Podlaskie Voivodeship',
                'value': 'PD'
            },
            {
                'label': 'Pomeranian Voivodeship',
                'value': 'PM'
            },
            {
                'label': 'Silesian Voivodeship',
                'value': 'SL'
            },
            {
                'label': 'Świętokrzyskie Voivodeship',
                'value': 'SK'
            },
            {
                'label': 'Warmian-Masurian Voivodeship',
                'value': 'WN'
            },
            {
                'label': 'West Pomeranian Voivodeship',
                'value': 'ZP'
            }
        ]
    },
    {
        'label': 'Portugal',
        'iso3': 'PRT',
        'value': 'PT',
        'states': [
            {
                'label': 'Aveiro District',
                'value': '01'
            },
            {
                'label': 'Azores',
                'value': '20'
            },
            {
                'label': 'Beja District',
                'value': '02'
            },
            {
                'label': 'Braga District',
                'value': '03'
            },
            {
                'label': 'Bragança District',
                'value': '04'
            },
            {
                'label': 'Castelo Branco District',
                'value': '05'
            },
            {
                'label': 'Coimbra District',
                'value': '06'
            },
            {
                'label': 'Évora District',
                'value': '07'
            },
            {
                'label': 'Faro District',
                'value': '08'
            },
            {
                'label': 'Guarda District',
                'value': '09'
            },
            {
                'label': 'Leiria District',
                'value': '10'
            },
            {
                'label': 'Lisbon District',
                'value': '11'
            },
            {
                'label': 'Madeira',
                'value': '30'
            },
            {
                'label': 'Portalegre District',
                'value': '12'
            },
            {
                'label': 'Porto District',
                'value': '13'
            },
            {
                'label': 'Santarém District',
                'value': '14'
            },
            {
                'label': 'Setúbal District',
                'value': '15'
            },
            {
                'label': 'Viana do Castelo District',
                'value': '16'
            },
            {
                'label': 'Vila Real District',
                'value': '17'
            },
            {
                'label': 'Viseu District',
                'value': '18'
            }
        ]
    },
    {
        'label': 'Qatar',
        'iso3': 'QAT',
        'value': 'QA',
        'states': [
            {
                'label': 'Al Daayen',
                'value': 'ZA'
            },
            {
                'label': 'Al Khor',
                'value': 'KH'
            },
            {
                'label': 'Al Rayyan Municipality',
                'value': 'RA'
            },
            {
                'label': 'Al Wakrah',
                'value': 'WA'
            },
            {
                'label': 'Al-Shahaniya',
                'value': 'SH'
            },
            {
                'label': 'Doha',
                'value': 'DA'
            },
            {
                'label': 'Madinat ash Shamal',
                'value': 'MS'
            },
            {
                'label': 'Umm Salal Municipality',
                'value': 'US'
            }
        ]
    },
    {
        'label': 'Romania',
        'iso3': 'ROU',
        'value': 'RO',
        'states': [
            {
                'label': 'Alba',
                'value': 'AB'
            },
            {
                'label': 'Arad County',
                'value': 'AR'
            },
            {
                'label': 'Arges',
                'value': 'AG'
            },
            {
                'label': 'Bacău County',
                'value': 'BC'
            },
            {
                'label': 'Bihor County',
                'value': 'BH'
            },
            {
                'label': 'Bistrița-Năsăud County',
                'value': 'BN'
            },
            {
                'label': 'Botoșani County',
                'value': 'BT'
            },
            {
                'label': 'Braila',
                'value': 'BR'
            },
            {
                'label': 'Brașov County',
                'value': 'BV'
            },
            {
                'label': 'Bucharest',
                'value': 'B'
            },
            {
                'label': 'Buzău County',
                'value': 'BZ'
            },
            {
                'label': 'Călărași County',
                'value': 'CL'
            },
            {
                'label': 'Caraș-Severin County',
                'value': 'CS'
            },
            {
                'label': 'Cluj County',
                'value': 'CJ'
            },
            {
                'label': 'Constanța County',
                'value': 'CT'
            },
            {
                'label': 'Covasna County',
                'value': 'CV'
            },
            {
                'label': 'Dâmbovița County',
                'value': 'DB'
            },
            {
                'label': 'Dolj County',
                'value': 'DJ'
            },
            {
                'label': 'Galați County',
                'value': 'GL'
            },
            {
                'label': 'Giurgiu County',
                'value': 'GR'
            },
            {
                'label': 'Gorj County',
                'value': 'GJ'
            },
            {
                'label': 'Harghita County',
                'value': 'HR'
            },
            {
                'label': 'Hunedoara County',
                'value': 'HD'
            },
            {
                'label': 'Ialomița County',
                'value': 'IL'
            },
            {
                'label': 'Iași County',
                'value': 'IS'
            },
            {
                'label': 'Ilfov County',
                'value': 'IF'
            },
            {
                'label': 'Maramureș County',
                'value': 'MM'
            },
            {
                'label': 'Mehedinți County',
                'value': 'MH'
            },
            {
                'label': 'Neamț County',
                'value': 'NT'
            },
            {
                'label': 'Olt County',
                'value': 'OT'
            },
            {
                'label': 'Prahova County',
                'value': 'PH'
            },
            {
                'label': 'Sălaj County',
                'value': 'SJ'
            },
            {
                'label': 'Satu Mare County',
                'value': 'SM'
            },
            {
                'label': 'Sibiu County',
                'value': 'SB'
            },
            {
                'label': 'Suceava County',
                'value': 'SV'
            },
            {
                'label': 'Teleorman County',
                'value': 'TR'
            },
            {
                'label': 'Timiș County',
                'value': 'TM'
            },
            {
                'label': 'Tulcea County',
                'value': 'TL'
            },
            {
                'label': 'Vâlcea County',
                'value': 'VL'
            },
            {
                'label': 'Vaslui County',
                'value': 'VS'
            },
            {
                'label': 'Vrancea County',
                'value': 'VN'
            }
        ]
    },
    {
        'label': 'Russia',
        'iso3': 'RUS',
        'value': 'RU',
        'states': [
            {
                'label': 'Altai Krai',
                'value': 'ALT'
            },
            {
                'label': 'Altai Republic',
                'value': 'AL'
            },
            {
                'label': 'Amur Oblast',
                'value': 'AMU'
            },
            {
                'label': 'Arkhangelsk',
                'value': 'ARK'
            },
            {
                'label': 'Astrakhan Oblast',
                'value': 'AST'
            },
            {
                'label': 'Belgorod Oblast',
                'value': 'BEL'
            },
            {
                'label': 'Bryansk Oblast',
                'value': 'BRY'
            },
            {
                'label': 'Chechen Republic',
                'value': 'CE'
            },
            {
                'label': 'Chelyabinsk Oblast',
                'value': 'CHE'
            },
            {
                'label': 'Chukotka Autonomous Okrug',
                'value': 'CHU'
            },
            {
                'label': 'Chuvash Republic',
                'value': 'CU'
            },
            {
                'label': 'Irkutsk',
                'value': 'IRK'
            },
            {
                'label': 'Ivanovo Oblast',
                'value': 'IVA'
            },
            {
                'label': 'Jewish Autonomous Oblast',
                'value': 'YEV'
            },
            {
                'label': 'Kabardino-Balkar Republic',
                'value': 'KB'
            },
            {
                'label': 'Kaliningrad',
                'value': 'KGD'
            },
            {
                'label': 'Kaluga Oblast',
                'value': 'KLU'
            },
            {
                'label': 'Kamchatka Krai',
                'value': 'KAM'
            },
            {
                'label': 'Karachay-Cherkess Republic',
                'value': 'KC'
            },
            {
                'label': 'Kemerovo Oblast',
                'value': 'KEM'
            },
            {
                'label': 'Khabarovsk Krai',
                'value': 'KHA'
            },
            {
                'label': 'Khanty-Mansi Autonomous Okrug',
                'value': 'KHM'
            },
            {
                'label': 'Kirov Oblast',
                'value': 'KIR'
            },
            {
                'label': 'Komi Republic',
                'value': 'KO'
            },
            {
                'label': 'Kostroma Oblast',
                'value': 'KOS'
            },
            {
                'label': 'Krasnodar Krai',
                'value': 'KDA'
            },
            {
                'label': 'Krasnoyarsk Krai',
                'value': 'KYA'
            },
            {
                'label': 'Kurgan Oblast',
                'value': 'KGN'
            },
            {
                'label': 'Kursk Oblast',
                'value': 'KRS'
            },
            {
                'label': 'Leningrad Oblast',
                'value': 'LEN'
            },
            {
                'label': 'Lipetsk Oblast',
                'value': 'LIP'
            },
            {
                'label': 'Magadan Oblast',
                'value': 'MAG'
            },
            {
                'label': 'Mari El Republic',
                'value': 'ME'
            },
            {
                'label': 'Moscow',
                'value': 'MOW'
            },
            {
                'label': 'Moscow Oblast',
                'value': 'MOS'
            },
            {
                'label': 'Murmansk Oblast',
                'value': 'MUR'
            },
            {
                'label': 'Nenets Autonomous Okrug',
                'value': 'NEN'
            },
            {
                'label': 'Nizhny Novgorod Oblast',
                'value': 'NIZ'
            },
            {
                'label': 'Novgorod Oblast',
                'value': 'NGR'
            },
            {
                'label': 'Novosibirsk',
                'value': 'NVS'
            },
            {
                'label': 'Omsk Oblast',
                'value': 'OMS'
            },
            {
                'label': 'Orenburg Oblast',
                'value': 'ORE'
            },
            {
                'label': 'Oryol Oblast',
                'value': 'ORL'
            },
            {
                'label': 'Penza Oblast',
                'value': 'PNZ'
            },
            {
                'label': 'Perm Krai',
                'value': 'PER'
            },
            {
                'label': 'Primorsky Krai',
                'value': 'PRI'
            },
            {
                'label': 'Pskov Oblast',
                'value': 'PSK'
            },
            {
                'label': 'Republic of Adygea',
                'value': 'AD'
            },
            {
                'label': 'Republic of Bashkortostan',
                'value': 'BA'
            },
            {
                'label': 'Republic of Buryatia',
                'value': 'BU'
            },
            {
                'label': 'Republic of Dagestan',
                'value': 'DA'
            },
            {
                'label': 'Republic of Ingushetia',
                'value': 'IN'
            },
            {
                'label': 'Republic of Kalmykia',
                'value': 'KL'
            },
            {
                'label': 'Republic of Karelia',
                'value': 'KR'
            },
            {
                'label': 'Republic of Khakassia',
                'value': 'KK'
            },
            {
                'label': 'Republic of Mordovia',
                'value': 'MO'
            },
            {
                'label': 'Republic of North Ossetia-Alania',
                'value': 'SE'
            },
            {
                'label': 'Republic of Tatarstan',
                'value': 'TA'
            },
            {
                'label': 'Rostov Oblast',
                'value': 'ROS'
            },
            {
                'label': 'Ryazan Oblast',
                'value': 'RYA'
            },
            {
                'label': 'Saint Petersburg',
                'value': 'SPE'
            },
            {
                'label': 'Sakha Republic',
                'value': 'SA'
            },
            {
                'label': 'Sakhalin',
                'value': 'SAK'
            },
            {
                'label': 'Samara Oblast',
                'value': 'SAM'
            },
            {
                'label': 'Saratov Oblast',
                'value': 'SAR'
            },
            {
                'label': 'Sevastopol',
                'value': 'UA-40'
            },
            {
                'label': 'Smolensk Oblast',
                'value': 'SMO'
            },
            {
                'label': 'Stavropol Krai',
                'value': 'STA'
            },
            {
                'label': 'Sverdlovsk',
                'value': 'SVE'
            },
            {
                'label': 'Tambov Oblast',
                'value': 'TAM'
            },
            {
                'label': 'Tomsk Oblast',
                'value': 'TOM'
            },
            {
                'label': 'Tula Oblast',
                'value': 'TUL'
            },
            {
                'label': 'Tuva Republic',
                'value': 'TY'
            },
            {
                'label': 'Tver Oblast',
                'value': 'TVE'
            },
            {
                'label': 'Tyumen Oblast',
                'value': 'TYU'
            },
            {
                'label': 'Udmurt Republic',
                'value': 'UD'
            },
            {
                'label': 'Ulyanovsk Oblast',
                'value': 'ULY'
            },
            {
                'label': 'Vladimir Oblast',
                'value': 'VLA'
            },
            {
                'label': 'Vologda Oblast',
                'value': 'VLG'
            },
            {
                'label': 'Voronezh Oblast',
                'value': 'VOR'
            },
            {
                'label': 'Yamalo-Nenets Autonomous Okrug',
                'value': 'YAN'
            },
            {
                'label': 'Yaroslavl Oblast',
                'value': 'YAR'
            },
            {
                'label': 'Zabaykalsky Krai',
                'value': 'ZAB'
            }
        ]
    },
    {
        'label': 'Rwanda',
        'iso3': 'RWA',
        'value': 'RW',
        'states': [
            {
                'label': 'Eastern Province',
                'value': '02'
            },
            {
                'label': 'Kigali district',
                'value': '01'
            },
            {
                'label': 'Northern Province',
                'value': '03'
            },
            {
                'label': 'Southern Province',
                'value': '05'
            },
            {
                'label': 'Western Province',
                'value': '04'
            }
        ]
    },
    {
        'label': 'Saint Kitts And Nevis',
        'iso3': 'KNA',
        'value': 'KN',
        'states': [
            {
                'label': 'Christ Church Nichola Town Parish',
                'value': '01'
            },
            {
                'label': 'Nevis',
                'value': 'N'
            },
            {
                'label': 'Saint Anne Sandy Point Parish',
                'value': '02'
            },
            {
                'label': 'Saint George Gingerland Parish',
                'value': '04'
            },
            {
                'label': 'Saint James Windward Parish',
                'value': '05'
            },
            {
                'label': 'Saint John Capisterre Parish',
                'value': '06'
            },
            {
                'label': 'Saint John Figtree Parish',
                'value': '07'
            },
            {
                'label': 'Saint Kitts',
                'value': 'K'
            },
            {
                'label': 'Saint Mary Cayon Parish',
                'value': '08'
            },
            {
                'label': 'Saint Paul Capisterre Parish',
                'value': '09'
            },
            {
                'label': 'Saint Paul Charlestown Parish',
                'value': '10'
            },
            {
                'label': 'Saint Peter Basseterre Parish',
                'value': '11'
            },
            {
                'label': 'Saint Thomas Lowland Parish',
                'value': '12'
            },
            {
                'label': 'Saint Thomas Middle Island Parish',
                'value': '13'
            },
            {
                'label': 'Trinity Palmetto Point Parish',
                'value': '15'
            }
        ]
    },
    {
        'label': 'Saint Lucia',
        'iso3': 'LCA',
        'value': 'LC',
        'states': [
            {
                'label': 'Anse la Raye Quarter',
                'value': '01'
            },
            {
                'label': 'Canaries',
                'value': '12'
            },
            {
                'label': 'Castries Quarter',
                'value': '02'
            },
            {
                'label': 'Choiseul Quarter',
                'value': '03'
            },
            {
                'label': 'Dauphin Quarter',
                'value': '04'
            },
            {
                'label': 'Dennery Quarter',
                'value': '05'
            },
            {
                'label': 'Gros Islet Quarter',
                'value': '06'
            },
            {
                'label': 'Laborie Quarter',
                'value': '07'
            },
            {
                'label': 'Micoud Quarter',
                'value': '08'
            },
            {
                'label': 'Praslin Quarter',
                'value': '09'
            },
            {
                'label': 'Soufrière Quarter',
                'value': '10'
            },
            {
                'label': 'Vieux Fort Quarter',
                'value': '11'
            }
        ]
    },
    {
        'label': 'Saint Vincent And The Grenadines',
        'iso3': 'VCT',
        'value': 'VC',
        'states': [
            {
                'label': 'Charlotte Parish',
                'value': '01'
            },
            {
                'label': 'Grenadines Parish',
                'value': '06'
            },
            {
                'label': 'Saint Andrew Parish',
                'value': '02'
            },
            {
                'label': 'Saint David Parish',
                'value': '03'
            },
            {
                'label': 'Saint George Parish',
                'value': '04'
            },
            {
                'label': 'Saint Patrick Parish',
                'value': '05'
            }
        ]
    },
    {
        'label': 'Samoa',
        'iso3': 'WSM',
        'value': 'WS',
        'states': [
            {
                'label': 'A\'ana',
                'value': 'AA'
            },
            {
                'label': 'Aiga-i-le-Tai',
                'value': 'AL'
            },
            {
                'label': 'Atua',
                'value': 'AT'
            },
            {
                'label': 'Fa\'asaleleaga',
                'value': 'FA'
            },
            {
                'label': 'Gaga\'emauga',
                'value': 'GE'
            },
            {
                'label': 'Gaga\'ifomauga',
                'value': 'GI'
            },
            {
                'label': 'Palauli',
                'value': 'PA'
            },
            {
                'label': 'Satupa\'itea',
                'value': 'SA'
            },
            {
                'label': 'Tuamasaga',
                'value': 'TU'
            },
            {
                'label': 'Va\'a- o - Fonoti',
                'value': 'VF'
            },
            {
                'label': 'Vaisigano',
                'value': 'VS'
            }
        ]
    },
    {
        'label': 'San Marino',
        'iso3': 'SMR',
        'value': 'SM',
        'states': [
            {
                'label': 'Acquaviva',
                'value': '01'
            },
            {
                'label': 'Borgo Maggiore',
                'value': '06'
            },
            {
                'label': 'Chiesanuova',
                'value': '02'
            },
            {
                'label': 'Domagnano',
                'value': '03'
            },
            {
                'label': 'Faetano',
                'value': '04'
            },
            {
                'label': 'Fiorentino',
                'value': '05'
            },
            {
                'label': 'Montegiardino',
                'value': '08'
            },
            {
                'label': 'San Marino',
                'value': '07'
            },
            {
                'label': 'Serravalle',
                'value': '09'
            }
        ]
    },
    {
        'label': 'Sao Tome and Principe',
        'iso3': 'STP',
        'value': 'ST',
        'states': [
            {
                'label': 'Príncipe Province',
                'value': 'P'
            },
            {
                'label': 'São Tomé Province',
                'value': 'S'
            }
        ]
    },
    {
        'label': 'Saudi Arabia',
        'iso3': 'SAU',
        'value': 'SA',
        'states': [
            {
                'label': 'Asir Region',
                'value': '14'
            },
            {
                'label': 'Al Bahah Region',
                'value': '11'
            },
            {
                'label': 'Al Jawf Region',
                'value': '12'
            },
            {
                'label': 'Al Madinah Region',
                'value': '03'
            },
            {
                'label': 'Al-Qassim Region',
                'value': '05'
            },
            {
                'label': 'Eastern Province',
                'value': '04'
            },
            {
                'label': 'Ha\'il Region',
                'value': '06'
            },
            {
                'label': 'Jizan Region',
                'value': '09'
            },
            {
                'label': 'Makkah Region',
                'value': '02'
            },
            {
                'label': 'Najran Region',
                'value': '10'
            },
            {
                'label': 'Northern Borders Region',
                'value': '08'
            },
            {
                'label': 'Riyadh Region',
                'value': '01'
            },
            {
                'label': 'Tabuk Region',
                'value': '07'
            }
        ]
    },
    {
        'label': 'Senegal',
        'iso3': 'SEN',
        'value': 'SN',
        'states': [
            {
                'label': 'Dakar',
                'value': 'DK'
            },
            {
                'label': 'Diourbel Region',
                'value': 'DB'
            },
            {
                'label': 'Fatick',
                'value': 'FK'
            },
            {
                'label': 'Kaffrine',
                'value': 'KA'
            },
            {
                'label': 'Kaolack',
                'value': 'KL'
            },
            {
                'label': 'Kédougou',
                'value': 'KE'
            },
            {
                'label': 'Kolda',
                'value': 'KD'
            },
            {
                'label': 'Louga',
                'value': 'LG'
            },
            {
                'label': 'Matam',
                'value': 'MT'
            },
            {
                'label': 'Saint-Louis',
                'value': 'SL'
            },
            {
                'label': 'Sédhiou',
                'value': 'SE'
            },
            {
                'label': 'Tambacounda Region',
                'value': 'TC'
            },
            {
                'label': 'Thiès Region',
                'value': 'TH'
            },
            {
                'label': 'Ziguinchor',
                'value': 'ZG'
            }
        ]
    },
    {
        'label': 'Serbia',
        'iso3': 'SRB',
        'value': 'RS',
        'states': [
            {
                'label': 'Belgrade',
                'value': '00'
            },
            {
                'label': 'Bor District',
                'value': '14'
            },
            {
                'label': 'Braničevo District',
                'value': '11'
            },
            {
                'label': 'Central Banat District',
                'value': '02'
            },
            {
                'label': 'Jablanica District',
                'value': '23'
            },
            {
                'label': 'Kolubara District',
                'value': '09'
            },
            {
                'label': 'Mačva District',
                'value': '08'
            },
            {
                'label': 'Moravica District',
                'value': '17'
            },
            {
                'label': 'Nišava District',
                'value': '20'
            },
            {
                'label': 'North Bačka District',
                'value': '01'
            },
            {
                'label': 'North Banat District',
                'value': '03'
            },
            {
                'label': 'Pčinja District',
                'value': '24'
            },
            {
                'label': 'Pirot District',
                'value': '22'
            },
            {
                'label': 'Podunavlje District',
                'value': '10'
            },
            {
                'label': 'Pomoravlje District',
                'value': '13'
            },
            {
                'label': 'Rasina District',
                'value': '19'
            },
            {
                'label': 'Raška District',
                'value': '18'
            },
            {
                'label': 'South Bačka District',
                'value': '06'
            },
            {
                'label': 'South Banat District',
                'value': '04'
            },
            {
                'label': 'Srem District',
                'value': '07'
            },
            {
                'label': 'Šumadija District',
                'value': '12'
            },
            {
                'label': 'Toplica District',
                'value': '21'
            },
            {
                'label': 'Vojvodina',
                'value': 'VO'
            },
            {
                'label': 'West Bačka District',
                'value': '05'
            },
            {
                'label': 'Zaječar District',
                'value': '15'
            },
            {
                'label': 'Zlatibor District',
                'value': '16'
            }
        ]
    },
    {
        'label': 'Seychelles',
        'iso3': 'SYC',
        'value': 'SC',
        'states': [
            {
                'label': 'Anse Boileau',
                'value': '02'
            },
            {
                'label': 'Anse Royale',
                'value': '05'
            },
            {
                'label': 'Anse-aux-Pins',
                'value': '01'
            },
            {
                'label': 'Au Cap',
                'value': '04'
            },
            {
                'label': 'Baie Lazare',
                'value': '06'
            },
            {
                'label': 'Baie Sainte Anne',
                'value': '07'
            },
            {
                'label': 'Beau Vallon',
                'value': '08'
            },
            {
                'label': 'Bel Air',
                'value': '09'
            },
            {
                'label': 'Bel Ombre',
                'value': '10'
            },
            {
                'label': 'Cascade',
                'value': '11'
            },
            {
                'label': 'Glacis',
                'value': '12'
            },
            {
                'label': 'Grand\'Anse Mahé',
                'value': '13'
            },
            {
                'label': 'Grand\'Anse Praslin',
                'value': '14'
            },
            {
                'label': 'La Digue',
                'value': '15'
            },
            {
                'label': 'La Rivière Anglaise',
                'value': '16'
            },
            {
                'label': 'Les Mamelles',
                'value': '24'
            },
            {
                'label': 'Mont Buxton',
                'value': '17'
            },
            {
                'label': 'Mont Fleuri',
                'value': '18'
            },
            {
                'label': 'Plaisance',
                'value': '19'
            },
            {
                'label': 'Pointe La Rue',
                'value': '20'
            },
            {
                'label': 'Port Glaud',
                'value': '21'
            },
            {
                'label': 'Roche Caiman',
                'value': '25'
            },
            {
                'label': 'Saint Louis',
                'value': '22'
            },
            {
                'label': 'Takamaka',
                'value': '23'
            }
        ]
    },
    {
        'label': 'Sierra Leone',
        'iso3': 'SLE',
        'value': 'SL',
        'states': [
            {
                'label': 'Eastern Province',
                'value': 'E'
            },
            {
                'label': 'Northern Province',
                'value': 'N'
            },
            {
                'label': 'Southern Province',
                'value': 'S'
            },
            {
                'label': 'Western Area',
                'value': 'W'
            }
        ]
    },
    {
        'label': 'Singapore',
        'iso3': 'SGP',
        'value': 'SG',
        'states': [
            {
                'label': 'Central Singapore Community Development Council',
                'value': '01'
            },
            {
                'label': 'North East Community Development Council',
                'value': '02'
            },
            {
                'label': 'North West Community Development Council',
                'value': '03'
            },
            {
                'label': 'South East Community Development Council',
                'value': '04'
            },
            {
                'label': 'South West Community Development Council',
                'value': '05'
            }
        ]
    },
    {
        'label': 'Slovakia',
        'iso3': 'SVK',
        'value': 'SK',
        'states': [
            {
                'label': 'Banská Bystrica Region',
                'value': 'BC'
            },
            {
                'label': 'Bratislava Region',
                'value': 'BL'
            },
            {
                'label': 'Košice Region',
                'value': 'KI'
            },
            {
                'label': 'Nitra Region',
                'value': 'NI'
            },
            {
                'label': 'Prešov Region',
                'value': 'PV'
            },
            {
                'label': 'Trenčín Region',
                'value': 'TC'
            },
            {
                'label': 'Trnava Region',
                'value': 'TA'
            },
            {
                'label': 'Žilina Region',
                'value': 'ZI'
            }
        ]
    },
    {
        'label': 'Slovenia',
        'iso3': 'SVN',
        'value': 'SI',
        'states': [
            {
                'label': 'Ajdovščina Municipality',
                'value': '001'
            },
            {
                'label': 'Ankaran Municipality',
                'value': '213'
            },
            {
                'label': 'Beltinci Municipality',
                'value': '002'
            },
            {
                'label': 'Benedikt Municipality',
                'value': '148'
            },
            {
                'label': 'Bistrica ob Sotli Municipality',
                'value': '149'
            },
            {
                'label': 'Bled Municipality',
                'value': '003'
            },
            {
                'label': 'Bloke Municipality',
                'value': '150'
            },
            {
                'label': 'Bohinj Municipality',
                'value': '004'
            },
            {
                'label': 'Borovnica Municipality',
                'value': '005'
            },
            {
                'label': 'Bovec Municipality',
                'value': '006'
            },
            {
                'label': 'Braslovče Municipality',
                'value': '151'
            },
            {
                'label': 'Brda Municipality',
                'value': '007'
            },
            {
                'label': 'Brežice Municipality',
                'value': '009'
            },
            {
                'label': 'Brezovica Municipality',
                'value': '008'
            },
            {
                'label': 'Cankova Municipality',
                'value': '152'
            },
            {
                'label': 'Cerklje na Gorenjskem Municipality',
                'value': '012'
            },
            {
                'label': 'Cerknica Municipality',
                'value': '013'
            },
            {
                'label': 'Cerkno Municipality',
                'value': '014'
            },
            {
                'label': 'Cerkvenjak Municipality',
                'value': '153'
            },
            {
                'label': 'City Municipality of Celje',
                'value': '011'
            },
            {
                'label': 'City Municipality of Novo Mesto',
                'value': '085'
            },
            {
                'label': 'Črenšovci Municipality',
                'value': '015'
            },
            {
                'label': 'Črna na Koroškem Municipality',
                'value': '016'
            },
            {
                'label': 'Črnomelj Municipality',
                'value': '017'
            },
            {
                'label': 'Destrnik Municipality',
                'value': '018'
            },
            {
                'label': 'Divača Municipality',
                'value': '019'
            },
            {
                'label': 'Dobje Municipality',
                'value': '154'
            },
            {
                'label': 'Dobrepolje Municipality',
                'value': '020'
            },
            {
                'label': 'Dobrna Municipality',
                'value': '155'
            },
            {
                'label': 'Dobrova–Polhov Gradec Municipality',
                'value': '021'
            },
            {
                'label': 'Dobrovnik Municipality',
                'value': '156'
            },
            {
                'label': 'Dol pri Ljubljani Municipality',
                'value': '022'
            },
            {
                'label': 'Dolenjske Toplice Municipality',
                'value': '157'
            },
            {
                'label': 'Domžale Municipality',
                'value': '023'
            },
            {
                'label': 'Dornava Municipality',
                'value': '024'
            },
            {
                'label': 'Dravograd Municipality',
                'value': '025'
            },
            {
                'label': 'Duplek Municipality',
                'value': '026'
            },
            {
                'label': 'Gorenja Vas–Poljane Municipality',
                'value': '027'
            },
            {
                'label': 'Gorišnica Municipality',
                'value': '028'
            },
            {
                'label': 'Gorje Municipality',
                'value': '207'
            },
            {
                'label': 'Gornja Radgona Municipality',
                'value': '029'
            },
            {
                'label': 'Gornji Grad Municipality',
                'value': '030'
            },
            {
                'label': 'Gornji Petrovci Municipality',
                'value': '031'
            },
            {
                'label': 'Grad Municipality',
                'value': '158'
            },
            {
                'label': 'Grosuplje Municipality',
                'value': '032'
            },
            {
                'label': 'Hajdina Municipality',
                'value': '159'
            },
            {
                'label': 'Hoče–Slivnica Municipality',
                'value': '160'
            },
            {
                'label': 'Hodoš Municipality',
                'value': '161'
            },
            {
                'label': 'Horjul Municipality',
                'value': '162'
            },
            {
                'label': 'Hrastnik Municipality',
                'value': '034'
            },
            {
                'label': 'Hrpelje–Kozina Municipality',
                'value': '035'
            },
            {
                'label': 'Idrija Municipality',
                'value': '036'
            },
            {
                'label': 'Ig Municipality',
                'value': '037'
            },
            {
                'label': 'Ivančna Gorica Municipality',
                'value': '039'
            },
            {
                'label': 'Izola Municipality',
                'value': '040'
            },
            {
                'label': 'Jesenice Municipality',
                'value': '041'
            },
            {
                'label': 'Jezersko Municipality',
                'value': '163'
            },
            {
                'label': 'Juršinci Municipality',
                'value': '042'
            },
            {
                'label': 'Kamnik Municipality',
                'value': '043'
            },
            {
                'label': 'Kanal ob Soči Municipality',
                'value': '044'
            },
            {
                'label': 'Kidričevo Municipality',
                'value': '045'
            },
            {
                'label': 'Kobarid Municipality',
                'value': '046'
            },
            {
                'label': 'Kobilje Municipality',
                'value': '047'
            },
            {
                'label': 'Kočevje Municipality',
                'value': '048'
            },
            {
                'label': 'Komen Municipality',
                'value': '049'
            },
            {
                'label': 'Komenda Municipality',
                'value': '164'
            },
            {
                'label': 'Koper City Municipality',
                'value': '050'
            },
            {
                'label': 'Kostanjevica na Krki Municipality',
                'value': '197'
            },
            {
                'label': 'Kostel Municipality',
                'value': '165'
            },
            {
                'label': 'Kozje Municipality',
                'value': '051'
            },
            {
                'label': 'Kranj City Municipality',
                'value': '052'
            },
            {
                'label': 'Kranjska Gora Municipality',
                'value': '053'
            },
            {
                'label': 'Križevci Municipality',
                'value': '166'
            },
            {
                'label': 'Kungota',
                'value': '055'
            },
            {
                'label': 'Kuzma Municipality',
                'value': '056'
            },
            {
                'label': 'Laško Municipality',
                'value': '057'
            },
            {
                'label': 'Lenart Municipality',
                'value': '058'
            },
            {
                'label': 'Lendava Municipality',
                'value': '059'
            },
            {
                'label': 'Litija Municipality',
                'value': '060'
            },
            {
                'label': 'Ljubljana City Municipality',
                'value': '061'
            },
            {
                'label': 'Ljubno Municipality',
                'value': '062'
            },
            {
                'label': 'Ljutomer Municipality',
                'value': '063'
            },
            {
                'label': 'Log–Dragomer Municipality',
                'value': '208'
            },
            {
                'label': 'Logatec Municipality',
                'value': '064'
            },
            {
                'label': 'Loška Dolina Municipality',
                'value': '065'
            },
            {
                'label': 'Loški Potok Municipality',
                'value': '066'
            },
            {
                'label': 'Lovrenc na Pohorju Municipality',
                'value': '167'
            },
            {
                'label': 'Luče Municipality',
                'value': '067'
            },
            {
                'label': 'Lukovica Municipality',
                'value': '068'
            },
            {
                'label': 'Majšperk Municipality',
                'value': '069'
            },
            {
                'label': 'Makole Municipality',
                'value': '198'
            },
            {
                'label': 'Maribor City Municipality',
                'value': '070'
            },
            {
                'label': 'Markovci Municipality',
                'value': '168'
            },
            {
                'label': 'Medvode Municipality',
                'value': '071'
            },
            {
                'label': 'Mengeš Municipality',
                'value': '072'
            },
            {
                'label': 'Metlika Municipality',
                'value': '073'
            },
            {
                'label': 'Mežica Municipality',
                'value': '074'
            },
            {
                'label': 'Miklavž na Dravskem Polju Municipality',
                'value': '169'
            },
            {
                'label': 'Miren–Kostanjevica Municipality',
                'value': '075'
            },
            {
                'label': 'Mirna Municipality',
                'value': '212'
            },
            {
                'label': 'Mirna Peč Municipality',
                'value': '170'
            },
            {
                'label': 'Mislinja Municipality',
                'value': '076'
            },
            {
                'label': 'Mokronog–Trebelno Municipality',
                'value': '199'
            },
            {
                'label': 'Moravče Municipality',
                'value': '077'
            },
            {
                'label': 'Moravske Toplice Municipality',
                'value': '078'
            },
            {
                'label': 'Mozirje Municipality',
                'value': '079'
            },
            {
                'label': 'Municipality of Apače',
                'value': '195'
            },
            {
                'label': 'Municipality of Cirkulane',
                'value': '196'
            },
            {
                'label': 'Municipality of Ilirska Bistrica',
                'value': '038'
            },
            {
                'label': 'Municipality of Krško',
                'value': '054'
            },
            {
                'label': 'Municipality of Škofljica',
                'value': '123'
            },
            {
                'label': 'Murska Sobota City Municipality',
                'value': '080'
            },
            {
                'label': 'Muta Municipality',
                'value': '081'
            },
            {
                'label': 'Naklo Municipality',
                'value': '082'
            },
            {
                'label': 'Nazarje Municipality',
                'value': '083'
            },
            {
                'label': 'Nova Gorica City Municipality',
                'value': '084'
            },
            {
                'label': 'Odranci Municipality',
                'value': '086'
            },
            {
                'label': 'Oplotnica',
                'value': '171'
            },
            {
                'label': 'Ormož Municipality',
                'value': '087'
            },
            {
                'label': 'Osilnica Municipality',
                'value': '088'
            },
            {
                'label': 'Pesnica Municipality',
                'value': '089'
            },
            {
                'label': 'Piran Municipality',
                'value': '090'
            },
            {
                'label': 'Pivka Municipality',
                'value': '091'
            },
            {
                'label': 'Podčetrtek Municipality',
                'value': '092'
            },
            {
                'label': 'Podlehnik Municipality',
                'value': '172'
            },
            {
                'label': 'Podvelka Municipality',
                'value': '093'
            },
            {
                'label': 'Poljčane Municipality',
                'value': '200'
            },
            {
                'label': 'Polzela Municipality',
                'value': '173'
            },
            {
                'label': 'Postojna Municipality',
                'value': '094'
            },
            {
                'label': 'Prebold Municipality',
                'value': '174'
            },
            {
                'label': 'Preddvor Municipality',
                'value': '095'
            },
            {
                'label': 'Prevalje Municipality',
                'value': '175'
            },
            {
                'label': 'Ptuj City Municipality',
                'value': '096'
            },
            {
                'label': 'Puconci Municipality',
                'value': '097'
            },
            {
                'label': 'Rače–Fram Municipality',
                'value': '098'
            },
            {
                'label': 'Radeče Municipality',
                'value': '099'
            },
            {
                'label': 'Radenci Municipality',
                'value': '100'
            },
            {
                'label': 'Radlje ob Dravi Municipality',
                'value': '101'
            },
            {
                'label': 'Radovljica Municipality',
                'value': '102'
            },
            {
                'label': 'Ravne na Koroškem Municipality',
                'value': '103'
            },
            {
                'label': 'Razkrižje Municipality',
                'value': '176'
            },
            {
                'label': 'Rečica ob Savinji Municipality',
                'value': '209'
            },
            {
                'label': 'Renče–Vogrsko Municipality',
                'value': '201'
            },
            {
                'label': 'Ribnica Municipality',
                'value': '104'
            },
            {
                'label': 'Ribnica na Pohorju Municipality',
                'value': '177'
            },
            {
                'label': 'Rogaška Slatina Municipality',
                'value': '106'
            },
            {
                'label': 'Rogašovci Municipality',
                'value': '105'
            },
            {
                'label': 'Rogatec Municipality',
                'value': '107'
            },
            {
                'label': 'Ruše Municipality',
                'value': '108'
            },
            {
                'label': 'Šalovci Municipality',
                'value': '033'
            },
            {
                'label': 'Selnica ob Dravi Municipality',
                'value': '178'
            },
            {
                'label': 'Semič Municipality',
                'value': '109'
            },
            {
                'label': 'Šempeter–Vrtojba Municipality',
                'value': '183'
            },
            {
                'label': 'Šenčur Municipality',
                'value': '117'
            },
            {
                'label': 'Šentilj Municipality',
                'value': '118'
            },
            {
                'label': 'Šentjernej Municipality',
                'value': '119'
            },
            {
                'label': 'Šentjur Municipality',
                'value': '120'
            },
            {
                'label': 'Šentrupert Municipality',
                'value': '211'
            },
            {
                'label': 'Sevnica Municipality',
                'value': '110'
            },
            {
                'label': 'Sežana Municipality',
                'value': '111'
            },
            {
                'label': 'Škocjan Municipality',
                'value': '121'
            },
            {
                'label': 'Škofja Loka Municipality',
                'value': '122'
            },
            {
                'label': 'Slovenj Gradec City Municipality',
                'value': '112'
            },
            {
                'label': 'Slovenska Bistrica Municipality',
                'value': '113'
            },
            {
                'label': 'Slovenske Konjice Municipality',
                'value': '114'
            },
            {
                'label': 'Šmarje pri Jelšah Municipality',
                'value': '124'
            },
            {
                'label': 'Šmarješke Toplice Municipality',
                'value': '206'
            },
            {
                'label': 'Šmartno ob Paki Municipality',
                'value': '125'
            },
            {
                'label': 'Šmartno pri Litiji Municipality',
                'value': '194'
            },
            {
                'label': 'Sodražica Municipality',
                'value': '179'
            },
            {
                'label': 'Solčava Municipality',
                'value': '180'
            },
            {
                'label': 'Šoštanj Municipality',
                'value': '126'
            },
            {
                'label': 'Središče ob Dravi',
                'value': '202'
            },
            {
                'label': 'Starše Municipality',
                'value': '115'
            },
            {
                'label': 'Štore Municipality',
                'value': '127'
            },
            {
                'label': 'Straža Municipality',
                'value': '203'
            },
            {
                'label': 'Sveta Ana Municipality',
                'value': '181'
            },
            {
                'label': 'Sveta Trojica v Slovenskih Goricah Municipality',
                'value': '204'
            },
            {
                'label': 'Sveti Andraž v Slovenskih Goricah Municipality',
                'value': '182'
            },
            {
                'label': 'Sveti Jurij ob Ščavnici Municipality',
                'value': '116'
            },
            {
                'label': 'Sveti Jurij v Slovenskih Goricah Municipality',
                'value': '210'
            },
            {
                'label': 'Sveti Tomaž Municipality',
                'value': '205'
            },
            {
                'label': 'Tabor Municipality',
                'value': '184'
            },
            {
                'label': 'Tišina Municipality',
                'value': '010'
            },
            {
                'label': 'Tolmin Municipality',
                'value': '128'
            },
            {
                'label': 'Trbovlje Municipality',
                'value': '129'
            },
            {
                'label': 'Trebnje Municipality',
                'value': '130'
            },
            {
                'label': 'Trnovska Vas Municipality',
                'value': '185'
            },
            {
                'label': 'Tržič Municipality',
                'value': '131'
            },
            {
                'label': 'Trzin Municipality',
                'value': '186'
            },
            {
                'label': 'Turnišče Municipality',
                'value': '132'
            },
            {
                'label': 'Velika Polana Municipality',
                'value': '187'
            },
            {
                'label': 'Velike Lašče Municipality',
                'value': '134'
            },
            {
                'label': 'Veržej Municipality',
                'value': '188'
            },
            {
                'label': 'Videm Municipality',
                'value': '135'
            },
            {
                'label': 'Vipava Municipality',
                'value': '136'
            },
            {
                'label': 'Vitanje Municipality',
                'value': '137'
            },
            {
                'label': 'Vodice Municipality',
                'value': '138'
            },
            {
                'label': 'Vojnik Municipality',
                'value': '139'
            },
            {
                'label': 'Vransko Municipality',
                'value': '189'
            },
            {
                'label': 'Vrhnika Municipality',
                'value': '140'
            },
            {
                'label': 'Vuzenica Municipality',
                'value': '141'
            },
            {
                'label': 'Zagorje ob Savi Municipality',
                'value': '142'
            },
            {
                'label': 'Žalec Municipality',
                'value': '190'
            },
            {
                'label': 'Zavrč Municipality',
                'value': '143'
            },
            {
                'label': 'Železniki Municipality',
                'value': '146'
            },
            {
                'label': 'Žetale Municipality',
                'value': '191'
            },
            {
                'label': 'Žiri Municipality',
                'value': '147'
            },
            {
                'label': 'Žirovnica Municipality',
                'value': '192'
            },
            {
                'label': 'Zreče Municipality',
                'value': '144'
            },
            {
                'label': 'Žužemberk Municipality',
                'value': '193'
            }
        ]
    },
    {
        'label': 'Solomon Islands',
        'iso3': 'SLB',
        'value': 'SB',
        'states': [
            {
                'label': 'Central Province',
                'value': 'CE'
            },
            {
                'label': 'Choiseul Province',
                'value': 'CH'
            },
            {
                'label': 'Guadalcanal Province',
                'value': 'GU'
            },
            {
                'label': 'Honiara',
                'value': 'CT'
            },
            {
                'label': 'Isabel Province',
                'value': 'IS'
            },
            {
                'label': 'Makira-Ulawa Province',
                'value': 'MK'
            },
            {
                'label': 'Malaita Province',
                'value': 'ML'
            },
            {
                'label': 'Rennell and Bellona Province',
                'value': 'RB'
            },
            {
                'label': 'Temotu Province',
                'value': 'TE'
            },
            {
                'label': 'Western Province',
                'value': 'WE'
            }
        ]
    },
    {
        'label': 'Somalia',
        'iso3': 'SOM',
        'value': 'SO',
        'states': [
            {
                'label': 'Awdal Region',
                'value': 'AW'
            },
            {
                'label': 'Bakool',
                'value': 'BK'
            },
            {
                'label': 'Banaadir',
                'value': 'BN'
            },
            {
                'label': 'Bari',
                'value': 'BR'
            },
            {
                'label': 'Bay',
                'value': 'BY'
            },
            {
                'label': 'Galguduud',
                'value': 'GA'
            },
            {
                'label': 'Gedo',
                'value': 'GE'
            },
            {
                'label': 'Hiran',
                'value': 'HI'
            },
            {
                'label': 'Lower Juba',
                'value': 'JH'
            },
            {
                'label': 'Lower Shebelle',
                'value': 'SH'
            },
            {
                'label': 'Middle Juba',
                'value': 'JD'
            },
            {
                'label': 'Middle Shebelle',
                'value': 'SD'
            },
            {
                'label': 'Mudug',
                'value': 'MU'
            },
            {
                'label': 'Nugal',
                'value': 'NU'
            },
            {
                'label': 'Sanaag Region',
                'value': 'SA'
            },
            {
                'label': 'Togdheer Region',
                'value': 'TO'
            }
        ]
    },
    {
        'label': 'South Africa',
        'iso3': 'ZAF',
        'value': 'ZA',
        'states': [
            {
                'label': 'Eastern Cape',
                'value': 'EC'
            },
            {
                'label': 'Free State',
                'value': 'FS'
            },
            {
                'label': 'Gauteng',
                'value': 'GP'
            },
            {
                'label': 'KwaZulu-Natal',
                'value': 'KZN'
            },
            {
                'label': 'Limpopo',
                'value': 'LP'
            },
            {
                'label': 'Mpumalanga',
                'value': 'MP'
            },
            {
                'label': 'North West',
                'value': 'NW'
            },
            {
                'label': 'Northern Cape',
                'value': 'NC'
            },
            {
                'label': 'Western Cape',
                'value': 'WC'
            }
        ]
    },
    {
        'label': 'South Sudan',
        'iso3': 'SSD',
        'value': 'SS',
        'states': [
            {
                'label': 'Central Equatoria',
                'value': 'EC'
            },
            {
                'label': 'Eastern Equatoria',
                'value': 'EE'
            },
            {
                'label': 'Jonglei State',
                'value': 'JG'
            },
            {
                'label': 'Lakes',
                'value': 'LK'
            },
            {
                'label': 'Northern Bahr el Ghazal',
                'value': 'BN'
            },
            {
                'label': 'Unity',
                'value': 'UY'
            },
            {
                'label': 'Upper Nile',
                'value': 'NU'
            },
            {
                'label': 'Warrap',
                'value': 'WR'
            },
            {
                'label': 'Western Bahr el Ghazal',
                'value': 'BW'
            },
            {
                'label': 'Western Equatoria',
                'value': 'EW'
            }
        ]
    },
    {
        'label': 'Spain',
        'iso3': 'ESP',
        'value': 'ES',
        'states': [
            {
                'label': 'A Coruña Province',
                'value': 'C'
            },
            {
                'label': 'Albacete Province',
                'value': 'AB'
            },
            {
                'label': 'Alicante Province',
                'value': 'A'
            },
            {
                'label': 'Almería Province',
                'value': 'AL'
            },
            {
                'label': 'Andalusia',
                'value': 'AN'
            },
            {
                'label': 'Araba Álava Province',
                'value': 'VI'
            },
            {
                'label': 'Aragon',
                'value': 'AR'
            },
            {
                'label': 'Badajoz Province',
                'value': 'BA'
            },
            {
                'label': 'Balearic Islands',
                'value': 'PM'
            },
            {
                'label': 'Barcelona Province',
                'value': 'B'
            },
            {
                'label': 'Basque Country',
                'value': 'PV'
            },
            {
                'label': 'Biscay Province',
                'value': 'BI'
            },
            {
                'label': 'Burgos Province',
                'value': 'BU'
            },
            {
                'label': 'Cáceres Province',
                'value': 'CC'
            },
            {
                'label': 'Cádiz Province',
                'value': 'CA'
            },
            {
                'label': 'Canary Islands',
                'value': 'CN'
            },
            {
                'label': 'Cantabria',
                'value': 'S'
            },
            {
                'label': 'Castellón Province',
                'value': 'CS'
            },
            {
                'label': 'Castile and León',
                'value': 'CL'
            },
            {
                'label': 'Castile-La Mancha',
                'value': 'CM'
            },
            {
                'label': 'Catalonia',
                'value': 'CT'
            },
            {
                'label': 'Ceuta',
                'value': 'CE'
            },
            {
                'label': 'Ciudad Real Province',
                'value': 'CR'
            },
            {
                'label': 'Community of Madrid',
                'value': 'MD'
            },
            {
                'label': 'Córdoba Province',
                'value': 'CO'
            },
            {
                'label': 'Cuenca Province',
                'value': 'CU'
            },
            {
                'label': 'Extremadura',
                'value': 'EX'
            },
            {
                'label': 'Galicia',
                'value': 'GA'
            },
            {
                'label': 'Gipuzkoa Province',
                'value': 'SS'
            },
            {
                'label': 'Girona Province',
                'value': 'GI'
            },
            {
                'label': 'Granada Province',
                'value': 'GR'
            },
            {
                'label': 'Guadalajara Province',
                'value': 'GU'
            },
            {
                'label': 'Huelva Province',
                'value': 'H'
            },
            {
                'label': 'Huesca Province',
                'value': 'HU'
            },
            {
                'label': 'Jaén Province',
                'value': 'J'
            },
            {
                'label': 'La Rioja',
                'value': 'RI'
            },
            {
                'label': 'Las Palmas Province',
                'value': 'GC'
            },
            {
                'label': 'León Province',
                'value': 'LE'
            },
            {
                'label': 'Lleida Province',
                'value': 'L'
            },
            {
                'label': 'Lugo Province',
                'value': 'LU'
            },
            {
                'label': 'Madrid Province',
                'value': 'M'
            },
            {
                'label': 'Málaga Province',
                'value': 'MA'
            },
            {
                'label': 'Melilla',
                'value': 'ML'
            },
            {
                'label': 'Murcia Province',
                'value': 'MU'
            },
            {
                'label': 'Navarre',
                'value': 'NC'
            },
            {
                'label': 'Ourense Province',
                'value': 'OR'
            },
            {
                'label': 'Palencia Province',
                'value': 'P'
            },
            {
                'label': 'Pontevedra Province',
                'value': 'PO'
            },
            {
                'label': 'Province of Asturias',
                'value': 'O'
            },
            {
                'label': 'Province of Ávila',
                'value': 'AV'
            },
            {
                'label': 'Region of Murcia',
                'value': 'MC'
            },
            {
                'label': 'Salamanca Province',
                'value': 'SA'
            },
            {
                'label': 'Santa Cruz de Tenerife Province',
                'value': 'TF'
            },
            {
                'label': 'Segovia Province',
                'value': 'SG'
            },
            {
                'label': 'Seville Province',
                'value': 'SE'
            },
            {
                'label': 'Soria Province',
                'value': 'SO'
            },
            {
                'label': 'Tarragona Province',
                'value': 'T'
            },
            {
                'label': 'Teruel Province',
                'value': 'TE'
            },
            {
                'label': 'Toledo Province',
                'value': 'TO'
            },
            {
                'label': 'Valencia Province',
                'value': 'V'
            },
            {
                'label': 'Valencian Community',
                'value': 'VC'
            },
            {
                'label': 'Valladolid Province',
                'value': 'VA'
            },
            {
                'label': 'Zamora Province',
                'value': 'ZA'
            },
            {
                'label': 'Zaragoza Province',
                'value': 'Z'
            }
        ]
    },
    {
        'label': 'Sri Lanka',
        'iso3': 'LKA',
        'value': 'LK',
        'states': [
            {
                'label': 'Ampara District',
                'value': '52'
            },
            {
                'label': 'Anuradhapura District',
                'value': '71'
            },
            {
                'label': 'Badulla District',
                'value': '81'
            },
            {
                'label': 'Batticaloa District',
                'value': '51'
            },
            {
                'label': 'Central Province',
                'value': '2'
            },
            {
                'label': 'Colombo District',
                'value': '11'
            },
            {
                'label': 'Eastern Province',
                'value': '5'
            },
            {
                'label': 'Galle District',
                'value': '31'
            },
            {
                'label': 'Gampaha District',
                'value': '12'
            },
            {
                'label': 'Hambantota District',
                'value': '33'
            },
            {
                'label': 'Jaffna District',
                'value': '41'
            },
            {
                'label': 'Kalutara District',
                'value': '13'
            },
            {
                'label': 'Kandy District',
                'value': '21'
            },
            {
                'label': 'Kegalle District',
                'value': '92'
            },
            {
                'label': 'Kilinochchi District',
                'value': '42'
            },
            {
                'label': 'Mannar District',
                'value': '43'
            },
            {
                'label': 'Matale District',
                'value': '22'
            },
            {
                'label': 'Matara District',
                'value': '32'
            },
            {
                'label': 'Monaragala District',
                'value': '82'
            },
            {
                'label': 'Mullaitivu District',
                'value': '45'
            },
            {
                'label': 'North Central Province',
                'value': '7'
            },
            {
                'label': 'North Western Province',
                'value': '6'
            },
            {
                'label': 'Northern Province',
                'value': '4'
            },
            {
                'label': 'Nuwara Eliya District',
                'value': '23'
            },
            {
                'label': 'Polonnaruwa District',
                'value': '72'
            },
            {
                'label': 'Puttalam District',
                'value': '62'
            },
            {
                'label': 'Ratnapura district',
                'value': '91'
            },
            {
                'label': 'Sabaragamuwa Province',
                'value': '9'
            },
            {
                'label': 'Southern Province',
                'value': '3'
            },
            {
                'label': 'Trincomalee District',
                'value': '53'
            },
            {
                'label': 'Uva Province',
                'value': '8'
            },
            {
                'label': 'Vavuniya District',
                'value': '44'
            },
            {
                'label': 'Western Province',
                'value': '1'
            }
        ]
    },
    {
        'label': 'Sudan',
        'iso3': 'SDN',
        'value': 'SD',
        'states': [
            {
                'label': 'Al Jazirah',
                'value': 'GZ'
            },
            {
                'label': 'Al Qadarif',
                'value': 'GD'
            },
            {
                'label': 'Blue Nile',
                'value': 'NB'
            },
            {
                'label': 'Central Darfur',
                'value': 'DC'
            },
            {
                'label': 'East Darfur',
                'value': 'DE'
            },
            {
                'label': 'Kassala',
                'value': 'KA'
            },
            {
                'label': 'Khartoum',
                'value': 'KH'
            },
            {
                'label': 'North Darfur',
                'value': 'DN'
            },
            {
                'label': 'North Kordofan',
                'value': 'KN'
            },
            {
                'label': 'Northern',
                'value': 'NO'
            },
            {
                'label': 'Red Sea',
                'value': 'RS'
            },
            {
                'label': 'River Nile',
                'value': 'NR'
            },
            {
                'label': 'Sennar',
                'value': 'SI'
            },
            {
                'label': 'South Darfur',
                'value': 'DS'
            },
            {
                'label': 'South Kordofan',
                'value': 'KS'
            },
            {
                'label': 'West Darfur',
                'value': 'DW'
            },
            {
                'label': 'West Kordofan',
                'value': 'GK'
            },
            {
                'label': 'White Nile',
                'value': 'NW'
            }
        ]
    },
    {
        'label': 'Surilabel',
        'iso3': 'SUR',
        'value': 'SR',
        'states': [
            {
                'label': 'Brokopondo District',
                'value': 'BR'
            },
            {
                'label': 'Commewijne District',
                'value': 'CM'
            },
            {
                'label': 'Coronie District',
                'value': 'CR'
            },
            {
                'label': 'Marowijne District',
                'value': 'MA'
            },
            {
                'label': 'Nickerie District',
                'value': 'NI'
            },
            {
                'label': 'Para District',
                'value': 'PR'
            },
            {
                'label': 'Paramaribo District',
                'value': 'PM'
            },
            {
                'label': 'Saramacca District',
                'value': 'SA'
            },
            {
                'label': 'Sipaliwini District',
                'value': 'SI'
            },
            {
                'label': 'Wanica District',
                'value': 'WA'
            }
        ]
    },
    {
        'label': 'Swaziland',
        'iso3': 'SWZ',
        'value': 'SZ',
        'states': [
            {
                'label': 'Hhohho District',
                'value': 'HH'
            },
            {
                'label': 'Lubombo District',
                'value': 'LU'
            },
            {
                'label': 'Manzini District',
                'value': 'MA'
            },
            {
                'label': 'Shiselweni District',
                'value': 'SH'
            }
        ]
    },
    {
        'label': 'Sweden',
        'iso3': 'SWE',
        'value': 'SE',
        'states': [
            {
                'label': 'Blekinge',
                'value': 'K'
            },
            {
                'label': 'Dalarna County',
                'value': 'W'
            },
            {
                'label': 'Gävleborg County',
                'value': 'X'
            },
            {
                'label': 'Gotland County',
                'value': 'I'
            },
            {
                'label': 'Halland County',
                'value': 'N'
            },
            {
                'label': 'Jönköping County',
                'value': 'F'
            },
            {
                'label': 'Kalmar County',
                'value': 'H'
            },
            {
                'label': 'Kronoberg County',
                'value': 'G'
            },
            {
                'label': 'Norrbotten County',
                'value': 'BD'
            },
            {
                'label': 'Örebro County',
                'value': 'T'
            },
            {
                'label': 'Östergötland County',
                'value': 'E'
            },
            {
                'label': 'Skåne County',
                'value': 'M'
            },
            {
                'label': 'Södermanland County',
                'value': 'D'
            },
            {
                'label': 'Stockholm County',
                'value': 'AB'
            },
            {
                'label': 'Uppsala County',
                'value': 'C'
            },
            {
                'label': 'Värmland County',
                'value': 'S'
            },
            {
                'label': 'Västerbotten County',
                'value': 'AC'
            },
            {
                'label': 'Västernorrland County',
                'value': 'Y'
            },
            {
                'label': 'Västmanland County',
                'value': 'U'
            },
            {
                'label': 'Västra Götaland County',
                'value': 'O'
            }
        ]
    },
    {
        'label': 'Switzerland',
        'iso3': 'CHE',
        'value': 'CH',
        'states': [
            {
                'label': 'Aargau',
                'value': 'AG'
            },
            {
                'label': 'Appenzell Ausserrhoden',
                'value': 'AR'
            },
            {
                'label': 'Appenzell Innerrhoden',
                'value': 'AI'
            },
            {
                'label': 'Basel-Landschaft',
                'value': 'BL'
            },
            {
                'label': 'Basel-Stadt',
                'value': 'BS'
            },
            {
                'label': 'canton of Bern',
                'value': 'BE'
            },
            {
                'label': 'Canton of Fribourg',
                'value': 'FR'
            },
            {
                'label': 'Canton of Geneva',
                'value': 'GE'
            },
            {
                'label': 'Canton of Jura',
                'value': 'JU'
            },
            {
                'label': 'Canton of Lucerne',
                'value': 'LU'
            },
            {
                'label': 'Canton of Neuchâtel',
                'value': 'NE'
            },
            {
                'label': 'Canton of Schaffhausen',
                'value': 'SH'
            },
            {
                'label': 'Canton of Solothurn',
                'value': 'SO'
            },
            {
                'label': 'Canton of St. Gallen',
                'value': 'SG'
            },
            {
                'label': 'Canton of Valais',
                'value': 'VS'
            },
            {
                'label': 'Canton of Vaud',
                'value': 'VD'
            },
            {
                'label': 'Canton of Zug',
                'value': 'ZG'
            },
            {
                'label': 'canton of Zürich',
                'value': 'ZH'
            },
            {
                'label': 'Glarus',
                'value': 'GL'
            },
            {
                'label': 'Graubünden',
                'value': 'GR'
            },
            {
                'label': 'Nidwalden',
                'value': 'NW'
            },
            {
                'label': 'Obwalden',
                'value': 'OW'
            },
            {
                'label': 'Schwyz',
                'value': 'SZ'
            },
            {
                'label': 'Thurgau',
                'value': 'TG'
            },
            {
                'label': 'Ticino',
                'value': 'TI'
            },
            {
                'label': 'Uri',
                'value': 'UR'
            }
        ]
    },
    {
        'label': 'Syria',
        'iso3': 'SYR',
        'value': 'SY',
        'states': [
            {
                'label': 'Al-Hasakah Governorate',
                'value': 'HA'
            },
            {
                'label': 'Al-Raqqah Governorate',
                'value': 'RA'
            },
            {
                'label': 'Aleppo Governorate',
                'value': 'HL'
            },
            {
                'label': 'As-Suwayda Governorate',
                'value': 'SU'
            },
            {
                'label': 'Damascus Governorate',
                'value': 'DI'
            },
            {
                'label': 'Daraa Governorate',
                'value': 'DR'
            },
            {
                'label': 'Deir ez-Zor Governorate',
                'value': 'DY'
            },
            {
                'label': 'Hama Governorate',
                'value': 'HM'
            },
            {
                'label': 'Homs Governorate',
                'value': 'HI'
            },
            {
                'label': 'Idlib Governorate',
                'value': 'ID'
            },
            {
                'label': 'Latakia Governorate',
                'value': 'LA'
            },
            {
                'label': 'Quneitra Governorate',
                'value': 'QU'
            },
            {
                'label': 'Rif Dimashq Governorate',
                'value': 'RD'
            },
            {
                'label': 'Tartus Governorate',
                'value': 'TA'
            }
        ]
    },
    {
        'label': 'Taiwan',
        'iso3': 'TWN',
        'value': 'TW',
        'states': [
            {
                'label': 'Changhua County',
                'value': 'CHA'
            },
            {
                'label': 'Chiayi City',
                'value': 'CYQ'
            },
            {
                'label': 'Chiayi County',
                'value': 'CYI'
            },
            {
                'label': 'Hsinchu',
                'value': 'HSZ'
            },
            {
                'label': 'Hsinchu County',
                'value': 'HSQ'
            },
            {
                'label': 'Hualien County',
                'value': 'HUA'
            },
            {
                'label': 'Kaohsiung',
                'value': 'KHH'
            },
            {
                'label': 'Kaohsiung County',
                'value': 'KHQ'
            },
            {
                'label': 'Kinmen',
                'value': 'KIN'
            },
            {
                'label': 'Lienchiang County',
                'value': 'LIE'
            },
            {
                'label': 'Miaoli County',
                'value': 'MIA'
            },
            {
                'label': 'Nantou County',
                'value': 'NAN'
            },
            {
                'label': 'Penghu County',
                'value': 'PEN'
            },
            {
                'label': 'Pingtung County',
                'value': 'PIF'
            },
            {
                'label': 'Taichung',
                'value': 'TXG'
            },
            {
                'label': 'Taichung County',
                'value': 'TXQ'
            },
            {
                'label': 'Tainan',
                'value': 'TNN'
            },
            {
                'label': 'Tainan County',
                'value': 'TNQ'
            },
            {
                'label': 'Taipei',
                'value': 'TPE'
            },
            {
                'label': 'Taitung County',
                'value': 'TTT'
            },
            {
                'label': 'Taoyuan City',
                'value': 'TAO'
            },
            {
                'label': 'Yilan County',
                'value': 'ILA'
            },
            {
                'label': 'Yunlin County',
                'value': 'YUN'
            }
        ]
    },
    {
        'label': 'Tajikistan',
        'iso3': 'TJK',
        'value': 'TJ',
        'states': [
            {
                'label': 'districts of Republican Subordination',
                'value': 'RA'
            },
            {
                'label': 'Gorno-Badakhshan Autonomous Province',
                'value': 'GB'
            },
            {
                'label': 'Khatlon Province',
                'value': 'KT'
            },
            {
                'label': 'Sughd Province',
                'value': 'SU'
            }
        ]
    },
    {
        'label': 'Tanzania',
        'iso3': 'TZA',
        'value': 'TZ',
        'states': [
            {
                'label': 'Arusha Region',
                'value': '01'
            },
            {
                'label': 'Dar es Salaam Region',
                'value': '02'
            },
            {
                'label': 'Dodoma Region',
                'value': '03'
            },
            {
                'label': 'Geita Region',
                'value': '27'
            },
            {
                'label': 'Iringa Region',
                'value': '04'
            },
            {
                'label': 'Kagera Region',
                'value': '05'
            },
            {
                'label': 'Katavi Region',
                'value': '28'
            },
            {
                'label': 'Kigoma Region',
                'value': '08'
            },
            {
                'label': 'Kilimanjaro Region',
                'value': '09'
            },
            {
                'label': 'Lindi Region',
                'value': '12'
            },
            {
                'label': 'Manyara Region',
                'value': '26'
            },
            {
                'label': 'Mara Region',
                'value': '13'
            },
            {
                'label': 'Morogoro Region',
                'value': '16'
            },
            {
                'label': 'Mtwara Region',
                'value': '17'
            },
            {
                'label': 'Mwanza Region',
                'value': '18'
            },
            {
                'label': 'Njombe Region',
                'value': '29'
            },
            {
                'label': 'North Pemba Region',
                'value': '06'
            },
            {
                'label': 'Pwani Region',
                'value': '19'
            },
            {
                'label': 'Rukwa Region',
                'value': '20'
            },
            {
                'label': 'Ruvuma Region',
                'value': '21'
            },
            {
                'label': 'Shinyanga Region',
                'value': '22'
            },
            {
                'label': 'Simiyu Region',
                'value': '30'
            },
            {
                'label': 'Singida Region',
                'value': '23'
            },
            {
                'label': 'South Pemba Region',
                'value': '10'
            },
            {
                'label': 'Tabora Region',
                'value': '24'
            },
            {
                'label': 'Tanga Region',
                'value': '25'
            },
            {
                'label': 'Zanzibar Central South Region',
                'value': '11'
            },
            {
                'label': 'Zanzibar North Region',
                'value': '07'
            },
            {
                'label': 'Zanzibar Urban West Region',
                'value': '15'
            }
        ]
    },
    {
        'label': 'Thailand',
        'iso3': 'THA',
        'value': 'TH',
        'states': [
            {
                'label': 'Amnat Charoen',
                'value': '37'
            },
            {
                'label': 'Ang Thong',
                'value': '15'
            },
            {
                'label': 'Bangkok',
                'value': '10'
            },
            {
                'label': 'Bueng Kan',
                'value': '38'
            },
            {
                'label': 'Buri Ram',
                'value': '31'
            },
            {
                'label': 'Chachoengsao',
                'value': '24'
            },
            {
                'label': 'Chai Nat',
                'value': '18'
            },
            {
                'label': 'Chanthaburi',
                'value': '22'
            },
            {
                'label': 'Chiang Mai',
                'value': '50'
            },
            {
                'label': 'Chiang Rai',
                'value': '57'
            },
            {
                'label': 'Chon Buri',
                'value': '20'
            },
            {
                'label': 'Chumphon',
                'value': '86'
            },
            {
                'label': 'Kalasin',
                'value': '46'
            },
            {
                'label': 'Kamphaeng Phet',
                'value': '62'
            },
            {
                'label': 'Kanchanaburi',
                'value': '71'
            },
            {
                'label': 'Khon Kaen',
                'value': '40'
            },
            {
                'label': 'Krabi',
                'value': '81'
            },
            {
                'label': 'Lampang',
                'value': '52'
            },
            {
                'label': 'Lamphun',
                'value': '51'
            },
            {
                'label': 'Loei',
                'value': '42'
            },
            {
                'label': 'Lopburi',
                'value': '16'
            },
            {
                'label': 'Mae Hong Son',
                'value': '58'
            },
            {
                'label': 'Maha Sarakham',
                'value': '44'
            },
            {
                'label': 'Mukdahan',
                'value': '49'
            },
            {
                'label': 'Nakhon Nayok',
                'value': '26'
            },
            {
                'label': 'Nakhon Pathom',
                'value': '73'
            },
            {
                'label': 'Nakhon Phanom',
                'value': '48'
            },
            {
                'label': 'Nakhon Ratchasima',
                'value': '30'
            },
            {
                'label': 'Nakhon Sawan',
                'value': '60'
            },
            {
                'label': 'Nakhon Si Thammarat',
                'value': '80'
            },
            {
                'label': 'Nan',
                'value': '55'
            },
            {
                'label': 'Narathiwat',
                'value': '96'
            },
            {
                'label': 'Nong Bua Lam Phu',
                'value': '39'
            },
            {
                'label': 'Nong Khai',
                'value': '43'
            },
            {
                'label': 'Nonthaburi',
                'value': '12'
            },
            {
                'label': 'Pathum Thani',
                'value': '13'
            },
            {
                'label': 'Pattani',
                'value': '94'
            },
            {
                'label': 'Pattaya',
                'value': 'S'
            },
            {
                'label': 'Phang Nga',
                'value': '82'
            },
            {
                'label': 'Phatthalung',
                'value': '93'
            },
            {
                'label': 'Phayao',
                'value': '56'
            },
            {
                'label': 'Phetchabun',
                'value': '67'
            },
            {
                'label': 'Phetchaburi',
                'value': '76'
            },
            {
                'label': 'Phichit',
                'value': '66'
            },
            {
                'label': 'Phitsanulok',
                'value': '65'
            },
            {
                'label': 'Phra Nakhon Si Ayutthaya',
                'value': '14'
            },
            {
                'label': 'Phrae',
                'value': '54'
            },
            {
                'label': 'Phuket',
                'value': '83'
            },
            {
                'label': 'Prachin Buri',
                'value': '25'
            },
            {
                'label': 'Prachuap Khiri Khan',
                'value': '77'
            },
            {
                'label': 'Ranong',
                'value': '85'
            },
            {
                'label': 'Ratchaburi',
                'value': '70'
            },
            {
                'label': 'Rayong',
                'value': '21'
            },
            {
                'label': 'Roi Et',
                'value': '45'
            },
            {
                'label': 'Sa Kaeo',
                'value': '27'
            },
            {
                'label': 'Sakon Nakhon',
                'value': '47'
            },
            {
                'label': 'Samut Prakan',
                'value': '11'
            },
            {
                'label': 'Samut Sakhon',
                'value': '74'
            },
            {
                'label': 'Samut Songkhram',
                'value': '75'
            },
            {
                'label': 'Saraburi',
                'value': '19'
            },
            {
                'label': 'Satun',
                'value': '91'
            },
            {
                'label': 'Si Sa Ket',
                'value': '33'
            },
            {
                'label': 'Sing Buri',
                'value': '17'
            },
            {
                'label': 'Songkhla',
                'value': '90'
            },
            {
                'label': 'Sukhothai',
                'value': '64'
            },
            {
                'label': 'Suphanburi',
                'value': '72'
            },
            {
                'label': 'Surat Thani',
                'value': '84'
            },
            {
                'label': 'Surin',
                'value': '32'
            },
            {
                'label': 'Tak',
                'value': '63'
            },
            {
                'label': 'Trang',
                'value': '92'
            },
            {
                'label': 'Trat',
                'value': '23'
            },
            {
                'label': 'Ubon Ratchathani',
                'value': '34'
            },
            {
                'label': 'Udon Thani',
                'value': '41'
            },
            {
                'label': 'Uthai Thani',
                'value': '61'
            },
            {
                'label': 'Uttaradit',
                'value': '53'
            },
            {
                'label': 'Yala',
                'value': '95'
            },
            {
                'label': 'Yasothon',
                'value': '35'
            }
        ]
    },
    {
        'label': 'Togo',
        'iso3': 'TGO',
        'value': 'TG',
        'states': [
            {
                'label': 'Centrale Region',
                'value': 'C'
            },
            {
                'label': 'Kara Region',
                'value': 'K'
            },
            {
                'label': 'Maritime',
                'value': 'M'
            },
            {
                'label': 'Plateaux Region',
                'value': 'P'
            },
            {
                'label': 'Savanes Region',
                'value': 'S'
            }
        ]
    },
    {
        'label': 'Tonga',
        'iso3': 'TON',
        'value': 'TO',
        'states': [
            {
                'label': 'Haʻapai',
                'value': '02'
            },
            {
                'label': 'ʻEua',
                'value': '01'
            },
            {
                'label': 'Niuas',
                'value': '03'
            },
            {
                'label': 'Tongatapu',
                'value': '04'
            },
            {
                'label': 'Vavaʻu',
                'value': '05'
            }
        ]
    },
    {
        'label': 'Trinidad And Tobago',
        'iso3': 'TTO',
        'value': 'TT',
        'states': [
            {
                'label': 'Arima',
                'value': 'ARI'
            },
            {
                'label': 'Chaguanas',
                'value': 'CHA'
            },
            {
                'label': 'Couva-Tabaquite-Talparo Regional Corporation',
                'value': 'CTT'
            },
            {
                'label': 'Diego Martin Regional Corporation',
                'value': 'DMN'
            },
            {
                'label': 'Eastern Tobago',
                'value': 'ETO'
            },
            {
                'label': 'Penal-Debe Regional Corporation',
                'value': 'PED'
            },
            {
                'label': 'Point Fortin',
                'value': 'PTF'
            },
            {
                'label': 'Port of Spain',
                'value': 'POS'
            },
            {
                'label': 'Princes Town Regional Corporation',
                'value': 'PRT'
            },
            {
                'label': 'Rio Claro-Mayaro Regional Corporation',
                'value': 'MRC'
            },
            {
                'label': 'San Fernando',
                'value': 'SFO'
            },
            {
                'label': 'San Juan-Laventille Regional Corporation',
                'value': 'SJL'
            },
            {
                'label': 'Sangre Grande Regional Corporation',
                'value': 'SGE'
            },
            {
                'label': 'Siparia Regional Corporation',
                'value': 'SIP'
            },
            {
                'label': 'Tunapuna-Piarco Regional Corporation',
                'value': 'TUP'
            },
            {
                'label': 'Western Tobago',
                'value': 'WTO'
            }
        ]
    },
    {
        'label': 'Tunisia',
        'iso3': 'TUN',
        'value': 'TN',
        'states': [
            {
                'label': 'Ariana Governorate',
                'value': '12'
            },
            {
                'label': 'Ben Arous Governorate',
                'value': '13'
            },
            {
                'label': 'Bizerte Governorate',
                'value': '23'
            },
            {
                'label': 'Gabès Governorate',
                'value': '81'
            },
            {
                'label': 'Gafsa Governorate',
                'value': '71'
            },
            {
                'label': 'Jendouba Governorate',
                'value': '32'
            },
            {
                'label': 'Kairouan Governorate',
                'value': '41'
            },
            {
                'label': 'Kasserine Governorate',
                'value': '42'
            },
            {
                'label': 'Kassrine',
                'value': '31'
            },
            {
                'label': 'Kebili Governorate',
                'value': '73'
            },
            {
                'label': 'Kef Governorate',
                'value': '33'
            },
            {
                'label': 'Mahdia Governorate',
                'value': '53'
            },
            {
                'label': 'Manouba Governorate',
                'value': '14'
            },
            {
                'label': 'Medenine Governorate',
                'value': '82'
            },
            {
                'label': 'Monastir Governorate',
                'value': '52'
            },
            {
                'label': 'Sfax Governorate',
                'value': '61'
            },
            {
                'label': 'Sidi Bouzid Governorate',
                'value': '43'
            },
            {
                'label': 'Siliana Governorate',
                'value': '34'
            },
            {
                'label': 'Sousse Governorate',
                'value': '51'
            },
            {
                'label': 'Tataouine Governorate',
                'value': '83'
            },
            {
                'label': 'Tozeur Governorate',
                'value': '72'
            },
            {
                'label': 'Tunis Governorate',
                'value': '11'
            },
            {
                'label': 'Zaghouan Governorate',
                'value': '22'
            }
        ]
    },
    {
        'label': 'Turkey',
        'iso3': 'TUR',
        'value': 'TR',
        'states': [
            {
                'label': 'Adana Province',
                'value': '01'
            },
            {
                'label': 'Adıyaman Province',
                'value': '02'
            },
            {
                'label': 'Afyonkarahisar Province',
                'value': '03'
            },
            {
                'label': 'Ağrı Province',
                'value': '04'
            },
            {
                'label': 'Aksaray Province',
                'value': '68'
            },
            {
                'label': 'Amasya Province',
                'value': '05'
            },
            {
                'label': 'Ankara Province',
                'value': '06'
            },
            {
                'label': 'Antalya Province',
                'value': '07'
            },
            {
                'label': 'Ardahan Province',
                'value': '75'
            },
            {
                'label': 'Artvin Province',
                'value': '08'
            },
            {
                'label': 'Aydın Province',
                'value': '09'
            },
            {
                'label': 'Balıkesir Province',
                'value': '10'
            },
            {
                'label': 'Bartın Province',
                'value': '74'
            },
            {
                'label': 'Batman Province',
                'value': '72'
            },
            {
                'label': 'Bayburt Province',
                'value': '69'
            },
            {
                'label': 'Bilecik Province',
                'value': '11'
            },
            {
                'label': 'Bingöl Province',
                'value': '12'
            },
            {
                'label': 'Bitlis Province',
                'value': '13'
            },
            {
                'label': 'Bolu Province',
                'value': '14'
            },
            {
                'label': 'Burdur Province',
                'value': '15'
            },
            {
                'label': 'Bursa Province',
                'value': '16'
            },
            {
                'label': 'Çanakkale Province',
                'value': '17'
            },
            {
                'label': 'Çankırı Province',
                'value': '18'
            },
            {
                'label': 'Çorum Province',
                'value': '19'
            },
            {
                'label': 'Denizli Province',
                'value': '20'
            },
            {
                'label': 'Diyarbakır Province',
                'value': '21'
            },
            {
                'label': 'Düzce Province',
                'value': '81'
            },
            {
                'label': 'Edirne Province',
                'value': '22'
            },
            {
                'label': 'Elazığ Province',
                'value': '23'
            },
            {
                'label': 'Erzincan Province',
                'value': '24'
            },
            {
                'label': 'Erzurum Province',
                'value': '25'
            },
            {
                'label': 'Eskişehir Province',
                'value': '26'
            },
            {
                'label': 'Gaziantep Province',
                'value': '27'
            },
            {
                'label': 'Giresun Province',
                'value': '28'
            },
            {
                'label': 'Gümüşhane Province',
                'value': '29'
            },
            {
                'label': 'Hakkâri Province',
                'value': '30'
            },
            {
                'label': 'Hatay Province',
                'value': '31'
            },
            {
                'label': 'Iğdır Province',
                'value': '76'
            },
            {
                'label': 'Isparta Province',
                'value': '32'
            },
            {
                'label': 'Istanbul Province',
                'value': '34'
            },
            {
                'label': 'İzmir Province',
                'value': '35'
            },
            {
                'label': 'Kahramanmaraş Province',
                'value': '46'
            },
            {
                'label': 'Karabük Province',
                'value': '78'
            },
            {
                'label': 'Karaman Province',
                'value': '70'
            },
            {
                'label': 'Kars Province',
                'value': '36'
            },
            {
                'label': 'Kastamonu Province',
                'value': '37'
            },
            {
                'label': 'Kayseri Province',
                'value': '38'
            },
            {
                'label': 'Kilis Province',
                'value': '79'
            },
            {
                'label': 'Kırıkkale Province',
                'value': '71'
            },
            {
                'label': 'Kırklareli Province',
                'value': '39'
            },
            {
                'label': 'Kırşehir Province',
                'value': '40'
            },
            {
                'label': 'Kocaeli Province',
                'value': '41'
            },
            {
                'label': 'Konya Province',
                'value': '42'
            },
            {
                'label': 'Kütahya Province',
                'value': '43'
            },
            {
                'label': 'Malatya Province',
                'value': '44'
            },
            {
                'label': 'Manisa Province',
                'value': '45'
            },
            {
                'label': 'Mardin Province',
                'value': '47'
            },
            {
                'label': 'Mersin Province',
                'value': '33'
            },
            {
                'label': 'Muğla Province',
                'value': '48'
            },
            {
                'label': 'Muş Province',
                'value': '49'
            },
            {
                'label': 'Nevşehir Province',
                'value': '50'
            },
            {
                'label': 'Niğde Province',
                'value': '51'
            },
            {
                'label': 'Ordu Province',
                'value': '52'
            },
            {
                'label': 'Osmaniye Province',
                'value': '80'
            },
            {
                'label': 'Rize Province',
                'value': '53'
            },
            {
                'label': 'Sakarya Province',
                'value': '54'
            },
            {
                'label': 'Samsun Province',
                'value': '55'
            },
            {
                'label': 'Şanlıurfa Province',
                'value': '63'
            },
            {
                'label': 'Siirt Province',
                'value': '56'
            },
            {
                'label': 'Sinop Province',
                'value': '57'
            },
            {
                'label': 'Sivas Province',
                'value': '58'
            },
            {
                'label': 'Şırnak Province',
                'value': '73'
            },
            {
                'label': 'Tekirdağ Province',
                'value': '59'
            },
            {
                'label': 'Tokat Province',
                'value': '60'
            },
            {
                'label': 'Trabzon Province',
                'value': '61'
            },
            {
                'label': 'Tunceli Province',
                'value': '62'
            },
            {
                'label': 'Uşak Province',
                'value': '64'
            },
            {
                'label': 'Van Province',
                'value': '65'
            },
            {
                'label': 'Yalova Province',
                'value': '77'
            },
            {
                'label': 'Yozgat Province',
                'value': '66'
            },
            {
                'label': 'Zonguldak Province',
                'value': '67'
            }
        ]
    },
    {
        'label': 'Turkmenistan',
        'iso3': 'TKM',
        'value': 'TM',
        'states': [
            {
                'label': 'Ahal Region',
                'value': 'A'
            },
            {
                'label': 'Ashgabat',
                'value': 'S'
            },
            {
                'label': 'Balkan Region',
                'value': 'B'
            },
            {
                'label': 'Daşoguz Region',
                'value': 'D'
            },
            {
                'label': 'Lebap Region',
                'value': 'L'
            },
            {
                'label': 'Mary Region',
                'value': 'M'
            }
        ]
    },
    {
        'label': 'Tuvalu',
        'iso3': 'TUV',
        'value': 'TV',
        'states': [
            {
                'label': 'Funafuti',
                'value': 'FUN'
            },
            {
                'label': 'Nanumanga',
                'value': 'NMG'
            },
            {
                'label': 'Nanumea',
                'value': 'NMA'
            },
            {
                'label': 'Niutao Island Council',
                'value': 'NIT'
            },
            {
                'label': 'Nui',
                'value': 'NUI'
            },
            {
                'label': 'Nukufetau',
                'value': 'NKF'
            },
            {
                'label': 'Nukulaelae',
                'value': 'NKL'
            },
            {
                'label': 'Vaitupu',
                'value': 'VAI'
            }
        ]
    },
    {
        'label': 'Uganda',
        'iso3': 'UGA',
        'value': 'UG',
        'states': [
            {
                'label': 'Abim District',
                'value': '314'
            },
            {
                'label': 'Adjumani District',
                'value': '301'
            },
            {
                'label': 'Agago District',
                'value': '322'
            },
            {
                'label': 'Alebtong District',
                'value': '323'
            },
            {
                'label': 'Amolatar District',
                'value': '315'
            },
            {
                'label': 'Amudat District',
                'value': '324'
            },
            {
                'label': 'Amuria District',
                'value': '216'
            },
            {
                'label': 'Amuru District',
                'value': '316'
            },
            {
                'label': 'Apac District',
                'value': '302'
            },
            {
                'label': 'Arua District',
                'value': '303'
            },
            {
                'label': 'Budaka District',
                'value': '217'
            },
            {
                'label': 'Bududa District',
                'value': '218'
            },
            {
                'label': 'Bugiri District',
                'value': '201'
            },
            {
                'label': 'Buhweju District',
                'value': '420'
            },
            {
                'label': 'Buikwe District',
                'value': '117'
            },
            {
                'label': 'Bukedea District',
                'value': '219'
            },
            {
                'label': 'Bukomansimbi District',
                'value': '118'
            },
            {
                'label': 'Bukwo District',
                'value': '220'
            },
            {
                'label': 'Bulambuli District',
                'value': '225'
            },
            {
                'label': 'Buliisa District',
                'value': '416'
            },
            {
                'label': 'Bundibugyo District',
                'value': '401'
            },
            {
                'label': 'Bunyangabu District',
                'value': '430'
            },
            {
                'label': 'Bushenyi District',
                'value': '402'
            },
            {
                'label': 'Busia District',
                'value': '202'
            },
            {
                'label': 'Butaleja District',
                'value': '221'
            },
            {
                'label': 'Butambala District',
                'value': '119'
            },
            {
                'label': 'Butebo District',
                'value': '233'
            },
            {
                'label': 'Buvuma District',
                'value': '120'
            },
            {
                'label': 'Buyende District',
                'value': '226'
            },
            {
                'label': 'Central Region',
                'value': 'C'
            },
            {
                'label': 'Dokolo District',
                'value': '317'
            },
            {
                'label': 'Eastern Region',
                'value': 'E'
            },
            {
                'label': 'Gomba District',
                'value': '121'
            },
            {
                'label': 'Gulu District',
                'value': '304'
            },
            {
                'label': 'Ibanda District',
                'value': '417'
            },
            {
                'label': 'Iganga District',
                'value': '203'
            },
            {
                'label': 'Isingiro District',
                'value': '418'
            },
            {
                'label': 'Jinja District',
                'value': '204'
            },
            {
                'label': 'Kaabong District',
                'value': '318'
            },
            {
                'label': 'Kabale District',
                'value': '404'
            },
            {
                'label': 'Kabarole District',
                'value': '405'
            },
            {
                'label': 'Kaberamaido District',
                'value': '213'
            },
            {
                'label': 'Kagadi District',
                'value': '427'
            },
            {
                'label': 'Kakumiro District',
                'value': '428'
            },
            {
                'label': 'Kalangala District',
                'value': '101'
            },
            {
                'label': 'Kaliro District',
                'value': '222'
            },
            {
                'label': 'Kalungu District',
                'value': '122'
            },
            {
                'label': 'Kampala District',
                'value': '102'
            },
            {
                'label': 'Kamuli District',
                'value': '205'
            },
            {
                'label': 'Kamwenge District',
                'value': '413'
            },
            {
                'label': 'Kanungu District',
                'value': '414'
            },
            {
                'label': 'Kapchorwa District',
                'value': '206'
            },
            {
                'label': 'Kasese District',
                'value': '406'
            },
            {
                'label': 'Katakwi District',
                'value': '207'
            },
            {
                'label': 'Kayunga District',
                'value': '112'
            },
            {
                'label': 'Kibaale District',
                'value': '407'
            },
            {
                'label': 'Kiboga District',
                'value': '103'
            },
            {
                'label': 'Kibuku District',
                'value': '227'
            },
            {
                'label': 'Kiruhura District',
                'value': '419'
            },
            {
                'label': 'Kiryandongo District',
                'value': '421'
            },
            {
                'label': 'Kisoro District',
                'value': '408'
            },
            {
                'label': 'Kitgum District',
                'value': '305'
            },
            {
                'label': 'Koboko District',
                'value': '319'
            },
            {
                'label': 'Kole District',
                'value': '325'
            },
            {
                'label': 'Kotido District',
                'value': '306'
            },
            {
                'label': 'Kumi District',
                'value': '208'
            },
            {
                'label': 'Kween District',
                'value': '228'
            },
            {
                'label': 'Kyankwanzi District',
                'value': '123'
            },
            {
                'label': 'Kyegegwa District',
                'value': '422'
            },
            {
                'label': 'Kyenjojo District',
                'value': '415'
            },
            {
                'label': 'Kyotera District',
                'value': '125'
            },
            {
                'label': 'Lamwo District',
                'value': '326'
            },
            {
                'label': 'Lira District',
                'value': '307'
            },
            {
                'label': 'Luuka District',
                'value': '229'
            },
            {
                'label': 'Luwero District',
                'value': '104'
            },
            {
                'label': 'Lwengo District',
                'value': '124'
            },
            {
                'label': 'Lyantonde District',
                'value': '114'
            },
            {
                'label': 'Manafwa District',
                'value': '223'
            },
            {
                'label': 'Maracha District',
                'value': '320'
            },
            {
                'label': 'Masaka District',
                'value': '105'
            },
            {
                'label': 'Masindi District',
                'value': '409'
            },
            {
                'label': 'Mayuge District',
                'value': '214'
            },
            {
                'label': 'Mbale District',
                'value': '209'
            },
            {
                'label': 'Mbarara District',
                'value': '410'
            },
            {
                'label': 'Mitooma District',
                'value': '423'
            },
            {
                'label': 'Mityana District',
                'value': '115'
            },
            {
                'label': 'Moroto District',
                'value': '308'
            },
            {
                'label': 'Moyo District',
                'value': '309'
            },
            {
                'label': 'Mpigi District',
                'value': '106'
            },
            {
                'label': 'Mubende District',
                'value': '107'
            },
            {
                'label': 'Mukono District',
                'value': '108'
            },
            {
                'label': 'Nakapiripirit District',
                'value': '311'
            },
            {
                'label': 'Nakaseke District',
                'value': '116'
            },
            {
                'label': 'Nakasongola District',
                'value': '109'
            },
            {
                'label': 'Namayingo District',
                'value': '230'
            },
            {
                'label': 'Namisindwa District',
                'value': '234'
            },
            {
                'label': 'Namutumba District',
                'value': '224'
            },
            {
                'label': 'Napak District',
                'value': '327'
            },
            {
                'label': 'Nebbi District',
                'value': '310'
            },
            {
                'label': 'Ngora District',
                'value': '231'
            },
            {
                'label': 'Northern Region',
                'value': 'N'
            },
            {
                'label': 'Ntoroko District',
                'value': '424'
            },
            {
                'label': 'Ntungamo District',
                'value': '411'
            },
            {
                'label': 'Nwoya District',
                'value': '328'
            },
            {
                'label': 'Omoro District',
                'value': '331'
            },
            {
                'label': 'Otuke District',
                'value': '329'
            },
            {
                'label': 'Oyam District',
                'value': '321'
            },
            {
                'label': 'Pader District',
                'value': '312'
            },
            {
                'label': 'Pakwach District',
                'value': '332'
            },
            {
                'label': 'Pallisa District',
                'value': '210'
            },
            {
                'label': 'Rakai District',
                'value': '110'
            },
            {
                'label': 'Rubanda District',
                'value': '429'
            },
            {
                'label': 'Rubirizi District',
                'value': '425'
            },
            {
                'label': 'Rukiga District',
                'value': '431'
            },
            {
                'label': 'Rukungiri District',
                'value': '412'
            },
            {
                'label': 'Sembabule District',
                'value': '111'
            },
            {
                'label': 'Serere District',
                'value': '232'
            },
            {
                'label': 'Sheema District',
                'value': '426'
            },
            {
                'label': 'Sironko District',
                'value': '215'
            },
            {
                'label': 'Soroti District',
                'value': '211'
            },
            {
                'label': 'Tororo District',
                'value': '212'
            },
            {
                'label': 'Wakiso District',
                'value': '113'
            },
            {
                'label': 'Western Region',
                'value': 'W'
            },
            {
                'label': 'Yumbe District',
                'value': '313'
            },
            {
                'label': 'Zombo District',
                'value': '330'
            }
        ]
    },
    {
        'label': 'Ukraine',
        'iso3': 'UKR',
        'value': 'UA',
        'states': [
            {
                'label': 'Autonomous Republic of Crimea',
                'value': '43'
            },
            {
                'label': 'Cherkasy Oblast',
                'value': '71'
            },
            {
                'label': 'Chernihiv Oblast',
                'value': '74'
            },
            {
                'label': 'Chernivtsi Oblast',
                'value': '77'
            },
            {
                'label': 'Dnipropetrovsk Oblast',
                'value': '12'
            },
            {
                'label': 'Donetsk Oblast',
                'value': '14'
            },
            {
                'label': 'Ivano-Frankivsk Oblast',
                'value': '26'
            },
            {
                'label': 'Kharkiv Oblast',
                'value': '63'
            },
            {
                'label': 'Kherson Oblast',
                'value': '65'
            },
            {
                'label': 'Khmelnytsky Oblast',
                'value': '68'
            },
            {
                'label': 'Kiev',
                'value': '30'
            },
            {
                'label': 'Kirovohrad Oblast',
                'value': '35'
            },
            {
                'label': 'Kyiv Oblast',
                'value': '32'
            },
            {
                'label': 'Luhansk Oblast',
                'value': '09'
            },
            {
                'label': 'Lviv Oblast',
                'value': '46'
            },
            {
                'label': 'Mykolaiv Oblast',
                'value': '48'
            },
            {
                'label': 'Odessa Oblast',
                'value': '51'
            },
            {
                'label': 'Rivne Oblast',
                'value': '56'
            },
            {
                'label': 'Sumy Oblast',
                'value': '59'
            },
            {
                'label': 'Ternopil Oblast',
                'value': '61'
            },
            {
                'label': 'Vinnytsia Oblast',
                'value': '05'
            },
            {
                'label': 'Volyn Oblast',
                'value': '07'
            },
            {
                'label': 'Zakarpattia Oblast',
                'value': '21'
            },
            {
                'label': 'Zaporizhzhya Oblast',
                'value': '23'
            },
            {
                'label': 'Zhytomyr Oblast',
                'value': '18'
            }
        ]
    },
    {
        'label': 'United Arab Emirates',
        'iso3': 'ARE',
        'value': 'AE',
        'states': [
            {
                'label': 'Abu Dhabi Emirate',
                'value': 'AZ'
            },
            {
                'label': 'Ajman Emirate',
                'value': 'AJ'
            },
            {
                'label': 'Dubai',
                'value': 'DU'
            },
            {
                'label': 'Fujairah',
                'value': 'FU'
            },
            {
                'label': 'Ras al-Khaimah',
                'value': 'RK'
            },
            {
                'label': 'Sharjah Emirate',
                'value': 'SH'
            },
            {
                'label': 'Umm al-Quwain',
                'value': 'UQ'
            }
        ]
    },
    {
        'label': 'United Kingdom',
        'iso3': 'GBR',
        'value': 'GB',
        'states': [
            {
                'label': 'Aberdeen',
                'value': 'ABE'
            },
            {
                'label': 'Aberdeenshire',
                'value': 'ABD'
            },
            {
                'label': 'Angus',
                'value': 'ANS'
            },
            {
                'label': 'Antrim',
                'value': 'ANT'
            },
            {
                'label': 'Antrim and Newtownabbey',
                'value': 'ANN'
            },
            {
                'label': 'Ards',
                'value': 'ARD'
            },
            {
                'label': 'Ards and North Down',
                'value': 'AND'
            },
            {
                'label': 'Argyll and Bute',
                'value': 'AGB'
            },
            {
                'label': 'Armagh City and District Council',
                'value': 'ARM'
            },
            {
                'label': 'Armagh, Banbridge and Craigavon',
                'value': 'ABC'
            },
            {
                'label': 'Ascension Island',
                'value': 'SH-AC'
            },
            {
                'label': 'Ballymena Borough',
                'value': 'BLA'
            },
            {
                'label': 'Ballymoney',
                'value': 'BLY'
            },
            {
                'label': 'Banbridge',
                'value': 'BNB'
            },
            {
                'label': 'Barnsley',
                'value': 'BNS'
            },
            {
                'label': 'Bath and North East Somerset',
                'value': 'BAS'
            },
            {
                'label': 'Bedford',
                'value': 'BDF'
            },
            {
                'label': 'Belfast district',
                'value': 'BFS'
            },
            {
                'label': 'Birmingham',
                'value': 'BIR'
            },
            {
                'label': 'Blackburn with Darwen',
                'value': 'BBD'
            },
            {
                'label': 'Blackpool',
                'value': 'BPL'
            },
            {
                'label': 'Blaenau Gwent County Borough',
                'value': 'BGW'
            },
            {
                'label': 'Bolton',
                'value': 'BOL'
            },
            {
                'label': 'Bournemouth',
                'value': 'BMH'
            },
            {
                'label': 'Bracknell Forest',
                'value': 'BRC'
            },
            {
                'label': 'Bradford',
                'value': 'BRD'
            },
            {
                'label': 'Bridgend County Borough',
                'value': 'BGE'
            },
            {
                'label': 'Brighton and Hove',
                'value': 'BNH'
            },
            {
                'label': 'Buckinghamshire',
                'value': 'BKM'
            },
            {
                'label': 'Bury',
                'value': 'BUR'
            },
            {
                'label': 'Caerphilly County Borough',
                'value': 'CAY'
            },
            {
                'label': 'Calderdale',
                'value': 'CLD'
            },
            {
                'label': 'Cambridgeshire',
                'value': 'CAM'
            },
            {
                'label': 'Carmarthenshire',
                'value': 'CMN'
            },
            {
                'label': 'Carrickfergus Borough Council',
                'value': 'CKF'
            },
            {
                'label': 'Castlereagh',
                'value': 'CSR'
            },
            {
                'label': 'Causeway Coast and Glens',
                'value': 'CCG'
            },
            {
                'label': 'Central Bedfordshire',
                'value': 'CBF'
            },
            {
                'label': 'Ceredigion',
                'value': 'CGN'
            },
            {
                'label': 'Cheshire East',
                'value': 'CHE'
            },
            {
                'label': 'Cheshire West and Chester',
                'value': 'CHW'
            },
            {
                'label': 'City and County of Cardiff',
                'value': 'CRF'
            },
            {
                'label': 'City and County of Swansea',
                'value': 'SWA'
            },
            {
                'label': 'City of Bristol',
                'value': 'BST'
            },
            {
                'label': 'City of Derby',
                'value': 'DER'
            },
            {
                'label': 'City of Kingston upon Hull',
                'value': 'KHL'
            },
            {
                'label': 'City of Leicester',
                'value': 'LCE'
            },
            {
                'label': 'City of London',
                'value': 'LND'
            },
            {
                'label': 'City of Nottingham',
                'value': 'NGM'
            },
            {
                'label': 'City of Peterborough',
                'value': 'PTE'
            },
            {
                'label': 'City of Plymouth',
                'value': 'PLY'
            },
            {
                'label': 'City of Portsmouth',
                'value': 'POR'
            },
            {
                'label': 'City of Southampton',
                'value': 'STH'
            },
            {
                'label': 'City of Stoke-on-Trent',
                'value': 'STE'
            },
            {
                'label': 'City of Sunderland',
                'value': 'SND'
            },
            {
                'label': 'City of Westminster',
                'value': 'WSM'
            },
            {
                'label': 'City of Wolverhampton',
                'value': 'WLV'
            },
            {
                'label': 'City of York',
                'value': 'YOR'
            },
            {
                'label': 'Clackmannanshire',
                'value': 'CLK'
            },
            {
                'label': 'Coleraine Borough Council',
                'value': 'CLR'
            },
            {
                'label': 'Conwy County Borough',
                'value': 'CWY'
            },
            {
                'label': 'Cookstown District Council',
                'value': 'CKT'
            },
            {
                'label': 'Cornwall',
                'value': 'CON'
            },
            {
                'label': 'County Durham',
                'value': 'DUR'
            },
            {
                'label': 'Coventry',
                'value': 'COV'
            },
            {
                'label': 'Craigavon Borough Council',
                'value': 'CGV'
            },
            {
                'label': 'Cumbria',
                'value': 'CMA'
            },
            {
                'label': 'Darlington',
                'value': 'DAL'
            },
            {
                'label': 'Denbighshire',
                'value': 'DEN'
            },
            {
                'label': 'Derbyshire',
                'value': 'DBY'
            },
            {
                'label': 'Derry City and Strabane',
                'value': 'DRS'
            },
            {
                'label': 'Derry City Council',
                'value': 'DRY'
            },
            {
                'label': 'Devon',
                'value': 'DEV'
            },
            {
                'label': 'Doncaster',
                'value': 'DNC'
            },
            {
                'label': 'Dorset',
                'value': 'DOR'
            },
            {
                'label': 'Down District Council',
                'value': 'DOW'
            },
            {
                'label': 'Dudley',
                'value': 'DUD'
            },
            {
                'label': 'Dumfries and Galloway',
                'value': 'DGY'
            },
            {
                'label': 'Dundee',
                'value': 'DND'
            },
            {
                'label': 'Dungannon and South Tyrone Borough Council',
                'value': 'DGN'
            },
            {
                'label': 'East Ayrshire',
                'value': 'EAY'
            },
            {
                'label': 'East Dunbartonshire',
                'value': 'EDU'
            },
            {
                'label': 'East Lothian',
                'value': 'ELN'
            },
            {
                'label': 'East Renfrewshire',
                'value': 'ERW'
            },
            {
                'label': 'East Riding of Yorkshire',
                'value': 'ERY'
            },
            {
                'label': 'East Sussex',
                'value': 'ESX'
            },
            {
                'label': 'Edinburgh',
                'value': 'EDH'
            },
            {
                'label': 'England',
                'value': 'ENG'
            },
            {
                'label': 'Essex',
                'value': 'ESS'
            },
            {
                'label': 'Falkirk',
                'value': 'FAL'
            },
            {
                'label': 'Fermanagh and Omagh',
                'value': 'FMO'
            },
            {
                'label': 'Fermanagh District Council',
                'value': 'FER'
            },
            {
                'label': 'Fife',
                'value': 'FIF'
            },
            {
                'label': 'Flintshire',
                'value': 'FLN'
            },
            {
                'label': 'Gateshead',
                'value': 'GAT'
            },
            {
                'label': 'Glasgow',
                'value': 'GLG'
            },
            {
                'label': 'Gloucestershire',
                'value': 'GLS'
            },
            {
                'label': 'Gwynedd',
                'value': 'GWN'
            },
            {
                'label': 'Halton',
                'value': 'HAL'
            },
            {
                'label': 'Hampshire',
                'value': 'HAM'
            },
            {
                'label': 'Hartlepool',
                'value': 'HPL'
            },
            {
                'label': 'Herefordshire',
                'value': 'HEF'
            },
            {
                'label': 'Hertfordshire',
                'value': 'HRT'
            },
            {
                'label': 'Highland',
                'value': 'HLD'
            },
            {
                'label': 'Inverclyde',
                'value': 'IVC'
            },
            {
                'label': 'Isle of Wight',
                'value': 'IOW'
            },
            {
                'label': 'Isles of Scilly',
                'value': 'IOS'
            },
            {
                'label': 'Kent',
                'value': 'KEN'
            },
            {
                'label': 'Kirklees',
                'value': 'KIR'
            },
            {
                'label': 'Knowsley',
                'value': 'KWL'
            },
            {
                'label': 'Lancashire',
                'value': 'LAN'
            },
            {
                'label': 'Larne Borough Council',
                'value': 'LRN'
            },
            {
                'label': 'Leeds',
                'value': 'LDS'
            },
            {
                'label': 'Leicestershire',
                'value': 'LEC'
            },
            {
                'label': 'Limavady Borough Council',
                'value': 'LMV'
            },
            {
                'label': 'Lincolnshire',
                'value': 'LIN'
            },
            {
                'label': 'Lisburn and Castlereagh',
                'value': 'LBC'
            },
            {
                'label': 'Lisburn City Council',
                'value': 'LSB'
            },
            {
                'label': 'Liverpool',
                'value': 'LIV'
            },
            {
                'label': 'London Borough of Barking and Dagenham',
                'value': 'BDG'
            },
            {
                'label': 'London Borough of Barnet',
                'value': 'BNE'
            },
            {
                'label': 'London Borough of Bexley',
                'value': 'BEX'
            },
            {
                'label': 'London Borough of Brent',
                'value': 'BEN'
            },
            {
                'label': 'London Borough of Bromley',
                'value': 'BRY'
            },
            {
                'label': 'London Borough of Camden',
                'value': 'CMD'
            },
            {
                'label': 'London Borough of Croydon',
                'value': 'CRY'
            },
            {
                'label': 'London Borough of Ealing',
                'value': 'EAL'
            },
            {
                'label': 'London Borough of Enfield',
                'value': 'ENF'
            },
            {
                'label': 'London Borough of Hackney',
                'value': 'HCK'
            },
            {
                'label': 'London Borough of Hammersmith and Fulham',
                'value': 'HMF'
            },
            {
                'label': 'London Borough of Haringey',
                'value': 'HRY'
            },
            {
                'label': 'London Borough of Harrow',
                'value': 'HRW'
            },
            {
                'label': 'London Borough of Havering',
                'value': 'HAV'
            },
            {
                'label': 'London Borough of Hillingdon',
                'value': 'HIL'
            },
            {
                'label': 'London Borough of Hounslow',
                'value': 'HNS'
            },
            {
                'label': 'London Borough of Islington',
                'value': 'ISL'
            },
            {
                'label': 'London Borough of Lambeth',
                'value': 'LBH'
            },
            {
                'label': 'London Borough of Lewisham',
                'value': 'LEW'
            },
            {
                'label': 'London Borough of Merton',
                'value': 'MRT'
            },
            {
                'label': 'London Borough of Newham',
                'value': 'NWM'
            },
            {
                'label': 'London Borough of Redbridge',
                'value': 'RDB'
            },
            {
                'label': 'London Borough of Richmond upon Thames',
                'value': 'RIC'
            },
            {
                'label': 'London Borough of Southwark',
                'value': 'SWK'
            },
            {
                'label': 'London Borough of Sutton',
                'value': 'STN'
            },
            {
                'label': 'London Borough of Tower Hamlets',
                'value': 'TWH'
            },
            {
                'label': 'London Borough of Waltham Forest',
                'value': 'WFT'
            },
            {
                'label': 'London Borough of Wandsworth',
                'value': 'WND'
            },
            {
                'label': 'Magherafelt District Council',
                'value': 'MFT'
            },
            {
                'label': 'Manchester',
                'value': 'MAN'
            },
            {
                'label': 'Medway',
                'value': 'MDW'
            },
            {
                'label': 'Merthyr Tydfil County Borough',
                'value': 'MTY'
            },
            {
                'label': 'Metropolitan Borough of Wigan',
                'value': 'WGN'
            },
            {
                'label': 'Mid and East Antrim',
                'value': 'MEA'
            },
            {
                'label': 'Mid Ulster',
                'value': 'MUL'
            },
            {
                'label': 'Middlesbrough',
                'value': 'MDB'
            },
            {
                'label': 'Midlothian',
                'value': 'MLN'
            },
            {
                'label': 'Milton Keynes',
                'value': 'MIK'
            },
            {
                'label': 'Monmouthshire',
                'value': 'MON'
            },
            {
                'label': 'Moray',
                'value': 'MRY'
            },
            {
                'label': 'Moyle District Council',
                'value': 'MYL'
            },
            {
                'label': 'Neath Port Talbot County Borough',
                'value': 'NTL'
            },
            {
                'label': 'Newcastle upon Tyne',
                'value': 'NET'
            },
            {
                'label': 'Newport',
                'value': 'NWP'
            },
            {
                'label': 'Newry and Mourne District Council',
                'value': 'NYM'
            },
            {
                'label': 'Newry, Mourne and Down',
                'value': 'NMD'
            },
            {
                'label': 'Newtownabbey Borough Council',
                'value': 'NTA'
            },
            {
                'label': 'Norfolk',
                'value': 'NFK'
            },
            {
                'label': 'North Ayrshire',
                'value': 'NAY'
            },
            {
                'label': 'North Down Borough Council',
                'value': 'NDN'
            },
            {
                'label': 'North East Lincolnshire',
                'value': 'NEL'
            },
            {
                'label': 'North Lanarkshire',
                'value': 'NLK'
            },
            {
                'label': 'North Lincolnshire',
                'value': 'NLN'
            },
            {
                'label': 'North Somerset',
                'value': 'NSM'
            },
            {
                'label': 'North Tyneside',
                'value': 'NTY'
            },
            {
                'label': 'North Yorkshire',
                'value': 'NYK'
            },
            {
                'label': 'Northamptonshire',
                'value': 'NTH'
            },
            {
                'label': 'Northern Ireland',
                'value': 'NIR'
            },
            {
                'label': 'Northumberland',
                'value': 'NBL'
            },
            {
                'label': 'Nottinghamshire',
                'value': 'NTT'
            },
            {
                'label': 'Oldham',
                'value': 'OLD'
            },
            {
                'label': 'Omagh District Council',
                'value': 'OMH'
            },
            {
                'label': 'Orkney Islands',
                'value': 'ORK'
            },
            {
                'label': 'Outer Hebrides',
                'value': 'ELS'
            },
            {
                'label': 'Oxfordshire',
                'value': 'OXF'
            },
            {
                'label': 'Pembrokeshire',
                'value': 'PEM'
            },
            {
                'label': 'Perth and Kinross',
                'value': 'PKN'
            },
            {
                'label': 'Poole',
                'value': 'POL'
            },
            {
                'label': 'Powys',
                'value': 'POW'
            },
            {
                'label': 'Reading',
                'value': 'RDG'
            },
            {
                'label': 'Redcar and Cleveland',
                'value': 'RCC'
            },
            {
                'label': 'Renfrewshire',
                'value': 'RFW'
            },
            {
                'label': 'Rhondda Cynon Taf',
                'value': 'RCT'
            },
            {
                'label': 'Rochdale',
                'value': 'RCH'
            },
            {
                'label': 'Rotherham',
                'value': 'ROT'
            },
            {
                'label': 'Royal Borough of Greenwich',
                'value': 'GRE'
            },
            {
                'label': 'Royal Borough of Kensington and Chelsea',
                'value': 'KEC'
            },
            {
                'label': 'Royal Borough of Kingston upon Thames',
                'value': 'KTT'
            },
            {
                'label': 'Rutland',
                'value': 'RUT'
            },
            {
                'label': 'Saint Helena',
                'value': 'SH-HL'
            },
            {
                'label': 'Salford',
                'value': 'SLF'
            },
            {
                'label': 'Sandwell',
                'value': 'SAW'
            },
            {
                'label': 'Scotland',
                'value': 'SCT'
            },
            {
                'label': 'Scottish Borders',
                'value': 'SCB'
            },
            {
                'label': 'Sefton',
                'value': 'SFT'
            },
            {
                'label': 'Sheffield',
                'value': 'SHF'
            },
            {
                'label': 'Shetland Islands',
                'value': 'ZET'
            },
            {
                'label': 'Shropshire',
                'value': 'SHR'
            },
            {
                'label': 'Slough',
                'value': 'SLG'
            },
            {
                'label': 'Solihull',
                'value': 'SOL'
            },
            {
                'label': 'Somerset',
                'value': 'SOM'
            },
            {
                'label': 'South Ayrshire',
                'value': 'SAY'
            },
            {
                'label': 'South Gloucestershire',
                'value': 'SGC'
            },
            {
                'label': 'South Lanarkshire',
                'value': 'SLK'
            },
            {
                'label': 'South Tyneside',
                'value': 'STY'
            },
            {
                'label': 'Southend-on-Sea',
                'value': 'SOS'
            },
            {
                'label': 'St Helens',
                'value': 'SHN'
            },
            {
                'label': 'Staffordshire',
                'value': 'STS'
            },
            {
                'label': 'Stirling',
                'value': 'STG'
            },
            {
                'label': 'Stockport',
                'value': 'SKP'
            },
            {
                'label': 'Stockton-on-Tees',
                'value': 'STT'
            },
            {
                'label': 'Strabane District Council',
                'value': 'STB'
            },
            {
                'label': 'Suffolk',
                'value': 'SFK'
            },
            {
                'label': 'Surrey',
                'value': 'SRY'
            },
            {
                'label': 'Swindon',
                'value': 'SWD'
            },
            {
                'label': 'Tameside',
                'value': 'TAM'
            },
            {
                'label': 'Telford and Wrekin',
                'value': 'TFW'
            },
            {
                'label': 'Thurrock',
                'value': 'THR'
            },
            {
                'label': 'Torbay',
                'value': 'TOB'
            },
            {
                'label': 'Torfaen',
                'value': 'TOF'
            },
            {
                'label': 'Trafford',
                'value': 'TRF'
            },
            {
                'label': 'United Kingdom',
                'value': 'UKM'
            },
            {
                'label': 'Vale of Glamorgan',
                'value': 'VGL'
            },
            {
                'label': 'Wakefield',
                'value': 'WKF'
            },
            {
                'label': 'Wales',
                'value': 'WLS'
            },
            {
                'label': 'Walsall',
                'value': 'WLL'
            },
            {
                'label': 'Warrington',
                'value': 'WRT'
            },
            {
                'label': 'Warwickshire',
                'value': 'WAR'
            },
            {
                'label': 'West Berkshire',
                'value': 'WBK'
            },
            {
                'label': 'West Dunbartonshire',
                'value': 'WDU'
            },
            {
                'label': 'West Lothian',
                'value': 'WLN'
            },
            {
                'label': 'West Sussex',
                'value': 'WSX'
            },
            {
                'label': 'Wiltshire',
                'value': 'WIL'
            },
            {
                'label': 'Windsor and Maidenhead',
                'value': 'WNM'
            },
            {
                'label': 'Wirral',
                'value': 'WRL'
            },
            {
                'label': 'Wokingham',
                'value': 'WOK'
            },
            {
                'label': 'Worcestershire',
                'value': 'WOR'
            },
            {
                'label': 'Wrexham County Borough',
                'value': 'WRX'
            }
        ]
    },
    {
        'label': 'United States',
        'iso3': 'USA',
        'value': 'US',
        'states': [
            {
                'label': 'Alabama',
                'value': 'AL'
            },
            {
                'label': 'Alaska',
                'value': 'AK'
            },
            {
                'label': 'American Samoa',
                'value': 'AS'
            },
            {
                'label': 'Arizona',
                'value': 'AZ'
            },
            {
                'label': 'Arkansas',
                'value': 'AR'
            },
            {
                'label': 'Baker Island',
                'value': 'UM-81'
            },
            {
                'label': 'California',
                'value': 'CA'
            },
            {
                'label': 'Colorado',
                'value': 'CO'
            },
            {
                'label': 'Connecticut',
                'value': 'CT'
            },
            {
                'label': 'Delaware',
                'value': 'DE'
            },
            {
                'label': 'District of Columbia',
                'value': 'DC'
            },
            {
                'label': 'Florida',
                'value': 'FL'
            },
            {
                'label': 'Georgia',
                'value': 'GA'
            },
            {
                'label': 'Guam',
                'value': 'GU'
            },
            {
                'label': 'Hawaii',
                'value': 'HI'
            },
            {
                'label': 'Howland Island',
                'value': 'UM-84'
            },
            {
                'label': 'Idaho',
                'value': 'ID'
            },
            {
                'label': 'Illinois',
                'value': 'IL'
            },
            {
                'label': 'Indiana',
                'value': 'IN'
            },
            {
                'label': 'Iowa',
                'value': 'IA'
            },
            {
                'label': 'Jarvis Island',
                'value': 'UM-86'
            },
            {
                'label': 'Johnston Atoll',
                'value': 'UM-67'
            },
            {
                'label': 'Kansas',
                'value': 'KS'
            },
            {
                'label': 'Kentucky',
                'value': 'KY'
            },
            {
                'label': 'Kingman Reef',
                'value': 'UM-89'
            },
            {
                'label': 'Louisiana',
                'value': 'LA'
            },
            {
                'label': 'Maine',
                'value': 'ME'
            },
            {
                'label': 'Maryland',
                'value': 'MD'
            },
            {
                'label': 'Massachusetts',
                'value': 'MA'
            },
            {
                'label': 'Michigan',
                'value': 'MI'
            },
            {
                'label': 'Midway Atoll',
                'value': 'UM-71'
            },
            {
                'label': 'Minnesota',
                'value': 'MN'
            },
            {
                'label': 'Mississippi',
                'value': 'MS'
            },
            {
                'label': 'Missouri',
                'value': 'MO'
            },
            {
                'label': 'Montana',
                'value': 'MT'
            },
            {
                'label': 'Navassa Island',
                'value': 'UM-76'
            },
            {
                'label': 'Nebraska',
                'value': 'NE'
            },
            {
                'label': 'Nevada',
                'value': 'NV'
            },
            {
                'label': 'New Hampshire',
                'value': 'NH'
            },
            {
                'label': 'New Jersey',
                'value': 'NJ'
            },
            {
                'label': 'New Mexico',
                'value': 'NM'
            },
            {
                'label': 'New York',
                'value': 'NY'
            },
            {
                'label': 'North Carolina',
                'value': 'NC'
            },
            {
                'label': 'North Dakota',
                'value': 'ND'
            },
            {
                'label': 'Northern Mariana Islands',
                'value': 'MP'
            },
            {
                'label': 'Ohio',
                'value': 'OH'
            },
            {
                'label': 'Oklahoma',
                'value': 'OK'
            },
            {
                'label': 'Oregon',
                'value': 'OR'
            },
            {
                'label': 'Palmyra Atoll',
                'value': 'UM-95'
            },
            {
                'label': 'Pennsylvania',
                'value': 'PA'
            },
            {
                'label': 'Puerto Rico',
                'value': 'PR'
            },
            {
                'label': 'Rhode Island',
                'value': 'RI'
            },
            {
                'label': 'South Carolina',
                'value': 'SC'
            },
            {
                'label': 'South Dakota',
                'value': 'SD'
            },
            {
                'label': 'Tennessee',
                'value': 'TN'
            },
            {
                'label': 'Texas',
                'value': 'TX'
            },
            {
                'label': 'United States Minor Outlying Islands',
                'value': 'UM'
            },
            {
                'label': 'United States Virgin Islands',
                'value': 'VI'
            },
            {
                'label': 'Utah',
                'value': 'UT'
            },
            {
                'label': 'Vermont',
                'value': 'VT'
            },
            {
                'label': 'Virginia',
                'value': 'VA'
            },
            {
                'label': 'Wake Island',
                'value': 'UM-79'
            },
            {
                'label': 'Washington',
                'value': 'WA'
            },
            {
                'label': 'West Virginia',
                'value': 'WV'
            },
            {
                'label': 'Wisconsin',
                'value': 'WI'
            },
            {
                'label': 'Wyoming',
                'value': 'WY'
            }
        ]
    },
    {
        'label': 'Uruguay',
        'iso3': 'URY',
        'value': 'UY',
        'states': [
            {
                'label': 'Artigas Department',
                'value': 'AR'
            },
            {
                'label': 'Canelones Department',
                'value': 'CA'
            },
            {
                'label': 'Cerro Largo Department',
                'value': 'CL'
            },
            {
                'label': 'Colonia Department',
                'value': 'CO'
            },
            {
                'label': 'Durazno Department',
                'value': 'DU'
            },
            {
                'label': 'Flores Department',
                'value': 'FS'
            },
            {
                'label': 'Florida Department',
                'value': 'FD'
            },
            {
                'label': 'Lavalleja Department',
                'value': 'LA'
            },
            {
                'label': 'Maldonado Department',
                'value': 'MA'
            },
            {
                'label': 'Montevideo Department',
                'value': 'MO'
            },
            {
                'label': 'Paysandú Department',
                'value': 'PA'
            },
            {
                'label': 'Río Negro Department',
                'value': 'RN'
            },
            {
                'label': 'Rivera Department',
                'value': 'RV'
            },
            {
                'label': 'Rocha Department',
                'value': 'RO'
            },
            {
                'label': 'Salto Department',
                'value': 'SA'
            },
            {
                'label': 'San José Department',
                'value': 'SJ'
            },
            {
                'label': 'Soriano Department',
                'value': 'SO'
            },
            {
                'label': 'Tacuarembó Department',
                'value': 'TA'
            },
            {
                'label': 'Treinta y Tres Department',
                'value': 'TT'
            }
        ]
    },
    {
        'label': 'Uzbekistan',
        'iso3': 'UZB',
        'value': 'UZ',
        'states': [
            {
                'label': 'Andijan Region',
                'value': 'AN'
            },
            {
                'label': 'Bukhara Region',
                'value': 'BU'
            },
            {
                'label': 'Fergana Region',
                'value': 'FA'
            },
            {
                'label': 'Jizzakh Region',
                'value': 'JI'
            },
            {
                'label': 'Karakalpakstan',
                'value': 'QR'
            },
            {
                'label': 'Namangan Region',
                'value': 'NG'
            },
            {
                'label': 'Navoiy Region',
                'value': 'NW'
            },
            {
                'label': 'Qashqadaryo Region',
                'value': 'QA'
            },
            {
                'label': 'Samarqand Region',
                'value': 'SA'
            },
            {
                'label': 'Sirdaryo Region',
                'value': 'SI'
            },
            {
                'label': 'Surxondaryo Region',
                'value': 'SU'
            },
            {
                'label': 'Tashkent',
                'value': 'TK'
            },
            {
                'label': 'Tashkent Region',
                'value': 'TO'
            },
            {
                'label': 'Xorazm Region',
                'value': 'XO'
            }
        ]
    },
    {
        'label': 'Vanuatu',
        'iso3': 'VUT',
        'value': 'VU',
        'states': [
            {
                'label': 'Malampa',
                'value': 'MAP'
            },
            {
                'label': 'Penama',
                'value': 'PAM'
            },
            {
                'label': 'Sanma',
                'value': 'SAM'
            },
            {
                'label': 'Shefa',
                'value': 'SEE'
            },
            {
                'label': 'Tafea',
                'value': 'TAE'
            },
            {
                'label': 'Torba',
                'value': 'TOB'
            }
        ]
    },
    {
        'label': 'Venezuela',
        'iso3': 'VEN',
        'value': 'VE',
        'states': [
            {
                'label': 'Amazonas',
                'value': 'Z'
            },
            {
                'label': 'Anzoátegui',
                'value': 'B'
            },
            {
                'label': 'Apure',
                'value': 'C'
            },
            {
                'label': 'Aragua',
                'value': 'D'
            },
            {
                'label': 'Barinas',
                'value': 'E'
            },
            {
                'label': 'Bolívar',
                'value': 'F'
            },
            {
                'label': 'Capital District',
                'value': 'A'
            },
            {
                'label': 'Carabobo',
                'value': 'G'
            },
            {
                'label': 'Cojedes',
                'value': 'H'
            },
            {
                'label': 'Delta Amacuro',
                'value': 'Y'
            },
            {
                'label': 'Falcón',
                'value': 'I'
            },
            {
                'label': 'Federal Dependencies of Venezuela',
                'value': 'W'
            },
            {
                'label': 'Guárico',
                'value': 'J'
            },
            {
                'label': 'Lara',
                'value': 'K'
            },
            {
                'label': 'Mérida',
                'value': 'L'
            },
            {
                'label': 'Miranda',
                'value': 'M'
            },
            {
                'label': 'Monagas',
                'value': 'N'
            },
            {
                'label': 'Nueva Esparta',
                'value': 'O'
            },
            {
                'label': 'Portuguesa',
                'value': 'P'
            },
            {
                'label': 'Sucre',
                'value': 'R'
            },
            {
                'label': 'Táchira',
                'value': 'S'
            },
            {
                'label': 'Trujillo',
                'value': 'T'
            },
            {
                'label': 'Vargas',
                'value': 'X'
            },
            {
                'label': 'Yaracuy',
                'value': 'U'
            },
            {
                'label': 'Zulia',
                'value': 'V'
            }
        ]
    },
    {
        'label': 'Vietnam',
        'iso3': 'VNM',
        'value': 'VN',
        'states': [
            {
                'label': 'An Giang',
                'value': '44'
            },
            {
                'label': 'Bà Rịa-Vũng Tàu',
                'value': '43'
            },
            {
                'label': 'Bắc Giang',
                'value': '54'
            },
            {
                'label': 'Bắc Kạn',
                'value': '53'
            },
            {
                'label': 'Bạc Liêu',
                'value': '55'
            },
            {
                'label': 'Bắc Ninh',
                'value': '56'
            },
            {
                'label': 'Bến Tre',
                'value': '50'
            },
            {
                'label': 'Bình Dương',
                'value': '57'
            },
            {
                'label': 'Bình Định',
                'value': '31'
            },
            {
                'label': 'Bình Phước',
                'value': '58'
            },
            {
                'label': 'Bình Thuận',
                'value': '40'
            },
            {
                'label': 'Cà Mau',
                'value': '59'
            },
            {
                'label': 'Cao Bằng',
                'value': '04'
            },
            {
                'label': 'Da Nang',
                'value': 'DN'
            },
            {
                'label': 'Đắk Lắk',
                'value': '33'
            },
            {
                'label': 'Đắk Nông',
                'value': '72'
            },
            {
                'label': 'Điện Biên',
                'value': '71'
            },
            {
                'label': 'Đồng Nai',
                'value': '39'
            },
            {
                'label': 'Đồng Tháp',
                'value': '45'
            },
            {
                'label': 'Gia Lai',
                'value': '30'
            },
            {
                'label': 'Hà Giang',
                'value': '03'
            },
            {
                'label': 'Hà Nam',
                'value': '63'
            },
            {
                'label': 'Hà Tây',
                'value': '15'
            },
            {
                'label': 'Hà Tĩnh',
                'value': '23'
            },
            {
                'label': 'Hải Dương',
                'value': '61'
            },
            {
                'label': 'Haiphong',
                'value': 'HP'
            },
            {
                'label': 'Hanoi',
                'value': 'HN'
            },
            {
                'label': 'Hậu Giang',
                'value': '73'
            },
            {
                'label': 'Ho Chi Minh City',
                'value': 'SG'
            },
            {
                'label': 'Hòa Bình',
                'value': '14'
            },
            {
                'label': 'Hưng Yên',
                'value': '66'
            },
            {
                'label': 'Khánh Hòa',
                'value': '34'
            },
            {
                'label': 'Kiên Giang',
                'value': '47'
            },
            {
                'label': 'Kon Tum',
                'value': '28'
            },
            {
                'label': 'Lai Châu',
                'value': '01'
            },
            {
                'label': 'Lâm Đồng',
                'value': '35'
            },
            {
                'label': 'Lạng Sơn',
                'value': '09'
            },
            {
                'label': 'Lào Cai',
                'value': '02'
            },
            {
                'label': 'Long An',
                'value': '41'
            },
            {
                'label': 'Nam Định',
                'value': '67'
            },
            {
                'label': 'Nghệ An',
                'value': '22'
            },
            {
                'label': 'Ninh Bình',
                'value': '18'
            },
            {
                'label': 'Ninh Thuận',
                'value': '36'
            },
            {
                'label': 'Phú Thọ',
                'value': '68'
            },
            {
                'label': 'Phú Yên',
                'value': '32'
            },
            {
                'label': 'Quảng Bình',
                'value': '24'
            },
            {
                'label': 'Quảng Nam',
                'value': '27'
            },
            {
                'label': 'Quảng Ngãi',
                'value': '29'
            },
            {
                'label': 'Quảng Ninh',
                'value': '13'
            },
            {
                'label': 'Quảng Trị',
                'value': '25'
            },
            {
                'label': 'Sóc Trăng',
                'value': '52'
            },
            {
                'label': 'Sơn La',
                'value': '05'
            },
            {
                'label': 'Tây Ninh',
                'value': '37'
            },
            {
                'label': 'Thái Bình',
                'value': '20'
            },
            {
                'label': 'Thái Nguyên',
                'value': '69'
            },
            {
                'label': 'Thanh Hóa',
                'value': '21'
            },
            {
                'label': 'Thừa Thiên-Huế',
                'value': '26'
            },
            {
                'label': 'Tiền Giang',
                'value': '46'
            },
            {
                'label': 'Trà Vinh',
                'value': '51'
            },
            {
                'label': 'Tuyên Quang',
                'value': '07'
            },
            {
                'label': 'Vĩnh Long',
                'value': '49'
            },
            {
                'label': 'Vĩnh Phúc',
                'value': '70'
            },
            {
                'label': 'Yên Bái',
                'value': '06'
            }
        ]
    },
    {
        'label': 'Yemen',
        'iso3': 'YEM',
        'value': 'YE',
        'states': [
            {
                'label': 'Adan Governorate',
                'value': 'AD'
            },
            {
                'label': 'Amran Governorate',
                'value': 'AM'
            },
            {
                'label': 'Abyan Governorate',
                'value': 'AB'
            },
            {
                'label': 'Al Bayda\' Governorate',
                'value': 'BA'
            },
            {
                'label': 'Al Hudaydah Governorate',
                'value': 'HU'
            },
            {
                'label': 'Al Jawf Governorate',
                'value': 'JA'
            },
            {
                'label': 'Al Mahrah Governorate',
                'value': 'MR'
            },
            {
                'label': 'Al Mahwit Governorate',
                'value': 'MW'
            },
            {
                'label': 'Dhamar Governorate',
                'value': 'DH'
            },
            {
                'label': 'Hadhramaut Governorate',
                'value': 'HD'
            },
            {
                'label': 'Hajjah Governorate',
                'value': 'HJ'
            },
            {
                'label': 'Ibb Governorate',
                'value': 'IB'
            },
            {
                'label': 'Lahij Governorate',
                'value': 'LA'
            },
            {
                'label': 'Ma\'rib Governorate',
                'value': 'MA'
            },
            {
                'label': 'Raymah Governorate',
                'value': 'RA'
            },
            {
                'label': 'Saada Governorate',
                'value': 'SD'
            },
            {
                'label': 'Sana\'a',
                'value': 'SA'
            },
            {
                'label': 'Sana\'a Governorate',
                'value': 'SN'
            },
            {
                'label': 'Shabwah Governorate',
                'value': 'SH'
            },
            {
                'label': 'Socotra Governorate',
                'value': 'SU'
            },
            {
                'label': 'Ta\'izz Governorate',
                'value': 'TA'
            }
        ]
    },
    {
        'label': 'Zambia',
        'iso3': 'ZMB',
        'value': 'ZM',
        'states': [
            {
                'label': 'Central Province',
                'value': '02'
            },
            {
                'label': 'Copperbelt Province',
                'value': '08'
            },
            {
                'label': 'Eastern Province',
                'value': '03'
            },
            {
                'label': 'Luapula Province',
                'value': '04'
            },
            {
                'label': 'Lusaka Province',
                'value': '09'
            },
            {
                'label': 'Muchinga Province',
                'value': '10'
            },
            {
                'label': 'Northern Province',
                'value': '05'
            },
            {
                'label': 'Northwestern Province',
                'value': '06'
            },
            {
                'label': 'Southern Province',
                'value': '07'
            },
            {
                'label': 'Western Province',
                'value': '01'
            }
        ]
    },
    {
        'label': 'Zimbabwe',
        'iso3': 'ZWE',
        'value': 'ZW',
        'states': [
            {
                'label': 'Bulawayo Province',
                'value': 'BU'
            },
            {
                'label': 'Harare Province',
                'value': 'HA'
            },
            {
                'label': 'Manicaland',
                'value': 'MA'
            },
            {
                'label': 'Mashonaland Central Province',
                'value': 'MC'
            },
            {
                'label': 'Mashonaland East Province',
                'value': 'ME'
            },
            {
                'label': 'Mashonaland West Province',
                'value': 'MW'
            },
            {
                'label': 'Masvingo Province',
                'value': 'MV'
            },
            {
                'label': 'Matabeleland North Province',
                'value': 'MN'
            },
            {
                'label': 'Matabeleland South Province',
                'value': 'MS'
            },
            {
                'label': 'Midlands Province',
                'value': 'MI'
            }
        ]
    }
]