import CommonService from './common.service';
import { generateQueryParams } from 'utils';
import { _Object } from 'utils/interfaces';

class InventoryService extends CommonService {

  async getinventory(params: _Object) {
    return await this.get(`inventory?${generateQueryParams(params)}`)
  }
  async getInventoryDetails(id: _Object | string) {
    return await this.get(`inventory/${id}`)
  }
  async createInventory(parmas: _Object) {
    return await this.post('inventory', parmas)
  }
  async updateInventory(id: string, params: _Object) {
    return await this.put(`inventory/${id}`, params)
  }
  async deleteInventory(id: _Object | string) {
    return await this.delete(`inventory/${id}`)
  }
}

export const inventoryService = new InventoryService();
