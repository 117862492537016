import React, { Suspense, useEffect, useState } from 'react'
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { routes } from 'utils'
import PublicRoutes from 'routes/public'
import PrivateRoutes from 'routes/private'
import PublicLayout from 'views/layouts/public'
import PrivateLayout from 'views/layouts/private'
import { SessionState } from 'utils/interfaces'
import { NoInternetConnection } from 'views/components'

const App = () => {
  const { isUserLoggedIn } = useSelector((state: { [key: string]: SessionState }) => state.session)

  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    window.addEventListener('online', () => setIsOnline(true));
    window.addEventListener('offline', () => setIsOnline(false));

    return () => {
      window.removeEventListener('online', () => setIsOnline(true));
      window.removeEventListener('offline', () => setIsOnline(false));
    };
  }, []);

  return (
    <>
      {
        isOnline ?
          <BrowserRouter>
            <Suspense>
              <Routes>
                <Route path="/app" element={isUserLoggedIn ? <PrivateLayout /> : <Navigate to={`${routes.login}`} />} >
                  <Route index element={<Navigate to={`${routes.sales}`} />} />

                  {PrivateRoutes.map((route, i) => {
                    return (
                      <Route
                        key={i}
                        path={route.path}
                        element={<route.component />}
                      />
                    )
                  })}
                  <Route path="/app/*" element={<Navigate to={`${routes.sales}`} />} />

                </Route>

                <Route path="/" element={<PublicLayout />}>
                  <Route index element={<Navigate to={`${routes.login}`} />} />

                  {PublicRoutes.map((route, i) => {
                    return (
                      <Route
                        key={i}
                        path={route.path}
                        element={<route.component />}
                      />
                    )
                  })}

                  <Route path="/*" element={<Navigate to={`${routes.login}`} />} />
                </Route>
              </Routes>
            </Suspense>
          </BrowserRouter>
          :
          <NoInternetConnection />
      }
    </>
  )
}
export default App

