import React from 'react';

import { FormType } from 'utils/interfaces';

const CheckBox = ({ name, values, options, onChange, className = '', label, error }: FormType) => {
  return (
    <div className={`form-group mb-3 ${className}`}>
      {label && <label className="label-form mb-1 d-block">{label}</label>}

      {
        options?.map((option: { [key: string]: string }, i: number) => {
          return (
            <div className="form-check form-check-inline" key={i}>
              <input
                type="checkbox"
                name={name}
                value={option.value}
                onChange={onChange}
                checked={values?.includes(option.value)}
                className={'form-check-input'}
                id={`${name}-${i}-${option.value}`}
              />
              <label htmlFor={`${name}-${i}`} className="form-check-label">{option.label}</label>
            </div>
          )
        })
      }
      
      {error && <span className="text-danger">{error}</span>}
    </div>
  )
}
export default CheckBox