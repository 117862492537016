import React, { useEffect, useState } from 'react'
import { useParams, useLocation, useNavigate } from 'react-router-dom';


import * as Yup from 'yup'
import { useFormik } from 'formik';

import { expenseService } from 'services';
import { routes } from 'utils';
import { _Object } from 'utils/interfaces';
import { InputField, TextArea, Button, LinkButton, SelectField } from 'views/components';

const AddCategory = () => {
  const { id } = useParams()
  const location = useLocation()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [isEditMode, setEditMode] = useState(false)
  const [expense, setExpense] = useState<_Object>([])
  const [initialData, setInitialData] = useState<_Object>({})

  useEffect(() => {
    setEditMode(false)
    setInitialData({
      parent_id: '',
      name: '',
      description: ''
    })
    if (id) {
      setEditMode(true)
      expenseService.getExpenseCategoroyDetails(id).then((data: _Object) => {
        setInitialData(data)
      })
    }
    expenseService.getExpenses().then((data: _Object) => {
      setExpense(data.items)
    })
  }, [location])

  const formik = useFormik({
    initialValues: initialData,
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string().label('Name').required(),
      parent_id: Yup.string().label('Parent id').required(),
      description: Yup.string().label('Descriptions').required(),
    }),
    onSubmit: (values) => {
      setLoading(true)
      if (id) {
        delete values.id,
          delete values.created_by
        delete values.parent
        delete values.created_at
        delete values.category
        delete values.updated_at
        expenseService.updateExpenseCategories(id, values).then((data: _Object) => {
          data.error === false && navigate(`${routes.expenseCategories}`)
        })
      } else {
        expenseService.createExpenseCategories(values).then((data: _Object) => {
          data.error === false && navigate(`${routes.expenseCategories}`)
        })
      }
      setLoading(false)
    },
  })

  return (
    <>
      <header className="my-2">
        <h2>{isEditMode ? 'Update expense category' : 'Create expense category'}</h2>
        <h4>{isEditMode ? 'Update expense category' : 'Create new expense category'}</h4>
      </header>

      <form className="row filters" onSubmit={formik.handleSubmit}>

        <InputField
          name="name"
          className="col-6"
          label="Name"
          required={true}
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && formik.errors.name}
        />

        <SelectField
          name="parent_id"
          className="col-6"
          label="Parent"
          // placeholder={isEditMode && formik?.values?.parent?.name}
          required={true}
          options={expense}
          value={expense?.filter((obj: _Object) => obj.id === formik.values.parent_id)}
          onChange={(e: _Object) => {
            formik.setFieldValue('parent_id', e.id)
          }}
          getOptionLabel={(option: _Object) => option.name}
          getOptionValue={(option: _Object) => option.id || formik?.values?.parent?.name}
          error={formik.touched.parent_id && formik.errors.parent_id}
          isClearable
        />

        <TextArea
          name="description"
          className="col-12"
          label="Description"
          placeholder="Enter description"
          value={formik.values.description}
          onChange={formik.handleChange}
          error={formik.touched.description && formik.errors.description}
        />

        <footer className="row my-2">
          <div className="col-12 d-flex gap-3">
            <Button
              type="submit"
              name={isEditMode ? 'Update' : 'Submit'}
              className="primary"
              disabled={loading} />

            <LinkButton
              path={routes.expenseCategories}
              label="Cancel"
              className="link text-dark" />
          </div>
        </footer>
      </form>
    </>
  )
}
export default AddCategory;