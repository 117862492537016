import CommonService from './common.service';
import { generateQueryParams } from 'utils';

class SupplierService extends CommonService {

  async getSuppliers(params: any) {
    return await this.get(`suppliers?${generateQueryParams(params)}`)
  }
  async getSupplierDetails(id: string) {
    return await this.get(`suppliers/${id}`)
  }
  async createSupplier(parmas: { [key: string]: boolean | string }) {
    return await this.post('suppliers', parmas)
  }
  async updateSupplier(id: string, params: { [key: string]: boolean | string }) {
    return await this.put(`suppliers/${id}`, params)
  }
  async deleteSupplier(id: string) {
    return await this.delete(`suppliers/${id}`)
  }
}
export const supplierService = new SupplierService();
