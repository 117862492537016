export const routes = {
  sales: '/app/sales',
  createCustomer: '/app/sales/new-customer',
  orders: '/app/orders',

  taxRates: '/app/tax-rates',

  customers: '/app/customers',
  createCustomers: '/app/customer/create',

  users: '/app/users',
  createUser: '/app/user/create',

  products: '/app/products',
  createProduct: '/app/product/create',
  attributes: '/app/products/attributes',
  attributesValues: '/app/products/attributes',

  categories: '/app/products/categories',
  createCategory: '/app/products/categories/create',

  expense: '/app/expense',
  createExpense: '/app/create',
  expenseCategories: '/app/expense/categories',
  createExpenseCategory: '/app/expense/categories/create',

  tasks: '/app/tasks',
  createTask: '/app/tasks/create',
  taskCategories: '/app/tasks/categories',

  tags: '/app/products/tags',
  createTag: '/app/products/tags/create',

  brands: '/app/products/brands',
  createBrand: '/app/products/brands/create',

  suppilers: '/app/products/suppliers',
  createSuppiler: '/app/products/suppliers/create',

  inventory: '/app/inventory',
  createInventory: '/app/inventory/create',

  locations: '/app/locations',
  createLocation: '/app/locations/create',

  more: '/app/more',
  analytics: '/app/analytics',
  support: '/app/support',
  settings: '/app/settings',

  login: '/login',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password'
}

